const removeEmptyFieldsFromObject = (obj: Object) => {
  const result = { ...obj };

  Object.keys(result).forEach((key) => {
    if (result[key] == null) {
      delete result[key];
    }
  });

  return result;
};

export default removeEmptyFieldsFromObject;
