import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
// import startOfMonth from 'date-fns/startOfMonth';
// import endOfMonth from 'date-fns/endOfMonth';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
// import addMonths from 'date-fns/addMonths';
import subDays from 'date-fns/subDays';
import subWeeks from 'date-fns/subWeeks';
// import subMonths from 'date-fns/subMonths';
import { ScheduleView } from 'src/types/schedule-dialog';
import { JobEvent } from 'src/types/job';

interface ScheduleDialogState {
  date: Date;
  startDate: Date;
  endDate: Date;
  view: ScheduleView;
  jobEvents: JobEvent[];
  selectedTechnicianId: number | null;
}

const initialState: ScheduleDialogState = {
  date: new Date(),
  startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
  view: ScheduleView.week,
  jobEvents: [],
  selectedTechnicianId: null,
};

// TODO: return new date
function getStartAndEndDates(view: ScheduleView, date: Date): [Date, Date] {
  let result: [Date, Date];
  switch (view) {
    case ScheduleView.day:
      result = [startOfDay(date), endOfDay(date)];
      break;
    case ScheduleView.week:
      result = [startOfWeek(date, { weekStartsOn: 1 }), endOfWeek(date, { weekStartsOn: 1 })];
      break;
    // case View.month:
    //   result = [startOfMonth(date), endOfMonth(date)];
    //   break;
    default:
  }

  return result;
}

const slice = createSlice({
  name: 'scheduleDialog',
  initialState,
  reducers: {
    setView(state: ScheduleDialogState, action: PayloadAction<{ view: ScheduleView }>): void {
      const { view } = action.payload;
      state.view = view;
      [state.startDate, state.endDate] = getStartAndEndDates(view, state.date);
    },
    next(state: ScheduleDialogState) {
      let nextDate: Date;
      switch (state.view) {
        case ScheduleView.day:
          nextDate = addDays(state.startDate, 1);
          state.date = nextDate;
          state.startDate = startOfDay(nextDate);
          state.endDate = endOfDay(nextDate);
          break;
        case ScheduleView.week:
          nextDate = addWeeks(state.startDate, 1);
          state.date = nextDate;
          state.startDate = startOfWeek(nextDate, { weekStartsOn: 1 });
          state.endDate = endOfWeek(nextDate, { weekStartsOn: 1 });
          break;
        // case ScheduleView.month:
        //   nextDate = addMonths(state.startDate, 1);
        //   state.date = nextDate;
        //   state.startDate = startOfMonth(nextDate);
        //   state.endDate = endOfMonth(nextDate);
        //   break;
      }
    },
    prev(state: ScheduleDialogState) {
      let nextDate: Date;
      switch (state.view) {
        case ScheduleView.day:
          nextDate = subDays(state.startDate, 1);
          state.date = nextDate;
          state.startDate = startOfDay(nextDate);
          state.endDate = endOfDay(nextDate);
          return;
        case ScheduleView.week:
          nextDate = subWeeks(state.startDate, 1);
          state.date = nextDate;
          state.startDate = startOfWeek(nextDate, { weekStartsOn: 1 });
          state.endDate = endOfWeek(nextDate, { weekStartsOn: 1 });
          return;
        // case ScheduleView.month:
        //   nextDate = subMonths(state.startDate, 1);
        //   state.date = nextDate;
        //   state.startDate = startOfMonth(nextDate);
        //   state.endDate = endOfMonth(nextDate);
        //   return;
      }
    },
    today(state: ScheduleDialogState) {
      const now = new Date();
      [state.startDate, state.endDate] = getStartAndEndDates(state.view, now);
      state.date = now;
    },
    setDayView(state: ScheduleDialogState, action: PayloadAction<{ day: Date }>) {
      const { day } = action.payload;
      state.view = ScheduleView.day;
      [state.startDate, state.endDate] = getStartAndEndDates(ScheduleView.day, action.payload.day);
      state.date = day;
    },
    setJobEvents(
      state: ScheduleDialogState,
      action: PayloadAction<{ jobEvents: JobEvent[] }>
    ): void {
      const { jobEvents } = action.payload;
      state.jobEvents = jobEvents;
    },
    setSelectedTechnician(
      state: ScheduleDialogState,
      action: PayloadAction<{ technicianId: number | null }>
    ): void {
      const { technicianId } = action.payload;
      state.selectedTechnicianId = technicianId;
    },
  },
});

export const { reducer } = slice;
export const { setView, next, prev, today, setDayView, setJobEvents, setSelectedTechnician } =
  slice.actions;
