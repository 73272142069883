export const ALL_CAT_FILTER = 'all';

export interface Product {
  id?: number;
  category?: Category;
  product_category_id: number;
  name: string;
  quantity: number;
  cost: number;
  unit_price?: number;
  gst_cost?: number;
  sku: string | null;
  volume: number;
  vend_product?: VendProduct | null;
  vend_product_id?: string | null;
  xero_item?: XeroItem | null;
  xero_item_id?: string | null;
  metric?: Metric | null;
  product_metric_id: number | null;
  brand?: Brand | null;
  accounting_sync_message?: string | null;
  accounting_sync_status?: string | null;
  barcode_text: string;
  is_gst_price: boolean;
  organisation_id?: number;
  product_brand_id: null | number;
  visibility: boolean;
  created_at?: string;
  updated_at?: string;
  submit?: null | string;
}

export interface VendProduct {
  brand: string;
  description: string;
  handle: string;
  id: number;
  image_thumbnail_url: string;
  image_url: string;
  name: string;
  organisation_id: number;
  price_excluding_tax: number;
  price_including_tax: number;
  product_id: number;
  product_metric_id: number | null;
  product_type_id: null;
  quantity: number;
  type: string;
  variant_name: string;
  vend_product_id: string;
  sku: string;
}

export interface XeroItem {
  account_code: string;
  barcode_text: string;
  code: string;
  cost: number;
  id: number;
  inventory_asset_account_code: string;
  is_gst_price: number;
  name: string;
  organisation_id: number;
  product_category_id: number;
  product_id: number;
  product_metric_id: number;
  quantity: number;
  sku: string;
  tax_type: string;
  visibility: boolean;
  volume: number;
  xero_item_id: string;
}

export interface Metric {
  id?: number;
  name: string;
  organisation_id: number;
  short_name: string;
}

export interface Category {
  id: number | string;
  name: string;
}

export interface Brand {
  id?: number;
  name: string;
}
