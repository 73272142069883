export const paths = {
  index: '/',
  overview: '/overview',
  operations: '/operations',
  authentication: {
    login: '/authentication/login',
    passwordRecovery: '/authentication/password-recovery',
    passwordReset: '/authentication/password-reset',
    register: '/authentication/register',
    verifyCode: '/authentication/verify-code',
  },
  dashboard: {
    index: '/dashboard',
    analytics: '/dashboard/analytics',
    finance: '/dashboard/finance',
  },
  lab: {
    index: '/lab',
    testHistory: '/lab/test-history',
    waterTest: {
      index: '/lab/water-test',
      details: '/lab/water-test/:id',
    },
  },
  job: {
    index: '/job',
    new: '/job/new',
    edit: '/job/:id',
    createInvoice: '/job/:id/invoice',
    toInvoiceList: '/job/to-invoice-list',
    calendar: {
      index: '/job/calendar',
    },
    recurrence: {
      index: '/job/recurrence',
      edit: '/job/recurrence/:id',
    },
  },
  quote: {
    index: '/quote',
    openList: '/quote/open-list',
    new: '/quote/new',
    detail: '/quote/detail/:id',
    edit: '/quote/edit/:id',
    previewSend: '/quote/preview-send/:id',
    archivedList: '/quote/archived-list',
  },
  invoice: {
    index: '/invoice',
    list: '/invoice/list',
    details: '/invoice/:id',
    resend: '/invoice/:id/resend',
    batch: {
      index: '/invoice/batch',
      list: '/invoice/batch/list',
      details: '/invoice/batch/:id',
      resend: '/invoice/batch/:id/resend',
      preview: '/invoice/batch/preview',
      queue: '/invoice/batch/queue',
    },
  },
  contact: {
    index: '/contact',
    new: '/contact/new',
    import: '/contact/import',
    detail: '/contact/:id/detail',
    jobs: '/contact/:id/jobs',
    quotes: '/contact/:id/quotes',
    invoices: '/contact/:id/invoices',
  },
  pool: {
    index: '/pool',
    new: '/pool/new',
    import: '/pool/import',
    detail: '/pool/:id/detail',
    jobs: '/pool/:id/jobs',
    quotes: '/pool/:id/quotes',
    invoices: '/pool/:id/invoices',
    waterTests: '/pool/:id/water-tests',
    jobPhotos: '/pool/:id/job-photos',
    equipment: {
      new: '/pool/:id/equipment/new',
      edit: '/pool/:id/equipment/:equipmentId',
    },
  },
  product: {
    index: '/product',
    new: '/product/new',
    import: '/product/import',
    edit: '/product/:id',
  },
  campaign: {
    index: '/campaign',
    new: '/campaign/new',
    edit: '/campaign/:id',
  },
  sms: {
    index: '/sms',
    configure: '/sms/configure',
    sentMessages: '/sms/sent-messages',
  },
  setup: {
    index: '/setup',
    myBusiness: {
      index: '/setup/organisation',
      general: '/setup/organisation/general',
      brand: '/setup/organisation/brand',
    },
    planAndPayment: {
      index: '/setup/plan-and-payment',
      overview: '/setup/plan-and-payment/overview',
      transactions: '/setup/plan-and-payment/transactions',
    },
    team: {
      index: '/setup/team',
      userList: '/setup/team/users',
      editUser: '/setup/team/user/:id',
      newUser: '/setup/team/user/new',
      roleList: '/setup/team/roles',
      editRole: '/setup/team/role/:id',
      newRole: '/setup/team/role/new',
    },
    quotes: {
      index: '/setup/quote',
      email: '/setup/quote/email-settings',
      settings: '/setup/quote/settings',
    },
    jobs: {
      index: '/setup/job',
      email: '/setup/job/email-settings',
      settings: '/setup/job/settings',
      smsSettings: '/setup/job/sms-settings',
      jobSheetSettings: '/setup/job/job-sheet-settings',
      invoiceSettings: '/setup/job/invoice-settings',
      colors: '/setup/job/color',
      tradingTerm: '/setup/job/trading-term',
      jobTemplate: {
        index: '/setup/job/job-template',
        new: '/setup/job/job-template/new',
        edit: '/setup/job/job-template/:id',
      },
    },
    batchBilling: {
      index: '/setup/batch-billing',
      email: '/setup/batch-billing/email-settings',
      settings: '/setup/batch-billing/settings',
    },
    lab: {
      index: '/setup/lab',
      email: '/setup/lab/email-settings',
      settings: '/setup/lab/settings',
      smsSettings: '/setup/lab/sms-settings',
    },
    integration: {
      index: '/setup/integration',
      stripe: '/setup/integration/stripe',
      xero: '/setup/integration/xero',
      vend: '/setup/integration/vend',
    },
    waterTesting: {
      index: '/setup/water-testing',
      chemicalTest: {
        index: '/setup/water-testing/chemical-test',
        edit: '/setup/water-testing/chemical-test/edit/:id',
        list: '/setup/water-testing/chemical-test/list',
        recommendationsOrder: '/setup/water-testing/chemical-test/recommendations-order',
      },
      observationTest: {
        index: '/setup/water-testing/observation-test',
        new: '/setup/water-testing/observation-test/new',
        edit: '/setup/water-testing/observation-test/edit/:id',
        list: '/setup/water-testing/observation-test/list',
        recommendationsOrder: '/setup/water-testing/observation-test/recommendations-order',
        categoryList: '/setup/water-testing/observation-test/category-list',
      },
    },
    dosageGroup: {
      index: '/setup/dosage-group',
      chemicalGroup: {
        index: '/setup/dosage-group/chemical-group',
        new: '/setup/dosage-group/chemical-group/new',
        edit: '/setup/dosage-group/chemical-group/:id',
        list: '/setup/dosage-group/chemical-group/list',
      },
      observationGroup: {
        index: '/setup/dosage-group/observation-group',
        new: '/setup/dosage-group/observation-group/new',
        edit: '/setup/dosage-group/observation-group/:id',
        list: '/setup/dosage-group/observation-group/list',
      },
    },
    dataCleaning: {
      index: '/setup/data-cleaning',
      contact: {
        index: '/setup/data-cleaning/contact',
        deduplicate: '/setup/data-cleaning/contact/deduplicate',
      },
      pool: {
        index: '/setup/data-cleaning/pool',
        deduplicate: '/setup/data-cleaning/pool/deduplicate',
      },
    },
    equipment: {
      index: '/setup/equipment',
      type: {
        index: '/setup/equipment/type',
        new: '/setup/equipment/type/new',
        edit: '/setup/equipment/type/:id',
      },
      template: {
        index: '/setup/equipment/template',
        new: '/setup/equipment/template/new',
        edit: '/setup/equipment/template/:id',
      },
    },
    customFields: {
      index: '/setup/custom-fields',
      contactRelationships: '/setup/custom-fields/contact-relationships',
      contactCategories: '/setup/custom-fields/contact-categories',
      surfaces: '/setup/custom-fields/surfaces',
      sanitisers: '/setup/custom-fields/sanitisers',
      customExceptions: '/setup/custom-fields/custom-exceptions',
      brands: '/setup/custom-fields/brands',
      recurrencePeriods: '/setup/custom-fields/recurrence-periods',
    },
    tags: {
      index: '/setup/tag',
    },
  },
  401: '/401',
  404: '/404',
  500: '/500',
};
