import { TFunction } from 'i18next';
import { SmsTypeOption } from 'src/types/sms';

export const SMS_STATUS_DELIVERED = 'delivered';
export const SMS_STATUS_UNDELIVERED = 'undelivered';
export const SMS_STATUS_QUEUED = 'queued';
export const SMS_STATUS_SENT = 'sent';
export const SMS_STATUS_ERROR = 'error';
export const SMS_STATUS_LOW_BALANCE = 'low_balance';
export const SMS_STATUS_FAILED = 'failed';

export const SUCCESS_STATUSES = [SMS_STATUS_DELIVERED, SMS_STATUS_SENT];
export const NEUTRAL_STATUSES = [SMS_STATUS_QUEUED];
export const ERROR_STATUSES = [
  SMS_STATUS_UNDELIVERED,
  SMS_STATUS_LOW_BALANCE,
  SMS_STATUS_ERROR,
  SMS_STATUS_FAILED,
];

export const TECH_ON_THE_WAY_TYPE = 'tech_on_the_way';
export const JOB_COMPLETE_TYPE = 'job_complete';
export const BOOKING_REMINDER_TYPE = 'booking_reminder';
export const BOOKING_TYPE = 'booking_changed';
export const WATER_TEST_RESULT_TYPE = 'water_test_result';

export const getSmsTypes = (t: TFunction): SmsTypeOption[] => [
  {
    label: t('Tech on the way'),
    value: TECH_ON_THE_WAY_TYPE,
  },
  {
    label: t('Job complete'),
    value: JOB_COMPLETE_TYPE,
  },
  {
    label: t('Booking reminder'),
    value: BOOKING_REMINDER_TYPE,
  },
  {
    label: t('Booking changed'),
    value: BOOKING_TYPE,
  },
  {
    label: t('Water test result'),
    value: WATER_TEST_RESULT_TYPE,
  },
];

export const SMS_MERGE_TAGS = {
  ORG_NAME: '{org_name}',
  ORG_ADDRESS: '{org_address}',
  ORG_EMAIL: '{org_email}',
  ORG_PHONE: '{org_phone}',
  ORG_WEBSITE: '{org_website}',
  CONTACT_FIRST_NAME: '{contact_first_name}',
  CONTACT_LAST_NAME: '{contact_last_name}',
  CONTACT_ADDRESS: '{contact_address}',
  TEST_RESULTS_URL: '{test_results_url}',
  SYSTEM_ADDRESS: '{system_address}',
  TEST_DATE: '{test_date}',
  NEXT_TEST_DATE: '{next_test_date}',
};

export const MIN_HOURS_BEFORE_SCHEDULE_TIME = 1;
export const MAX_HOURS_BEFORE_SCHEDULE_TIME = 1000;

export const SMS_COLUMN_ID = 'id';
export const SMS_COLUMN_JOB_ID = 'job_id';
export const SMS_COLUMN_STATUS = 'status';
export const SMS_COLUMN_CONTACT = 'contact';
export const SMS_COLUMN_TYPE = 'type';
export const SMS_COLUMN_PHONE_NUMBER = 'phone_number';
export const SMS_COLUMN_CREATED_AT = 'created_at';
export const SMS_COLUMN_SEND_DATE = 'send_date';
