/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import type { AppThunk } from '../store';
import type { Job, Task, JobDetailScrollTo, Invoice } from '../types/job';
import type { Pool } from '../types/pool';
import type { Contact } from '../types/contact';
import type { JobTemplate } from '../types/job-template';
import type { User } from '../types/user';
import type { Product } from '../types/product';
import { transformProductToInvoice } from '../utils/job';
import type { Color } from '../types/organisation';
import { UNSCHEDULED } from '../constants/job';

interface JobDetailState {
  isLoaded: boolean;
  jobId?: number;
  user?: User;
  job?: Job;
  jobTemplate?: JobTemplate;
  pool?: Pool;
  contact?: Contact;
  org_color?: Color;
  scrollPosition?: JobDetailScrollTo | null;
  tasks: Task[];
  invoices: Invoice[];
}

const initialState: JobDetailState = {
  isLoaded: false,
  jobId: null,
  job: null,
  user: null,
  jobTemplate: null,
  pool: null,
  contact: null,
  org_color: null,
  scrollPosition: null,
  tasks: [],
  invoices: [],
};

const slice = createSlice({
  name: 'jobDetail',
  initialState,
  reducers: {
    reset(state: JobDetailState): void {
      state.isLoaded = false;
      state.scrollPosition = null;
      state.job = null;
      state.user = null;
      state.jobTemplate = null;
      state.pool = null;
      state.contact = null;
      state.org_color = null;
      state.tasks = [];
      state.invoices = [];
    },
    getJob(state: JobDetailState, action: PayloadAction<{ job: Job | null }>): void {
      const { job } = action.payload;

      if (job) {
        state.job = job;
        state.user = job.user;
        state.jobTemplate = job.job_template;
        state.pool = job.pool;
        state.contact = job.contact;
        state.org_color = job.org_color;
        state.tasks = job.tasks;
        state.invoices = job.invoices;
      } else {
        state.jobId = null;
        state.job = {
          job_template_id: null,
          job_status_id: UNSCHEDULED,
          contact_id: null,
          pool_id: null,
          user_id: null,
          colorcode_id: null,
          follow_up: false,
          job_notification_phones: [],
          recurrence: null,
        };
        state.user = null;
        state.jobTemplate = null;
        state.pool = null;
        state.contact = null;
        state.org_color = null;
        state.tasks = [];
        state.invoices = [];
      }
      state.isLoaded = true;
    },
    scrollTo(state: JobDetailState, action: PayloadAction<{ view: JobDetailScrollTo }>): void {
      const { view } = action.payload;
      state.scrollPosition = view;
    },
    updateUser(state: JobDetailState, action: PayloadAction<{ user: User }>): void {
      const { user } = action.payload;
      state.user = user;
      state.job.user_id = user.id;
    },
    updateContact(state: JobDetailState, action: PayloadAction<{ contact: Contact }>): void {
      const { contact } = action.payload;
      state.contact = contact;
      state.job.contact_id = contact.id;
    },
    updatePool(state: JobDetailState, action: PayloadAction<{ pool: Pool }>): void {
      const { pool } = action.payload;
      state.pool = pool;
      state.job.pool_id = pool.id;
    },
    updateJobTemplate(
      state: JobDetailState,
      action: PayloadAction<{ jobTemplate: JobTemplate }>
    ): void {
      const { jobTemplate } = action.payload;
      state.jobTemplate = { ...jobTemplate };
      state.job.job_template_id = jobTemplate.id;
      state.tasks = state.jobTemplate.tasks.map((task, index) => {
        return {
          ...task,
          id: index + 1,
          status_id: 1,
        };
      });
      state.invoices = state.jobTemplate.products.map((product: Product, index) => {
        const invoice = transformProductToInvoice(product);
        invoice.id = index + 1;
        return invoice;
      });
    },
    updateColor(state: JobDetailState, action: PayloadAction<{ color: Color | null }>): void {
      const { color } = action.payload;
      state.org_color = color;
      state.job.colorcode_id = color ? color.id : null;
    },
    addTask(state: JobDetailState, action: PayloadAction<{ task: Task }>): void {
      const { task } = action.payload;

      task.id = state.tasks.length + 1;

      state.tasks.push(task);
    },
    updateTask(state: JobDetailState, action: PayloadAction<{ task: Task }>): void {
      const { task } = action.payload;

      state.tasks = state.tasks.map((_task) => {
        if (_task.id === task.id) {
          return task;
        }

        return _task;
      });
    },
    deleteTask(state: JobDetailState, action: PayloadAction<{ taskId: number | string }>): void {
      const { taskId } = action.payload;

      state.tasks = state.tasks.filter((task) => task.id !== taskId);
    },
    reorderTasks(state: JobDetailState, action: PayloadAction<{ tasks: Task[] }>): void {
      const { tasks } = action.payload;

      state.tasks = tasks;
    },
    addInvoice(state: JobDetailState, action: PayloadAction<{ invoice: Invoice }>): void {
      const { invoice } = action.payload;
      let existingInvoice;

      if (invoice.product_id) {
        existingInvoice = state.invoices.find(
          (_invoice) => _invoice.product_id && _invoice.product_id === invoice.product_id
        );
      }

      if (!existingInvoice) {
        invoice.id = state.invoices.length + 1;
        state.invoices.push(invoice);
      }
    },
    updateInvoice(state: JobDetailState, action: PayloadAction<{ invoice: Invoice }>): void {
      const { invoice } = action.payload;

      state.invoices = state.invoices.map((_invoice) => {
        if (_invoice.id === invoice.id) {
          return invoice;
        }

        return _invoice;
      });
    },
    deleteInvoice(state: JobDetailState, action: PayloadAction<{ invoiceId: number }>): void {
      const { invoiceId } = action.payload;

      state.invoices = state.invoices.filter((invoice) => invoice.id !== invoiceId);
    },
  },
});

export const { reducer } = slice;

export const reset =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.reset());
  };

export const getJob =
  (organisationId?: number, jobId?: number): AppThunk =>
  async (dispatch): Promise<void> => {
    if (organisationId && jobId) {
      const response = await axios.get(`v1/organisations/${organisationId}/jobs/${jobId}`);

      dispatch(slice.actions.getJob({ job: response.data }));
    } else {
      dispatch(slice.actions.getJob({ job: null }));
    }
  };

export const updateContact =
  (contact: Contact): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateContact({ contact }));
  };

export const updatePool =
  (pool: Pool): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updatePool({ pool }));
  };

export const updateUser =
  (user: User): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateUser({ user }));
  };

export const updateJobTemplate =
  (jobTemplate: JobTemplate): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateJobTemplate({ jobTemplate }));
  };

export const addTask =
  (task: Task): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.addTask({ task }));
  };

export const updateTask =
  (task: Task): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.updateTask({ task }));
  };

export const deleteTask =
  (taskId: number | string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.deleteTask({ taskId }));
  };

export const reorderTasks =
  (tasks: Task[]): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.reorderTasks({ tasks }));
  };

export const scrollTo =
  (view: JobDetailScrollTo | null): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.scrollTo({ view }));
  };

export const addInvoice =
  (invoice: Invoice): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.addInvoice({ invoice }));
  };

export const updateInvoice =
  (invoice: Invoice): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.updateInvoice({ invoice }));
  };

export const deleteInvoice =
  (invoiceId: number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.deleteInvoice({ invoiceId }));
  };

export const updateColor =
  (color: Color | null): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.updateColor({ color }));
  };

export default slice;
