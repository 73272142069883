import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithInterceptor } from './base-query';

export const rootSplitApi = createApi({
  reducerPath: 'rootApi',
  baseQuery: baseQueryWithInterceptor,
  endpoints: () => ({}),
  tagTypes: [
    'Job',
    'UnscheduledJob',
    'Color',

    'Recurrence',
    'RecurrencePeriod',

    'Quote',
    'ArchivedQuotes',
    'QuoteSettings',

    'Tag',
    'Tags',

    'Pool',
    'PoolQuotes',
    'PoolTestHistory',
    'PoolTestResults',

    'Contact',
    'ContactQuotes',

    'ContactRelationship',
    'ContactCategory',

    'LabJob',
    'LabJobs',

    'NotificationSettings',
    'MessagingSettings',
    'SmsTiers',
    'SmsList',
    'Product',
    'Products',
    'Brand',
    'Brands',
    'Category',
    'Categories',
    'Metrics',

    'User',
    'Users',
    'Technicians',
    'Role',
    'Roles',
    'Permissions',

    'JobTemplate',
  ],
});
