import { createSlice } from '@reduxjs/toolkit';
import { authApi } from 'src/api/auth';
import { ErrorResponseOld } from 'src/api/model/base.model';
import { subscriptionApi } from 'src/api/subscription';
import { RootState } from 'src/store';
import { Permission, User } from 'src/types/user';

interface AuthState {
  user: null | User;
  permissions: [] | Permission[];
  token: string | null;
  tokenExpiresAt: string | null;
  lastLoginAt: string | null;
  isInitialised: boolean;
  isAuthenticated: boolean;
  loginError: string | null;
  subscriptionPlan: string;
}

const initialState: AuthState = {
  user: null,
  permissions: [],
  token: null,
  tokenExpiresAt: null,
  lastLoginAt: null,
  isInitialised: false,
  isAuthenticated: false,
  loginError: null,
  subscriptionPlan: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initialise(state: AuthState, action): void {
      state.isInitialised = action.payload;
    },
    // need to clean up the storage
    logout: () => {
      return {
        ...initialState,
        isInitialised: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
        state.loginError = null;
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        const { user, token, last_login, expires_at } = action.payload;
        state.isAuthenticated = true;
        state.user = user;
        state.permissions = user.permissions;
        state.token = token;
        state.lastLoginAt = last_login;
        state.tokenExpiresAt = expires_at;
      })
      .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
        const { data } = action.payload as ErrorResponseOld;
        state.loginError = data?.error;
      })
      .addMatcher(authApi.endpoints.refreshToken.matchPending, (state, action) => {
        state.loginError = null;
      })
      .addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, action) => {
        const { user, token, expires_at } = action.payload;
        state.isAuthenticated = true;
        state.user = user;
        state.token = token;
        state.tokenExpiresAt = expires_at;
      })
      .addMatcher(authApi.endpoints.refreshToken.matchRejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.permissions = [];
        state.token = null;
        state.tokenExpiresAt = null;
      })
      .addMatcher(authApi.endpoints.logout.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
        return {
          ...initialState,
          isInitialised: true,
        };
      })
      .addMatcher(authApi.endpoints.logout.matchRejected, (state, action) => {
        return {
          ...initialState,
          isInitialised: true,
        };
      })
      .addMatcher(subscriptionApi.endpoints.getSubscription.matchFulfilled, (state, action) => {
        state.subscriptionPlan = action.payload.plan_id;
      })
      .addMatcher(subscriptionApi.endpoints.updateSubscription.matchFulfilled, (state, action) => {
        state.subscriptionPlan = action.payload.plan_id;
      })
      .addMatcher(authApi.endpoints.getAuthenticatedUser.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(authApi.endpoints.getAuthenticatedUser.matchFulfilled, (state, action) => {
        state.user = action.payload;
        state.permissions = action.payload.permissions;
      })
      .addMatcher(authApi.endpoints.getAuthenticatedUser.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export default slice.reducer;

export const { logout, initialise } = slice.actions;

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;
export const selectSubscriptionPlan = (state: RootState) => state.auth.subscriptionPlan;
export const selectIsInitialised = (state: RootState) => state.auth.isInitialised;
export const selectPermissions = (state: RootState) => state.auth.permissions;
