import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { logout } from 'src/slices/auth';
import { RootState } from 'src/store';
import { apiConfig } from '../config';

export const getBaseUrl = () => {
  let baseUrl = apiConfig.baseUrl;
  if (baseUrl === 'undefined' && typeof window !== 'undefined') {
    const { location } = window;
    const urlParts = location.hostname.split('.');
    const apiUrl = urlParts.slice(-3).join('.');

    baseUrl = `${location.protocol}//api.${apiUrl}/api`;
  }
  return baseUrl;
};

export const rawBaseQuery = fetchBaseQuery({
  baseUrl: getBaseUrl(),
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await rawBaseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // TODO: add proper handler of unauthenticated access or expired token
    api.dispatch(logout());
    // try to get a new token
    // const refreshResult = await rawBaseQuery('/refreshToken', api, extraOptions);
    // if (refreshresult?.data) {
    //   // store the new token
    //   // api.dispatch(tokenReceived(refreshresult?.data))
    //   // retry the initial query
    //   result = await rawBaseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(logout());
    // }
  }
  return result;
};
