import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import type {
  ChartData,
  ClassicMetric,
  RangeType,
  ProductTabData,
  StaffTabItem,
  CustomerTabItem,
} from '../types/dashboard';

interface DashboardFinanceState {
  rangeType: RangeType;
  financeChartData: ChartData;
  classicMetrics: ClassicMetric[];
  classicMetricsInitialised: boolean;
  chartDataInitialised: boolean;
  chartDataLoading: boolean;
  classicMetricsLoading: boolean;
  productTabData: ProductTabData;
  staffTabData: StaffTabItem[];
  customerTabData: CustomerTabItem[];
  tabDataLoading: boolean;
}

const initialState: DashboardFinanceState = {
  rangeType: 100,
  financeChartData: null,
  classicMetrics: [],
  classicMetricsInitialised: false,
  chartDataInitialised: false,
  chartDataLoading: true,
  classicMetricsLoading: true,
  tabDataLoading: false,
  productTabData: null,
  staffTabData: null,
  customerTabData: null,
};

const slice = createSlice({
  name: 'dashboardFinance',
  initialState,
  reducers: {
    getChartsData(
      state: DashboardFinanceState,
      action: PayloadAction<{ financeChartData: ChartData }>
    ) {
      const { financeChartData } = action.payload;

      state.financeChartData = financeChartData;
      state.chartDataLoading = false;
      state.chartDataInitialised = true;
    },
    changeRangeType(state: DashboardFinanceState, action: PayloadAction<{ rangeType: RangeType }>) {
      const { rangeType } = action.payload;

      state.rangeType = rangeType;
    },
    getClassicMetrics(
      state: DashboardFinanceState,
      action: PayloadAction<{ classicMetrics: ClassicMetric[] }>
    ) {
      const { classicMetrics } = action.payload;

      state.classicMetrics = classicMetrics;
      state.classicMetricsLoading = false;
      state.classicMetricsInitialised = true;
    },
    setClassicMetricsLoading(
      state: DashboardFinanceState,
      action: PayloadAction<{ isLoading: boolean }>
    ) {
      const { isLoading } = action.payload;

      state.classicMetricsLoading = isLoading;
    },
    setChartDataLoading(
      state: DashboardFinanceState,
      action: PayloadAction<{ isLoading: boolean }>
    ) {
      const { isLoading } = action.payload;

      state.chartDataLoading = isLoading;
    },
    setTabDataLoading(state: DashboardFinanceState, action: PayloadAction<{ isLoading: boolean }>) {
      const { isLoading } = action.payload;

      state.tabDataLoading = isLoading;
    },
    getProductTabData(
      state: DashboardFinanceState,
      action: PayloadAction<{ productTabData: ProductTabData }>
    ) {
      const { productTabData } = action.payload;

      state.productTabData = productTabData;
    },
    getStaffTabData(
      state: DashboardFinanceState,
      action: PayloadAction<{ staffTabData: StaffTabItem[] }>
    ) {
      const { staffTabData } = action.payload;

      state.staffTabData = staffTabData;
    },
    getCustomerTabData(
      state: DashboardFinanceState,
      action: PayloadAction<{ customerTabData: CustomerTabItem[] }>
    ) {
      const { customerTabData } = action.payload;

      state.customerTabData = customerTabData;
    },
  },
});

export const { reducer } = slice;

export const getClassicMetrics =
  (organisationId: number, isPreviousMonth: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setClassicMetricsLoading({ isLoading: true }));
    const response = await axios.get(
      `v2/organisations/${organisationId}/dashboard/finance-classic-metrics?is_previous_month=${
        isPreviousMonth ? '1' : '0'
      }`
    );

    dispatch(slice.actions.getClassicMetrics({ classicMetrics: response.data }));
  };

export const changeRangeType = (rangeType: RangeType) => (dispatch) => {
  dispatch(slice.actions.changeRangeType({ rangeType }));
};

export const getChartsData =
  (organisationId: number, rangeType: RangeType): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setChartDataLoading({ isLoading: true }));
    const response = await axios.get(
      `v2/organisations/${organisationId}/dashboard/finance-line-chart-data?type=${rangeType}`
    );

    dispatch(slice.actions.getChartsData({ financeChartData: response.data }));
  };

export const getProductTabData =
  (rangeType: RangeType, organisationId: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setTabDataLoading({ isLoading: true }));
    const response = await axios.get(
      `v1/organisations/${organisationId}/dashboard/product-stats?type=${rangeType}`
    );
    dispatch(slice.actions.getProductTabData({ productTabData: response.data }));
    dispatch(slice.actions.setTabDataLoading({ isLoading: false }));
  };

export const getStaffTabData =
  (rangeType: RangeType, organisationId: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setTabDataLoading({ isLoading: true }));
    const response = await axios.get(
      `v1/organisations/${organisationId}/dashboard/staff-stats?type=${rangeType}`
    );
    dispatch(slice.actions.getStaffTabData({ staffTabData: response.data }));
    dispatch(slice.actions.setTabDataLoading({ isLoading: false }));
  };

export const getCustomerTabData =
  (rangeType: RangeType, organisationId: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setTabDataLoading({ isLoading: true }));
    const response = await axios.get(
      `v1/organisations/${organisationId}/dashboard/customer-stats?type=${rangeType}`
    );
    dispatch(slice.actions.getCustomerTabData({ customerTabData: response.data }));
    dispatch(slice.actions.setTabDataLoading({ isLoading: false }));
  };

export default slice;
