/* eslint-disable no-nested-ternary */
import get from 'lodash/get';
import { Address, RegionOption } from 'src/types/address';
import { CountryRegionData as regionData } from 'react-country-region-selector';
import find from 'lodash/find';

export const getRegionOptions = (country: string): RegionOption[] => {
  const region = find(regionData, (regionItem) => {
    return regionItem[1] === country;
  });
  return region && region[2]
    ? region[2].split('|').map((regionItem) => {
        const regionDetails = regionItem.split('~');

        return {
          value: regionDetails[1],
          label: regionDetails[0],
        };
      })
    : [];
};

export const getEntityAddress = (
  data:
    | {
        address_street_one?: string;
        address_street_two?: string;
        address_city?: string;
        address_postcode?: string;
        address_state?: string;
      }
    | {
        company_address_street_one?: string;
        company_address_street_two?: string;
        company_address_city?: string;
        company_address_postcode?: string;
        company_address_state?: string;
      }
    | {
        physical_address_1?: string;
        physical_address_2?: string;
        physical_city?: string;
        physical_postcode?: string;
        physical_state?: string;
      },
  type: 'pool' | 'contact' | 'company' | 'vendContact',
  isShort = false
): string => {
  let fullAddress = '';
  if (data) {
    let addr1;
    let addr2;
    let city;
    let state;
    let postal;
    switch (type) {
      case 'pool':
        addr1 = get(data, 'address_street_one');
        addr2 = get(data, 'address_street_two');
        city = get(data, 'address_city');
        postal = get(data, 'address_postcode');
        state = get(data, 'address_state', '');
        break;
      case 'contact':
        addr1 = get(data, 'address_street_one');
        addr2 = get(data, 'address_street_two');
        city = get(data, 'address_city');
        postal = get(data, 'address_postcode');
        state = get(data, 'address_state');
        break;
      case 'company':
        addr1 = get(data, 'company_address_street_one');
        addr2 = get(data, 'company_address_street_two');
        city = get(data, 'company_address_city');
        postal = get(data, 'company_address_postcode');
        state = get(data, 'company_address_state');
        break;
      case 'vendContact':
        addr1 = get(data, 'physical_address_1')
          ? get(data, 'physical_address_1')
          : get(data, 'postal_address_1');
        addr2 = get(data, 'physical_address_2')
          ? get(data, 'physical_address_2')
          : get(data, 'postal_address_2');
        city = get(data, 'physical_city') ? get(data, 'physical_city') : get(data, 'postal_city');
        postal = get(data, 'physical_postcode')
          ? get(data, 'physical_postcode')
          : get(data, 'postal_postcode');
        state = get(data, 'physical_state')
          ? get(data, 'physical_state')
          : get(data, 'postal_state');
        break;
      default:
    }

    if (isShort) {
      fullAddress = `${addr1 ? (addr2 ? addr1 : `${addr1},`) : ''} ${addr2 ? `${addr2},` : ''} ${
        city || ''
      }`;
    } else {
      fullAddress = `${addr1 ? (addr2 ? addr1 : `${addr1},`) : ''} ${addr2 ? `${addr2},` : ''} ${
        city ? `${city},` : ''
      } ${state || ''} ${postal || ''}`;
    }
  }
  return fullAddress;
};

export const uniqueAddresses = (
  addresses: Address[],
  type: 'pool' | 'contact' | 'company'
): Address[] => {
  const mapAddressStrToAddress: { [key: string]: Address } = {};
  addresses.forEach((address) => {
    mapAddressStrToAddress[getEntityAddress(address, type)] = address;
  });
  return Object.values(mapAddressStrToAddress);
};
