import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { equipmentApi } from 'src/api/equipment';

interface EquipmentSetupState {
  equipmentTypeSearch?: string;
  equipmentTypeFilterModel?: GridFilterModel;
  equipmentTypeLimit?: number;
  equipmentTypePage?: number;
  equipmentTypeTotal?: number;
  equipmentTypeOrderBy: string;
  equipmentTypeOrder: SortDirection;
  equipmentTemplateSearch?: string;
  equipmentTemplateFilterModel?: GridFilterModel;
  equipmentTemplateLimit?: number;
  equipmentTemplatePage?: number;
  equipmentTemplateTotal?: number;
  equipmentTemplateOrderBy: string;
  equipmentTemplateOrder: SortDirection;
}

const initialState: EquipmentSetupState = {
  equipmentTypeSearch: '',
  equipmentTypeFilterModel: {
    items: [],
  },
  equipmentTypeLimit: DEFAULT_ROWS_PER_PAGE,
  equipmentTypePage: 0,
  equipmentTypeTotal: 0,
  equipmentTypeOrderBy: 'created_at',
  equipmentTypeOrder: 'desc',
  equipmentTemplateSearch: '',
  equipmentTemplateFilterModel: {
    items: [],
  },
  equipmentTemplateLimit: DEFAULT_ROWS_PER_PAGE,
  equipmentTemplatePage: 0,
  equipmentTemplateTotal: 0,
  equipmentTemplateOrderBy: 'created_at',
  equipmentTemplateOrder: 'desc',
};

const slice = createSlice({
  name: 'equipmentSetup',
  initialState,
  reducers: {
    setEquipmentTypeLimit(state: EquipmentSetupState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.equipmentTypeLimit = limit;
      state.equipmentTypePage = 0;
      state.equipmentTypeTotal = 0;
    },
    setEquipmentTypePage(state: EquipmentSetupState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.equipmentTypePage = page;
    },
    setEquipmentTypeSearch(
      state: EquipmentSetupState,
      action: PayloadAction<{ searchText: string }>
    ) {
      const { searchText } = action.payload;

      state.equipmentTypeSearch = searchText;
      state.equipmentTypePage = 0;
    },
    setEquipmentTypeOrder(
      state: EquipmentSetupState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.equipmentTypeOrderBy = orderBy;
      state.equipmentTypeOrder = order;
    },
    setEquipmentTypeFilterModel(
      state: EquipmentSetupState,
      action: PayloadAction<{ filterModel: GridFilterModel }>
    ) {
      const { filterModel } = action.payload;

      state.equipmentTypeFilterModel = filterModel;
    },
    setEquipmentTemplateLimit(
      state: EquipmentSetupState,
      action: PayloadAction<{ limit: number }>
    ) {
      const { limit } = action.payload;

      state.equipmentTemplateLimit = limit;
      state.equipmentTemplatePage = 0;
      state.equipmentTemplateTotal = 0;
    },
    setEquipmentTemplatePage(state: EquipmentSetupState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.equipmentTemplatePage = page;
    },
    setEquipmentTemplateSearch(
      state: EquipmentSetupState,
      action: PayloadAction<{ searchText: string }>
    ) {
      const { searchText } = action.payload;

      state.equipmentTemplateSearch = searchText;
      state.equipmentTemplatePage = 0;
    },
    setEquipmentTemplateOrder(
      state: EquipmentSetupState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.equipmentTemplateOrderBy = orderBy;
      state.equipmentTemplateOrder = order;
    },
    setEquipmentTemplateFilterModel(
      state: EquipmentSetupState,
      action: PayloadAction<{ filterModel: GridFilterModel }>
    ) {
      const { filterModel } = action.payload;

      state.equipmentTemplateFilterModel = filterModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        equipmentApi.endpoints.getPaginatedEquipmentTypes.matchPending,
        (state, action) => {
          console.log('pending', action);
        }
      )
      .addMatcher(
        equipmentApi.endpoints.getPaginatedEquipmentTypes.matchFulfilled,
        (state, action) => {
          const { meta } = action.payload;
          state.equipmentTypeTotal = meta.total;
        }
      )
      .addMatcher(
        equipmentApi.endpoints.getPaginatedEquipmentTypes.matchRejected,
        (state, action) => {
          console.log('rejected', action);
        }
      )
      .addMatcher(
        equipmentApi.endpoints.getPaginatedEquipmentTemplates.matchPending,
        (state, action) => {
          console.log('pending', action);
        }
      )
      .addMatcher(
        equipmentApi.endpoints.getPaginatedEquipmentTemplates.matchFulfilled,
        (state, action) => {
          const { meta } = action.payload;
          state.equipmentTemplateTotal = meta.total;
        }
      )
      .addMatcher(
        equipmentApi.endpoints.getPaginatedEquipmentTemplates.matchRejected,
        (state, action) => {
          console.log('rejected', action);
        }
      );
  },
});

export const { reducer } = slice;

export const setEquipmentTypeLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTypeLimit({ limit }));
};

export const setEquipmentTypePage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTypePage({ page }));
};

export const setEquipmentTypeSearch = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTypeSearch({ searchText }));
};

export const setEquipmentTypeOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTypeOrder({ orderBy, order }));
};

export const setEquipmentTypeFilterModel = (filterModel: GridFilterModel) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTypeFilterModel({ filterModel }));
};

export const setEquipmentTemplateLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTemplateLimit({ limit }));
};

export const setEquipmentTemplatePage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTemplatePage({ page }));
};

export const setEquipmentTemplateSearch = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTemplateSearch({ searchText }));
};

export const setEquipmentTemplateOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTemplateOrder({ orderBy, order }));
};

export const setEquipmentTemplateFilterModel = (filterModel: GridFilterModel) => (dispatch) => {
  dispatch(slice.actions.setEquipmentTemplateFilterModel({ filterModel }));
};

export default slice;
