import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { rescheduleJobs, unscheduleJob } from 'src/api/job';
import { JobEvent } from 'src/types/job';
import { updateJobEventsByRescheduleJobApi } from 'src/utils/job';
import type { AppThunk } from '../store';
import { IMapEvent } from 'src/types/calendar-day-view-map';

interface CalendarDayViewState {
  selectedDate: Date;
  jobEvents: JobEvent[];
  isRescheduling: boolean;
  isUnscheduling: boolean;
  isCalendarLocked: boolean;
  selectedTechnicianId: number | null;
  selectedUnassignedTechnician: boolean;
  mapDrawerOpen: boolean;
  mapEvents: IMapEvent[];
  jobIdToDistance: { [jobId: number]: number };
}

const initialState: CalendarDayViewState = {
  selectedDate: new Date(),
  jobEvents: [],
  isRescheduling: false,
  isUnscheduling: false,
  isCalendarLocked: false,
  selectedTechnicianId: null,
  selectedUnassignedTechnician: false,
  mapDrawerOpen: false,
  mapEvents: [],
  jobIdToDistance: {},
};

const slice = createSlice({
  name: 'calendarDayView',
  initialState,
  reducers: {
    moveFromUnscheduledList(
      state: CalendarDayViewState,
      action: PayloadAction<{ jobEvent: JobEvent }>
    ): void {
      state.jobEvents = [...state.jobEvents, action.payload.jobEvent];
      state.mapEvents = getMapEvents(state);
    },
    setSelectedDate(state: CalendarDayViewState, action: PayloadAction<{ date: Date }>): void {
      const { date } = action.payload;
      state.selectedDate = date;
    },
    setJobEvents(
      state: CalendarDayViewState,
      action: PayloadAction<{ jobEvents: JobEvent[] }>
    ): void {
      const { jobEvents } = action.payload;
      state.jobEvents = jobEvents;
      state.mapEvents = getMapEvents(state);
    },
    selectTechnician(
      state: CalendarDayViewState,
      action: PayloadAction<{ technicianId: number | null }>
    ) {
      const { technicianId } = action.payload;
      if (technicianId == null || technicianId === 0) {
        state.selectedUnassignedTechnician = !state.selectedUnassignedTechnician;
        state.selectedTechnicianId = null;
      } else if (state.selectedTechnicianId === technicianId) {
        state.selectedTechnicianId = null;
        state.selectedUnassignedTechnician = false;
      } else {
        state.selectedTechnicianId = technicianId;
        state.selectedUnassignedTechnician = false;
      }

      if (state.selectedTechnicianId != null || state.selectedUnassignedTechnician) {
        state.mapDrawerOpen = true;
      }
      state.mapEvents = getMapEvents(state);
    },

    toggleMap(state: CalendarDayViewState): void {
      state.mapDrawerOpen = !state.mapDrawerOpen;
    },
    closeMap(state: CalendarDayViewState): void {
      state.mapDrawerOpen = false;
    },
    unselectTechnicians(state: CalendarDayViewState): void {
      state.selectedTechnicianId = null;
      state.selectedUnassignedTechnician = false;
      state.mapEvents = getMapEvents(state);
    },
    setDistances(
      state: CalendarDayViewState,
      action: PayloadAction<{ distances: CalendarDayViewState['jobIdToDistance'] }>
    ) {
      state.jobIdToDistance = action.payload.distances;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(rescheduleJobs.matchPending, (state, action) => {
        state.jobEvents = updateJobEventsByRescheduleJobApi(
          state.jobEvents,
          action.meta.arg.originalArgs
        );
        state.mapEvents = getMapEvents(state);
      })
      .addMatcher(rescheduleJobs.matchFulfilled, (state, action) => {
        state.isRescheduling = false;
        state.isCalendarLocked = state.isRescheduling || state.isUnscheduling;
      })

      .addMatcher(unscheduleJob.matchPending, (state, action) => {
        state.isUnscheduling = true;
        state.isCalendarLocked = true;
        const { originalArgs } = action.meta.arg;
        state.jobEvents = state.jobEvents.filter(({ id }) => id !== originalArgs.id);
        state.mapEvents = getMapEvents(state);
      })
      .addMatcher(unscheduleJob.matchFulfilled, (state, action) => {
        state.isUnscheduling = false;
        state.isCalendarLocked = state.isRescheduling || state.isUnscheduling;
      });
  },
});

export const { reducer } = slice;
export const {
  moveFromUnscheduledList,
  setJobEvents,
  selectTechnician,
  toggleMap,
  closeMap,
  unselectTechnicians,
  setDistances,
} = slice.actions;

export const setSelectedDate =
  (date: Date): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.setSelectedDate({ date }));
  };

export default slice;

const getMapEvents = (state: CalendarDayViewState): IMapEvent[] => {
  const { jobEvents } = state;
  let filteredEvents: JobEvent[];

  if (state.selectedTechnicianId != null) {
    filteredEvents = jobEvents.filter(({ user_id }) => user_id === state.selectedTechnicianId);
  } else if (state.selectedUnassignedTechnician) {
    filteredEvents = jobEvents.filter(({ user_id }) => user_id == null || user_id === 0);
  } else {
    filteredEvents = jobEvents;
  }

  filteredEvents = filteredEvents.filter(
    ({ pool }) => pool?.latitude != null && pool?.longitude != null
  );

  const mapEvents: IMapEvent[] = [];

  Object.values(groupBy(filteredEvents, 'user_id')).forEach((events) => {
    const markers: IMapEvent[] = sortBy(events, 'start_time', 'asc').map((jobEvent, index) => ({
      jobEvent,
      order: index + 1,
    }));
    mapEvents.push(...markers);
  });

  return mapEvents;
};
