import { FIELD_PLAN, LAB_PLAN, PRO_PLAN } from './subscription';

export interface SectionPermission {
  permission: string;
  availablePlans: string[] | [];
}

export const JOB_PERMISSION = 'jobs';
export const USER_PERMISSION = 'users';
export const ROLE_PERMISSION = 'roles';
export const INVOICE_PERMISSION = 'invoices';
export const CONTACT_PERMISSION = 'contacts';
export const POOL_PERMISSION = 'pools';
export const PRODUCT_PERMISSION = 'products';

// Mobile user permissions
export const MOBILE_ACCESS = 'mobile.access';

export const MOBILE_POOLS_VIEW = 'mobile.pools.view';
export const MOBILE_CONTACTS_VIEW = 'mobile.contacts.view';
export const MOBILE_QUOTES_VIEW = 'mobile.quotes.view';
export const MOBILE_QUOTES_DELETE = 'mobile.quotes.delete';
export const MOBILE_JOBS_DELETE = 'mobile.jobs.delete';
export const MOBILE_JOBS_RESCHEDULE = 'mobile.jobs.reschedule';
export const MOBILE_INVOICES_CREATE = 'mobile.invoices.create';
export const MOBILE_PRODUCES_PRICE = 'mobile.products.price';

export const MOBILE_USER_PERMISSIONS = [
  MOBILE_POOLS_VIEW,
  MOBILE_CONTACTS_VIEW,
  MOBILE_QUOTES_VIEW,
  MOBILE_QUOTES_DELETE,
  MOBILE_JOBS_DELETE,
  MOBILE_JOBS_RESCHEDULE,
  MOBILE_INVOICES_CREATE,
  MOBILE_PRODUCES_PRICE,
];

// Portal user permissions
export const PORTAL_ACCESS = 'portal.access';

export const OPERATIONS_DASHBOARD_OVERVIEW = 'operations.dashboard.overview';
export const OPERATIONS_DASHBOARD_ANALYTICS = 'operations.dashboard.analytics';
export const OPERATIONS_DASHBOARD_FINANCE = 'operations.dashboard.finance';
export const OPERATIONS_LAB = 'operations.lab';
export const OPERATIONS_JOBS = 'operations.jobs';
export const OPERATIONS_QUOTES = 'operations.quotes';
export const OPERATIONS_INVOICES = 'operations.invoicing';
export const OPERATIONS_CONTACTS = 'operations.contacts';
export const OPERATIONS_POOLS = 'operations.pools';
export const OPERATIONS_PRODUCTS = 'operations.products';
export const OPERATIONS_CAMPAIGNS = 'operations.campaigns';
export const OPERATIONS_SMS = 'operations.sms';

export const OPERATIONS_PERMISSIONS: SectionPermission[] = [
  {
    permission: OPERATIONS_DASHBOARD_OVERVIEW,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_DASHBOARD_ANALYTICS,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_DASHBOARD_FINANCE,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_LAB,
    availablePlans: [LAB_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_JOBS,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_QUOTES,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_INVOICES,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_CONTACTS,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_POOLS,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_PRODUCTS,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_CAMPAIGNS,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: OPERATIONS_SMS,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
];

export const SETUP_MY_BUSINESS = 'setup.my_business';
export const SETUP_PLAN_AND_PAYMENT = 'setup.plan_and_payment';
export const SETUP_TEAM = 'setup.team';
export const SETUP_QUOTES = 'setup.quotes';
export const SETUP_JOBS = 'setup.jobs';
export const SETUP_BATCH = 'setup.batch';
export const SETUP_LAB = 'setup.lab';
export const SETUP_INTEGRATIONS = 'setup.integrations';
export const SETUP_DATA_CLEANING = 'setup.data_cleaning';
export const SETUP_WATER_TESTING = 'setup.water_testing';
export const SETUP_DOSAGE_GROUPS = 'setup.dosage_groups';
export const SETUP_POOL_CHARACTERISTICS = 'setup.pool_characteristics';
export const SETUP_TAGS = 'setup.tags';

export const SETUP_PERMISSIONS: SectionPermission[] = [
  {
    permission: SETUP_MY_BUSINESS,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_PLAN_AND_PAYMENT,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_TEAM,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_QUOTES,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_JOBS,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_BATCH,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_LAB,
    availablePlans: [LAB_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_INTEGRATIONS,
    availablePlans: [FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_DATA_CLEANING,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_WATER_TESTING,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_DOSAGE_GROUPS,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_POOL_CHARACTERISTICS,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
  {
    permission: SETUP_TAGS,
    availablePlans: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
  },
];

export const USERS_MANAGE = 'users.manage';
export const ROLES_MANAGE = 'roles.manage';
export const JOBS_INVOICE = 'jobs.invoice';
export const INVOICES_EXPORT = 'invoices.export';
export const CONTACTS_EXPORT = 'contacts.export';
export const CONTACTS_IMPORT = 'contacts.import';
export const POOLS_EXPORT = 'pools.export';
export const POOLS_IMPORT = 'pools.import';
export const PRODUCTS_IMPORT = 'products.import';
export const PRODUCTS_EXPORT = 'products.export';
export const PRODUCTS_PRICE = 'products.price';

export const LEGACY_ADMIN = 'legacy.admin';
export const LEGACY_PORTAL = 'legacy.portal';
export const LEGACY_PORTAL_ADMIN = 'legacy.portal-admin';
export const LEGACY_MOBILE_ORG = 'legacy.mobile-org';
export const LEGACY_MOBILE_ADMIN = 'legacy.mobile-admin';
export const LEGACY_MOBILE_TECH = 'legacy.mobile-tech';
export const LEGACY_LAB = 'legacy.lab';
export const LEGACY_SETTINGS_ADMIN = 'legacy.settings-admin';
export const LEGACY_ACCOUNT_ADMIN = 'legacy.account-admin';
export const LEGACY_MOBILE_TECH_PRICES = 'legacy.mobile-tech-prices';
export const LEGACY_DASHBOARD_ACCESS = 'legacy.dashboard-access';
