import { EMPTY_DATE_STRING } from 'src/constants/date';
import { RecurrencePeriod } from 'src/types/recurrence-period';

export const isEmptyDate = (date: Date | string | null) =>
  date == null || date === EMPTY_DATE_STRING;

export const convertRecurrencePeriodToArray = (recurrencePeriod: RecurrencePeriod): number[] => {
  const { start_month, end_month } = recurrencePeriod;
  const result = [];

  if (start_month > end_month) {
    for (let i = start_month; i <= 12; i++) {
      // eslint-disable-next-line no-bitwise
      result.push(i);
    }
    for (let i = 1; i <= end_month; i++) {
      // eslint-disable-next-line no-bitwise
      result.push(i);
    }
  } else {
    for (let i = start_month; i <= end_month; i++) {
      // eslint-disable-next-line no-bitwise
      result.push(i);
    }
  }
  return result;
};
