import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { authApi } from './auth';
import { productApi } from './product';
import { contactApi } from './contact';
import { messagingApi } from './messaging';
import { poolApi } from './pool';
import { invoiceApi } from './invoice';
import { recurrenceApi } from './recurrence';
import { dosageGroupApi } from './dosage-group';
import { tagApi } from './tag';
import { colorApi } from './color';
import { campaignApi } from './campaign';
import { labJobApi } from './labjob';
import { jobApi } from './job';
import { quoteApi } from './quote';
import { waterTestApi } from './water-test';
import { noteApi } from './note';
import { spinTouchApi } from './spin-touch';
import { userApi } from './user';
import { organisationApi } from './organisation';
import { publicApi } from './public';
import { storeImagesApi } from './store-images';
import { jobTemplateApi } from './job-template';
import { dashboardApi } from './dashboard';
import { subscriptionApi } from './subscription';
import { customFieldsApi } from './custom-fields';
import { invoiceApi as batchInvoiceApi } from './batch-invoice';
import { equipmentApi } from './equipment';
import { integrationApi } from './integration';

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    try {
      //TODO: implement proper handler when error API responses standardised
      const errorMessage = action.payload.data?.error
        ? JSON.stringify(action.payload.data.error)
        : JSON.stringify(action.payload.data);
      // toast.error(errorMessage);
      console.error(errorMessage);
    } catch (e) {
      console.error(e.message);
    }
  }
  return next(action);
};

export const middlewareList = [
  authApi.middleware,
  productApi.middleware,
  contactApi.middleware,
  poolApi.middleware,
  invoiceApi.middleware,
  recurrenceApi.middleware,
  dosageGroupApi.middleware,
  tagApi.middleware,
  colorApi.middleware,
  campaignApi.middleware,
  labJobApi.middleware,
  jobApi.middleware,
  quoteApi.middleware,
  waterTestApi.middleware,
  noteApi.middleware,
  spinTouchApi.middleware,
  userApi.middleware,
  organisationApi.middleware,
  publicApi.middleware,
  storeImagesApi.middleware,
  jobTemplateApi.middleware,
  batchInvoiceApi.middleware,
  messagingApi.middleware,
  dashboardApi.middleware,
  customFieldsApi.middleware,
  subscriptionApi.middleware,
  equipmentApi.middleware,
  integrationApi.middleware,
  rtkQueryErrorLogger,
];

export const apiReducer = {
  [authApi.reducerPath]: authApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [contactApi.reducerPath]: contactApi.reducer,
  [poolApi.reducerPath]: poolApi.reducer,
  [invoiceApi.reducerPath]: invoiceApi.reducer,
  [recurrenceApi.reducerPath]: recurrenceApi.reducer,
  [tagApi.reducerPath]: tagApi.reducer,
  [colorApi.reducerPath]: colorApi.reducer,
  [campaignApi.reducerPath]: campaignApi.reducer,
  [dosageGroupApi.reducerPath]: dosageGroupApi.reducer,
  [labJobApi.reducerPath]: labJobApi.reducer,
  [jobApi.reducerPath]: jobApi.reducer,
  [quoteApi.reducerPath]: quoteApi.reducer,
  [waterTestApi.reducerPath]: waterTestApi.reducer,
  [noteApi.reducerPath]: noteApi.reducer,
  [spinTouchApi.reducerPath]: spinTouchApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [organisationApi.reducerPath]: organisationApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,
  [storeImagesApi.reducerPath]: storeImagesApi.reducer,
  [jobTemplateApi.reducerPath]: jobTemplateApi.reducer,
  [batchInvoiceApi.reducerPath]: batchInvoiceApi.reducer,
  [messagingApi.reducerPath]: messagingApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [customFieldsApi.reducerPath]: customFieldsApi.reducer,
  [equipmentApi.reducerPath]: equipmentApi.reducer,
  [integrationApi.reducerPath]: integrationApi.reducer,
};
