import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { User } from '../types/user';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import { SortDirection } from '@mui/material';
import { userApi } from 'src/api/user';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { userHasPermission } from 'src/utils/permission';
import { MOBILE_ACCESS } from 'src/constants/permission';
import { authApi } from 'src/api/auth';
import cycleArray from 'src/utils/cycleArray';
import { technicianColors } from 'src/constants/calendar-day-view';

interface UserState {
  searchText?: string;
  tagsFilter?: string[];
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
  users: User[];
  technicians: User[];
  technicianIdToColor: { [technicianId: number]: string };
  technicianUnassignedColor: string | null;
}

const initialState: UserState = {
  searchText: '',
  tagsFilter: [],
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'first_name',
  order: 'asc',
  users: [],
  technicians: [],
  technicianIdToColor: {},
  technicianUnassignedColor: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLimit(state: UserState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setTotal(state: UserState, action: PayloadAction<{ total: number }>) {
      const { total } = action.payload;

      state.total = total;
    },
    setPage(state: UserState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setSearchText(state: UserState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setOrder(state: UserState, action: PayloadAction<{ orderBy: string; order: SortDirection }>) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    setTagsFilter(state: UserState, action: PayloadAction<{ tags: string[] }>) {
      const { tags } = action.payload;

      state.tagsFilter = tags;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(userApi.endpoints.getUsers.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(userApi.endpoints.getUsers.matchFulfilled, (state, action) => {
        const { data, meta } = action.payload;
        state.users = data;
        state.total = meta.total;
      })
      .addMatcher(userApi.endpoints.getUsers.matchRejected, (state, action) => {
        console.log('rejected', action);
      })
      .addMatcher(userApi.endpoints.getTechnicians.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(userApi.endpoints.getTechnicians.matchFulfilled, (state, action) => {
        const activeTechnicians = filter(
          action.payload,
          (user) => userHasPermission(user, MOBILE_ACCESS) && user.active
        );
        state.technicians = activeTechnicians;
        const idToColor = {};

        const getColor = cycleArray(technicianColors);
        sortBy(activeTechnicians, 'id').forEach((technician) => {
          idToColor[technician.id] = getColor();
        });

        state.technicianIdToColor = idToColor;
        state.technicianUnassignedColor = getColor();
      })
      .addMatcher(userApi.endpoints.getTechnicians.matchRejected, (state, action) => {
        console.log('rejected', action);
      })
      .addMatcher(userApi.endpoints.changeActive.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(userApi.endpoints.changeActive.matchFulfilled, (state, action) => {
        const user = action.payload;
        state.users = state.users.map((_user) => {
          if (_user.id === user.id) {
            return user;
          }

          return _user;
        });
        const activeTechnicians = filter(
          state.users,
          (user) => userHasPermission(user, MOBILE_ACCESS) && user.active
        );
        state.technicians = activeTechnicians;
        const idToColor = {};

        const getColor = cycleArray(technicianColors);
        sortBy(activeTechnicians, 'id').forEach((technician) => {
          idToColor[technician.id] = getColor();
        });

        state.technicianIdToColor = idToColor;
        state.technicianUnassignedColor = getColor();
      })
      .addMatcher(userApi.endpoints.changeActive.matchRejected, (state, action) => {
        console.log('rejected', action);
      })
      .addMatcher(authApi.endpoints.logout.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
        return initialState;
      })
      .addMatcher(authApi.endpoints.logout.matchRejected, (state, action) => {
        return initialState;
      });
  },
});

export const { reducer } = slice;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setTotal = (total: number) => (dispatch) => {
  dispatch(slice.actions.setTotal({ total }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export const setTagsFilter = (tags: string[]) => (dispatch) => {
  dispatch(slice.actions.setTagsFilter({ tags }));
};

export default slice;
