export const CONTACT_TAG_TYPE = 'contact';
export const JOB_TAG_TYPE = 'job';
export const POOL_TAG_TYPE = 'pool';
export const PRODUCT_TAG_TYPE = 'product';
export const USER_TAG_TYPE = 'user';

export const TAG_TYPES = [
  CONTACT_TAG_TYPE,
  POOL_TAG_TYPE,
  JOB_TAG_TYPE,
  PRODUCT_TAG_TYPE,
  USER_TAG_TYPE,
];
