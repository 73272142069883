import { JobTemplate } from 'src/types/job-template';
import { Meta } from 'src/types/pagination';
import { IPaginatedResponse } from './model/base.model';
import {
  IAddJobTemplate,
  IGetJobTemplates,
  IGetTemplate,
  IJobTemplate,
  IUpdateJobTemplate,
} from './model/job-template.model';
import { rootSplitApi } from './root';

export const jobTemplateApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getJobTemplate: builder.query<JobTemplate, IGetTemplate>({
      query: (param: IGetTemplate) =>
        `v2/organisation/${param.organisationId}/job-template/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'JobTemplate', id: param.id }],
    }),
    addJobTemplate: builder.mutation<IJobTemplate, IAddJobTemplate>({
      query: (param: IAddJobTemplate) => ({
        url: `v2/organisation/${param.organisationId}/job-template`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'JobTemplate', id: 'LIST' }],
    }),
    updateJobTemplate: builder.mutation<IJobTemplate, IUpdateJobTemplate>({
      query: (param: IUpdateJobTemplate) => ({
        url: `v2/organisation/${param.organisationId}/job-template/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          jobTemplateApi.util.updateQueryData(
            'getJobTemplate',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'JobTemplate', id: param.id },
        { type: 'JobTemplate', id: 'LIST' },
      ],
    }),
    getJobTemplates: builder.query<{ data: JobTemplate[]; meta: Meta }, IGetJobTemplates>({
      query: (param: IGetJobTemplates) => {
        const { organisationId, job_type_id, filter, limit, sortBy, page } = param;

        let url = `v2/organisation/${organisationId}/job-template?limit=${limit}&order=${sortBy}&page=${
          page + 1
        }`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (job_type_id) {
          url += `&job_type_id=${job_type_id}`;
        }

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'JobTemplate' as const, id })),
              { type: 'JobTemplate', id: 'LIST' },
            ]
          : [{ type: 'JobTemplate', id: 'LIST' }],
    }),
    getJobTemplatesSearch: builder.query<JobTemplate[], IGetJobTemplates>({
      query: (param: IGetJobTemplates) => {
        const { organisationId, filter } = param;

        let url = `v2/organisation/${organisationId}/job-template?limit=100&page=1`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        return url;
      },
      transformResponse(response: IPaginatedResponse) {
        return response?.data;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'JobTemplate' as const, id })),
              { type: 'JobTemplate', id: 'LIST' },
            ]
          : [{ type: 'JobTemplate', id: 'LIST' }],
    }),
  }),
});

export const {
  useAddJobTemplateMutation,
  useUpdateJobTemplateMutation,
  useGetJobTemplatesQuery,
  useGetJobTemplatesSearchQuery,
  useGetJobTemplateQuery,
} = jobTemplateApi;

export const {
  endpoints: {
    addJobTemplate,
    updateJobTemplate,
    getJobTemplates,
    getJobTemplatesSearch,
    getJobTemplate,
  },
} = jobTemplateApi;
