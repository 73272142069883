import { ChemicalTest, ObservationTest, TestOrderCard } from 'src/types/chemical';
import {
  ORDERED_RECOMMENDATIONS_ID,
  UNORDERED_RECOMMENDATIONS_ID,
} from 'src/constants/chemical-test';
import sortBy from 'lodash/sortBy';
import each from 'lodash/each';
import map from 'lodash/map';

export const getChemicalTestOrderCards = (chemicalTests: ChemicalTest[]): TestOrderCard[] => {
  const recommendationCards: TestOrderCard[] = [];
  each(chemicalTests, (chemicalTest: ChemicalTest) => {
    const highTestOrderCard = {
      id: `${chemicalTest.id}_high`,
      test: chemicalTest,
      is_high: true,
      name: `${chemicalTest.name} (HIGH)`,
      order: chemicalTest.high_order,
      columnId:
        chemicalTest.high_order > 0 ? ORDERED_RECOMMENDATIONS_ID : UNORDERED_RECOMMENDATIONS_ID,
    } as TestOrderCard;
    const lowTestOrderCard = {
      id: `${chemicalTest.id}_low`,
      test: chemicalTest,
      is_high: false,
      name: `${chemicalTest.name} (LOW)`,
      order: chemicalTest.low_order,
      columnId:
        chemicalTest.low_order > 0 ? ORDERED_RECOMMENDATIONS_ID : UNORDERED_RECOMMENDATIONS_ID,
    } as TestOrderCard;
    recommendationCards.push(highTestOrderCard);
    recommendationCards.push(lowTestOrderCard);
  });

  return sortBy(recommendationCards, 'order');
};

export const getObservationTestOrderCards = (
  observationtests: ObservationTest[]
): TestOrderCard[] => {
  const recommendationCards: TestOrderCard[] = map(observationtests, (test: ObservationTest) => {
    return {
      id: test.id.toString(),
      test,
      name: test.name,
      is_high: false,
      order: test.order,
      columnId: test.order > 0 ? ORDERED_RECOMMENDATIONS_ID : UNORDERED_RECOMMENDATIONS_ID,
    } as TestOrderCard;
  });

  return sortBy(recommendationCards, 'order');
};
