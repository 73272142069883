export const INVOICE_MERGE_TAGS = {
  ORG_NAME: '{org_name}',
  ORG_ADDRESS: '{org_address}',
  ORG_EMAIL: '{org_email}',
  ORG_PHONE: '{org_phone}',
  ORG_WEBSITE: '{org_website}',
  TECH_FIRST_NAME: '{technician_first_name}',
  TECH_LAST_NAME: '{technician_last_name}',
  CONTACT_FIRST_NAME: '{contact_first_name}',
  CONTACT_LAST_NAME: '{contact_last_name}',
  CONTACT_ADDRESS: '{contact_address}',
  JOB_TEMPLATE: '{job_template}',
  JOB_POOL_TYPE: '{job_pool_type}',
  JOB_POOL_ADDRESS: '{job_pool_address}',
  TOTAL_COST: '{total_cost}',
  INVOICE_NO: '{invoice_no}',
  DATE_OF_VISIT: '{date_of_visit}',
};

export const BATCH_MERGE_TAGS = {
  ORG_NAME: '{org_name}',
  ORG_ADDRESS: '{org_address}',
  ORG_EMAIL: '{org_email}',
  ORG_PHONE: '{org_phone}',
  ORG_WEBSITE: '{org_website}',
  CONTACT_FIRST_NAME: '{contact_first_name}',
  CONTACT_LAST_NAME: '{contact_last_name}',
  CONTACT_ADDRESS: '{contact_address}',
  TOTAL_COST: '{total_cost}',
  INVOICE_NO: '{invoice_no}',
  DATE_OF_BATCH: '{date_of_batch}',
};

export const QUOTE_MERGE_TAGS = {
  ORG_NAME: '{org_name}',
  ORG_ADDRESS: '{org_address}',
  ORG_EMAIL: '{org_email}',
  ORG_PHONE: '{org_phone}',
  ORG_WEBSITE: '{org_website}',
  CONTACT_FIRST_NAME: '{contact_first_name}',
  CONTACT_LAST_NAME: '{contact_last_name}',
  CONTACT_ADDRESS: '{contact_address}',
  JOB_TEMPLATE: '{job_template}',
  QUOTE_POOL_TYPE: '{quote_pool_type}',
  QUOTE_POOL_ADDRESS: '{quote_pool_address}',
  TOTAL_COST: '{total_cost}',
};

export const SENT_STATUS = 'sent';
export const UNSENT_STATUS = 'unsent';
export const PAID_STATUS = 'paid';
export const UNPAID_STATUS = 'unpaid';
export const ENTERED_STATUS = 'entered';
export const NOT_ENTERED_STATUS = 'not-entered';
export const BATCH_STATUS = 'batch';
export const BATCHED_STATUS = 'batched';
export const OVERDUE_STATUS = 'overdue';

export const STATUS_FILTERS = [
  {
    value: SENT_STATUS,
    label: 'Sent',
  },
  {
    value: UNSENT_STATUS,
    label: 'Unsent',
  },
  {
    value: PAID_STATUS,
    label: 'Paid',
  },
  {
    value: UNPAID_STATUS,
    label: 'Unpaid',
  },
  {
    value: ENTERED_STATUS,
    label: 'Entered',
  },
  {
    value: NOT_ENTERED_STATUS,
    label: 'Not entered',
  },
  {
    value: BATCH_STATUS,
    label: 'Batch',
  },
  {
    value: BATCHED_STATUS,
    label: 'Batched',
  },
  {
    value: OVERDUE_STATUS,
    label: 'Overdue',
  },
];

export const BATCH_STATUS_FILTERS = [
  {
    value: SENT_STATUS,
    label: 'Sent',
  },
  {
    value: UNSENT_STATUS,
    label: 'Unsent',
  },
  {
    value: PAID_STATUS,
    label: 'Paid',
  },
  {
    value: UNPAID_STATUS,
    label: 'Unpaid',
  },
  {
    value: ENTERED_STATUS,
    label: 'Entered',
  },
  {
    value: NOT_ENTERED_STATUS,
    label: 'Not entered',
  },
  {
    value: OVERDUE_STATUS,
    label: 'Overdue',
  },
];

export const JOB_SHEET_FIELDS = [
  'display_tasks',
  'display_water_tests',
  'display_chemical_history',
  'display_chemical_actions',
];
