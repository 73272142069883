import { createSlice } from '@reduxjs/toolkit';
import { messagingApi } from 'src/api/messaging';
import { MessagingSettings } from 'src/types/messaging-settings';
import { NotificationSettings } from 'src/types/notification-settings';
import { SmsTier } from 'src/types/sms';

interface MessagingSettingsState {
  messagingSettings?: MessagingSettings;
  notificationSettings?: NotificationSettings;
  smsTiers?: SmsTier[];
}

const initialState: MessagingSettingsState = {
  messagingSettings: null,
  notificationSettings: null,
  smsTiers: [],
};

const slice = createSlice({
  name: 'messagingSettings',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(messagingApi.endpoints.getSmsTiers.matchFulfilled, (state, action) => {
        state.smsTiers = action.payload;
      })
      .addMatcher(
        messagingApi.endpoints.getNotificationSettings.matchFulfilled,
        (state, action) => {
          state.notificationSettings = action.payload;
        }
      )
      .addMatcher(
        messagingApi.endpoints.updateNotificationSettings.matchFulfilled,
        (state, action) => {
          state.notificationSettings = action.payload;
        }
      )
      .addMatcher(messagingApi.endpoints.buySmsPack.matchFulfilled, (state, action) => {
        state.notificationSettings = action.payload;
      })
      .addMatcher(messagingApi.endpoints.getMessagingSettings.matchFulfilled, (state, action) => {
        state.messagingSettings = action.payload;
      })
      .addMatcher(
        messagingApi.endpoints.updateMessagingSettings.matchFulfilled,
        (state, action) => {
          state.messagingSettings = action.payload;
        }
      );
  },
});

export const { reducer } = slice;
export const { reset } = slice.actions;
