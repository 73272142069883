import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './base-query';
import {
  IDeleteInvoice,
  IGetInvoices,
  IInvoice,
  IMarkInvoice,
  IPreviewEmail,
  IPreviewInvoicePdf,
  IPreviewJobSheepPdf,
  ISendInvoice,
  IUpdateInvoiceSettings,
} from './model/invoice.model';
import { InvoiceSettings } from 'src/types/invoice-settings';
import format from 'date-fns/format';

export const invoiceApi = createApi({
  reducerPath: 'invoiceApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['InvoiceSettings', 'Invoices', 'Invoice'],
  endpoints: (builder) => ({
    getInvoices: builder.query<any, IGetInvoices>({
      query: (param: IGetInvoices) => ({
        url: `v2/organisation/${param.organisationId}/contact-invoice`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Invoices' as const, id })),
              { type: 'Invoices', id: 'LIST' },
            ]
          : [{ type: 'Invoices', id: 'LIST' }],
    }),
    getInvoice: builder.query<any, IInvoice>({
      query: (param: IInvoice) =>
        `v1/organisations/${param.organisationId}/contactinvoice/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'Invoice', id: param.id }],
    }),
    getInvoiceSettings: builder.query<any, number>({
      query: (id) => `v1/organisations/${id}/invoice_settings`,
      providesTags: (result, error, id) => [{ type: 'InvoiceSettings', id }],
    }),
    updateInvoiceSettings: builder.mutation<InvoiceSettings, IUpdateInvoiceSettings>({
      query: (param: IUpdateInvoiceSettings) => ({
        url: `v1/organisations/${param.organisationId}/invoice_settings/${param.id}`,
        method: 'PUT',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [{ type: 'InvoiceSettings', id: param.id }],
    }),
    resentInvoice: builder.mutation<any, any>({
      query: (param: any) => ({
        url: `v1/organisations/${param.organisationId}/contactinvoice/${param.id}/resend`,
        method: 'POST',
        body: param.body,
      }),
    }),
    deleteInvoice: builder.mutation<any, IDeleteInvoice>({
      query: (param: IDeleteInvoice) => ({
        url: `v1/organisations/${param.organisationId}/contactinvoice/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Invoice', id: param.id },
        { type: 'Invoices', id: param.id },
      ],
    }),
    markInvoice: builder.mutation<any, IMarkInvoice>({
      query: (param: IMarkInvoice) => ({
        url: `v2/organisation/${param.organisationId}/contact-invoice/${param.id}/mark`,
        method: 'POST',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Invoice', id: param.id },
        { type: 'Invoices', id: param.id },
      ],
    }),
    previewInvoiceEmail: builder.query<any, IPreviewEmail>({
      query: (param: IPreviewEmail) => {
        return {
          url: `v2/organisations/${param.organisationId}/invoice/preview-email`,
          method: 'POST',
          body: {
            issued_date: param.issuedDate ? format(param.issuedDate, 'yyyy-MM-dd') : null,
            date_of_visit: param.dateOfVisit ? format(param.dateOfVisit, 'yyyy-MM-dd') : null,
            due_date: param.dueDate ? format(param.dueDate, 'yyyy-MM-dd') : null,
            invoice_message: param.invoiceMessage,
            is_invoice_disp: param.isInvoiceDisp ? 1 : 0,
          },
        };
      },
      keepUnusedDataFor: 1,
    }),
    previewJobSheetPdf: builder.query<any, IPreviewJobSheepPdf>({
      query: (param: IPreviewJobSheepPdf) => {
        return {
          url: `v1/organisations/${param.organisationId}/previewjobsheetpdf`,
          method: 'POST',
          responseHandler: async (response) => await response.blob(),
          body: {
            contact_intro: param.invoiceMessage,
            is_from_invoice: 1,
            date_of_visit: param.dateOfVisit ? format(param.dateOfVisit, 'yyyy-MM-dd') : null,
            contact_id: param.contactId,
            job_id: param.jobId,
            tasks_list: param.tasksList ? 1 : 0,
            water_result: param.waterResult ? 1 : 0,
            chemical_history: param.chemicalHistory ? 1 : 0,
            chemical_actions: param.chemicalActions ? 1 : 0,
            display_invoice_notes: param.displayInvoiceNotes ? 1 : 0,
            display_job_photos: param.displayJobPhotos ? 1 : 0,
          },
        };
      },
      keepUnusedDataFor: 1,
    }),
    previewInvoicePdf: builder.query<any, IPreviewInvoicePdf>({
      query: (param: IPreviewInvoicePdf) => {
        return {
          url: `v1/organisations/${param.organisationId}/previewinvoicepdf`,
          method: 'POST',
          responseHandler: async (response) => await response.blob(),
          body: {
            display_invoice_notes: param.invoiceNotes,
            issued_date: param.issuedDate ? format(param.issuedDate, 'yyyy-MM-dd') : null,
            date_of_visit: param.dateOfVisit ? format(param.dateOfVisit, 'yyyy-MM-dd') : null,
            due_date: param.dueDate ? format(param.dueDate, 'yyyy-MM-dd') : null,
            invoice_message: param.invoiceMessage,
            is_invoice_disp: param.isInvoiceDisp ? 1 : 0,
            contact_id: param.contactId,
            job_id: param.jobId,
          },
        };
      },
      keepUnusedDataFor: 1,
    }),

    sendInvoice: builder.mutation<{ data: object[] }, ISendInvoice>({
      query: (param: ISendInvoice) => {
        const body = {
          job_id: param.jobId,
          contact_id: param.contactId,
          pool_id: param.poolId,
          contact_first_name: param.contactFirstName,
          contact_last_name: param.contactLastName,
          is_invoice_sent: param.isInvoiceSent,
          is_invoice_paid: 0,
          address: param.address,
          job_template_name: param.jobTemplateName,
          job_created_date: format(param.jobCreatedDate, 'yyyy-MM-dd'),
          send_date: format(param.sendDate, 'yyyy-MM-dd'),
          due_date: format(param.dueDate, 'yyyy-MM-dd'),
          is_batch: 0,
          contact_intro: param.contactIntro,
          subject_message: param.subjectMessage,
          isChemicalHistoryDisp: param.isChemicalHistoryDisp,
          isChemicalActionsDisp: param.isChemicalActionsDisp,
          job_sheet_invoice_notes_display: param.jobSheetInvoiceNotesDisplay,
          isInvoiceDisp: param.isInvoiceDisp,
          isTaskDisp: param.isTaskDisp,
          isWatertestDisp: param.isWatertestDisp,
          display_job_photos: param.displayJobPhotos,
          display_invoice_notes: param.displayInvoiceNotes,
          products: param.products.map((product) => ({
            name: product.name,
            quantity: product.quantity,
            cost: product.cost,
            volume: product.volume,
            product_id: product.product_id,
            is_gst_price: product.is_gst_price,
          })),
          email: param.email,
          cc_emails: param.ccEmails.join(','),
          invoice_no: param.invoiceNo,
        };

        if (body.invoice_no == null) {
          delete body.invoice_no;
        }
        return {
          url: `v1/organisations/${param.organisationId}/contactinvoice`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useGetInvoiceSettingsQuery,
  useDeleteInvoiceMutation,
  useResentInvoiceMutation,
  useMarkInvoiceMutation,
  useUpdateInvoiceSettingsMutation,
  usePreviewInvoiceEmailQuery,
  usePreviewInvoicePdfQuery,
  usePreviewJobSheetPdfQuery,
  useSendInvoiceMutation,
} = invoiceApi;

export const {
  endpoints: {
    getInvoiceSettings,
    updateInvoiceSettings,
    getInvoice,
    getInvoices,
    previewInvoiceEmail,
    previewJobSheetPdf,
  },
} = invoiceApi;
