import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import type { Campaign } from '../types/campaign';
import { Meta } from 'src/types/pagination';

interface CampaignState {
  searchText?: string;
  limit?: number;
  page?: number;
  total?: number;
  lastPage?: number;
  orderBy: string;
  order: SortDirection;
  campaigns: Campaign[];
}

const initialState: CampaignState = {
  searchText: '',
  limit: 9,
  page: 1,
  total: 0,
  lastPage: 0,
  orderBy: 'name',
  order: 'asc',
  campaigns: [],
};

const slice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setLimit(state: CampaignState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setMeta(state: CampaignState, action: PayloadAction<{ meta: Meta }>) {
      const { meta } = action.payload;

      state.total = meta.total;
      state.lastPage = meta.last_page;
    },
    setPage(state: CampaignState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setSearchText(state: CampaignState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
  },
});

export const { reducer } = slice;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setMeta = (meta: Meta) => (dispatch) => {
  dispatch(slice.actions.setMeta({ meta }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export default slice;
