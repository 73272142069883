import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'src/store';
import { Contact, VendContact } from 'src/types/contact';
import { Organisation } from 'src/types/organisation';
import { VendOrganisation } from 'src/types/vend';
import { ContactMatch } from 'src/types/vend-integration';
import { parseBoolByStr } from 'src/utils/bool';
import { wait } from 'src/utils/wait';

const CONTACTS_COUNT_PER_PAGE = 100;
const CONTACTS_UNMATCHED_COUNT_PER_PAGE = 100;

const LS_CURRENT_STEP = 'vendSyncContacts_currentStep';
const LS_CONTACTS_PAGE = 'vendSynContacts_contactsPage';
const LS_CONTACTS_UNMATCHED_VEND_PAGE = 'vendSynContacts_contactsUnmatchedVendPage';
const LS_CONTACTS_UNMATCHED_PT_PAGE = 'vendSynContacts_contactsUnmatchedPtPage';

const POLL_MATCHING_CONTACTS_DELAY = 1000;
const POLL_DELAY = 2000;

export const UNSELECTED_VEND_CONTACT_ID = '-1';
const MATCH_FILTERS = [
  {
    name: 'by_contact_company_name',
    value: 1,
  },
  {
    name: 'by_company_name',
    value: 1,
  },
  {
    name: 'by_email',
    value: 1,
  },
  {
    name: 'by_address',
    value: 1,
  },
  {
    name: 'by_name',
    value: 1,
  },
  {
    name: 'by_phone',
    value: 1,
  },
];

export enum VendIntegrationSyncContactsStep {
  // step 1
  matchingContactsProgress = 'matchingContactsProgress',
  // step 2
  matchingContactsSelection = 'matchingContactsSelection',
  // step 3
  unmatchedVendContacts = 'unmatchedVendContacts',
  // step 4
  unmatchedPtContacts = 'unmatchedPtContacts',
  // step 5
  synchronizingProgress = 'synchronizingProgress',
}

type MapContactIdToVendContactId = {
  [contactId: number]: string;
};

interface VendIntegrationSyncContactsState {
  currentStep?: VendIntegrationSyncContactsStep;
  isLoading: boolean;
  isLoadingBack: boolean;
  isSynced: boolean;

  contacts: ContactMatch[];
  currentContactsPage: number;

  // selected matches
  mapContactIdToSelectedVendContactId: MapContactIdToVendContactId;

  // statistics
  totalContacts: number;
  handledContacts: number;

  unmatchedVendContacts: VendContact[];
  ignoreUnmatchedVendContactsIds: string[];
  unmatchedVendContactsPage: number;
  unmatchedVendContactsTotal: number;
  unmatchedVendContactsLatestPage: number;

  unmatchedPtContacts: Contact[];
  ignoreUnmatchedPtContactsIds: number[];
  unmatchedPtContactsPage: number;
  unmatchedPtContactsTotal: number;
  unmatchedPtContactsLatestPage: number;

  // synchronization statistics
  synchronizationProcessedCount: number;
  synchronizationTotalQueuedCount: number;

  latestStep?: VendIntegrationSyncContactsStep;
}

const initialState: VendIntegrationSyncContactsState = {
  currentStep: VendIntegrationSyncContactsStep.matchingContactsProgress,
  isLoading: false,
  isLoadingBack: false,
  isSynced: false,

  contacts: [],
  currentContactsPage: 1,

  mapContactIdToSelectedVendContactId: {},

  totalContacts: 0,
  handledContacts: 0,

  unmatchedVendContacts: [],
  ignoreUnmatchedVendContactsIds: [],
  unmatchedVendContactsPage: 1,
  unmatchedVendContactsTotal: 0,
  unmatchedVendContactsLatestPage: 0,

  unmatchedPtContacts: [],
  ignoreUnmatchedPtContactsIds: [],
  unmatchedPtContactsPage: 1,
  unmatchedPtContactsTotal: 0,
  unmatchedPtContactsLatestPage: 0,

  synchronizationProcessedCount: 0,
  synchronizationTotalQueuedCount: 0,

  latestStep: null,
};

const slice = createSlice({
  name: 'vendIntegrationSyncContacts',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    initialize(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{
        currentContactsPage: number;
        unmatchedVendContactsPage: number;
        unmatchedPtContactsPage: number;
      }>
    ) {
      state.currentContactsPage = action.payload.currentContactsPage;
      state.unmatchedVendContactsPage = action.payload.unmatchedVendContactsPage;
      state.unmatchedPtContactsPage = action.payload.unmatchedPtContactsPage;
    },
    setStep(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{ step: VendIntegrationSyncContactsStep }>
    ) {
      state.currentStep = action.payload.step;
    },
    startLoading(state: VendIntegrationSyncContactsState) {
      state.isLoading = true;
    },
    startLoadingBack(state: VendIntegrationSyncContactsState) {
      state.isLoadingBack = true;
    },
    stopLoading(state: VendIntegrationSyncContactsState) {
      state.isLoading = false;
      state.isLoadingBack = false;
    },
    setContacts(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{
        contacts: ContactMatch[];
        totalCount: number;
        page: number;
      }>
    ) {
      const { contacts, page, totalCount } = action.payload;
      state.contacts = contacts;
      state.currentContactsPage = page;
      state.mapContactIdToSelectedVendContactId = {};
      state.totalContacts = totalCount;
      state.handledContacts = (page - 1) * CONTACTS_COUNT_PER_PAGE;

      state.contacts.forEach((contact) => {
        const mostSuitable = contact.matchable.reduce((prevMatch, currMatch) =>
          prevMatch.probability > currMatch.probability ? prevMatch : currMatch
        );
        state.mapContactIdToSelectedVendContactId[contact.id] =
          mostSuitable.vend_contact.vend_contact_id;
      });
    },
    setUnmatchedVendContacts(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{
        contacts: VendContact[];
        page: number;
        total: number;
      }>
    ) {
      const { contacts, page, total } = action.payload;
      state.ignoreUnmatchedVendContactsIds = [];
      state.unmatchedVendContactsPage = page;
      state.unmatchedVendContacts = contacts;
      state.unmatchedVendContactsTotal = total;
      state.unmatchedPtContactsLatestPage = Math.ceil(total / CONTACTS_UNMATCHED_COUNT_PER_PAGE);
    },
    setUnmatchedPtContacts(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{
        contacts: Contact[];
        page: number;
        total: number;
      }>
    ) {
      const { contacts, page, total } = action.payload;

      state.ignoreUnmatchedPtContactsIds = [];
      state.unmatchedPtContacts = contacts;
      state.unmatchedPtContactsPage = page;
      state.unmatchedPtContactsTotal = total;
      state.unmatchedPtContactsLatestPage = Math.ceil(total / CONTACTS_UNMATCHED_COUNT_PER_PAGE);
    },
    selectMatch(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{ vendContactId: string; contactId: number | null }>
    ) {
      const { vendContactId, contactId } = action.payload;

      // remove duplicated selection for `contactId`
      Object.keys(state.mapContactIdToSelectedVendContactId).forEach((key) => {
        if (state.mapContactIdToSelectedVendContactId[key] === vendContactId) {
          state.mapContactIdToSelectedVendContactId[key] = UNSELECTED_VEND_CONTACT_ID;
        }
      });

      state.mapContactIdToSelectedVendContactId[contactId] = vendContactId;
    },
    ignoreVendContact(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{ vendContactId: string; ignored: boolean }>
    ) {
      const { vendContactId, ignored } = action.payload;

      if (ignored) {
        state.ignoreUnmatchedVendContactsIds = [
          ...state.ignoreUnmatchedVendContactsIds,
          vendContactId,
        ];
      } else {
        state.ignoreUnmatchedVendContactsIds = state.ignoreUnmatchedVendContactsIds.filter(
          (id) => id !== vendContactId
        );
      }
    },
    ignoreAllVendContacts(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{ ignored: boolean }>
    ) {
      if (action.payload.ignored) {
        state.ignoreUnmatchedVendContactsIds = state.unmatchedVendContacts.map(
          ({ vend_contact_id }) => vend_contact_id
        );
      } else {
        state.ignoreUnmatchedVendContactsIds = [];
      }
    },
    ignorePtContact(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{ contactId: number; ignored: boolean }>
    ) {
      const { contactId, ignored } = action.payload;

      if (ignored) {
        state.ignoreUnmatchedPtContactsIds = [...state.ignoreUnmatchedPtContactsIds, contactId];
      } else {
        state.ignoreUnmatchedPtContactsIds = state.ignoreUnmatchedPtContactsIds.filter(
          (id) => id !== contactId
        );
      }
    },
    ignoreAllPtContacts(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{ ignored: boolean }>
    ) {
      if (action.payload.ignored) {
        state.ignoreUnmatchedPtContactsIds = state.unmatchedPtContacts.map(({ id }) => id);
      } else {
        state.ignoreUnmatchedPtContactsIds = [];
      }
    },
    updateSynchronizationStatus(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{ total: number; processed: number }>
    ) {
      state.synchronizationTotalQueuedCount = action.payload.total;
      state.synchronizationProcessedCount = action.payload.processed;
    },
    synced(state: VendIntegrationSyncContactsState) {
      state.isSynced = true;
    },
    setLatestStep(
      state: VendIntegrationSyncContactsState,
      action: PayloadAction<{ latestStep?: VendIntegrationSyncContactsStep }>
    ) {
      state.latestStep = action.payload.latestStep;
    },
  },
});

export const { reducer } = slice;
export const {
  selectMatch,
  ignoreVendContact,
  ignoreAllVendContacts,
  ignorePtContact,
  ignoreAllPtContacts,
} = slice.actions;

export default slice;

export const reset = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.reset());
  localStorage.removeItem(LS_CURRENT_STEP);
  localStorage.removeItem(LS_CONTACTS_PAGE);
  localStorage.removeItem(LS_CONTACTS_UNMATCHED_VEND_PAGE);
  localStorage.removeItem(LS_CONTACTS_UNMATCHED_PT_PAGE);
};

const setStep =
  (step: VendIntegrationSyncContactsStep): AppThunk =>
  (dispatch) => {
    localStorage.setItem(LS_CURRENT_STEP, step);
    dispatch(slice.actions.setStep({ step }));
  };

const setMatchingContactsStep = (): AppThunk => (dispatch) => {
  dispatch(setStep(VendIntegrationSyncContactsStep.matchingContactsProgress));
};

const setSelectContactsStep =
  (contacts: ContactMatch[], totalCount: number, page: number): AppThunk =>
  (dispatch) => {
    dispatch(setStep(VendIntegrationSyncContactsStep.matchingContactsSelection));
    dispatch(slice.actions.setContacts({ contacts, totalCount, page }));
  };

const setUnmatchedVendContactsStep =
  (contacts: VendContact[], total: number, page: number): AppThunk =>
  (dispatch) => {
    dispatch(setStep(VendIntegrationSyncContactsStep.unmatchedVendContacts));
    dispatch(slice.actions.setUnmatchedVendContacts({ contacts, page, total }));
  };

const setUnmatchedPtContactsStep =
  (contacts: Contact[], total: number, page: number): AppThunk =>
  (dispatch) => {
    dispatch(setStep(VendIntegrationSyncContactsStep.unmatchedPtContacts));
    dispatch(slice.actions.setUnmatchedPtContacts({ contacts, page, total }));
  };

const setSynchronizingContactsStep = (): AppThunk => (dispatch) => {
  dispatch(setStep(VendIntegrationSyncContactsStep.synchronizingProgress));
};

const synced = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.synced());
  localStorage.removeItem(LS_CURRENT_STEP);
};

const resolveLatestStep =
  (organisationId: number): AppThunk =>
  async (dispatch) => {
    const response = await axios.get(
      `v2/organisations/${organisationId}/vend/not-matched-contacts`,
      { params: { page: 1, limit: 1 } }
    );

    let latestStep: VendIntegrationSyncContactsStep | null = null;

    const totalUnmatchedPtContacts = response.data.meta.total;

    if (totalUnmatchedPtContacts === 0) {
      latestStep = VendIntegrationSyncContactsStep.unmatchedVendContacts;
    } else {
      latestStep = VendIntegrationSyncContactsStep.unmatchedPtContacts;
    }

    dispatch(slice.actions.setLatestStep({ latestStep }));
  };

// Sequence of calling methods below:
// 1. initialize
// 2. pollMatchingContacts
// 3. handleCurrentPageAndLoadMore
// 4. handleCurrentPageOfUnmatchedVendAndLoadMore
// 5. handleCurrentPageOfUnmatchedPtAndLoadMore
// 6. synchronizeContacts

/**
 * STEP: Syncing contacts
 * @param organisationId organisationId
 * @returns void
 */
const synchronizeContacts =
  (organisationId: number, isSyncingContacts: boolean): AppThunk =>
  async (dispatch) => {
    if (!isSyncingContacts) {
      await axios.post(`v1/organisations/${organisationId}/vend/run-contacts-sync`);
    }

    dispatch(setSynchronizingContactsStep());

    async function pollStatus() {
      try {
        const { data } = await axios.get(
          `v1/organisations/${organisationId}/vend/sync-contacts-status`
        );
        dispatch(
          slice.actions.updateSynchronizationStatus({
            total: data.totalQueued,
            processed: data.totalProcessed,
          })
        );
        if (data.isSynced) {
          dispatch(synced());
          return null;
        }

        await wait(POLL_DELAY);
        return pollStatus();
      } catch (e) {
        console.error(e);
        await wait(POLL_DELAY);
        return pollStatus();
      }
    }

    await pollStatus();
  };

/**
 * STEP: Create or ignore Pt contacts
 * ---
 * - applies contacts ids for current part of data
 * - loads next page
 * - goes to the next step if there is no data (STEP: Syncing contacts)
 * @param organisationId organisationId
 * @param currentContactsPage currentContactsPage
 * @param ignoredUnmatchedVendContactsIds ids of contacts to ignore
 * @returns void
 */
export const handleCurrentPageOfUnmatchedPtAndLoadMore =
  (
    organisationId: number,
    currentContactsPage: number,
    ignoredUnmatchedPtContactsIds: number[],
    unmatchedPtContacts: Contact[]
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const page = currentContactsPage + 1;
    // apply current part of data
    const ignoreVendContacts = ignoredUnmatchedPtContactsIds.map((id) => ({
      id,
    }));
    const syncVendContacts = unmatchedPtContacts
      .filter((contact) => !ignoredUnmatchedPtContactsIds.includes(contact.id))
      .map((contact) => ({
        id: contact.id,
        vend_contact_id: contact.vend_contact_id,
      }));
    const payload = {
      ignore_contacts: ignoreVendContacts,
      contacts: syncVendContacts,
    };
    const hasPayload = payload.ignore_contacts.length !== 0 || payload.contacts.length !== 0;

    if (hasPayload) {
      await axios.post(`v1/organisations/${organisationId}/vend/sync-pooltrackr-contacts`, payload);
    }

    // load next part of data
    const response = await axios.get(
      `v2/organisations/${organisationId}/vend/not-matched-contacts`,
      { params: { page, limit: CONTACTS_UNMATCHED_COUNT_PER_PAGE } }
    );

    const contacts = response.data.data as Contact[];
    dispatch(slice.actions.stopLoading());

    // in case of empty data - go to the next step
    if (contacts.length === 0) {
      dispatch(synchronizeContacts(organisationId, false));
      return;
    }

    localStorage.setItem(LS_CONTACTS_UNMATCHED_PT_PAGE, page.toString());
    dispatch(setUnmatchedPtContactsStep(contacts, response.data.meta.total, page));
  };

/**
 * STEP: Create or ignore Vend contacts
 * ---
 * - applies contacts ids for current part of data
 * - loads next page
 * - goes to the next step if there is no data (STEP: Create or ignore Pt contacts)
 * @param organisationId organisationId
 * @param currentContactsPage currentContactsPage
 * @param ignoredUnmatchedVendContactsIds ids of contacts to ignore
 * @param unmatchedVendContacts
 * @returns void
 */
export const handleCurrentPageOfUnmatchedVendAndLoadMore =
  (
    organisationId: number,
    currentContactsPage: number,
    ignoredUnmatchedVendContactsIds: string[],
    unmatchedVendContacts: VendContact[]
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const page = currentContactsPage + 1;

    // apply current part of data
    const ignoreVendContacts = ignoredUnmatchedVendContactsIds.map((id) => ({
      vend_contact_id: id,
    }));
    const syncVendContacts = unmatchedVendContacts
      .filter((contact) => !ignoredUnmatchedVendContactsIds.includes(contact.vend_contact_id))
      .map((contact) => ({ vend_contact_id: contact.vend_contact_id }));
    const payload = {
      ignore_vend_contacts: ignoreVendContacts,
      vend_contacts: syncVendContacts,
    };
    const hasPayload =
      payload.ignore_vend_contacts.length !== 0 || payload.vend_contacts.length !== 0;

    if (hasPayload) {
      await axios.post(`v1/organisations/${organisationId}/vend/sync-vend-contacts`, payload);
    }

    // load next part of data
    const response = await axios.get(
      `v2/organisations/${organisationId}/vend/not-matched-vend-contacts`,
      { params: { page, limit: CONTACTS_UNMATCHED_COUNT_PER_PAGE } }
    );

    const vendContacts = response.data.data as VendContact[];

    dispatch(slice.actions.stopLoading());

    // in case of empty data - go to the next step
    if (vendContacts.length === 0) {
      dispatch(handleCurrentPageOfUnmatchedPtAndLoadMore(organisationId, 0, [], []));
      return;
    }

    localStorage.setItem(LS_CONTACTS_UNMATCHED_VEND_PAGE, page.toString());
    dispatch(setUnmatchedVendContactsStep(vendContacts, response.data.meta.total, page));
  };

const pollMatchingContacts =
  (organisationId: number): AppThunk =>
  async () => {
    async function poll() {
      const response = await axios.get(`v2/organisations/${organisationId}/vend`);
      const vend = response.data as VendOrganisation;
      const isMatchingContacts = parseBoolByStr(vend.is_matching_contacts);

      if (!isMatchingContacts) {
        return null;
      }

      await wait(POLL_MATCHING_CONTACTS_DELAY);
      return poll();
    }

    await poll();
  };

export const handleCurrentPageAndLoadMore =
  (
    organisationId: number,
    currentContactsPage: number,
    mapContactIdToSelectedVendContactId: MapContactIdToVendContactId
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const page = currentContactsPage + 1;

    // push matches for current page to the server
    if (Object.keys(mapContactIdToSelectedVendContactId).length !== 0) {
      const payload = {
        contacts: Object.entries(mapContactIdToSelectedVendContactId).map(
          ([contactId, vendContactId]) => ({
            id: contactId,
            vend_contact_id: vendContactId === UNSELECTED_VEND_CONTACT_ID ? null : vendContactId,
          })
        ),
      };
      await axios.post(`v1/organisations/${organisationId}/vend/sync-pooltrackr-contacts`, payload);
    }

    // get next part of data for matching
    const response = await axios.post(
      `v2/organisations/${organisationId}/vend/contact-matches`,
      {
        match_filters: MATCH_FILTERS,
      },
      { params: { limit: CONTACTS_COUNT_PER_PAGE, page, include: 'phones' } }
    );
    const contacts = response.data.data as ContactMatch[];
    const totalCount: number = response.data.meta.total;

    dispatch(slice.actions.stopLoading());

    // there is no data - go to the next page
    if (contacts.length === 0) {
      dispatch(resolveLatestStep(organisationId));
      dispatch(handleCurrentPageOfUnmatchedVendAndLoadMore(organisationId, 0, [], []));
      return;
    }

    // it has data - leave at the current step
    localStorage.setItem(LS_CONTACTS_PAGE, page.toString());
    dispatch(setSelectContactsStep(contacts, totalCount, page));
  };

export const matchingContactsProgress =
  (organisation: Organisation): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoadingBack());

    if (organisation.vend_organisation.is_matching_contacts) {
      dispatch(setMatchingContactsStep());
      await dispatch(pollMatchingContacts(organisation.id));
    }

    await dispatch(handleCurrentPageAndLoadMore(organisation.id, 0, {}));

    dispatch(slice.actions.stopLoading());
  };

export const initialize =
  (organisation: Organisation): AppThunk =>
  async (dispatch) => {
    const currentContactsPage = Number(localStorage.getItem(LS_CONTACTS_PAGE) ?? 1);
    const unmatchedVendContactsPage = Number(
      localStorage.getItem(LS_CONTACTS_UNMATCHED_VEND_PAGE) ?? 1
    );
    const unmatchedPtContactsPage = Number(
      localStorage.getItem(LS_CONTACTS_UNMATCHED_PT_PAGE) ?? 1
    );

    dispatch(
      slice.actions.initialize({
        currentContactsPage,
        unmatchedVendContactsPage,
        unmatchedPtContactsPage,
      })
    );

    if (organisation.vend_organisation.is_syncing_contacts) {
      dispatch(
        synchronizeContacts(organisation.id, organisation.vend_organisation.is_syncing_contacts)
      );
      return;
    }

    const savedStep = localStorage.getItem(LS_CURRENT_STEP);

    if (savedStep === VendIntegrationSyncContactsStep.matchingContactsProgress) {
      dispatch(matchingContactsProgress(organisation));
    } else if (savedStep === VendIntegrationSyncContactsStep.matchingContactsSelection) {
      await dispatch(handleCurrentPageAndLoadMore(organisation.id, currentContactsPage - 1, {}));
    } else if (savedStep === VendIntegrationSyncContactsStep.unmatchedVendContacts) {
      await dispatch(resolveLatestStep(organisation.id));
      await dispatch(
        handleCurrentPageOfUnmatchedVendAndLoadMore(
          organisation.id,
          unmatchedVendContactsPage - 1,
          [],
          []
        )
      );
    } else if (savedStep === VendIntegrationSyncContactsStep.unmatchedPtContacts) {
      await dispatch(resolveLatestStep(organisation.id));
      await dispatch(
        handleCurrentPageOfUnmatchedPtAndLoadMore(
          organisation.id,
          unmatchedPtContactsPage - 1,
          [],
          []
        )
      );
    } else if (savedStep === VendIntegrationSyncContactsStep.synchronizingProgress) {
      await dispatch(synchronizeContacts(organisation.id, true));
    } else {
      dispatch(matchingContactsProgress(organisation));
    }
  };

export const back =
  (organisationId: number, currentContactsPage: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoadingBack());

    const page = currentContactsPage - 1;
    const response = await axios.post(
      `v2/organisations/${organisationId}/vend/contact-matches`,
      {
        match_filters: MATCH_FILTERS,
      },
      { params: { limit: CONTACTS_COUNT_PER_PAGE, page } }
    );
    const contacts = response.data.data as ContactMatch[];
    const totalCount: number = response.data.meta.total;
    dispatch(setSelectContactsStep(contacts, totalCount, page));

    localStorage.setItem(LS_CONTACTS_PAGE, page.toString());
    dispatch(slice.actions.stopLoading());
  };

/**
 * goes to the prev page of unmatched vend
 * or goes to the prev step
 * @param organisationId organisationId
 * @param currentContactsPageUnmatchedVend current page for unmatched vend list
 * @param currentContactsPage current page of contacts
 * @returns void
 */
export const backUnmatchedVend =
  (
    organisationId: number,
    currentContactsPageUnmatchedVend: number,
    currentContactsPage: number
  ): AppThunk =>
  async (dispatch) => {
    // If this is the first page - go to the prev step
    if (currentContactsPageUnmatchedVend === 1) {
      dispatch(back(organisationId, currentContactsPage + 1));
      return;
    }

    dispatch(slice.actions.startLoadingBack());

    const page = currentContactsPageUnmatchedVend - 1;
    const response = await axios.get(
      `v2/organisations/${organisationId}/vend/not-matched-vend-contacts`,
      { params: { page, limit: CONTACTS_UNMATCHED_COUNT_PER_PAGE } }
    );
    const contacts = response.data.data as VendContact[];
    dispatch(setUnmatchedVendContactsStep(contacts, response.data.meta.total, page));
    localStorage.setItem(LS_CONTACTS_UNMATCHED_VEND_PAGE, page.toString());
    dispatch(slice.actions.stopLoading());
  };

/**
 * goes to the prev page of unmatched Pooltrackr contacts
 * or goes to the prev step
 * @param organisationId organisationId
 * @param currentContactsPageUnmatchedPtContacts
 * @param currentContactsPageUnmatchedVend current page for unmatched vend list
 * @param currentContactsPage current page of contacts
 * @returns void
 */
export const backUnmatchedPtContacts =
  (
    organisationId: number,
    currentContactsPageUnmatchedPtContacts: number,
    currentContactsPageUnmatchedVend: number,
    currentContactsPage: number
  ): AppThunk =>
  async (dispatch) => {
    // If this is the first page - go to the prev step
    if (currentContactsPageUnmatchedPtContacts === 1) {
      dispatch(
        backUnmatchedVend(organisationId, currentContactsPageUnmatchedVend + 1, currentContactsPage)
      );
      return;
    }

    dispatch(slice.actions.startLoadingBack());

    const page = currentContactsPageUnmatchedPtContacts - 1;
    const response = await axios.get(
      `v2/organisations/${organisationId}/vend/not-matched-contacts`,
      { params: { page, limit: CONTACTS_UNMATCHED_COUNT_PER_PAGE } }
    );
    const contacts = response.data.data as Contact[];
    dispatch(setUnmatchedPtContactsStep(contacts, response.data.meta.total, page));
    localStorage.setItem(LS_CONTACTS_UNMATCHED_PT_PAGE, page.toString());
    dispatch(slice.actions.stopLoading());
  };
