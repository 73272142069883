import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { BatchSettings } from 'src/types/invoice';
import { baseQueryWithInterceptor } from './base-query';
import {
  IDeleteInvoice,
  IGetBatchInvoices,
  IMarkInvoice,
  IUpdateBatchSettings,
} from './model/invoice.model';

export const invoiceApi = createApi({
  reducerPath: 'batchInvoiceApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['BatchInvoice', 'BatchInvoices', 'BatchSettings'],
  endpoints: (builder) => ({
    getBatchInvoices: builder.query<any, IGetBatchInvoices>({
      query: (param: IGetBatchInvoices) => ({
        url: `v2/organisation/${param.organisationId}/batch-invoice`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'BatchInvoices' as const, id })),
              { type: 'BatchInvoices', id: 'LIST' },
            ]
          : [{ type: 'BatchInvoices', id: 'LIST' }],
    }),
    deleteBatchInvoice: builder.mutation<any, IDeleteInvoice>({
      query: (param: IDeleteInvoice) => ({
        url: `v2/organisation/${param.organisationId}/batch-invoice/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'BatchInvoice', id: param.id },
        { type: 'BatchInvoices', id: param.id },
      ],
    }),
    markInvoice: builder.mutation<any, IMarkInvoice>({
      query: (param: IMarkInvoice) => ({
        url: `v2/organisation/${param.organisationId}/batch-invoice/${param.id}/mark`,
        method: 'POST',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'BatchInvoice', id: param.id },
        { type: 'BatchInvoices', id: 'LIST' },
      ],
    }),
    getBatchSettings: builder.query<BatchSettings, number>({
      query: (id) => `v1/organisations/${id}/batch-settings`,
      providesTags: (result, error, id) => [{ type: 'BatchSettings', id }],
    }),
    updateBatchSettings: builder.mutation<BatchSettings, IUpdateBatchSettings>({
      query: (param: IUpdateBatchSettings) => ({
        url: `v1/organisations/${param.organisationId}/batch-settings`,
        method: 'PUT',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'BatchSettings', id: param.organisationId },
      ],
    }),
  }),
});

export const {
  useMarkInvoiceMutation,
  useGetBatchSettingsQuery,
  useUpdateBatchSettingsMutation,
  useGetBatchInvoicesQuery,
  useDeleteBatchInvoiceMutation,
} = invoiceApi;

export const {
  endpoints: { getBatchSettings, updateBatchSettings, getBatchInvoices, deleteBatchInvoice },
} = invoiceApi;
