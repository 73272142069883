import { PickByType } from './ts';

export function providesList<R extends { id?: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id: id || null })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}

export function providesListByKey<R, T extends string>(
  resultsWithIds: R[] | undefined,
  key: keyof PickByType<R, string | number>,
  tagType: T
) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map((object) => ({ type: tagType, id: object[key] })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}
