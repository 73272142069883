export const technicianColors = [
  '#46d6db',
  '#a4bdfc',
  '#51b749',
  '#fbd75b',
  '#ffb878',
  '#ff887c',
  '#7ae7bf',
  '#dbadff',
  '#e1e1e1',
  '#ce6c70',
];

export const UNASSIGNED_CALENDAR_RESOURCE_ID = 'UNASSIGNED';
export const MIN_ROW_HEIGHT = 60;
