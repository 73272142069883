import { TestOrderColumn } from 'src/types/chemical';

export const ORDERED_RECOMMENDATIONS_ID = 'ordered';
export const UNORDERED_RECOMMENDATIONS_ID = 'unordered';

export const RECOMMENDATIONS_ORDER_COLUMNS: TestOrderColumn[] = [
  {
    id: UNORDERED_RECOMMENDATIONS_ID,
    name: 'Unordered tests',
    cardIds: [],
  },
  {
    id: ORDERED_RECOMMENDATIONS_ID,
    name: 'Recommendations order',
    cardIds: [],
  },
];
