import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { jobApi } from 'src/api/job';
import { JobEvent } from 'src/types/job';

interface CalendarMoveJobModal {
  open: boolean;
  jobEvent: JobEvent | null;
  isMoving: boolean;
}

const initialState: CalendarMoveJobModal = {
  open: false,
  jobEvent: null,
  isMoving: false,
};

const slice = createSlice({
  name: 'calendarMoveJobModal',
  initialState,
  reducers: {
    open(state: CalendarMoveJobModal, action: PayloadAction<{ jobEvent: JobEvent }>): void {
      state.open = true;
      state.jobEvent = action.payload.jobEvent;
    },
    close(state: CalendarMoveJobModal): void {
      if (!state.isMoving) {
        state.open = false;
        state.jobEvent = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(jobApi.endpoints.rescheduleJobs.matchPending, (state, action) => {
        state.isMoving = true;
      })
      .addMatcher(jobApi.endpoints.rescheduleJobs.matchFulfilled, (state, action) => {
        state.isMoving = false;
      })
      .addMatcher(jobApi.endpoints.rescheduleJobs.matchRejected, (state, action) => {
        state.isMoving = false;
      });
  },
});

export const { reducer } = slice;
export const { open, close } = slice.actions;

export default slice;
