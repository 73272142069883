import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Job } from '../types/job';
import type { LabJob } from '../types/labJob';
import type { Pool } from '../types/pool';
import type {
  ChemicalGroup,
  ObservationGroup,
  ChemicalTest,
  ObservationTest,
} from 'src/lib/chemical-calculator/src/types/chemical';
import { ChemicalResult, ObservationResult } from 'src/types/water-test';
import { parse } from 'date-fns';

interface WaterTestCalculatorState {
  initialised: boolean;
  job: LabJob | Job;
  nextTestDate: null | Date;
  pool: Pool;
  chemicalGroups: ChemicalGroup[];
  observationGroups: ObservationGroup[];
  chemicalTests: ChemicalTest[];
  chemicalResults: ChemicalResult[];
  savedChemicalResults: ChemicalResult[];
  observationTests: ObservationTest[];
  observationResults: ObservationResult[];
  savedObservationResults: ObservationResult[];
}

const initialState: WaterTestCalculatorState = {
  initialised: false,
  job: null,
  nextTestDate: null,
  pool: null,
  chemicalGroups: [],
  observationGroups: [],
  chemicalTests: [],
  chemicalResults: [],
  savedChemicalResults: [],
  observationTests: [],
  observationResults: [],
  savedObservationResults: [],
};

const slice = createSlice({
  name: 'waterTestCalculator',
  initialState,
  reducers: {
    reset: () => initialState,
    init(
      state: WaterTestCalculatorState,
      action: PayloadAction<{
        job: LabJob | Job;
        pool: Pool;
        chemicalGroups: ChemicalGroup[];
        observationGroups: ObservationGroup[];
        chemicalTests: ChemicalTest[];
        observationTests: ObservationTest[];
      }>
    ) {
      const { job, pool, chemicalTests, observationTests, chemicalGroups, observationGroups } =
        action.payload;

      state.nextTestDate = job?.next_test_date
        ? parse(job?.next_test_date, 'yyyy-MM-dd', new Date())
        : null;
      state.chemicalGroups = chemicalGroups;
      state.observationGroups = observationGroups;
      state.chemicalTests = chemicalTests;
      state.savedChemicalResults = job?.chemical_results.map((chemicalResult: ChemicalResult) => {
        const chemicalTest: ChemicalTest = state.chemicalTests.find(
          (chemicalTest) =>
            chemicalTest.id === chemicalResult.chemical_test_id ||
            chemicalTest.id === parseInt(chemicalResult?.chemicals?.chemical_id, 10) // TODO: remove when migrated
        );
        const chemicalGroup: ChemicalGroup = state.chemicalGroups.find(
          (chemicalGroup) => chemicalGroup.id === chemicalResult.chemical_group_id
        );

        return {
          id: chemicalResult.id,
          value: chemicalResult.value,
          side_effect_value: 0,
          min_value: chemicalResult.min_value,
          max_value: chemicalResult.max_value,
          target_value: chemicalResult.target_value,
          show_on_report: chemicalResult.show_on_report,
          status: chemicalResult.status,
          enabled: true,
          name: chemicalTest.name,
          action: chemicalResult.action,
          description: chemicalResult.description,
          short_description: chemicalResult.short_description,
          is_saved: true,
          chemical_test_id: chemicalTest.id,
          chemical_test: chemicalTest,
          chemical_group_id: chemicalResult.chemical_group_id || null,
          chemical_group: chemicalGroup || null,
          selected_recommendation: null,
          recommendations: [],
        } as ChemicalResult;
      });
      state.observationTests = observationTests;
      state.savedObservationResults = job?.observation_results.map(
        (observationResult: ObservationResult) => {
          const observationTest: ObservationTest = state.observationTests.find(
            (observationTest) => observationTest.id === observationResult.observation_test_id
          );
          const observationGroup: ObservationGroup = state.observationGroups.find(
            (observationGroup) => observationGroup.id === observationResult.observation_group_id
          );

          return {
            id: observationResult.id,
            value: true,
            show_on_report: observationResult.show_on_report,
            name: observationTest.name,
            action: observationResult.action,
            description: observationResult.description,
            enabled: true,
            is_saved: true,
            observation_test_id: observationTest.id,
            observation_test: observationTest,
            observation_group_id: observationResult.observation_group_id || null,
            observation_group: observationGroup || null,
          } as ObservationResult;
        }
      );

      state.job = job;
      state.pool = pool;
      state.initialised = true;
    },
    updateChemicalResults(
      state: WaterTestCalculatorState,
      action: PayloadAction<{ chemicalResults: ChemicalResult[] }>
    ) {
      const { chemicalResults } = action.payload;

      state.chemicalResults = chemicalResults;
    },
    updateObservationResults(
      state: WaterTestCalculatorState,
      action: PayloadAction<{ observationResults: ObservationResult[] }>
    ) {
      const { observationResults } = action.payload;

      state.observationResults = observationResults;
    },
    setNextTestDate(state: WaterTestCalculatorState, action: PayloadAction<Date>): void {
      state.nextTestDate = action.payload;
    },
  },
});

export const { reducer } = slice;

export const init =
  (
    job: LabJob | Job,
    pool: Pool,
    chemicalTests: ChemicalTest[],
    observationTests: ObservationTest[],
    chemicalGroups: ChemicalGroup[],
    observationGroups: ObservationGroup[]
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(
      slice.actions.init({
        job,
        pool,
        chemicalTests,
        observationTests,
        chemicalGroups,
        observationGroups,
      })
    );
  };

export const { reset } = slice.actions;
export const { setNextTestDate } = slice.actions;

export const updateChemicalResults =
  (chemicalResults: ChemicalResult[]): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateChemicalResults({ chemicalResults }));
  };

export const updateObservationResults =
  (observationResults: ObservationResult[]): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateObservationResults({ observationResults }));
  };

export default slice;
