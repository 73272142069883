import type { PhoneOption, TypeOption } from 'src/types/contact';

export const INVOICE_PREF_EMAIL = 1;
export const INVOICE_PREF_NOT_SEND = 3;

export const INDIVIDUAL_TYPE = 1;
export const COMPANY_TYPE = 2;

export const INDIVIDUAL_LABEL = 'Individual';
export const COMPANY_LABEL = 'Company';

export const INVOICE_PREFERENCES = [
  {
    value: INVOICE_PREF_EMAIL,
    label: 'Email',
  },
  {
    value: INVOICE_PREF_NOT_SEND,
    label: 'Do not send',
  },
];

export const CONTACT_TYPES: TypeOption[] = [
  {
    value: INDIVIDUAL_TYPE,
    label: INDIVIDUAL_LABEL,
  },
  {
    value: COMPANY_TYPE,
    label: COMPANY_LABEL,
  },
];

export const PHONE = 1;
export const MOBILE = 2;
export const HOME = 3;
export const OFFICE = 4;
export const ASSISTANT = 5;
export const GUARD = 6;

export const PHONE_LABEL = 'PHONE';
export const MOBILE_LABEL = 'MOBILE';
export const HOME_LABEL = 'HOME';
export const OFFICE_LABEL = 'OFFICE';
export const ASSISTANT_LABEL = 'ASSISTANT';
export const GUARD_LABEL = 'GUARD';

export const PHONE_TYPES: PhoneOption[] = [
  {
    label: PHONE_LABEL,
    value: PHONE,
  },
  {
    label: MOBILE_LABEL,
    value: MOBILE,
  },
  {
    label: HOME_LABEL,
    value: HOME,
  },
  {
    label: OFFICE_LABEL,
    value: OFFICE,
  },
  {
    label: ASSISTANT_LABEL,
    value: ASSISTANT,
  },
  {
    label: GUARD_LABEL,
    value: GUARD,
  },
];

export const MOBILE_PATTERNS = {
  AU: new RegExp(
    /^((?:\+61|0)4(?:[01]\d{3}|(?:2[1-9]|3[0-57-9]|4[7-9]|5[0-15-9]|6[679]|7[3-8]|8[1478]|9[07-9])\d{2}|(?:20[2-9]|444|52[0-6]|68[3-9]|70[0-7]|79[01]|820|890|91[0-4])\d|(?:200[0-3]|201[01]|8984))\d{4}$)|((?:\+?7|8)91(?:9|8)(?:\d{7})$)/
  ),
  NZ: new RegExp(/(^[0][2]{1})(\d{6,7})|(^(?:\+?[6][4][2]{1}))(\d{6,7}$)/),
  ZA: /(^\+[27][ 0-9]{14})|(^[\+27]( ?)[0-9]{11})|(^0[0-9]{9})|(^0[ 0-9]{11})$/,
  US: /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
};

export const SMS_MESSAGE_MIN_CHARACTERS = 1;
export const SMS_MESSAGE_MAX_CHARACTERS = 140;
