import type { Product } from './product';

export const ALL_TYPE_FILTER = 0;

export interface JobTemplate {
  id?: number;
  organisation_id?: number;
  job_type_id: number | null;
  job_type?: JobType;
  total_hours: number;
  name: string | null;
  tasks?: Task[];
  products?: Product[];
  updated_at?: string;
  created_at?: string;
  submit?: boolean;
}

export interface JobType {
  id: number;
  job_cost?: number;
  job_hours?: number;
  name: string | null;
  updated_at?: string;
  created_at?: string;
}

export interface Task {
  id?: number | string;
  name: string | null;
  order: number;
}
