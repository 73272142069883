import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk } from 'src/store';
import { VendUser } from 'src/types/user';
import { Register, UserRegister } from 'src/types/vend-integration';

interface MapUserIdToUserRegister {
  [id: number]: UserRegister;
}

interface VendIntegrationMatchUsersState {
  isInitialized: boolean;

  registers: Register[];
  vendUsers: VendUser[];
  mapUserIdToUserRegister: MapUserIdToUserRegister;
}

const initialState: VendIntegrationMatchUsersState = {
  isInitialized: false,

  registers: [],
  vendUsers: [],
  mapUserIdToUserRegister: {},
};

const slice = createSlice({
  name: 'vendIntegrationMatchUsers',
  initialState,
  reducers: {
    setData(
      state: VendIntegrationMatchUsersState,
      action: PayloadAction<{
        registers: Register[];
        vendUsers: VendUser[];
        usersRegisters: UserRegister[];
      }>
    ) {
      state.mapUserIdToUserRegister = {};

      const { registers, vendUsers, usersRegisters } = action.payload;
      state.registers = registers;
      state.vendUsers = vendUsers;

      usersRegisters.forEach((userRegister) => {
        state.mapUserIdToUserRegister[userRegister.user_id] = userRegister;
      });

      state.isInitialized = true;
    },
    updateMatch(
      state: VendIntegrationMatchUsersState,
      action: PayloadAction<{
        userId: number;
        registerId: number;
        vendUserId: number;
      }>
    ) {
      const { userId, registerId, vendUserId } = action.payload;
      state.mapUserIdToUserRegister[userId].vend_user_id = vendUserId;
      state.mapUserIdToUserRegister[userId].register_id = registerId;
    },
  },
});

export const { reducer } = slice;

export const initialize =
  (organisationId: number): AppThunk =>
  async (dispatch) => {
    const responses = await Promise.all([
      axios.get(`v1/organisations/${organisationId}/vend/users`),
      axios.get(`v1/organisations/${organisationId}/vend/registers`),
      axios.get(`v1/organisations/${organisationId}/vend/users-registers`),
    ]);
    const [vendUsersResponse, registersResponse, usersRegistersResponse] = responses;
    dispatch(
      slice.actions.setData({
        vendUsers: vendUsersResponse.data,
        registers: registersResponse.data,
        usersRegisters: usersRegistersResponse.data,
      })
    );
  };

export const updateMatch =
  (organisationId: number, userId: number, vendUserId: number, registerId: number): AppThunk =>
  async (dispatch) => {
    dispatch(
      slice.actions.updateMatch({
        userId,
        vendUserId,
        registerId,
      })
    );
    await axios.put(`v1/organisations/${organisationId}/vend/update-register`, {
      user_id: userId,
      vend_user_id: vendUserId,
      register_id: registerId,
    });
  };
