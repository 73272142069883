import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk } from 'src/store';
import { VendProduct } from 'src/types/product';
import { ProductMatch } from 'src/types/vend-integration';
import { wait } from 'src/utils/wait';

const PRODUCTS_COUNT_PER_PAGE = 100;
const PRODUCTS_UNMATCHED_COUNT_PER_PAGE = 100;

const LS_CURRENT_STEP = 'vendSyncProducts_currentStep';
const LS_PRODUCTS_PAGE = 'venSyncProducts_currentProductsPage';
const LS_UNMATCHED_VEND_PRODUCTS_PAGE = 'vendSyncProducts_unmatchedVendProductsPage';

const POLL_DELAY = 2000;

export const UNSELECTED_VEND_PRODUCT_ID = '-1';

export enum SyncProductsStep {
  // step 1
  info = 'info',
  // step 2
  matchingSelection = 'matchingSelection',
  // step 3
  unmatchedVend = 'unmatchedVend',
  // step 4
  synchronizingProgress = 'synchronizingProgress',
}

type MapProductIdToVendProductId = {
  [productId: number]: string;
};

interface VendIntegrationSyncProductsState {
  currentStep: SyncProductsStep | null;
  isFinished: boolean;

  isDeletingUnassociatedProducts: boolean;
  isLoadingProductsCount: boolean;
  isLoading: boolean;
  isLoadingBack: boolean;

  isSomethingWentWrong: boolean;

  mapProductIdToVendProductId: MapProductIdToVendProductId;
  productsCount: number;
  products: ProductMatch[];
  currentProductsPage: number;
  handledProducts: number;

  unmatchedVendProductsPage: number;
  ignoreUnmatchedVendProductIds: string[];
  unmatchedVendProducts: VendProduct[];
  unmatchedVendProductsTotal: number;
  unmatchedPtContactsLatestPage: number;

  synchronizationTotalQueuedCount: number;
  synchronizationProcessedCount: number;
}

const initialState: VendIntegrationSyncProductsState = {
  currentStep: null,
  isFinished: false,

  isLoading: false,
  isLoadingBack: false,
  isDeletingUnassociatedProducts: false,
  isLoadingProductsCount: false,

  isSomethingWentWrong: false,

  mapProductIdToVendProductId: {},
  productsCount: 0,
  products: [],
  currentProductsPage: 0,
  handledProducts: 0,

  unmatchedVendProductsPage: 0,
  ignoreUnmatchedVendProductIds: [],
  unmatchedVendProducts: [],
  unmatchedVendProductsTotal: 0,
  unmatchedPtContactsLatestPage: 0,

  synchronizationTotalQueuedCount: 0,
  synchronizationProcessedCount: 0,
};

const slice = createSlice({
  name: 'vendIntegrationSyncProducts',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    initialize(
      state: VendIntegrationSyncProductsState,
      action: PayloadAction<{
        currentProductsPage: number;
        unmatchedVendProductsPage: number;
      }>
    ) {
      state.currentProductsPage = action.payload.currentProductsPage;
      state.unmatchedVendProductsPage = action.payload.unmatchedVendProductsPage;
    },
    setStep(
      state: VendIntegrationSyncProductsState,
      action: PayloadAction<{ step: SyncProductsStep }>
    ) {
      state.currentStep = action.payload.step;
    },

    startLoading(state: VendIntegrationSyncProductsState) {
      state.isLoading = true;
    },
    stopLoading(state: VendIntegrationSyncProductsState) {
      state.isLoading = false;
      state.isLoadingBack = false;
    },
    startLoadingBack(state: VendIntegrationSyncProductsState) {
      state.isLoadingBack = true;
    },

    startDeletingUnassociatedProducts(state: VendIntegrationSyncProductsState) {
      state.isDeletingUnassociatedProducts = true;
    },
    stopDeletingUnassociatedProducts(state: VendIntegrationSyncProductsState) {
      state.isDeletingUnassociatedProducts = false;
    },
    startLoadingProductsCount(state: VendIntegrationSyncProductsState) {
      state.isDeletingUnassociatedProducts = true;
    },
    stopLoadingProductsCount(state: VendIntegrationSyncProductsState) {
      state.isDeletingUnassociatedProducts = false;
    },

    somethingWentWrong(state: VendIntegrationSyncProductsState) {
      state.isSomethingWentWrong = true;
    },

    setProductsCount(
      state: VendIntegrationSyncProductsState,
      action: PayloadAction<{ count: number }>
    ) {
      state.productsCount = action.payload.count;
    },
    setProducts(
      state: VendIntegrationSyncProductsState,
      action: PayloadAction<{
        products: ProductMatch[];
        totalCount: number;
        page: number;
      }>
    ) {
      const { products, page, totalCount } = action.payload;
      state.products = products;
      state.currentProductsPage = page;
      state.mapProductIdToVendProductId = {};
      state.productsCount = totalCount;
      state.handledProducts = (page - 1) * PRODUCTS_COUNT_PER_PAGE;

      state.products.forEach((product) => {
        // state.mapProductIdToVendProductId[product.id] = UNSELECTED_VEND_PRODUCT_ID;
        state.mapProductIdToVendProductId[product.id] = product.matchable[0].match.vend_product_id;
      });
    },
    setUnmatchedVendProducts(
      state: VendIntegrationSyncProductsState,
      action: PayloadAction<{
        vendProducts: VendProduct[];
        totalCount: number;
        page: number;
      }>
    ) {
      const { vendProducts, page, totalCount } = action.payload;
      state.ignoreUnmatchedVendProductIds = [];
      state.unmatchedVendProductsPage = page;
      state.unmatchedVendProducts = vendProducts;
      state.unmatchedVendProductsTotal = totalCount;
      state.unmatchedPtContactsLatestPage = Math.ceil(
        totalCount / PRODUCTS_UNMATCHED_COUNT_PER_PAGE
      );
    },
    selectMatch(
      state: VendIntegrationSyncProductsState,
      action: PayloadAction<{ vendProductId: string; productId: number }>
    ) {
      const { vendProductId, productId } = action.payload;

      // remove duplicated selection for `contactId`
      Object.keys(state.mapProductIdToVendProductId).forEach((key) => {
        if (state.mapProductIdToVendProductId[key] === vendProductId) {
          state.mapProductIdToVendProductId[key] = UNSELECTED_VEND_PRODUCT_ID;
        }
      });

      state.mapProductIdToVendProductId[productId] = vendProductId;
    },
    ignoreVendProduct(
      state: VendIntegrationSyncProductsState,
      action: PayloadAction<{ vendProductId: string; ignored: boolean }>
    ) {
      const { vendProductId, ignored } = action.payload;

      if (ignored) {
        state.ignoreUnmatchedVendProductIds = [
          ...state.ignoreUnmatchedVendProductIds,
          vendProductId,
        ];
      } else {
        state.ignoreUnmatchedVendProductIds = state.ignoreUnmatchedVendProductIds.filter(
          (id) => id !== vendProductId
        );
      }
    },
    ignoreAllVendContacts(
      state: VendIntegrationSyncProductsState,
      action: PayloadAction<{ ignored: boolean }>
    ) {
      if (action.payload.ignored) {
        state.ignoreUnmatchedVendProductIds = state.unmatchedVendProducts.map(
          ({ vend_product_id }) => vend_product_id
        );
      } else {
        state.ignoreUnmatchedVendProductIds = [];
      }
    },
    updateSynchronizationStatus(
      state: VendIntegrationSyncProductsState,
      action: PayloadAction<{ total: number; processed: number }>
    ) {
      state.synchronizationTotalQueuedCount = action.payload.total;
      state.synchronizationProcessedCount = action.payload.processed;
    },
    finish(state: VendIntegrationSyncProductsState) {
      state.isFinished = true;
    },
  },
});

export const { reducer } = slice;
export const { selectMatch, ignoreAllVendContacts, ignoreVendProduct } = slice.actions;

export const reset = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.reset());
  localStorage.removeItem(LS_CURRENT_STEP);
  localStorage.removeItem(LS_PRODUCTS_PAGE);
  localStorage.removeItem(LS_UNMATCHED_VEND_PRODUCTS_PAGE);
};

const setStep =
  (step: SyncProductsStep): AppThunk =>
  (dispatch) => {
    localStorage.setItem(LS_CURRENT_STEP, step);
    dispatch(slice.actions.setStep({ step }));
  };

const setInfoStep = (): AppThunk => (dispatch) => {
  dispatch(setStep(SyncProductsStep.info));
};

const setMatchingSelectionStep =
  (products: ProductMatch[], totalCount: number, page: number): AppThunk =>
  (dispatch) => {
    dispatch(setStep(SyncProductsStep.matchingSelection));
    dispatch(slice.actions.setProducts({ products, totalCount, page }));
  };

const setUnmatchedVendStep =
  (vendProducts: VendProduct[], totalCount: number, page: number): AppThunk =>
  (dispatch) => {
    dispatch(setStep(SyncProductsStep.unmatchedVend));
    dispatch(slice.actions.setUnmatchedVendProducts({ vendProducts, totalCount, page }));
  };

const setSynchronizingProductsStep = (): AppThunk => (dispatch) => {
  dispatch(setStep(SyncProductsStep.synchronizingProgress));
};

export const back =
  (organisationId: number, currentProductsPage: number): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoadingBack());

    const page = currentProductsPage - 1;

    if (page === 0) {
      dispatch(setInfoStep());
      return;
    }

    const response = await axios.post(
      `v2/organisations/${organisationId}/vend/product-matches`,
      null,
      { params: { limit: PRODUCTS_COUNT_PER_PAGE, page } }
    );
    const products = response.data.data as ProductMatch[];
    const totalCount: number = response.data.meta.total;
    dispatch(setMatchingSelectionStep(products, totalCount, page));

    localStorage.setItem(LS_PRODUCTS_PAGE, page.toString());
    dispatch(slice.actions.stopLoading());
  };

export const backUnmatchedVend =
  (
    organisationId: number,
    unmatchedVendProductsPage: number,
    currentProductsPage: number
  ): AppThunk =>
  async (dispatch) => {
    // If this is the first page - go to the prev step
    if (unmatchedVendProductsPage === 1) {
      dispatch(back(organisationId, currentProductsPage + 1));
      return;
    }

    dispatch(slice.actions.startLoadingBack());

    const page = unmatchedVendProductsPage - 1;
    const response = await axios.get(
      `v2/organisations/${organisationId}/vend/not-matched-vend-products`,
      { params: { page, limit: PRODUCTS_UNMATCHED_COUNT_PER_PAGE } }
    );
    const products = response.data.data as VendProduct[];
    dispatch(setUnmatchedVendStep(products, response.data.meta.total, page));
    localStorage.setItem(LS_UNMATCHED_VEND_PRODUCTS_PAGE, page.toString());
    dispatch(slice.actions.stopLoading());
  };

const synchronizeProducts =
  (organisationId: number, isSyncingProducts: boolean): AppThunk =>
  async (dispatch) => {
    if (!isSyncingProducts) {
      await axios.post(`v1/organisations/${organisationId}/vend/run-products-sync`);
    }

    dispatch(setSynchronizingProductsStep());

    async function pollStatus() {
      try {
        const { data } = await axios.get(
          `v1/organisations/${organisationId}/vend/sync-products-status`
        );
        dispatch(
          slice.actions.updateSynchronizationStatus({
            total: data.totalQueued,
            processed: data.totalProcessed,
          })
        );

        if (data.isSynced) {
          dispatch(slice.actions.finish());
          return null;
        }

        await wait(POLL_DELAY);
        return pollStatus();
      } catch (e) {
        console.error(e);
        await wait(POLL_DELAY);
        return pollStatus();
      }
    }

    await pollStatus();
  };

export const handleCurrentPageOfUnmatchedVendAndLoadMore =
  (
    organisationId: number,
    currentPage: number,
    ignoredUnmatchedVendProductIds: string[],
    unmatchedVendProducts: VendProduct[]
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const page = currentPage + 1;

    // apply current part of data
    const ignoreVendProducts = ignoredUnmatchedVendProductIds.map((id) => ({
      vend_product_id: id,
    }));
    const syncVendProducts = unmatchedVendProducts
      .filter((product) => !ignoredUnmatchedVendProductIds.includes(product.vend_product_id))
      .map((product) => ({ vend_product_id: product.vend_product_id }));
    const payload = {
      vend_products: syncVendProducts,
      ignore_vend_products: ignoreVendProducts,
    };
    const hasPayload =
      payload.ignore_vend_products.length !== 0 || payload.vend_products.length !== 0;

    if (hasPayload) {
      await axios.post(`v1/organisations/${organisationId}/vend/sync-vend-products`, payload);
    }

    // load next part of data
    const response = await axios.get(
      `v2/organisations/${organisationId}/vend/not-matched-vend-products`,
      { params: { page, limit: PRODUCTS_UNMATCHED_COUNT_PER_PAGE } }
    );

    const vendProducts = response.data.data as VendProduct[];

    dispatch(slice.actions.stopLoading());

    // in case of empty data - go to the next step
    if (vendProducts.length === 0) {
      dispatch(synchronizeProducts(organisationId, false));
      return;
    }

    localStorage.setItem(LS_UNMATCHED_VEND_PRODUCTS_PAGE, page.toString());
    dispatch(setUnmatchedVendStep(vendProducts, response.data.meta.total, page));
  };

export const handleCurrentPageAndLoadMore =
  (
    organisationId: number,
    currentContactsPage: number,
    mapProductIdToVendProductId: MapProductIdToVendProductId
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoading());

    const page = currentContactsPage + 1;

    // push matches for current page to the server
    if (Object.keys(mapProductIdToVendProductId).length !== 0) {
      const payload = {
        products: Object.entries(mapProductIdToVendProductId).map(([productId, vendProductId]) => ({
          id: productId,
          vend_product_id: vendProductId === UNSELECTED_VEND_PRODUCT_ID ? null : vendProductId,
        })),
      };
      await axios.post(`v1/organisations/${organisationId}/vend/sync-pooltrackr-products`, payload);
    }
    // get next part of data for matching
    const response = await axios.post(
      `v2/organisations/${organisationId}/vend/product-matches`,
      null,
      { params: { limit: PRODUCTS_COUNT_PER_PAGE, page } }
    );
    const products = response.data.data as ProductMatch[];
    const totalCount: number = response.data.meta.total;

    dispatch(slice.actions.stopLoading());

    // there is no data - go to the next page
    if (products.length === 0) {
      dispatch(handleCurrentPageOfUnmatchedVendAndLoadMore(organisationId, 0, [], []));
      return;
    }

    // it has data - leave at the current step
    localStorage.setItem(LS_PRODUCTS_PAGE, page.toString());
    dispatch(setMatchingSelectionStep(products, totalCount, page));
  };

export const deleteUnassociatedProducts =
  (organisationId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.startDeletingUnassociatedProducts());
      await axios.delete(`v1/organisations/${organisationId}/delete-unused-products`);
      handleCurrentPageOfUnmatchedVendAndLoadMore(organisationId, 0, [], []);
    } catch (e) {
      dispatch(slice.actions.somethingWentWrong());
      throw e;
    } finally {
      dispatch(slice.actions.stopDeletingUnassociatedProducts());
    }
  };

export const loadProductsCount =
  (organisationId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingProductsCount());
      const response = await axios.get(`v1/organisations/${organisationId}/unused-products-count`);
      dispatch(slice.actions.setProductsCount({ count: response.data.count }));
    } catch (e) {
      dispatch(slice.actions.somethingWentWrong());
      throw e;
    } finally {
      dispatch(slice.actions.stopLoadingProductsCount());
    }
  };

export const initialize =
  (organisationId: number): AppThunk =>
  async (dispatch) => {
    const savedStep = localStorage.getItem(LS_CURRENT_STEP);

    const currentProductsPage = Number(localStorage.getItem(LS_PRODUCTS_PAGE) ?? 1);
    const unmatchedVendProductsPage = Number(
      localStorage.getItem(LS_UNMATCHED_VEND_PRODUCTS_PAGE) ?? 1
    );

    dispatch(
      slice.actions.initialize({
        currentProductsPage,
        unmatchedVendProductsPage,
      })
    );

    if (savedStep === SyncProductsStep.info || savedStep == null) {
      // data for the StepInfo is loaded inside the component by `useEffect`
      dispatch(setInfoStep());
    } else if (savedStep === SyncProductsStep.matchingSelection) {
      dispatch(handleCurrentPageAndLoadMore(organisationId, currentProductsPage - 1, {}));
    } else if (savedStep === SyncProductsStep.unmatchedVend) {
      dispatch(
        handleCurrentPageOfUnmatchedVendAndLoadMore(
          organisationId,
          unmatchedVendProductsPage - 1,
          [],
          []
        )
      );
    } else if (savedStep === SyncProductsStep.synchronizingProgress) {
      dispatch(synchronizeProducts(organisationId, false));
    }
  };
