import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { CONTACT_TAG_TYPE } from 'src/constants/tag';
import { tagApi } from 'src/api/tag';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';

interface TagState {
  searchText?: string;
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
  types?: string[];
}

const initialState: TagState = {
  searchText: '',
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  types: [CONTACT_TAG_TYPE],
  orderBy: 'name',
  order: 'asc',
};

const slice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setLimit(state: TagState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: TagState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setTypes(state: TagState, action: PayloadAction<{ types: string[] }>) {
      const { types } = action.payload;

      state.types = types;
    },
    setSearchText(state: TagState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setOrder(state: TagState, action: PayloadAction<{ orderBy: string; order: SortDirection }>) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(tagApi.endpoints.getTags.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(tagApi.endpoints.getTags.matchFulfilled, (state, action) => {
        const { meta } = action.payload;
        state.total = meta.total;
      })
      .addMatcher(tagApi.endpoints.getTags.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const { reducer } = slice;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setTypes = (types: string[]) => (dispatch) => {
  dispatch(slice.actions.setTypes({ types }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export default slice;
