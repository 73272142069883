import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { format, startOfDay, endOfDay, subMonths } from 'date-fns';
import { SortDirection } from '@mui/material';
import { CURRENT_STATUS } from '@/components/job/recurrence-dashboard/costants';
import { recurrenceApi } from 'src/api/recurrence';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { DateRange } from '@mui/x-date-pickers-pro';
import { GridFilterModel } from '@mui/x-data-grid-pro';

interface RecurrenceState {
  searchText?: string;
  statusFilter?: string;
  tagsFilter?: string[];
  filterModel?: GridFilterModel;
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;

  selectedRange: DateRange<Date>;
  expandedJob?: number | null;
}

const initialState: RecurrenceState = {
  searchText: '',
  statusFilter: CURRENT_STATUS,
  tagsFilter: [],
  filterModel: {
    items: [],
  },
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'created_at',
  order: 'desc',

  selectedRange: [startOfDay(subMonths(new Date(), 1)), endOfDay(new Date())],
  expandedJob: null,
};

const slice = createSlice({
  name: 'recurrence',
  initialState,
  reducers: {
    setLimit(state: RecurrenceState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: RecurrenceState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setSearchText(state: RecurrenceState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setStatusFilter(state: RecurrenceState, action: PayloadAction<{ statusFilter: any }>) {
      const { statusFilter } = action.payload;

      state.statusFilter = statusFilter;
    },
    setOrder(
      state: RecurrenceState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    setTagsFilter(state: RecurrenceState, action: PayloadAction<{ tags: string[] }>) {
      const { tags } = action.payload;

      state.tagsFilter = tags;
      state.expandedJob = null;
    },
    setExpandedRow(state: RecurrenceState, action: PayloadAction<{ jobId: number | null }>) {
      const { jobId } = action.payload;

      state.expandedJob = jobId;
    },
    selectRange(
      state: RecurrenceState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedRange = dateRange;
    },
    resetDateRange(state: RecurrenceState): void {
      state.selectedRange = initialState.selectedRange;
    },
    setFilterModel(
      state: RecurrenceState,
      action: PayloadAction<{ filterModel: GridFilterModel }>
    ) {
      const { filterModel } = action.payload;

      state.filterModel = filterModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(recurrenceApi.endpoints.getRecurrenceJobs.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(recurrenceApi.endpoints.getRecurrenceJobs.matchFulfilled, (state, action) => {
        const { meta } = action.payload;
        state.total = meta.total;
      })
      .addMatcher(recurrenceApi.endpoints.getRecurrenceJobs.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const { reducer } = slice;

export const { resetDateRange } = slice.actions;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setStatusFilter = (statusFilter: any) => (dispatch) => {
  dispatch(slice.actions.setStatusFilter({ statusFilter }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export const setTagsFilter = (tags: string[]) => (dispatch) => {
  dispatch(slice.actions.setTagsFilter({ tags }));
};

export const setExpandedRow = (jobId: number | null) => (dispatch) => {
  dispatch(slice.actions.setExpandedRow({ jobId }));
};

export const selectRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectRange({ dateRange }));
  };

export const setFilterModel = (filterModel: GridFilterModel) => (dispatch) => {
  dispatch(slice.actions.setFilterModel({ filterModel }));
};

export default slice;
