import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './base-query';
import {
  IGetNotes,
  IAddNote,
  IGetNote,
  IEditNote,
  IDeleteNote,
  IDeleteAttachment,
  IEditAttachment,
} from './model/note.model';
import { Note } from 'src/types/note';

export const noteApi = createApi({
  reducerPath: 'noteApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['Note', 'Notes'],
  endpoints: (builder) => ({
    getNotes: builder.query<Note[], IGetNotes>({
      query: (param: IGetNotes) => ({
        url: `v2/organisation/${param.organisationId}/note`,
        params: {
          entity_type: param.entityType,
          entity_id: param.entityId,
          filter: param.filter,
          order: param.order,
        },
      }),
      providesTags: (result, error, param) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Note' as const, id })),
              { type: 'Notes', id: `${param.entityType}-${param.entityId}` },
            ]
          : [{ type: 'Notes', id: `${param.entityType}-${param.entityId}` }],
    }),
    addNote: builder.mutation<Note, IAddNote>({
      query: (param: IAddNote) => {
        const fd = new FormData();
        fd.append('entity_id', String(param.body.entity_id));
        fd.append('entity_type', param.body.entity_type);
        fd.append('content', param.body.content);
        if (param.body.attachment) fd.append('attachment', param.body.attachment);
        return {
          url: `v2/organisation/${param.organisationId}/note`,
          method: 'post',
          body: fd,
        };
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Notes', id: `${param.body.entity_type}-${param.body.entity_id}` },
      ],
    }),
    getNote: builder.query<Note, IGetNote>({
      query: (param: IGetNote) => `v2/organisation/${param.organisationId}/note/${param.id}`,
      providesTags: (result) => [{ type: 'Note', id: result.id }],
    }),
    editNote: builder.mutation<Note, IEditNote>({
      query: (param: IEditNote) => ({
        url: `v2/organisation/${param.organisationId}/note/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Notes', id: `${param.entityType}-${param.entityId}` },
        { type: 'Note', id: param.id },
      ],
    }),
    deleteNote: builder.mutation<any, IDeleteNote>({
      query: (param: IDeleteNote) => ({
        url: `v2/organisation/${param.organisationId}/note/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Notes', id: `${param.entityType}-${param.entityId}` },
        { type: 'Note', id: param.id },
      ],
    }),
    deleteAttachment: builder.mutation<any, IDeleteAttachment>({
      query: (param: IDeleteAttachment) => ({
        url: `v2/organisation/${param.organisationId}/note/${param.id}/attachment`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Notes', id: `${param.entityType}-${param.entityId}` },
        { type: 'Note', id: param.id },
      ],
    }),
    editAttachment: builder.mutation<any, IEditAttachment>({
      query: (param: IEditAttachment) => {
        const fd = new FormData();
        fd.append('attachment', param.body.attachment);
        return {
          url: `v2/organisation/${param.organisationId}/note/${param.id}/attachment`,
          method: 'post',
          body: fd,
        };
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Notes', id: `${param.entityType}-${param.entityId}` },
        { type: 'Note', id: param.id },
      ],
    }),
  }),
});
export const {
  useAddNoteMutation,
  useDeleteNoteMutation,
  useEditNoteMutation,
  useGetNoteQuery,
  useGetNotesQuery,
  useDeleteAttachmentMutation,
  useEditAttachmentMutation,
} = noteApi;
