import {
  IAddPool,
  IChangeVisibility,
  IDeletePool,
  IEditPool,
  IGetPools,
  IGetPoolTestResults,
  IGetTestHistory,
  IImportPool,
  IPool,
  IUpdateCoordinates,
} from './model/pool.model';
import each from 'lodash/each';
import { rootSplitApi } from './root';
import { POOL_TAG_TYPE } from 'src/constants/tag';
import { providesList } from 'src/utils/rtk';
import { Pool } from 'src/types/pool';
import { Meta } from 'src/types/pagination';

export const poolApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPool: builder.query<Pool, IPool>({
      query: (param: IPool) => {
        const queryParams: { include?: string } = {};
        if (param.include != null) {
          queryParams.include = param.include.join(',');
        }
        return {
          url: `v1/organisations/${param.organisationId}/pools/${param.id}`,
          params: queryParams,
        };
      },
      providesTags: (result, error, param) => [
        { type: 'Pool', id: param.id },
        ...providesList(result?.contacts, 'Contact'),
      ],
    }),
    getPools: builder.query<{ data: Pool[]; meta: Meta }, IGetPools>({
      query: (param: IGetPools) => ({
        url: `v2/organisation/${param.organisationId}/pool-list`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result) => [
        ...providesList(result?.data, 'Pool'),
        ...providesList(result?.data.map((pool) => pool.contacts).flat(), 'Contact'),
      ],
    }),
    editPool: builder.mutation<any, IEditPool>({
      query: (param: IEditPool) => ({
        url: `v1/organisations/${param.organisationId}/pools/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          poolApi.util.updateQueryData(
            'getPool',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Pool', id: param.id },
        { type: 'Job', poolId: param.id },
        { type: 'Tags', id: `LIST_TYPE_${POOL_TAG_TYPE}` },
      ],
    }),
    updateCoordinates: builder.mutation<any, IUpdateCoordinates>({
      query: (param: IUpdateCoordinates) => ({
        url: `v1/organisations/${param.organisationId}/pools/${param.id}/updatelatlng`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          poolApi.util.updateQueryData(
            'getPool',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Pool', id: param.id },
        { type: 'Job', poolId: param.id },
      ],
    }),
    addPool: builder.mutation<any, IAddPool>({
      query: (param: IAddPool) => ({
        url: `v1/organisations/${param.organisationId}/pools`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [
        { type: 'Pool', id: 'LIST' },
        { type: 'Tags', id: `LIST_TYPE_${POOL_TAG_TYPE}` },
      ],
    }),
    deletePool: builder.mutation<any, IDeletePool>({
      query: (param: IDeletePool) => ({
        url: `v1/organisations/${param.organisationId}/pools/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Pool', id: param.id },
        { type: 'Job', poolId: param.id },
        { type: 'Tags', id: `LIST_TYPE_${POOL_TAG_TYPE}` },
      ],
    }),
    deletePoolBulk: builder.mutation<any, IDeletePool[]>({
      async queryFn(params, _queryApi, _extraOptions, baseQuery) {
        const promises = params.map((param) =>
          baseQuery({
            url: `v1/organisations/${param.organisationId}/pools/${param.id}`,
            method: 'delete',
          })
        );
        const result = await Promise.all(promises);
        return { data: result };
      },
      invalidatesTags: (result, error, params) =>
        params
          .map((param) => [
            { type: 'Pool' as const, id: param.id },
            { type: 'Job' as const, poolId: param.id },
            { type: 'Tags' as const, id: `LIST_TYPE_${POOL_TAG_TYPE}` },
          ])
          .flat(),
    }),
    changePoolVisibility: builder.mutation<any, IChangeVisibility>({
      query: (param: IChangeVisibility) => ({
        url: `v1/organisations/${param.organisationId}/pools/${param.id}/visibility`,
        method: 'PUT',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = poolApi.util.selectInvalidatedBy(getState(), [
          { type: 'Pool', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getPools' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                poolApi.util.updateQueryData('getPools', originalArgs, (pools) => {
                  pools.data.forEach((pool) => {
                    if (pool.id === param.id) {
                      Object.assign(pool, param.body);
                    }
                  });
                })
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Pool', id: param.id },
        { type: 'Pool', id: 'LIST' },
      ],
    }),
    changePoolVisibilityBulk: builder.mutation<any, IChangeVisibility[]>({
      async queryFn(params, _queryApi, _extraOptions, baseQuery) {
        const promises = params.map((param) =>
          baseQuery({
            url: `v1/organisations/${param.organisationId}/pools/${param.id}/visibility`,
            method: 'PUT',
            body: param.body,
          })
        );
        const result = await Promise.all(promises);
        return { data: result };
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        params.forEach((param) => {
          const result = poolApi.util.selectInvalidatedBy(getState(), [
            { type: 'Pool', id: param.id },
          ]);
          if (result && result.length) {
            each(result, async ({ endpointName, originalArgs }) => {
              if (endpointName && endpointName === 'getPools' && originalArgs) {
                const patchResult = dispatch(
                  // @ts-ignore
                  poolApi.util.updateQueryData('getPools', originalArgs, (pools) => {
                    pools.data.forEach((pool) => {
                      if (pool.id === param.id) {
                        Object.assign(pool, param.body);
                      }
                    });
                  })
                );
                try {
                  await queryFulfilled;
                } catch {
                  patchResult.undo();
                }
              }
            });
          }
        });
      },
      invalidatesTags: (result, error, params) => [
        ...params
          .map((param) => [
            { type: 'Pool' as const, id: param.id },
            { type: 'Job' as const, poolId: param.id },
          ])
          .flat(),
        { type: 'Pool', id: 'LIST' },
      ],
    }),
    getTestHistory: builder.query<any, IGetTestHistory>({
      query: (param: IGetTestHistory) => {
        const { organisationId, id, from, to } = param;

        return `v2/organisation/${organisationId}/pool/${id}/chemical-history?from=${from}&to=${to}`;
      },
      providesTags: (result, error, param) => [{ type: 'PoolTestHistory', id: param.id }],
    }),
    getPoolTestResults: builder.query<any, IGetPoolTestResults>({
      query: (param: IGetPoolTestResults) => ({
        url: `v2/organisation/${param.organisationId}/pool/${param.id}/test-result`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result, error, param) => [{ type: 'PoolTestResults', id: param.id }],
    }),
    importPool: builder.mutation<any, IImportPool>({
      query: (param: IImportPool) => {
        const fd = new FormData();
        fd.append('type', 'pools_contacts');
        fd.append('import_data', param.file);

        return {
          url: `v1/organisations/${param.organisationId}/import`,
          method: 'post',
          body: fd,
        };
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Pool', id: 'LIST' },
        { type: 'Contact', id: 'LIST' },
        { type: 'Tags', id: `LIST_TYPE_${POOL_TAG_TYPE}` },
      ],
    }),
  }),
});
export const {
  useGetPoolQuery,
  useGetPoolsQuery,
  useEditPoolMutation,
  useAddPoolMutation,
  useDeletePoolMutation,
  useDeletePoolBulkMutation,
  useChangePoolVisibilityBulkMutation,
  useChangePoolVisibilityMutation,
  useGetTestHistoryQuery,
  useUpdateCoordinatesMutation,
  useGetPoolTestResultsQuery,
  useImportPoolMutation,
} = poolApi;

export const {
  endpoints: {
    getPoolTestResults,
    getPool,
    deletePool,
    deletePoolBulk,
    changePoolVisibility,
    changePoolVisibilityBulk,
  },
} = poolApi;
