import { IAddColor, IDeleteColor, IEditColor, IGetColors } from './model/color.model';
import each from 'lodash/each';
import { rootSplitApi } from './root';
import { Color } from 'src/types/organisation';
import { providesList } from 'src/utils/rtk';

export const colorApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getColors: builder.query<Color[], IGetColors>({
      query: (param: IGetColors) => {
        const { organisationId, filter } = param;
        let url = `v2/organisation/${organisationId}/color`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        return url;
      },
      providesTags: (result) => providesList(result, 'Color'),
    }),
    deleteColor: builder.mutation<any, IDeleteColor>({
      query: (param: IDeleteColor) => ({
        url: `v2/organisation/${param.organisationId}/color/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [{ type: 'Color', id: param.id }],
    }),
    editColor: builder.mutation<any, IEditColor>({
      query: (param: IEditColor) => ({
        url: `v2/organisation/${param.organisationId}/color/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = colorApi.util.selectInvalidatedBy(getState(), [
          { type: 'Color', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getColors' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                colorApi.util.updateQueryData('getColors', originalArgs, (colors) => {
                  colors?.forEach((color: Color) => {
                    if (color.id === param.id) {
                      Object.assign(color, param.body);
                    }
                  });
                })
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [{ type: 'Color', id: param.id }],
    }),
    addColor: builder.mutation<any, IAddColor>({
      query: (param: IAddColor) => ({
        url: `v2/organisation/${param.organisationId}/color`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'Color', id: 'LIST' }],
    }),
  }),
});
export const {
  useGetColorsQuery,
  useDeleteColorMutation,
  useEditColorMutation,
  useAddColorMutation,
} = colorApi;

export const {
  endpoints: { getColors, deleteColor, editColor, addColor },
} = colorApi;
