import { QuoteAction } from './quote-actions';

export const SCHEDULED_QUOTE_STATUS = 'sheduled';
export const SENT_QUOTE_STATUS = 'sent';

export const QUOTE_COLUMN_ID = 'id';
export const QUOTE_COLUMN_STATUS = 'status';
export const QUOTE_COLUMN_SENT = 'sent';
export const QUOTE_COLUMN_CREATED_DATE = 'created_date';
export const QUOTE_COLUMN_SENT_DATE = 'sent_date';
export const QUOTE_COLUMN_CONTACT = 'contact';
export const QUOTE_COLUMN_TEMPLATE = 'job_template';
export const QUOTE_COLUMN_AMOUNT = 'amount';

export const QUOTE_COLUMNS = [
  {
    id: QUOTE_COLUMN_ID,
    label: 'Quote no.',
  },
  {
    id: QUOTE_COLUMN_STATUS,
    label: 'Status',
  },
  {
    id: QUOTE_COLUMN_SENT,
    label: 'Sent',
  },
  {
    id: QUOTE_COLUMN_CREATED_DATE,
    label: 'Created date',
  },
  {
    id: QUOTE_COLUMN_CONTACT,
    label: 'Contact',
  },
  {
    id: QUOTE_COLUMN_TEMPLATE,
    label: 'Job type',
  },
  {
    id: QUOTE_COLUMN_AMOUNT,
    label: 'Amount',
  },
];

export const ARCHIVED_COLUMNS = [
  {
    id: QUOTE_COLUMN_ID,
    label: 'Quote no.',
  },
  {
    id: QUOTE_COLUMN_STATUS,
    label: 'Status',
  },
  // {
  //   id: QUOTE_COLUMN_SENT,
  //   label: 'Sent',
  // },
  {
    id: QUOTE_COLUMN_SENT_DATE,
    label: 'Date sent',
  },
  {
    id: QUOTE_COLUMN_CONTACT,
    label: 'Contact',
  },
  {
    id: QUOTE_COLUMN_TEMPLATE,
    label: 'Job type',
  },
  {
    id: QUOTE_COLUMN_AMOUNT,
    label: 'Amount',
  },
];

export const SAVE_QUOTE = 'save';
export const REOPEN_QUOTE = 'reopen';
export const RESEND_QUOTE = 'resend';
export const DELETE_QUOTE = 'delete';
export const SCHEDULE_QUOTE = 'schedule';
export const PREVIEW_SEND_QUOTE = 'preview-send';
export const PREVIEW_PDF = 'preview-pdf';
export const PREVIEW_EMAIL = 'preview-email';

export const QUOTE_ACTIONS: QuoteAction[] = [
  {
    id: SAVE_QUOTE,
    title: 'Save as draft',
  },
  {
    id: PREVIEW_SEND_QUOTE,
    title: 'Preview & send',
  },
];

export const QUOTE_PREVIEW_ACTIONS: QuoteAction[] = [
  {
    id: PREVIEW_PDF,
    title: 'Preview PDF',
  },
  {
    id: PREVIEW_EMAIL,
    title: 'Preview email',
  },
];

export const CONTACT_QUOTE_ACTIONS: QuoteAction[] = [
  {
    id: PREVIEW_PDF,
    title: 'Preview PDF',
  },
  {
    id: PREVIEW_EMAIL,
    title: 'Preview email',
  },
  {
    id: REOPEN_QUOTE,
    title: 'Reopen',
  },
  {
    id: RESEND_QUOTE,
    title: 'Resend',
  },
  {
    id: SCHEDULE_QUOTE,
    title: 'Schedule',
  },
  {
    id: DELETE_QUOTE,
    title: 'Delete',
  },
];
