import axios from 'axios';
import moment, { Moment } from 'moment/moment';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { AppThunk } from '../store';
import type { QuickScheduleColumn as Column } from '../types/calendar';
import type { Job } from '../types/job';
import objFromArray from '../utils/objFromArray';
import type { User } from '../types/user';

export const UNSCHEDULED_COLUMN_ID = '0';

export const LS_COLUMN_IDS_ORDERED = 'quick_schedule_column_ids_ordered';
export const LS_UNSCHEDULED_JOBS_EXPANDED = 'quick_schedule_unscheduled_expanded';

interface Jobs {
  byId: Record<number, Job>;
  allIds: string[];
}

interface QuickScheduleState {
  isLoaded: boolean;
  columns: {
    byId: Record<string, Column>;
    allIds: string[];
  };
  jobs: Jobs;
  movingJob: Job | null;
  unscheduledJobsExpanded: boolean;
}

const initialState: QuickScheduleState = {
  isLoaded: false,
  columns: {
    byId: {},
    allIds: [],
  },
  jobs: {
    byId: {},
    allIds: [],
  },
  movingJob: null,
  unscheduledJobsExpanded: false,
};

const slice = createSlice({
  name: 'quickSchedule',
  initialState,
  reducers: {
    getBoard(
      state: QuickScheduleState,
      action: PayloadAction<{
        technicians: User[];
        unscheduledJobs: Job[];
        jobs: Job[];
        orderedColumnIds: string[];
        unscheduledJobsExpanded: boolean;
      }>
    ): void {
      const { technicians, unscheduledJobs, orderedColumnIds, unscheduledJobsExpanded } =
        action.payload;
      const jobs = action.payload.jobs.filter((job) => job.id != null);

      const columns = [
        {
          id: UNSCHEDULED_COLUMN_ID,
          name: 'Unscheduled jobs',
          technician: null,
          jobIds: Object.keys(objFromArray(unscheduledJobs)),
        },
      ];

      technicians.forEach((user: User) => {
        columns.push({
          id: user.id.toString(),
          name: `${user.first_name} ${user.last_name}`,
          technician: user,
          jobIds: Object.keys(objFromArray(jobs.filter((job: Job) => job.user_id === user.id))),
        });
      });

      state.columns.byId = objFromArray(columns);

      // use ordered ids
      state.columns.allIds = orderedColumnIds.filter((id) => state.columns.byId[id] != null);

      // if we have new column, add to the end of sorted ids
      Object.keys(state.columns.byId).forEach((id) => {
        if (!state.columns.allIds.includes(id.toString()) && id !== UNSCHEDULED_COLUMN_ID) {
          state.columns.allIds.push(id);
        }
      });

      state.jobs.byId = objFromArray([...jobs, ...unscheduledJobs]);
      state.jobs.allIds = Object.keys(state.jobs.byId);
      state.isLoaded = true;
      state.unscheduledJobsExpanded = unscheduledJobsExpanded;
    },
    /**
     * moves job to another position and column
     */
    moveJob(
      state: QuickScheduleState,
      action: PayloadAction<{
        jobId: string;
        position: number;
        sourceColumnId: string;
        columnId?: string;
        job: Job;
      }>
    ): void {
      const { jobId, position, columnId, job, sourceColumnId } = action.payload;

      if (columnId !== UNSCHEDULED_COLUMN_ID) {
        // save previous state of moving job
        state.movingJob = { ...state.jobs.byId[jobId] };
      }

      // Remove card from source column
      state.columns.byId[sourceColumnId].jobIds = state.columns.byId[sourceColumnId].jobIds.filter(
        (_jobId) => _jobId !== jobId
      );

      state.jobs.byId[jobId] = {
        ...job,
        user_id: columnId,
      };
      // Push the cardId to the specified position
      state.columns.byId[columnId].jobIds.splice(position, 0, jobId);
    },
    /**
     * reschedules job
     * job has been moved to another column already, so use job from `state.jobs.byId`, to move again
     * because new `userId` could be selected
     */
    confirmMovingJob(
      state: QuickScheduleState,
      action: PayloadAction<{
        job: Job;
      }>
    ) {
      const { job } = action.payload;

      const jobId = job.id.toString();
      const prevJob = state.jobs.byId[jobId];

      const prevColumnId = prevJob.user_id == null ? UNSCHEDULED_COLUMN_ID : prevJob.user_id;
      const nextColumnId = job.user_id.toString();

      state.columns.byId[prevColumnId].jobIds = state.columns.byId[prevColumnId].jobIds.filter(
        (_jobId) => _jobId !== jobId
      );

      const prevStartTime = prevJob.start_time == null ? null : moment(prevJob.start_time);
      const newStartTime = moment(job.start_time);

      // add to current board if the day of job wasn't changed
      if (
        prevStartTime == null ||
        (prevStartTime.dayOfYear() === newStartTime.dayOfYear() &&
          prevStartTime.year() === newStartTime.year())
      ) {
        state.columns.byId[nextColumnId].jobIds = [
          ...state.columns.byId[nextColumnId].jobIds,
          jobId,
        ];
      }

      state.jobs.byId[jobId] = {
        ...job,
        user_id: job.user_id.toString(),
      };

      state.movingJob = null;
    },
    cancelMovingJob(
      state: QuickScheduleState
      // action: PayloadAction<{ jobId: string; }>
    ): void {
      const prevJob = state.jobs.byId[state.movingJob.id];
      const prevColumnId =
        prevJob.user_id == null ? UNSCHEDULED_COLUMN_ID : prevJob.user_id.toString();
      const jobId = prevJob.id.toString();

      const newJob = state.movingJob;
      const newJobId = state.movingJob.id.toString();
      const newColumnId =
        state.movingJob.user_id == null
          ? UNSCHEDULED_COLUMN_ID
          : state.movingJob.user_id.toString();

      state.columns.byId[prevColumnId].jobIds = state.columns.byId[prevColumnId].jobIds.filter(
        (_jobId) => _jobId !== jobId
      );

      state.columns.byId[newColumnId].jobIds = [
        ...state.columns.byId[newColumnId].jobIds,
        newJobId,
      ];

      state.jobs.byId[jobId] = {
        ...state.jobs.byId[jobId],
        ...newJob,
      };

      state.movingJob = null;
    },
    reorderColumns(state: QuickScheduleState, action: PayloadAction<{ ids: string[] }>) {
      state.columns.allIds = action.payload.ids;
    },
    toggleUnscheduledJobsExpand(state: QuickScheduleState) {
      state.unscheduledJobsExpanded = !state.unscheduledJobsExpanded;
    },
  },
});

export const { reducer } = slice;
export const { cancelMovingJob } = slice.actions;

export const getBoard =
  (technicians: User[], unscheduledJobs: Job[], jobs: Job[]): AppThunk =>
  async (dispatch): Promise<void> => {
    const columnIds = JSON.parse(localStorage.getItem(LS_COLUMN_IDS_ORDERED)) || [];
    const unscheduledJobsExpanded = localStorage.getItem(LS_UNSCHEDULED_JOBS_EXPANDED) === '1';
    dispatch(
      slice.actions.getBoard({
        technicians,
        unscheduledJobs,
        jobs,
        orderedColumnIds: columnIds,
        unscheduledJobsExpanded,
      })
    );
  };

export const moveJob =
  (
    organisationId: number,
    job: Job,
    position: number,
    column: Column,
    jobs: Jobs,
    sourceColumnId: string,
    columnId: string,
    currentDate: Date
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const moveToUnscheduledJob = columnId === UNSCHEDULED_COLUMN_ID;
    const moveToTech = columnId !== UNSCHEDULED_COLUMN_ID && columnId != null;
    const moveToSameTech = columnId == null;

    const data: Partial<Job> = {
      start_time: '',
      end_time: '',
      user_id: parseInt(columnId, 10),
    };

    if (moveToUnscheduledJob) {
      data.user_id = null;
      // TODO: handle errors, prevent jumping, prevent double action
      const response = await axios.put(
        `v2/organisations/${organisationId}/jobs/${job.id}/reschedule`,
        data
      );
      dispatch(
        slice.actions.moveJob({
          jobId: job.id.toString(),
          position,
          sourceColumnId,
          columnId,
          job: response.data,
        })
      );
    } else if (moveToTech || moveToSameTech) {
      let startDate: Moment | null;
      let endDate: Moment | null;

      const prevJob = jobs.byId[column.jobIds[position - 1]];

      if (position === 0 || prevJob == null) {
        startDate = moment(currentDate);
        // TODO: replace 9am to another time - should be based on the schedule of current user
        startDate.set({ hour: 9, minute: 0 }); // set 9am - 9am+delta
        endDate = startDate.clone();
        endDate.set({ hour: 10 });
      } else {
        startDate = moment(prevJob.end_time);
        const timeDelta =
          moment(job.end_time).diff(moment(job.start_time)) || moment.duration(1, 'hours');
        endDate = startDate.clone().add(timeDelta);
      }

      data.start_time = moment(startDate.toDate()).format('YYYY-MM-DD HH:mm:ss');
      data.end_time = moment(endDate.toDate()).format('YYYY-MM-DD HH:mm:ss');
      dispatch(
        slice.actions.moveJob({
          jobId: job.id.toString(),
          position,
          sourceColumnId,
          columnId,
          job: {
            ...job,
            start_time: data.start_time,
            end_time: data.end_time,
            user_id: data.user_id,
          },
        })
      );
    }
  };

export const confirmMovingJob =
  (
    organisationId: number,
    movingJobId: string,
    userId: string,
    startTime: string,
    endTime: string
  ) =>
  async (dispatch) => {
    const data = {
      start_time: startTime,
      end_time: endTime,
      user_id: userId,
    };
    // TODO: handle errors
    const response = await axios.put(
      `v2/organisations/${organisationId}/jobs/${movingJobId}/reschedule`,
      data
    );
    dispatch(slice.actions.confirmMovingJob({ job: response.data }));
  };

export const createJob =
  (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    columnId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: any
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    // TODO: implement
  };

export const updateJob =
  (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    jobId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    update: any
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    // TODO: implement
  };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const deleteJob =
  (jobId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    // TODO: implement
  };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const toggleUnscheduledJobsExpand =
  (currentlyExpanded: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.toggleUnscheduledJobsExpand());
    const expanded = !currentlyExpanded;
    localStorage.setItem(LS_UNSCHEDULED_JOBS_EXPANDED, expanded ? '1' : '0');
  };

export const moveColumn =
  (columnIds: string[], sourceIndex: number, destinationIndex: number): AppThunk =>
  (dispatch): void => {
    const ids = Array.from(columnIds);
    const [removed] = ids.splice(sourceIndex, 1);
    ids.splice(destinationIndex, 0, removed);
    dispatch(slice.actions.reorderColumns({ ids }));
    localStorage.setItem(LS_COLUMN_IDS_ORDERED, JSON.stringify(ids));
  };

export default slice;
