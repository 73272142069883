import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './base-query';
import {
  IDeleteImage,
  IGetImageByEntity,
  IEntityImage,
  IStoreImages,
} from './model/store-images.model';

export const storeImagesApi = createApi({
  reducerPath: 'storeImagesApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['StoreImage', 'StoreImages'],
  endpoints: (builder) => ({
    getImages: builder.query<IEntityImage[], IGetImageByEntity>({
      query: ({ organisationId, entity_type, entity_id, filter = '', order = '' }) => ({
        url: `v2/organisation/${organisationId}/image`,
        params: {
          entity_type: entity_type,
          entity_id: entity_id,
          filter: filter,
          order: order,
        },
      }),
      providesTags: (result, error, param) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'StoreImages' as const, id })),
              { type: 'StoreImages', id: `${param.entity_type}-${param.entity_id}` },
            ]
          : [{ type: 'StoreImages', id: `${param.entity_type}-${param.entity_id}` }],
    }),
    storeImages: builder.mutation<IEntityImage[], IStoreImages>({
      query: (param: IStoreImages) => ({
        url: `v2/organisation/${param.organisationId}/image`,
        method: 'POST',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'StoreImages', id: `${param.body.entity_type}-${param.body.entity_id}` },
      ],
    }),
    deleteImage: builder.mutation<{ message: string }, IDeleteImage>({
      query: (param: IDeleteImage) => ({
        url: `v2/organisation/${param.organisationId}/image/${param.imageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, param) => [{ type: 'StoreImages', id: param.imageId }],
    }),
  }),
});

export const { useGetImagesQuery, useStoreImagesMutation, useDeleteImageMutation } = storeImagesApi;
