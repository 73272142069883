import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './base-query';
import { IConnectVend, IResetVendConnection } from './model/integration.model';

export const integrationApi = createApi({
  reducerPath: 'integrationApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['Vend'],
  endpoints: (builder) => ({
    connectVend: builder.mutation<any, IConnectVend>({
      query: (param: IConnectVend) => ({
        url: `v1/vend/initiate`,
        method: 'post',
        body: param.body,
      }),
    }),
    resetVendConnection: builder.mutation<any, IResetVendConnection>({
      query: (param: IResetVendConnection) => ({
        url: `v1/organisations/${param.organisationId}/vend/reset`,
        method: 'post',
      }),
    }),
  }),
});
export const { useConnectVendMutation, useResetVendConnectionMutation } = integrationApi;

export const {
  endpoints: { connectVend, resetVendConnection },
} = integrationApi;
