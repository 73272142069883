import { createContext, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { authApi } from 'src/api/auth';
import { organisationApi } from 'src/api/organisation';
import { publicApi } from 'src/api/public';
import { productApi } from 'src/api/product';
import { useDispatch, useSelector } from 'src/store';
import {
  selectCurrentUser,
  selectIsAuthenticated,
  selectToken,
  selectSubscriptionPlan,
  selectIsInitialised,
  initialise,
  selectPermissions,
} from 'src/slices/auth';

import { Permission, User } from 'src/types/user';
import axios from 'axios';
import { apiConfig } from 'src/config';
import { getBaseUrl } from 'src/api/base-query';
import { subscriptionApi } from 'src/api/subscription';
import { useLogoutMutation } from 'src/api/auth';
import { analytics } from 'src/lib/firebase';
import { setUserProperties, setUserId } from 'firebase/analytics';

export interface AuthContextValue {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  token: string | null;
  subscriptionPlan: string | null;
  permissions: Permission[] | [];
}

interface AuthProviderProps {
  children?: ReactNode;
}

export const AuthContext = createContext<AuthContextValue>({
  isInitialised: false,
  isAuthenticated: false,
  user: null,
  token: null,
  subscriptionPlan: null,
  permissions: [],
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const isInitialised = useSelector(selectIsInitialised);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const authenticatedUser = useSelector(selectCurrentUser);
  const permissions = useSelector(selectPermissions);
  const token = useSelector(selectToken);
  const subscriptionPlan = useSelector(selectSubscriptionPlan);
  const [getOrganisation] = organisationApi.endpoints.getOrganisation.useLazyQuery();
  const [getSubscription] = subscriptionApi.endpoints.getSubscription.useLazyQuery();
  const [getBillingDetails] = organisationApi.endpoints.getBillingDetails.useLazyQuery();
  const [getLabSettings] = organisationApi.endpoints.getLabSettings.useLazyQuery();
  const [getJobTypes] = publicApi.endpoints.getJobTypes.useLazyQuery();
  const [getPoolParams] = publicApi.endpoints.getPoolParams.useLazyQuery();
  const [getSanitiserClassifications] =
    publicApi.endpoints.getSanitiserClassifications.useLazyQuery();
  const [getUser] = authApi.endpoints.getAuthenticatedUser.useLazyQuery();
  const [getMetrics] = productApi.endpoints.getMetrics.useLazyQuery();
  const [logout] = useLogoutMutation();

  useEffect(() => {
    const defaultUrl = apiConfig.baseUrl;
    if (defaultUrl !== 'undefined') {
      axios.defaults.baseURL = defaultUrl;
    } else {
      axios.defaults.baseURL = getBaseUrl();
    }
  }, []);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }, [token]);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        if (isAuthenticated && authenticatedUser) {
          const {
            organisation_id: organisationId,
            id: userId,
            email,
            organisation,
          } = authenticatedUser;
          await getUser({
            organisationId: organisationId,
            id: userId,
          }).unwrap();
          await getOrganisation(organisationId).unwrap();
          await getSubscription(organisationId).unwrap();
          await getBillingDetails(organisationId).unwrap();
          await getLabSettings(organisationId).unwrap();

          await getMetrics(organisationId).unwrap();
          await getJobTypes({}).unwrap();
          await getPoolParams({}).unwrap();
          await getSanitiserClassifications({}).unwrap();

          if (process.env.NODE_ENV === 'production') {
            setUserId(analytics(), email);
            setUserProperties(analytics(), {
              userId,
              organisationId,
              organisationName: organisation?.name || null,
              email,
            });
          }
        }

        dispatch(initialise(true));
      } catch (err) {
        console.error(err);
        // dispatch(initialise(false));
      }
    };

    initialize();
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        isInitialised,
        isAuthenticated,
        user: authenticatedUser,
        permissions,
        token,
        subscriptionPlan,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
