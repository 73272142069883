import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { JobType } from 'src/types/job-template';
import { PoolParams, SanitiserClassification } from 'src/types/pool';
import { baseQueryWithInterceptor } from './base-query';

export const publicApi = createApi({
  reducerPath: 'publicApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['Organisation'],
  endpoints: (build) => ({
    getJobTypes: build.query<JobType[], any>({
      query: (id) => `v1/jobtypes`,
    }),
    getPoolParams: build.query<PoolParams, any>({
      query: (id) => `v1/pool-params`,
    }),
    getSanitiserClassifications: build.query<SanitiserClassification[], any>({
      query: (id) => `v1/sanitiser-classifications`,
    }),
  }),
});
export const {
  useGetJobTypesQuery,
  useGetPoolParamsQuery,
  useLazyGetSanitiserClassificationsQuery,
} = publicApi;

export const {
  endpoints: { getJobTypes, getPoolParams, getSanitiserClassifications },
} = publicApi;
