import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  SMS_STATUS_DELIVERED,
  SMS_STATUS_ERROR,
  SMS_STATUS_FAILED,
  SMS_STATUS_LOW_BALANCE,
  SMS_STATUS_QUEUED,
  SMS_STATUS_SENT,
  SMS_STATUS_UNDELIVERED,
} from 'src/constants/sms';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { SortDirection } from '@mui/material';
import { messagingApi } from 'src/api/messaging';
import { DateRange } from '@mui/x-date-pickers-pro';

export const FILTER_STATUS_ALL = 'all_statuses';
export const FILTER_STATUS_DELIVERED = SMS_STATUS_DELIVERED;
export const FILTER_STATUS_UNDELIVERED = SMS_STATUS_UNDELIVERED;
export const FILTER_STATUS_QUEUED = SMS_STATUS_QUEUED;
export const FILTER_STATUS_SENT = SMS_STATUS_SENT;
export const FILTER_STATUS_FAILED = [
  SMS_STATUS_ERROR,
  SMS_STATUS_LOW_BALANCE,
  SMS_STATUS_FAILED,
].join(',');

export const FILTER_TYPE_ALL = 'all';

export type StatusFilter =
  | typeof FILTER_STATUS_ALL
  | typeof FILTER_STATUS_DELIVERED
  | typeof FILTER_STATUS_UNDELIVERED
  | typeof FILTER_STATUS_QUEUED
  | typeof FILTER_STATUS_SENT
  | typeof FILTER_STATUS_FAILED;

interface SentSmsState {
  searchText?: string;
  statusFilter?: StatusFilter;
  typeFilter?: string;
  startDateFilter?: Date;
  endDateFilter?: Date;

  limit: number;
  page: number;

  orderBy: string;
  order: SortDirection;

  total: number;
}

const initialState: SentSmsState = {
  searchText: '',
  statusFilter: FILTER_STATUS_ALL,
  typeFilter: FILTER_TYPE_ALL,
  startDateFilter: null,
  endDateFilter: null,

  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,

  orderBy: 'send_date',
  order: 'desc',

  total: 0,
};

const slice = createSlice({
  name: 'operationsSms',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setLimit(state: SentSmsState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = initialState.page;
      state.total = initialState.total;
    },
    setPage(state: SentSmsState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setStatusFilter(state: SentSmsState, action: PayloadAction<{ status: StatusFilter }>) {
      const { status } = action.payload;

      state.statusFilter = status;
    },
    setTypeFilter(state: SentSmsState, action: PayloadAction<{ type: string }>) {
      const { type } = action.payload;

      state.typeFilter = type;
    },
    setOrder(
      state: SentSmsState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },

    setDateRangeFilter(state: SentSmsState, action: PayloadAction<{ dateRange: DateRange<Date> }>) {
      const { dateRange } = action.payload;
      state.startDateFilter = dateRange[0] || null;
      state.endDateFilter = dateRange[1] || null;
    },
    setSearchTextFilter(state: SentSmsState, action: PayloadAction<{ text: string }>) {
      state.searchText = action.payload.text;
    },
    resetFilters(state: SentSmsState) {
      state.searchText = '';
      state.statusFilter = FILTER_STATUS_ALL;
      state.typeFilter = FILTER_TYPE_ALL;
      state.startDateFilter = null;
      state.endDateFilter = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(messagingApi.endpoints.getSmsList.matchFulfilled, (state, action) => {
      const { meta } = action.payload;
      // @ts-ignore
      state.total = meta?.pagination?.total;
    });
  },
});

export const { reducer } = slice;
export const {
  reset,
  setOrder,
  setLimit,
  setPage,
  setStatusFilter,
  setTypeFilter,
  setDateRangeFilter,
  setSearchTextFilter,
  resetFilters,
} = slice.actions;
