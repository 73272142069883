import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { quoteApi } from 'src/api/quote';
import { AppThunk } from 'src/store';
import moment from 'moment';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { DateRange } from '@mui/x-date-pickers-pro';

interface ArchivedQuoteState {
  searchText?: string;
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
  expandedQuote?: number | null;
  selectedRange: DateRange<Date>;
}

const initialState: ArchivedQuoteState = {
  searchText: '',
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'created_at',
  order: 'desc',
  expandedQuote: null,
  selectedRange: [
    moment().subtract(3, 'month').startOf('day').toDate(),
    moment().endOf('day').toDate(),
  ],
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setLimit(state: ArchivedQuoteState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: ArchivedQuoteState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setSearchText(state: ArchivedQuoteState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setOrder(
      state: ArchivedQuoteState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    setExpandedRow(state: ArchivedQuoteState, action: PayloadAction<{ quoteId: number | null }>) {
      const { quoteId } = action.payload;

      state.expandedQuote = quoteId;
    },
    selectRange(
      state: ArchivedQuoteState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedRange = dateRange;
    },
    resetDateRange(state: ArchivedQuoteState): void {
      state.selectedRange = initialState.selectedRange;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(quoteApi.endpoints.getArchiveQuotes.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(quoteApi.endpoints.getArchiveQuotes.matchFulfilled, (state, action) => {
        const { meta } = action.payload;
        state.total = meta.total;
      })
      .addMatcher(quoteApi.endpoints.getArchiveQuotes.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const { reducer } = slice;

export const { resetDateRange } = slice.actions;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export const setExpandedRow = (quoteId: number | null) => (dispatch) => {
  dispatch(slice.actions.setExpandedRow({ quoteId }));
};

export const selectRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectRange({ dateRange }));
  };

export default slice;
