import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { addYears } from 'date-fns';
import axios from 'axios';
import moment from 'moment';
import flatten from 'lodash/flatten';

import type { ContactInvoiceDetailed } from 'src/types/batch-queue';
import { Contact } from 'src/types/contact';
import { BatchSettings } from 'src/types/invoice';
import { AppThunk } from 'src/store';
import type { Product } from '../types/product';

interface BatchPreviewState {
  isLoading: boolean;

  isMarkingAsInvoiced: boolean;
  markedAsInvoiced: boolean;

  isExpandedFormat: boolean;

  contact?: Contact;

  sendDate: string;
  dueDate: string;
  ccEmails: string[];
  contactId?: number;
  contactInvoiceIds: number[];

  subjectMessage: string;
  introMessage: string;
  isIndividualInvoicesAttachedByDefault: boolean;
  isIndividualInvoicesAttached: boolean;
  isJobSheetsAttachedByDefault: boolean;
  isJobSheetsAttached: boolean;
  prefix?: string;

  configureServiceReport: boolean;
  contactInvoices: ContactInvoiceDetailed[];
  products: Product[];

  isLoadingPreview: boolean;
  previewHTML?: string;

  isLoadingPdfInvoice: boolean;
  pdfInvoiceBlob?: string;

  total: number;
}

const initialState: BatchPreviewState = {
  isLoading: false,

  isMarkingAsInvoiced: false,
  markedAsInvoiced: false,

  isExpandedFormat: false,

  contact: null,

  sendDate: new Date().toISOString(),
  dueDate: addYears(new Date(), 2).toISOString(),
  ccEmails: [],
  contactId: null,
  contactInvoiceIds: [],
  subjectMessage: '',
  introMessage: '',
  isIndividualInvoicesAttachedByDefault: false,
  isIndividualInvoicesAttached: true,
  isJobSheetsAttachedByDefault: false,
  isJobSheetsAttached: false,
  prefix: null,

  configureServiceReport: false,
  contactInvoices: [],
  products: [],

  isLoadingPreview: false,
  previewHTML: null,

  isLoadingPdfInvoice: false,
  pdfInvoiceBlob: null,

  total: 0,
};

const slice = createSlice({
  name: 'batchPreview',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    load(state: BatchPreviewState) {
      state.isLoading = true;
    },
    setSendDate(state: BatchPreviewState, action: PayloadAction<{ sendDate: string }>) {
      state.sendDate = action.payload.sendDate;
    },
    setDueDate(state: BatchPreviewState, action: PayloadAction<{ dueDate: string }>) {
      state.dueDate = action.payload.dueDate;
    },
    setCCEmails(state: BatchPreviewState, action: PayloadAction<{ emails: string[] }>) {
      state.ccEmails = [...action.payload.emails];
    },
    setSubjectMessage(state: BatchPreviewState, action: PayloadAction<{ message: string }>) {
      state.subjectMessage = action.payload.message;
    },
    setIntroMessage(state: BatchPreviewState, action: PayloadAction<{ message: string }>) {
      state.introMessage = action.payload.message;
    },
    setConfigureServiceReport(
      state: BatchPreviewState,
      action: PayloadAction<{ configure: boolean }>
    ) {
      state.configureServiceReport = action.payload.configure;
    },
    setIsIndividualInvoicesAttached(
      state: BatchPreviewState,
      action: PayloadAction<{ attached: boolean }>
    ) {
      state.isIndividualInvoicesAttached = action.payload.attached;
    },
    setIsJobSheetsAttached(state: BatchPreviewState, action: PayloadAction<{ attached: boolean }>) {
      state.isJobSheetsAttached = action.payload.attached;
    },
    setIsExpandedFormat(state: BatchPreviewState, action: PayloadAction<{ expanded: boolean }>) {
      state.isExpandedFormat = action.payload.expanded;
    },
    initialize(
      state: BatchPreviewState,
      action: PayloadAction<{
        batchSettings: BatchSettings;
        contact: Contact;
        contactInvoices: ContactInvoiceDetailed[];
        contactId: number;
        contactInvoiceIds: number[];
      }>
    ) {
      const { batchSettings, contact, contactInvoices, contactId, contactInvoiceIds } =
        action.payload;
      state.contact = contact;
      state.contactInvoices = contactInvoices;
      state.products = flatten(contactInvoices.map((contactInvoice) => contactInvoice.products));
      state.total = contactInvoices.reduce((sum, inv) => sum + Number(inv.total_amount), 0);

      state.subjectMessage = batchSettings.subject_message;
      state.introMessage = batchSettings.intro_message.replaceAll(
        '{contact_first_name}',
        contact.first_name
      );

      state.isIndividualInvoicesAttachedByDefault = batchSettings.attach_individual_invoices;
      state.isIndividualInvoicesAttached = batchSettings.attach_individual_invoices;

      state.isJobSheetsAttachedByDefault = batchSettings.attach_job_sheets;
      state.isJobSheetsAttached = batchSettings.attach_job_sheets;

      state.prefix = batchSettings.prefix;
      state.contactId = contactId;
      state.contactInvoiceIds = contactInvoiceIds;

      state.isLoading = false;
    },

    loadPreview(state: BatchPreviewState) {
      state.isLoadingPreview = true;
    },
    setPreviewHTML(state: BatchPreviewState, action: PayloadAction<{ html?: string }>) {
      state.previewHTML = action.payload.html;
      state.isLoadingPreview = false;
    },
    resetPreview(state: BatchPreviewState) {
      state.isLoadingPreview = false;
      state.previewHTML = null;
    },

    loadPdfInvoice(state: BatchPreviewState) {
      state.isLoadingPdfInvoice = true;
    },
    setPdfInvoiceBlob(state: BatchPreviewState, action: PayloadAction<{ blob?: string }>) {
      state.pdfInvoiceBlob = action.payload.blob;
      state.isLoadingPdfInvoice = false;
    },
    resetPdfInvoice(state: BatchPreviewState) {
      state.isLoadingPdfInvoice = false;
      state.pdfInvoiceBlob = null;
    },

    markAsInvoiced(state: BatchPreviewState) {
      state.isMarkingAsInvoiced = true;
    },
    markedAsInvoiced(state: BatchPreviewState) {
      state.markedAsInvoiced = true;
      state.isMarkingAsInvoiced = false;
    },
  },
});

export const { reducer } = slice;

export default slice;

export const {
  reset,

  setCCEmails,
  setSubjectMessage,
  setIntroMessage,

  setIsExpandedFormat,

  setConfigureServiceReport,
  setIsIndividualInvoicesAttached,
  setIsJobSheetsAttached,

  resetPreview,
  resetPdfInvoice,
} = slice.actions;

export const setSendDate = (date: Date) =>
  slice.actions.setSendDate({ sendDate: date.toISOString() });
export const setDueDate = (date: Date) => slice.actions.setDueDate({ dueDate: date.toISOString() });
export const initialize =
  (
    organisationId: number,
    contactId: number,
    contactInvoiceIds: number[],
    batchSettings: BatchSettings
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.load());

    const contactInvoicesFormData = new FormData();
    contactInvoicesFormData.append('contact_id', contactId.toString());
    contactInvoiceIds.forEach((id, index) =>
      contactInvoicesFormData.append(`contact_invoices[${index}][id]`, id.toString())
    );

    const [contact, contactInvoices] = await Promise.all([
      axios.get(`v1/organisations/${organisationId}/contacts/${contactId}`),
      axios.post(
        `v2/organisations/${organisationId}/batch-invoice/contact-invoices`,
        contactInvoicesFormData
      ),
    ]);

    dispatch(
      slice.actions.initialize({
        batchSettings,
        contact: contact.data,
        contactInvoices: contactInvoices.data,
        contactId,
        contactInvoiceIds,
      })
    );
  };

interface LoadPreviewPayload {
  organisationId: number;
  contactId: number;
  contactInvoiceIds: number[];
  dueDate: string;
  invoiceMessage: string;
  issuedDate: string;
}

export const loadPreview =
  (payload: LoadPreviewPayload): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.loadPreview());
    const preview = await axios.post(
      `v2/organisations/${payload.organisationId}/batch-invoice/preview-email`,
      {
        contact_id: payload.contactId,
        contact_invoices: payload.contactInvoiceIds.map((id) => ({ id })),
        due_date: moment(payload.dueDate).format('YYYY-MM-DD'),
        issued_date: moment(payload.issuedDate).format('YYYY-MM-DD'),
        invoice_message: payload.invoiceMessage,
      }
    );
    dispatch(slice.actions.setPreviewHTML({ html: preview.data }));
  };

interface LoadPdfInvoicePayload {
  organisationId: number;
  contactId: number;
  contactInvoiceIds: number[];
  dueDate: string;
  issuedDate: string;
}

export const loadPdfInvoice =
  (payload: LoadPdfInvoicePayload): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.loadPdfInvoice());
    const blob = await axios.post(
      `v1/organisations/${payload.organisationId}/preview-batch-pdf`,
      {
        is_from_invoice: true,
        contact_id: payload.contactId,
        contact_invoices: payload.contactInvoiceIds.map((id) => ({ id })),
        due_date: moment(payload.dueDate).format('YYYY-MM-DD'),
        issued_date: moment(payload.issuedDate).format('YYYY-MM-DD'),
      },
      {
        responseType: 'arraybuffer',
      }
    );
    dispatch(slice.actions.setPdfInvoiceBlob({ blob: blob.data }));
  };

export const markAsInvoiced =
  (organisationId: number, state: BatchPreviewState, send: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.markAsInvoiced());
    const response = await axios.post(`v2/organisations/${organisationId}/batch-invoice`, {
      send_invoice: send,
      cc_emails: state.ccEmails.join(','),
      contact_id: state.contactId,
      contact_invoices: state.contactInvoiceIds.map((id) => ({ id })),
      due_date: moment(state.dueDate).format('YYYY-MM-DD'),
      email: state.contact.email,
      intro_message: state.introMessage,
      is_individual_invoices_attached: state.configureServiceReport
        ? state.isIndividualInvoicesAttached
        : state.isIndividualInvoicesAttachedByDefault,
      is_job_sheets_attached: state.configureServiceReport
        ? state.isJobSheetsAttached
        : state.isJobSheetsAttachedByDefault,
      prefix: state.prefix,
      send_date: moment(state.sendDate).format('YYYY-MM-DD'),
      subject_message: state.subjectMessage,
    });

    if (response.status === 200) {
      dispatch(slice.actions.markedAsInvoiced());
    }
  };
