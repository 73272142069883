import each from 'lodash/each';
import { RecurrencePeriod } from 'src/types/recurrence-period';
import { providesList } from 'src/utils/rtk';

import {
  ICreateRecurrencePeriod,
  IDeleteRecurrencePeriod,
  IGetRecurrencePeriod,
  IUpdateRecurrencePeriod,
} from './model/recurrence-period.model';
import { rootSplitApi } from './root';

export const recurrencePeriodApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecurrencePeriods: builder.query<RecurrencePeriod[], IGetRecurrencePeriod>({
      query: (param: IGetRecurrencePeriod) => {
        const queryParams: { filter?: string; visibility?: boolean } = {};
        if (param.filterQuery) {
          queryParams.filter = param.filterQuery;
        }

        if (param.visibility != null) {
          queryParams.visibility = param.visibility;
        }

        return {
          url: `v2/organisation/${param.organisationId}/recurrence-period`,
          method: 'get',
          params: queryParams,
        };
      },
      providesTags: (result) => providesList(result, 'RecurrencePeriod'),
    }),
    addRecurrencePeriod: builder.mutation<any, ICreateRecurrencePeriod>({
      query: (param: ICreateRecurrencePeriod) => ({
        url: `v2/organisation/${param.organisationId}/recurrence-period`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'RecurrencePeriod', id: 'LIST' }],
    }),
    editRecurrencePeriod: builder.mutation<any, IUpdateRecurrencePeriod>({
      query: (param: IUpdateRecurrencePeriod) => ({
        url: `v2/organisation/${param.organisationId}/recurrence-period/${param.id}}`,
        method: 'put',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [{ type: 'RecurrencePeriod', id: param.id }],
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        const result = recurrencePeriodApi.util.selectInvalidatedBy(getState(), [
          { type: 'RecurrencePeriod', id: patch.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getRecurrencePeriods' && originalArgs) {
              const patchResult = dispatch(
                recurrencePeriodApi.util.updateQueryData(
                  'getRecurrencePeriods',
                  originalArgs,
                  (periods) => {
                    periods.forEach((period) => {
                      if (period.id === patch.id) {
                        Object.assign(period, patch.body);
                      }
                    });
                  }
                )
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
    }),
    deleteRecurrencePeriod: builder.mutation<any, IDeleteRecurrencePeriod>({
      query: (param: IDeleteRecurrencePeriod) => ({
        url: `v2/organisation/${param.organisationId}/recurrence-period/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [{ type: 'RecurrencePeriod', id: param.id }],
    }),
  }),
});
export const {
  useGetRecurrencePeriodsQuery,
  useAddRecurrencePeriodMutation,
  useEditRecurrencePeriodMutation,
  useDeleteRecurrencePeriodMutation,
} = recurrencePeriodApi;
