import {
  IAddRole,
  IAddUser,
  IChangeActive,
  IDeleteRole,
  IDeleteUser,
  IEditRole,
  IEditUser,
  IGetRoles,
  IGetUser,
  IGetUsers,
} from './model/user.model';
import each from 'lodash/each';
import { User, Role } from 'src/types/user';
import { IPaginatedResponse } from './model/base.model';
import { USER_TAG_TYPE } from 'src/constants/tag';
import { rootSplitApi } from './root';

export const userApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<any, IGetUsers>({
      query: (param: IGetUsers) => {
        const { organisationId, filter, limit, tags, sortBy, page } = param;

        let url = `v2/organisation/${organisationId}/user?limit=${limit}&order=${sortBy}&page=${
          page + 1
        }`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (tags && tags !== '') {
          url += `&tags=${tags}`;
        }

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users' as const, id })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    getTechnicians: builder.query<any, number>({
      query: (id) => `v2/organisation/${id}/user?limit=100&page=1&active=1`,
      transformResponse(response: IPaginatedResponse) {
        return response?.data;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Technicians' as const, id })),
              { type: 'Technicians', id: 'LIST' },
            ]
          : [{ type: 'Technicians', id: 'LIST' }],
    }),
    getUser: builder.query<User, IGetUser>({
      query: (param: IGetUser) => `v2/organisation/${param.organisationId}/user/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'User', id: param.id }],
    }),
    deleteUser: builder.mutation<any, IDeleteUser>({
      query: (param: IDeleteUser) => ({
        url: `v2/organisation/${param.organisationId}/user/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'User', id: param.id },
        { type: 'Users', id: param.id },
        { type: 'Tags', id: `LIST_TYPE_${USER_TAG_TYPE}` },
      ],
    }),
    editUser: builder.mutation<any, IEditUser>({
      query: (param: IEditUser) => ({
        url: `v2/organisation/${param.organisationId}/user/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = userApi.util.selectInvalidatedBy(getState(), [
          { type: 'Users', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getUsers' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                userApi.util.updateQueryData('getUsers', originalArgs, (users) => {
                  users.data.forEach((user) => {
                    if (user.id === param.id) {
                      Object.assign(user, param.body);
                    }
                  });
                })
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'User', id: param.id },
        { type: 'Users', id: param.id },
        { type: 'Tags', id: `LIST_TYPE_${USER_TAG_TYPE}` },
      ],
    }),
    addUser: builder.mutation<any, IAddUser>({
      query: (param: IAddUser) => ({
        url: `v2/organisation/${param.organisationId}/user`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [
        { type: 'Users', id: 'LIST' },
        { type: 'Tags', id: `LIST_TYPE_${USER_TAG_TYPE}` },
      ],
    }),
    getPermissions: builder.query<any, any>({
      query: () => {
        return `v2/permission`;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Permissions' as const, id })),
              { type: 'Permissions', id: 'LIST' },
            ]
          : [{ type: 'Permissions', id: 'LIST' }],
    }),
    getRoles: builder.query<any, IGetRoles>({
      query: (param: IGetRoles) => {
        const { organisationId } = param;

        return `v2/organisation/${organisationId}/role`;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Roles' as const, id })),
              { type: 'Roles', id: 'LIST' },
            ]
          : [{ type: 'Roles', id: 'LIST' }],
    }),
    getRole: builder.query<Role, IGetUser>({
      query: (param: IGetUser) => `v2/organisation/${param.organisationId}/role/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'Role', id: param.id }],
    }),
    deleteRole: builder.mutation<any, IDeleteRole>({
      query: (param: IDeleteRole) => ({
        url: `v2/organisation/${param.organisationId}/role/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Role', id: param.id },
        { type: 'Roles', id: param.id },
      ],
    }),
    editRole: builder.mutation<any, IEditRole>({
      query: (param: IEditRole) => ({
        url: `v2/organisation/${param.organisationId}/role/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = userApi.util.selectInvalidatedBy(getState(), [
          { type: 'Roles', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getRoles' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                userApi.util.updateQueryData('getRoles', originalArgs, (roles) => {
                  roles.data.forEach((role) => {
                    if (role.id === param.id) {
                      Object.assign(role, param.body);
                    }
                  });
                })
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Role', id: param.id },
        { type: 'Roles', id: param.id },
      ],
    }),
    addRole: builder.mutation<any, IAddRole>({
      query: (param: IAddRole) => ({
        url: `v2/organisation/${param.organisationId}/role`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'Roles', id: 'LIST' }],
    }),
    changeActive: builder.mutation<any, IChangeActive>({
      query: (param: IChangeActive) => ({
        url: `v2/organisation/${param.organisationId}/user/${param.id}/active`,
        method: 'PUT',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = userApi.util.selectInvalidatedBy(getState(), [
          { type: 'Users', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getUsers' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                userApi.util.updateQueryData('getUsers', originalArgs, (users) => {
                  users.data.forEach((user) => {
                    if (user.id === param.id) {
                      Object.assign(user, param.body);
                    }
                  });
                })
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [{ type: 'User', id: param.id }],
    }),
  }),
});
export const {
  useGetUsersQuery,
  useGetTechniciansQuery,
  useGetUserQuery,
  useDeleteUserMutation,
  useEditUserMutation,
  useAddUserMutation,
  useAddRoleMutation,
  useEditRoleMutation,
  useDeleteRoleMutation,
  useGetRoleQuery,
  useGetRolesQuery,
  useGetPermissionsQuery,
  useChangeActiveMutation,
} = userApi;

export const {
  endpoints: {
    getUser,
    getUsers,
    getTechnicians,
    addRole,
    editRole,
    deleteRole,
    getRole,
    getRoles,
    getPermissions,
    changeActive,
  },
} = userApi;
