import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import { SortDirection } from '@mui/material';
import type { Invoice, InvoiceStatus } from '../types/contact-invoice';
import { invoiceApi } from 'src/api/invoice';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { DateRange } from '@mui/x-date-pickers-pro';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { GridFilterModel } from '@mui/x-data-grid-pro';

interface ContactInvoiceState {
  searchText?: string;
  statusFilter?: string;
  filterModel?: GridFilterModel;
  contactTags?: string[];
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
  invoices: Invoice[];
  expandedContactInvoiceId?: number | null;
  selectedRange: DateRange<Date>;
}

const initialState: ContactInvoiceState = {
  searchText: '',
  filterModel: {
    items: [],
  },
  statusFilter: 'all',
  contactTags: [],
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'created_at',
  order: 'desc',
  invoices: [],
  expandedContactInvoiceId: null,
  selectedRange: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
};

const slice = createSlice({
  name: 'contactInvoice',
  initialState,
  reducers: {
    setLimit(state: ContactInvoiceState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: ContactInvoiceState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setExpandedRow(
      state: ContactInvoiceState,
      action: PayloadAction<{ contactInvoiceId: number | null }>
    ) {
      const { contactInvoiceId } = action.payload;

      state.expandedContactInvoiceId = contactInvoiceId;
    },
    setSearchText(state: ContactInvoiceState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setStatusFilter(
      state: ContactInvoiceState,
      action: PayloadAction<{ statusFilter: InvoiceStatus }>
    ) {
      const { statusFilter } = action.payload;

      state.statusFilter = statusFilter;
    },
    setContactTags(state: ContactInvoiceState, action: PayloadAction<{ contactTags: string[] }>) {
      const { contactTags } = action.payload;

      state.contactTags = contactTags;
    },
    setOrder(
      state: ContactInvoiceState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    deleteContactInvoice(state: ContactInvoiceState, action: PayloadAction<{ invoiceId: number }>) {
      const { invoiceId } = action.payload;

      state.invoices = state.invoices.filter((invoice) => invoice.id !== invoiceId);
    },
    selectRange(
      state: ContactInvoiceState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedRange = dateRange;
    },
    resetDateRange(state: ContactInvoiceState): void {
      state.selectedRange = initialState.selectedRange;
    },
    setFilterModel(
      state: ContactInvoiceState,
      action: PayloadAction<{ filterModel: GridFilterModel }>
    ) {
      const { filterModel } = action.payload;

      state.filterModel = filterModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(invoiceApi.endpoints.getInvoices.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(invoiceApi.endpoints.getInvoices.matchFulfilled, (state, action) => {
        const { data, meta } = action.payload;

        state.invoices = data;
        state.total = meta.total;
      })
      .addMatcher(invoiceApi.endpoints.getInvoices.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const { reducer } = slice;

export const { resetDateRange } = slice.actions;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setStatusFilter = (statusFilter: InvoiceStatus) => (dispatch) => {
  dispatch(slice.actions.setStatusFilter({ statusFilter }));
};

export const setContactTags = (contactTags: string[]) => (dispatch) => {
  dispatch(slice.actions.setContactTags({ contactTags }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export const deleteContactInvoice =
  (organisationId: number, invoiceId: number): AppThunk =>
  async (dispatch) => {
    await axios.delete(`v1/organisations/${organisationId}/contactinvoice/${invoiceId}`);

    dispatch(slice.actions.deleteContactInvoice({ invoiceId }));
  };

export const setExpandedRow = (contactInvoiceId: number | null) => (dispatch) => {
  dispatch(slice.actions.setExpandedRow({ contactInvoiceId }));
};

export const selectRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectRange({ dateRange }));
  };

export const setFilterModel = (filterModel: GridFilterModel) => (dispatch) => {
  dispatch(slice.actions.setFilterModel({ filterModel }));
};

export default slice;
