import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RangeType } from '../types/dashboard';

interface DashboardAnalyticsState {
  rangeType: RangeType;
}

const initialState: DashboardAnalyticsState = {
  rangeType: 100,
};

const slice = createSlice({
  name: 'dashboardAnalytics',
  initialState,
  reducers: {
    changeRangeType(
      state: DashboardAnalyticsState,
      action: PayloadAction<{ rangeType: RangeType }>
    ) {
      const { rangeType } = action.payload;

      state.rangeType = rangeType;
    },
  },
});

export const { reducer } = slice;

export const changeRangeType = (rangeType: RangeType) => (dispatch) => {
  dispatch(slice.actions.changeRangeType({ rangeType }));
};

export default slice;
