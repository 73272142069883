import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './base-query';
import { IPaginatedResponse } from './model/base.model';
import {
  IEditChemicalTest,
  IGetChemicalTest,
  IGetChemicalTests,
  IGetObservationTests,
  IGetObservationTest,
  IAddObservationTest,
  IEditObservationTest,
  IDeleteObservationTest,
  IChangeDefault,
  IGetWaterTestHistory,
  IUpdateTestOrder,
  IGetObservationCategories,
  IGetObservationCategory,
  IAddObservationCategory,
  IUpdateObservationCategory,
  IDeleteObservationCategory,
} from './model/water-test.model';
import each from 'lodash/each';
import { ObservationCategory } from 'src/types/chemical';

export const waterTestApi = createApi({
  reducerPath: 'waterTestApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: [
    'ChemicalTest',
    'ChemicalTests',
    'ObservationTest',
    'ObservationTests',
    'TestHistory',
    'ObservationCategory',
    'ObservationCategories',
  ],
  endpoints: (builder) => ({
    getChemicalTests: builder.query<any, IGetChemicalTests>({
      query: (param: IGetChemicalTests) =>
        `v2/organisations/${param.organisationId}/chemical-test?filter=${param.filter}&order=${param.sortBy}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ChemicalTests' as const, id })),
              { type: 'ChemicalTests', id: 'LIST' },
            ]
          : [{ type: 'ChemicalTests', id: 'LIST' }],
    }),
    getChemicalTest: builder.query<any, IGetChemicalTest>({
      query: (param: IGetChemicalTest) =>
        `v2/organisations/${param.organisationId}/chemical-test/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'ChemicalTest', id: param.id }],
    }),
    editChemicalTest: builder.mutation<any, IEditChemicalTest>({
      query: (param: IEditChemicalTest) => ({
        url: `v2/organisations/${param.organisationId}/chemical-test/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          waterTestApi.util.updateQueryData(
            'getChemicalTest',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'ChemicalTest', id: param.id },
        { type: 'ChemicalTests', id: param.id },
      ],
    }),
    changeDefaultChemicalTest: builder.mutation<any, IChangeDefault>({
      query: (param: IChangeDefault) => ({
        url: `v2/organisations/${param.organisationId}/chemical-test/${param.id}/change-default`,
        method: 'PUT',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = waterTestApi.util.selectInvalidatedBy(getState(), [
          { type: 'ChemicalTests', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getChemicalTests' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                waterTestApi.util.updateQueryData(
                  'getChemicalTests',
                  originalArgs,
                  (chemicalTests) => {
                    chemicalTests.forEach((chemicalTest) => {
                      if (chemicalTest.id === param.id) {
                        Object.assign(chemicalTest, param.body);
                      }
                    });
                  }
                )
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [{ type: 'ChemicalTest', id: param.id }],
    }),
    getPaginatedObservationTests: builder.query<any, IGetObservationTests>({
      query: (param: IGetObservationTests) =>
        `v2/organisations/${param.organisationId}/observation-test?filter=${param.filter}&limit=${
          param.limit
        }&order=${param.sortBy}&page=${param.page + 1}`,
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'ObservationTests' as const, id })),
              { type: 'ObservationTests', id: 'LIST' },
            ]
          : [{ type: 'ObservationTests', id: 'LIST' }],
    }),
    getObservationTests: builder.query<any, IGetObservationTests>({
      query: (param: IGetObservationTests) =>
        `v2/organisations/${param.organisationId}/observation-test?filter=${param.filter}&limit=${param.limit}&order=${param.sortBy}`,
      transformResponse(response: IPaginatedResponse) {
        return response?.data;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'ObservationTests' as const, id })),
              { type: 'ObservationTests', id: 'LIST' },
            ]
          : [{ type: 'ObservationTests', id: 'LIST' }],
    }),
    getObservationTest: builder.query<any, IGetObservationTest>({
      query: (param: IGetObservationTest) =>
        `v2/organisations/${param.organisationId}/observation-test/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'ObservationTest', id: param.id }],
    }),
    addObservationTest: builder.mutation<any, IAddObservationTest>({
      query: (param: IAddObservationTest) => ({
        url: `v2/organisations/${param.organisationId}/observation-test`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'ObservationTests', id: 'LIST' }],
    }),
    editObservationTest: builder.mutation<any, IEditObservationTest>({
      query: (param: IEditObservationTest) => ({
        url: `v2/organisations/${param.organisationId}/observation-test/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          waterTestApi.util.updateQueryData(
            'getObservationTest',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'ObservationTest', id: param.id },
        { type: 'ObservationTests', id: param.id },
      ],
    }),
    changeDefaultObservationTest: builder.mutation<any, IChangeDefault>({
      query: (param: IChangeDefault) => ({
        url: `v2/organisations/${param.organisationId}/observation-test/${param.id}/change-default`,
        method: 'PUT',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = waterTestApi.util.selectInvalidatedBy(getState(), [
          { type: 'ObservationTests', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getObservationTests' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                waterTestApi.util.updateQueryData(
                  'getObservationTests',
                  originalArgs,
                  (observationTests) => {
                    observationTests.data.forEach((observationTest) => {
                      if (observationTest.id === param.id) {
                        Object.assign(observationTest, param.body);
                      }
                    });
                  }
                )
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
            if (endpointName && endpointName === 'getPaginatedObservationTests' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                waterTestApi.util.updateQueryData(
                  'getPaginatedObservationTests',
                  originalArgs,
                  (observationTests) => {
                    observationTests.data.forEach((observationTest) => {
                      if (observationTest.id === param.id) {
                        Object.assign(observationTest, param.body);
                      }
                    });
                  }
                )
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [{ type: 'ObservationTest', id: param.id }],
    }),
    deleteObservationTest: builder.mutation<any, IDeleteObservationTest>({
      query: (param: IDeleteObservationTest) => ({
        url: `v2/organisations/${param.organisationId}/observation-test/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'ObservationTest', id: param.id },
        { type: 'ObservationTests', id: param.id },
      ],
    }),
    getTestHistory: builder.query<IPaginatedResponse, IGetWaterTestHistory>({
      query: (param: IGetWaterTestHistory) => {
        const { organisationId, poolId, contactId, from, to, limit, page, filter, sortBy } = param;

        let url = `v2/organisations/${organisationId}/water-test?from=${from}&to=${to}&limit=${limit}&order=${sortBy}&page=${
          page + 1
        }&with_tests=1`;

        if (poolId) {
          url += `$pool_id${poolId}`;
        }

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (contactId) {
          url += `$contact_id${contactId}`;
        }

        return url;
      },
      providesTags: (result, error, param) => [{ type: 'TestHistory', id: param.poolId }],
    }),
    updateTestsOrder: builder.mutation<any, IUpdateTestOrder>({
      query: (param: IUpdateTestOrder) => ({
        url: `v1/organisations/${param.organisationId}/recommendations-order`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'ChemicalTests', id: 'LIST' }],
    }),
    addObservationCategory: builder.mutation<ObservationCategory, IAddObservationCategory>({
      query: (param: IAddObservationCategory) => ({
        url: `v2/organisation/${param.organisationId}/observation-category`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'ObservationCategories', id: 'LIST' }],
    }),
    updateObservationCategory: builder.mutation<ObservationCategory, IUpdateObservationCategory>({
      query: (param: IUpdateObservationCategory) => ({
        url: `v2/organisation/${param.organisationId}/observation-category/${param.id}`,
        method: 'PUT',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          waterTestApi.util.updateQueryData(
            'getObservationCategory',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'ObservationCategory', id: param.id },
        { type: 'ObservationCategories', id: 'LIST' },
      ],
    }),
    getObservationCategories: builder.query<any, IGetObservationCategories>({
      query: (param: IGetObservationCategories) => {
        const { organisationId, filter, order } = param;

        let url = `v2/organisation/${organisationId}/observation-category`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (order && order !== '') {
          url += `&order=${order}`;
        }

        return url;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ObservationCategories' as const, id })),
              { type: 'ObservationCategories', id: 'LIST' },
            ]
          : [{ type: 'ObservationCategories', id: 'LIST' }],
    }),
    getObservationCategory: builder.query<ObservationCategory, IGetObservationCategory>({
      query: (param: IGetObservationCategory) =>
        `v2/organisation/${param.organisationId}/observation-category/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'ObservationCategory', id: param.id }],
    }),
    deleteObservationCategory: builder.mutation<any, IDeleteObservationCategory>({
      query: (param: IDeleteObservationCategory) => ({
        url: `v2/organisation/${param.organisationId}/observation-category/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'ObservationCategory', id: param.id },
        { type: 'ObservationCategories', id: param.id },
      ],
    }),
  }),
});
export const {
  useChangeDefaultChemicalTestMutation,
  useEditChemicalTestMutation,
  useGetChemicalTestQuery,
  useGetChemicalTestsQuery,
  useEditObservationTestMutation,
  useGetObservationTestQuery,
  useGetObservationTestsQuery,
  useGetPaginatedObservationTestsQuery,
  useAddObservationTestMutation,
  useDeleteObservationTestMutation,
  useChangeDefaultObservationTestMutation,
  useGetTestHistoryQuery,
  useGetObservationCategoriesQuery,
  useGetObservationCategoryQuery,
  useAddObservationCategoryMutation,
  useUpdateObservationCategoryMutation,
  useDeleteObservationCategoryMutation,
} = waterTestApi;

export const { useUpdateTestsOrderMutation } = waterTestApi;

export const {
  endpoints: { getTestHistory },
} = waterTestApi;
