export enum ContactType {
  individual = 1,
  company = 2,
}

export enum CommsPrefType {
  email = 1,
  doNotSent = 3,
}

export enum DuplicationSwitcher {
  contactName = 'by_contact_name',
  email = 'by_email',
  addressStreetOne = 'by_address_street_one',
  addressCity = 'by_address_city',
  addressPostCode = 'by_address_postcode',
  fistName = 'by_first_name',
  lastName = 'by_last_name',
  hasOnePool = 'by_has_one_pool',
}
