import each from 'lodash/each';
import {
  IAddContact,
  IChangeVisibility,
  IGetContact,
  IDeleteContact,
  IEditContact,
  IGetContacts,
  IUpdateContactPhones,
} from './model/contact.model';
import { rootSplitApi } from './root';
import { CONTACT_TAG_TYPE } from 'src/constants/tag';
import { providesList, providesListByKey } from 'src/utils/rtk';
import { Contact } from 'src/types/contact';
import { Meta } from 'src/types/pagination';

export const contactApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query<{ data: Contact[]; meta: Meta }, IGetContacts>({
      query: (param: IGetContacts) => ({
        url: `v2/organisation/${param.organisationId}/contact-list`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result) => [
        ...providesList(result?.data, 'Contact'),
        ...providesListByKey(result?.data, 'category_id', 'ContactCategory'),
        ...providesListByKey(result?.data, 'parent_relation_id', 'ContactRelationship'),
        ...result?.data?.map((contact) => providesList(contact.pools, 'Pool')).flat(),
        ...result?.data?.map((contact) => providesList(contact.child_contacts, 'Contact')).flat(),
      ],
    }),
    getContact: builder.query<Contact, IGetContact>({
      query: (param: IGetContact) =>
        `v2/organisation/${param.organisationId}/contact/${param.id}?include=pools,pools.contacts`,
      providesTags: (result, error, param) => [
        { type: 'Contact', id: param.id },
        { type: 'ContactCategory', id: result?.category_id },
        { type: 'ContactRelationship', id: result?.parent_relation_id },
        ...providesList(result?.pools, 'Pool'),
        ...providesList(result?.child_contacts, 'Contact'),
      ],
    }),
    getWaterTestContact: builder.query<Contact, IGetContact>({
      query: (param: IGetContact) =>
        `v2/organisation/${param.organisationId}/contact/${param.id}?include=pools,pools.contacts`,
      providesTags: (result, error, param) => [
        { type: 'Contact', id: param.id },
        { type: 'ContactCategory', id: result?.category_id },
        { type: 'ContactRelationship', id: result?.parent_relation_id },
        ...providesList(result?.pools, 'Pool'),
        ...providesList(result?.child_contacts, 'Contact'),
      ],
    }),
    changeContactVisibility: builder.mutation<any, IChangeVisibility>({
      query: (param: IChangeVisibility) => ({
        url: `v1/organisations/${param.organisationId}/contacts/${param.id}/visibility`,
        method: 'PUT',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = contactApi.util.selectInvalidatedBy(getState(), [
          { type: 'Contact', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getContacts' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                contactApi.util.updateQueryData('getContacts', originalArgs, (contacts) => {
                  contacts.data.forEach((contact) => {
                    if (contact.id === param.id) {
                      Object.assign(contact, param.body);
                    }
                  });
                })
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [{ type: 'Contact', id: param.id }],
    }),
    changeContactVisibilityBulk: builder.mutation<any, IChangeVisibility[]>({
      async queryFn(params, _queryApi, _extraOptions, baseQuery) {
        const promises = params.map((param) =>
          baseQuery({
            url: `v1/organisations/${param.organisationId}/contacts/${param.id}/visibility`,
            method: 'PUT',
            body: param.body,
          })
        );
        const result = await Promise.all(promises);
        return { data: result };
      },
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        params.forEach((param) => {
          const result = contactApi.util.selectInvalidatedBy(getState(), [
            { type: 'Contact', id: param.id },
          ]);
          if (result && result.length) {
            each(result, async ({ endpointName, originalArgs }) => {
              if (endpointName && endpointName === 'getContacts' && originalArgs) {
                const patchResult = dispatch(
                  // @ts-ignore
                  contactApi.util.updateQueryData('getContacts', originalArgs, (contacts) => {
                    contacts.data.forEach((contact) => {
                      if (contact.id === param.id) {
                        Object.assign(contact, param.body);
                      }
                    });
                  })
                );
                try {
                  await queryFulfilled;
                } catch {
                  patchResult.undo();
                }
              }
            });
          }
        });
      },
      invalidatesTags: (result, error, params) =>
        params
          .map((param) => [
            { type: 'Pool' as const, id: param.id },
            { type: 'Job' as const, poolId: param.id },
          ])
          .flat(),
    }),

    deleteContact: builder.mutation<any, IDeleteContact>({
      query: (param: IDeleteContact) => ({
        url: `v2/organisation/${param.organisationId}/contact/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Contact', id: param.id },
        { type: 'Tags', id: `LIST_TYPE_${CONTACT_TAG_TYPE}` },
      ],
    }),
    deleteContacts: builder.mutation<any, IDeleteContact[]>({
      async queryFn(params, _queryApi, _extraOptions, baseQuery) {
        const promises = params.map((param) =>
          baseQuery({
            url: `v2/organisation/${param.organisationId}/contact/${param.id}`,
            method: 'delete',
          })
        );
        return { data: await Promise.all(promises) };
      },
      invalidatesTags: (result, error, params) =>
        params
          .map((param) => [
            { type: 'Contact' as const, id: param.id },
            { type: 'Tags' as const, id: `LIST_TYPE_${CONTACT_TAG_TYPE}` },
          ])
          .flat(),
    }),
    editContact: builder.mutation<any, IEditContact>({
      query: (param: IEditContact) => ({
        url: `v2/organisation/${param.organisationId}/contact/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactApi.util.updateQueryData(
            'getContact',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Contact', id: param.id },
        { type: 'Tags', id: `LIST_TYPE_${CONTACT_TAG_TYPE}` },
      ],
    }),
    addContact: builder.mutation<any, IAddContact>({
      query: (param: IAddContact) => ({
        url: `v2/organisation/${param.organisationId}/contact`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [
        { type: 'Contact', id: 'LIST' },
        { type: 'Tags', id: `LIST_TYPE_${CONTACT_TAG_TYPE}` },
      ],
    }),
    updateContactPhones: builder.mutation<any, IUpdateContactPhones>({
      query: (param: IUpdateContactPhones) => {
        return {
          url: `v2/organisation/${param.organisationId}/contact/${param.id}/phone`,
          method: 'put',
          body: {
            phones: param.phones,
          },
        };
      },
      invalidatesTags: (result, error, param) => [{ type: 'Contact', id: param.id }],
    }),
  }),
});
export const {
  useGetContactQuery,
  useGetWaterTestContactQuery,
  useGetContactsQuery,
  useAddContactMutation,
  useEditContactMutation,
  useDeleteContactMutation,
  useDeleteContactsMutation,
  useChangeContactVisibilityMutation,
  useChangeContactVisibilityBulkMutation,
  useUpdateContactPhonesMutation,
} = contactApi;

export const {
  endpoints: { getWaterTestContact, getContacts, updateContactPhones },
} = contactApi;
