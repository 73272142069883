import { tokens } from '../tokens';

export const us = {
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.batchBilling]: 'Batch billing',
  [tokens.nav.batchInvoices]: 'Batch invoices',
  [tokens.nav.batchQueue]: 'Batch queue',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.campaigns]: 'Campaigns',
  [tokens.nav.colors]: 'Colors',
  [tokens.nav.contacts]: 'Contacts',
  [tokens.nav.chemicalGroups]: 'Chemical groups',
  [tokens.nav.chemicalTests]: 'Chemical tests',
  [tokens.nav.customFields]: 'Custom fields',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.dataCleaning]: 'Data cleaning',
  [tokens.nav.dosageGroups]: 'Dosage groups',
  [tokens.nav.email]: 'Email',
  [tokens.nav.equipment]: 'Equipment',
  [tokens.nav.template]: 'Template',
  [tokens.nav.type]: 'Type',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.finance]: 'Finance',
  [tokens.nav.operations]: 'Operations',
  [tokens.nav.observationGroups]: 'Observation groups',
  [tokens.nav.observationTests]: 'Observation tests',
  [tokens.nav.lab]: 'Lab',
  [tokens.nav.jobs]: 'Jobs',
  [tokens.nav.jobTemplates]: 'Job templates',
  [tokens.nav.jobSheet]: 'Job sheet',
  [tokens.nav.jobToInvoice]: 'Job to invoice',
  [tokens.nav.integrations]: 'Integrations',
  [tokens.nav.invoicing]: 'Invoicing',
  [tokens.nav.invoices]: 'Invoices',
  [tokens.nav.invoice]: 'Invoice',
  [tokens.nav.myBusiness]: 'My business',
  [tokens.nav.planAndPayment]: 'Plan & Payment',
  [tokens.nav.users]: 'Users',
  [tokens.nav.quotes]: 'Quotes',
  [tokens.nav.pools]: 'Pools',
  [tokens.nav.products]: 'Products',
  [tokens.nav.recurrenceDashboard]: 'Recurrence dashboard',
  [tokens.nav.roles]: 'Roles',
  [tokens.nav.settings]: 'Settings',
  [tokens.nav.setup]: 'Setup',
  [tokens.nav.sms]: 'SMS',
  [tokens.nav.stripe]: 'Stripe',
  [tokens.nav.tags]: 'Tags',
  [tokens.nav.team]: 'Team',
  [tokens.nav.tradingTerms]: 'Trading terms',
  [tokens.nav.testHistory]: 'Test history',
  [tokens.nav.waterTest]: 'Water test',
  [tokens.nav.waterTesting]: 'Water testing',
  [tokens.nav.xero]: 'Xero',
  [tokens.nav.vend]: 'Vend',

  [tokens.common.account]: 'Account',
  [tokens.common.activeCustomers]: 'Active customers',
  [tokens.common.activePools]: 'Active pools',
  [tokens.common.back]: 'Back',
  [tokens.common.configure]: 'Configure',
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.common.jobs]: 'Jobs',
  [tokens.common.featured]: 'Featured',
  [tokens.common.noFeaturedItems]: 'There are no featured items',
  [tokens.common.quickLinks]: 'Quick links',
  [tokens.common.waterTests]: 'Water tests',
  [tokens.common.newContact]: 'New contact',
  [tokens.common.newPool]: 'New pool',
  [tokens.common.newWaterTest]: 'New water test',
  [tokens.common.newJob]: 'New job',
  [tokens.common.newQuote]: 'New quote',
  [tokens.common.newProduct]: 'New product',
  [tokens.common.overview]: 'Overview',
  [tokens.common.sanitiser]: 'Sanitizer',
  [tokens.common.sanitisers]: 'Sanitizers',
  [tokens.common.settings]: 'Settings',

  [tokens.knowledgeBase.searchTheKnowledgeBase]: 'Search the knowledge base',
  [tokens.knowledgeBase.viewKnowledgeBase]: 'View knowledge base',
  [tokens.knowledgeBase.helpDescription]:
    "Browse the articles and the videos in the Knowledge Base to get answers to your questions or deep-dive into platform to ensure you're getting the most out of it.",
  [tokens.support.raiseTicket]: 'Raise a ticket',
  [tokens.support.contactSupportTeam]: 'Contact support team',
  [tokens.support.helpDescription]:
    "Our support team are on hand to walk you through the product or help you out with a specific question. Log a ticket with details of your issue and we'll respond to you as soon as possible",
};
