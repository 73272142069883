import { TestOrderColumn } from 'src/types/chemical';

export const SPIN_READER = 'spin';
export const XPRESS_READER = 'xpress-flex';

export const SPIN_TITLE = 'Spin Touch';
export const XPRESS_TITLE = 'Xpress Flex';

export const WATER_TEST_DEVICES = {
  SPIN_READER: SPIN_TITLE,
  XPRESS_READER: XPRESS_TITLE,
};

export const LOAD_STRIPS_STEP = 1;
export const DIP_STRIPS_STEP = 2;
export const INSERT_SHUTTLE_STEP = 3;
export const ANALYSE_WATER_STEP = 4;
export const FINISH_STEP = 5;

export const FLEX_TEST_CODES = {
  IRON: 'Fe',
  SALT: 'Salt',
  BORATE: 'Bo',
  COPPER: 'Cu',
  FREE_CHLORINE: 'FC',
  TOTAL_CHLORINE: 'TC',
  PH: 'pH',
  TOTAL_ALKALINITY: 'TA',
  TOTAL_HARDNESS: 'TH',
  CYANURIC_ACID: 'CYA',
  PHOSPHATE: 'PO4',
};

export const FLEX_STRIP_IDS = {
  FLEX_ONE: 'Flex1',
  FLEX_TWO: 'Flex2',
  FLEX_THREE: 'Flex3',
  FLEX_FOUR: 'Flex4',
};

export const FLEX_SANITISERS = {
  BIGUANIDE: 'Biguanide',
  CHLORINE: 'Chlorine',
  SALT: 'Salt',
  BROMINE: 'Bromine',
};

export const FLEX_SYSTEM_STATUSES = {
  NO_DEVICE: 'NoDevice',
  READY_HIDDEN: 'ReadyHidden',
  READY_VISIBLE: 'ReadyVisible',
  PREPARE_TO_DIP: 'PrepareToDip',
  DIPPING: 'Dipping',
  INSERTING_SHUTTLE: 'InsertingShuttle',
  INSERTING_SHUTTLE_NOT_IN: 'InsertingShuttle_NotInYet',
  ANALYZING: 'Analyzing',
  RESULTS: 'Results',
  UPDATING_FIRMWARE: 'UpdatingFirmware',
  UNEXPECTED_ERROR: 'UnexpectedError',
};

export const FLEX_TEST_IN_PROGRESS_STATUSES = [
  FLEX_SYSTEM_STATUSES.PREPARE_TO_DIP,
  FLEX_SYSTEM_STATUSES.DIPPING,
  FLEX_SYSTEM_STATUSES.INSERTING_SHUTTLE,
  FLEX_SYSTEM_STATUSES.INSERTING_SHUTTLE_NOT_IN,
  FLEX_SYSTEM_STATUSES.ANALYZING,
];

export const FLEX_TEST_STATUSES = {
  UNTESTED: 'Untested',
  LOW_LIMIT: 'LowLimit',
  UNDER_RANGE: 'UnderRange',
  OVER_RANGE: 'OverRange',
  OUT_OF_RANGE: 'OutOfRange',
  INVALID: 'Invalid',
  EXACT: 'Exact',
};

export const FLEX_ERROR_STATUSES = {
  API_CONNECTION: 'apiConnectionError',
  NO_DEVICE: 'noDeviceError',
  RESPONSE_FORMATTING: 'apiResponseFormattingError',
  API_USAGE: 'apiUsageError',
};
