import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ReagentType, SampleType, Status, WaterTest } from 'src/types/spinTouch';
import { spinTouchApi } from 'src/api/spin-touch';
import {
  FINISH_STEP,
  FLEX_ERROR_STATUSES,
  FLEX_SANITISERS,
  FLEX_SYSTEM_STATUSES,
  FLEX_TEST_IN_PROGRESS_STATUSES,
  LOAD_STRIPS_STEP,
} from 'src/constants/water-test';
import {
  DeviceDetails,
  FlexAboutResponse,
  FlexErrorResponse,
  FlexInitializeResponse,
  FlexInventoryResponse,
  FlexStatusResponse,
  FlexStrips,
  FlexWaterTestResult,
} from 'src/types/xpress-flex';

interface WaterTestDeviceState {
  // Spin related
  isSpinSynced: boolean;
  isSpinRunningTest: boolean;
  isSpinUpdatingFirmware: boolean;
  spinStatus: Status | null;
  spinReagents: ReagentType[] | [];
  spinSamples: SampleType[] | [];
  selectedSpinReagent: number | null;
  selectedSpinSample: number | null;
  waterTestResults: WaterTest | null;
  // ExpressFlex related
  isFlexRunningTest: boolean;
  isFlexConnected: boolean;
  isXfConnectRunning: boolean;
  isFlexUpdating: boolean;
  flexSanitiser: string;
  activeFlexStep: number;
  flexStrips: FlexStrips;
  flexDevice: DeviceDetails | null;
  flexDeviceStatus: string | null;
  flexTestResults: FlexWaterTestResult | null;
  // Common
  error: string | null;
}

const initialState: WaterTestDeviceState = {
  isSpinSynced: false,
  isSpinRunningTest: false,
  isSpinUpdatingFirmware: false,
  spinStatus: null,
  spinReagents: [],
  spinSamples: [],
  selectedSpinReagent: null,
  selectedSpinSample: null,
  waterTestResults: null,
  isFlexRunningTest: false,
  isFlexConnected: true,
  isXfConnectRunning: false,
  isFlexUpdating: false,
  flexSanitiser: FLEX_SANITISERS.CHLORINE,
  activeFlexStep: LOAD_STRIPS_STEP,
  flexStrips: {
    stripCodes: null,
    openBottleStripInventory: null,
  },
  flexDevice: null,
  flexDeviceStatus: null,
  flexTestResults: null,
  error: null,
};

const slice = createSlice({
  name: 'waterTestDevice',
  initialState,
  reducers: {
    reset: () => initialState,
    selectSpinReagent(state: WaterTestDeviceState, action: PayloadAction<number>): void {
      const reagent = action.payload;

      state.selectedSpinReagent = reagent;
    },
    selectSpinSample(state: WaterTestDeviceState, action: PayloadAction<number>): void {
      const sample = action.payload;

      state.selectedSpinSample = sample;
    },
    setSpinUpdatingFirmware(state: WaterTestDeviceState, action: PayloadAction<boolean>): void {
      const isSpinUpdatingFirmware = action.payload;

      state.isSpinUpdatingFirmware = isSpinUpdatingFirmware;
    },
    runSpinWaterTest(state: WaterTestDeviceState): void {
      state.isSpinRunningTest = true;
      state.waterTestResults = null;
      state.error = null;
    },
    setWaterTestResults(
      state: WaterTestDeviceState,
      action: PayloadAction<WaterTest | null>
    ): void {
      const waterTestResults = action.payload;
      if (waterTestResults?.Results && waterTestResults?.Results.length) {
        state.waterTestResults = waterTestResults;
      } else if (waterTestResults?.Errors && waterTestResults?.Errors.length) {
        state.waterTestResults = null;
        state.error = waterTestResults?.Errors[0]?.Details;
      }
      state.isSpinRunningTest = false;
    },
    updateFlexDetails(
      state: WaterTestDeviceState,
      action: PayloadAction<FlexAboutResponse | FlexInitializeResponse>
    ): void {
      const { details, deviceConnected, flexStrips, systemMode } = action.payload;
      state.flexDevice = details;
      state.isXfConnectRunning = true;
      state.isFlexConnected = deviceConnected;
      state.flexDeviceStatus = systemMode;
      state.flexStrips = flexStrips;
      state.isFlexRunningTest = FLEX_TEST_IN_PROGRESS_STATUSES.includes(systemMode as string);
      state.isFlexUpdating = systemMode === FLEX_SYSTEM_STATUSES.UPDATING_FIRMWARE;
    },
    updateFlexSettings(
      state: WaterTestDeviceState,
      action: PayloadAction<FlexInventoryResponse>
    ): void {
      const { flexStrips } = action.payload;
      state.flexStrips = flexStrips;
    },
    updateFlexStatus(state: WaterTestDeviceState, action: PayloadAction<FlexStatusResponse>): void {
      const { deviceConnected, systemMode } = action.payload;
      state.isXfConnectRunning = true;
      state.isFlexConnected = deviceConnected;
      state.flexDeviceStatus = systemMode;
      state.isFlexRunningTest = FLEX_TEST_IN_PROGRESS_STATUSES.includes(systemMode as string);
      state.isFlexUpdating = systemMode === FLEX_SYSTEM_STATUSES.UPDATING_FIRMWARE;
    },
    flexConnectFailed(state: WaterTestDeviceState): void {
      state.flexDevice = null;
      state.isFlexConnected = false;
    },
    runFlexWaterTest(state: WaterTestDeviceState): void {
      state.isFlexRunningTest = true;
      state.flexTestResults = null;
      state.error = null;
      state.activeFlexStep = LOAD_STRIPS_STEP;
    },
    cancelFlexWaterTest(state: WaterTestDeviceState): void {
      state.isFlexRunningTest = false;
      state.flexTestResults = null;
      state.error = null;
      state.activeFlexStep = LOAD_STRIPS_STEP;
    },
    onFlexError(state: WaterTestDeviceState, action: PayloadAction<FlexErrorResponse>): void {
      const { errorMsg, errorType } = action.payload;
      state.isFlexRunningTest = false;
      state.flexTestResults = null;
      state.error = errorMsg;
      state.activeFlexStep = LOAD_STRIPS_STEP;
      state.isXfConnectRunning = errorType !== FLEX_ERROR_STATUSES.API_CONNECTION;
      state.isFlexConnected = errorType !== FLEX_ERROR_STATUSES.NO_DEVICE;
    },
    setFlexWaterTestResults(
      state: WaterTestDeviceState,
      action: PayloadAction<FlexWaterTestResult>
    ): void {
      state.flexTestResults = action.payload;
      state.isFlexRunningTest = false;
      state.activeFlexStep = FINISH_STEP;
    },
    setFlexSanitiser(state: WaterTestDeviceState, action: PayloadAction<string>): void {
      state.flexSanitiser = action.payload;
    },
    setFlexActiveStep(state: WaterTestDeviceState, action: PayloadAction<number>): void {
      state.activeFlexStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(spinTouchApi.endpoints.getOptions.matchFulfilled, (state, action) => {
        const { SampleTypes: spinSamples, ReagentTypes: spinReagents } = action.payload;

        state.spinSamples = spinSamples;
        state.spinReagents = spinReagents;
        if (!state.selectedSpinReagent && state.spinReagents.length)
          state.selectedSpinReagent = state.spinReagents[0]?.Value;
        if (!state.selectedSpinSample && state.spinSamples.length)
          state.selectedSpinSample = state.spinSamples[0]?.Value;
      })
      .addMatcher(spinTouchApi.endpoints.checkStatus.matchFulfilled, (state, action) => {
        const spinStatus = action.payload;

        state.spinStatus = spinStatus;
        state.isSpinSynced = true;
      });
  },
});

export const { reducer } = slice;
export const {
  reset,
  selectSpinReagent,
  selectSpinSample,
  runSpinWaterTest,
  setWaterTestResults,
  setSpinUpdatingFirmware,
  setFlexSanitiser,
  updateFlexDetails,
  flexConnectFailed,
  runFlexWaterTest,
  cancelFlexWaterTest,
  setFlexWaterTestResults,
  setFlexActiveStep,
  onFlexError,
  updateFlexStatus,
  updateFlexSettings,
} = slice.actions;

export default slice;
