import { ExceptionOperator } from 'src/types/chemical';

export const CLASSIFICATION_TYPE = 'classification';
export const CUSTOM_TYPE = 'custom';
export const GROUND_LEVEL_TYPE = 'ground_level';
export const LOCATION_TYPE = 'location';
export const POOL_TYPE = 'pool_type';
export const SANITISER_TYPE = 'sanitiser';
export const SURFACE_TYPE = 'surface';
export const TEMPERATURE_TYPE = 'temperature';

export const LOW_TEMPERATURE = '< 28 degrees';
export const HIGH_TEMPERATURE = '>= 28 degrees';
export const HIGH_TEMPERATURE_VALUE = 28;

export const ORIGINAL_REPORT_TYPE = 'original';
export const DETAILED_REPORT_TYPE = 'detailed';
export const VISUAL_REPORT_TYPE = 'visual';
export const VITAL_REPORT_TYPE = 'vital';

export const EXCEPTION_TYPES = [
  {
    value: CLASSIFICATION_TYPE,
    label: 'Classification',
  },
  {
    value: CUSTOM_TYPE,
    label: 'Custom',
  },
  {
    value: GROUND_LEVEL_TYPE,
    label: 'Ground Lvl',
  },
  {
    value: LOCATION_TYPE,
    label: 'Location',
  },
  {
    value: POOL_TYPE,
    label: 'Pool Type',
  },
  {
    value: SANITISER_TYPE,
    label: 'Sanitiser',
  },
  {
    value: SURFACE_TYPE,
    label: 'Surface',
  },
  {
    value: TEMPERATURE_TYPE,
    label: 'Temperature',
  },
];

export const CHEMICAL_DOSAGE_TYPE = 'chemical';
export const OBSERVATION_DOSAGE_TYPE = 'observation';

export const GREATER_THAN_OPERATOR = 'Greater than';
export const LESS_THAN_OPERATOR = 'Less than';
export const EQUAL_TO_OPERATOR = 'Equal to';

export const GREATER_THAN = 0;
export const LESS_THAN = 1;
export const EQUAL_TO = 2;

export const EXCEPTION_OPERATORS: ExceptionOperator[] = [
  {
    id: GREATER_THAN,
    name: GREATER_THAN_OPERATOR,
  },
  {
    id: LESS_THAN,
    name: LESS_THAN_OPERATOR,
  },
  {
    id: EQUAL_TO,
    name: EQUAL_TO_OPERATOR,
  },
];
