import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { IPreviewPdf } from 'src/api/model/quote.model';

interface QuoteState {
  isLoadingPdf: boolean;
}

const initialState: QuoteState = {
  isLoadingPdf: false,
};

export const previewQuotePdf = createAsyncThunk<void, IPreviewPdf>(
  'quote/preview-pdf',
  async ({ organisationId, id, body }, { rejectWithValue }) => {
    try {
      return await axios.post(`v2/organisation/${organisationId}/quote/${id}/preview-pdf`, body, {
        responseType: 'arraybuffer',
      });
    } catch (e) {
      return rejectWithValue({
        error: JSON.stringify(e.response?.data),
      });
    }
  }
);

const slice = createSlice({
  name: 'quote',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(previewQuotePdf.pending, (state: QuoteState, action) => {
        state.isLoadingPdf = true;
      })
      .addCase(previewQuotePdf.fulfilled, (state: QuoteState, action) => {
        state.isLoadingPdf = false;
      })
      .addCase(previewQuotePdf.rejected, (state: QuoteState, action) => {
        state.isLoadingPdf = false;
      });
  },
});

export const { reducer } = slice;

export default slice;
