import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './base-query';
import { IGetTenantRequest } from './model/base.model';
import { IGetAnalyticsLineChartRequest } from './model/dashboard.model';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['ClassicMetrics', 'LineChart'],
  endpoints: (builder) => ({
    getClassicMetrics: builder.query<any, IGetTenantRequest>({
      query: (param: IGetTenantRequest) =>
        `v2/organisations/${
          param.organisationId
        }/dashboard/analytics-classic-metrics?is_previous_month=${
          param.isPreviousMonth ? '1' : '0'
        }`,
      providesTags: (result, error, param) => [{ type: 'ClassicMetrics', id: 'LIST' }],
    }),
    getAnalyticsLineChart: builder.query<any, IGetAnalyticsLineChartRequest>({
      query: (param: IGetAnalyticsLineChartRequest) =>
        `v2/organisations/${param.organisationId}/dashboard/analytics-line-chart-data?type=${param.type}`,
      providesTags: (result, error, param) => [{ type: 'LineChart', id: 'LIST' }],
    }),
  }),
});

export const { useGetClassicMetricsQuery, useGetAnalyticsLineChartQuery } = dashboardApi;

export const {
  endpoints: { getClassicMetrics, getAnalyticsLineChart },
} = dashboardApi;
