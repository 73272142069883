import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organisation } from 'src/types/organisation';
import { integrationApi } from 'src/api/integration';

interface VendIntegrationState {
  steps: string[];
  activeStep: number;

  initializing: boolean;

  connecting: boolean;
  resettingConnection: boolean;
  connectionResetSuccess: boolean;
}

export const STEP_CONNECT = 0;
export const STEP_SYNC_CONTACTS = 1;
export const STEP_SYNC_PRODUCTS = 2;
export const STEP_MATCH_USERS = 3;

const initialState: VendIntegrationState = {
  steps: ['Connect to Vend', 'Sync contacts', 'Sync products', 'Finish setup'],
  activeStep: STEP_CONNECT,

  initializing: false,

  connecting: false,
  resettingConnection: false,
  connectionResetSuccess: false,
};

const slice = createSlice({
  name: 'vendIntegration',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    initialize(state: VendIntegrationState, action: PayloadAction<{ organisation: Organisation }>) {
      const { organisation } = action.payload;

      if (!organisation?.has_active_vend_connection) {
        state.activeStep = STEP_CONNECT;
        return;
      }

      if (organisation.vend_organisation.last_contact_updated_at == null) {
        state.activeStep = STEP_SYNC_CONTACTS;
        return;
      }

      if (organisation.vend_organisation.last_product_updated_at == null) {
        state.activeStep = STEP_SYNC_PRODUCTS;
        return;
      }

      state.activeStep = STEP_MATCH_USERS;
    },
    connectionResetHandled(state: VendIntegrationState) {
      state.connectionResetSuccess = false;
    },
    nextStep(state: VendIntegrationState) {
      const nextStep = state.activeStep + 1;
      if (nextStep < initialState.steps.length) {
        state.activeStep = nextStep;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(integrationApi.endpoints.connectVend.matchPending, (state, action) => {
        state.connecting = true;
      })
      .addMatcher(integrationApi.endpoints.connectVend.matchFulfilled, (state, action) => {
        const { data } = action.payload;
        state.connecting = false;
      })
      .addMatcher(integrationApi.endpoints.connectVend.matchRejected, (state, action) => {
        state.connecting = false;
      })
      .addMatcher(integrationApi.endpoints.resetVendConnection.matchPending, (state, action) => {
        state.resettingConnection = true;
      })
      .addMatcher(integrationApi.endpoints.resetVendConnection.matchFulfilled, (state, action) => {
        state.resettingConnection = false;
        state.connectionResetSuccess = true;
      })
      .addMatcher(integrationApi.endpoints.resetVendConnection.matchRejected, (state, action) => {
        state.resettingConnection = false;
      });
  },
});

export const { reducer } = slice;
export const { reset, nextStep, connectionResetHandled } = slice.actions;

export default slice;

export const initialize = (organisation: Organisation) => (dispatch) => {
  dispatch(slice.actions.initialize({ organisation }));
};
