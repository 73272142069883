export const DEFAULT_ROWS_PER_PAGE = 25;
export const ROWS_PER_PAGE_OPTIONS = [10, 25, 100];

// Water test history table
export const WATER_TEST_HISTORY_ANALYSIS_DATE = 'analysis_date';
// export const CONTACT_COLUMN_VEND = 'vend';
// export const CONTACT_COLUMN_FIRST_NAME = 'first_name';
// export const CONTACT_COLUMN_LAST_NAME = 'last_name';
// export const CONTACT_COLUMN_COMPANY_NAME = 'company_name';
// export const CONTACT_COLUMN_CONTACT_NAME = 'contact_name';
// export const CONTACT_COLUMN_TYPE = 'type';
// export const CONTACT_COLUMN_EMAIL = 'email';
// export const CONTACT_COLUMN_PHONES = 'phones';
// export const CONTACT_COLUMN_ADDRESS = 'address_street_one';
// export const CONTACT_COLUMN_CREATED_DATE = 'created_at';
// export const CONTACT_COLUMN_POOLS = 'pools';
