export const DAY_COLUMN_WIDTH = 140;
export const ROW_HEIGHT = 55;

export const TECHS_DRAWER_COLLAPSED = 55;
export const TECHS_DRAWER_EXPANDED = 230;

export const DEFAULT_NUMBER_OF_TECHS = 5;

export const DROPPABLE_ID_UNSCHEDULED_JOBS = 'UNSCHEDULED_JOBS';

export const WEEK_TYPE = 'week';
export const FORTNIGHT_TYPE = 'fortnight';
