import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './base-query';
import {
  IAddCustomException,
  IAddSanitiser,
  IAddSurfaceType,
  ICustomException,
  IDeleteCustomException,
  IDeleteSanitiser,
  IDeleteSurfaceType,
  IEditCustomException,
  IEditSanitiser,
  IEditSurfaceType,
  IGetCustomExceptions,
  IGetSanitisers,
  IGetSurfaceTypes,
  ISanitiser,
  ISurfaceType,
} from './model/custom-fields';

export const customFieldsApi = createApi({
  reducerPath: 'customFieldsApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: [
    'SurfaceType',
    'SurfaceTypes',
    'Sanitiser',
    'Sanitisers',
    'CustomExceptions',
    'CustomException',
  ],
  endpoints: (builder) => ({
    getSurfaceType: builder.query<any, ISurfaceType>({
      query: (param: ISurfaceType) =>
        `v2/organisation/${param.organisationId}/surface-type/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'SurfaceType', id: param.id }],
    }),
    getSurfaceTypes: builder.query<any, IGetSurfaceTypes>({
      query: (param: IGetSurfaceTypes) =>
        `v2/organisation/${param.organisationId}/surface-type?order=name`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'SurfaceTypes' as const, id })),
              { type: 'SurfaceTypes', id: 'LIST' },
            ]
          : [{ type: 'SurfaceTypes', id: 'LIST' }],
    }),
    editSurfaceType: builder.mutation<any, IEditSurfaceType>({
      query: (param: IEditSurfaceType) => ({
        url: `v2/organisation/${param.organisationId}/surface-type/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          customFieldsApi.util.updateQueryData(
            'getSurfaceType',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'SurfaceType', id: param.id },
        { type: 'SurfaceTypes', id: param.id },
      ],
    }),
    addSurfaceType: builder.mutation<any, IAddSurfaceType>({
      query: (param: IAddSurfaceType) => ({
        url: `v2/organisation/${param.organisationId}/surface-type`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'SurfaceTypes', id: 'LIST' }],
    }),
    deleteSurfaceType: builder.mutation<any, IDeleteSurfaceType>({
      query: (param: IDeleteSurfaceType) => ({
        url: `v2/organisation/${param.organisationId}/surface-type/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'SurfaceType', id: param.id },
        { type: 'SurfaceTypes', id: param.id },
      ],
    }),
    getSanitiser: builder.query<any, ISanitiser>({
      query: (param: ISanitiser) => `v2/organisation/${param.organisationId}/sanitiser/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'Sanitiser', id: param.id }],
    }),
    getSanitisers: builder.query<any, IGetSanitisers>({
      query: (param: IGetSanitisers) =>
        `v2/organisation/${param.organisationId}/sanitiser?order=name`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Sanitisers' as const, id })),
              { type: 'Sanitisers', id: 'LIST' },
            ]
          : [{ type: 'Sanitisers', id: 'LIST' }],
    }),
    editSanitiser: builder.mutation<any, IEditSanitiser>({
      query: (param: IEditSanitiser) => ({
        url: `v2/organisation/${param.organisationId}/sanitiser/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          customFieldsApi.util.updateQueryData(
            'getSanitiser',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Sanitiser', id: param.id },
        { type: 'Sanitisers', id: param.id },
      ],
    }),
    addSanitiser: builder.mutation<any, IAddSanitiser>({
      query: (param: IAddSanitiser) => ({
        url: `v2/organisation/${param.organisationId}/sanitiser`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'Sanitisers', id: 'LIST' }],
    }),
    deleteSanitiser: builder.mutation<any, IDeleteSanitiser>({
      query: (param: IDeleteSanitiser) => ({
        url: `v2/organisation/${param.organisationId}/sanitiser/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Sanitiser', id: param.id },
        { type: 'Sanitisers', id: param.id },
      ],
    }),
    getCustomException: builder.query<any, ICustomException>({
      query: (param: ICustomException) =>
        `v2/organisation/${param.organisationId}/custom-exception/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'CustomException', id: param.id }],
    }),
    getCustomExceptions: builder.query<any, IGetCustomExceptions>({
      query: (param: IGetCustomExceptions) =>
        `v2/organisation/${param.organisationId}/custom-exception?orderBy=name`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CustomExceptions' as const, id })),
              { type: 'CustomExceptions', id: 'LIST' },
            ]
          : [{ type: 'CustomExceptions', id: 'LIST' }],
    }),
    editCustomException: builder.mutation<any, IEditCustomException>({
      query: (param: IEditCustomException) => ({
        url: `v2/organisation/${param.organisationId}/custom-exception/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          customFieldsApi.util.updateQueryData(
            'getCustomException',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'CustomException', id: param.id },
        { type: 'CustomExceptions', id: param.id },
      ],
    }),
    addCustomException: builder.mutation<any, IAddCustomException>({
      query: (param: IAddCustomException) => ({
        url: `v2/organisation/${param.organisationId}/custom-exception`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'CustomExceptions', id: 'LIST' }],
    }),
    deleteCustomException: builder.mutation<any, IDeleteCustomException>({
      query: (param: IDeleteCustomException) => ({
        url: `v2/organisation/${param.organisationId}/custom-exception/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'CustomException', id: param.id },
        { type: 'CustomExceptions', id: param.id },
      ],
    }),
  }),
});
export const {
  useGetSurfaceTypeQuery,
  useGetSurfaceTypesQuery,
  useEditSurfaceTypeMutation,
  useAddSurfaceTypeMutation,
  useDeleteSurfaceTypeMutation,
  useGetSanitiserQuery,
  useGetSanitisersQuery,
  useEditSanitiserMutation,
  useAddSanitiserMutation,
  useDeleteSanitiserMutation,
  useGetCustomExceptionQuery,
  useGetCustomExceptionsQuery,
  useEditCustomExceptionMutation,
  useAddCustomExceptionMutation,
  useDeleteCustomExceptionMutation,
} = customFieldsApi;

export const {
  endpoints: {
    deleteSurfaceType,
    editSurfaceType,
    addSurfaceType,
    getSurfaceType,
    getSurfaceTypes,
    deleteSanitiser,
    getSanitisers,
    getSanitiser,
    addSanitiser,
    editSanitiser,
    deleteCustomException,
    getCustomExceptions,
    getCustomException,
    addCustomException,
    editCustomException,
  },
} = customFieldsApi;
