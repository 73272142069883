export default function cycleArray<T>(array: T[]): () => T {
  let i = 0;

  return () => {
    if (i === array.length) {
      i = 0;
    }

    const result = array[i];
    i += 1;
    return result;
  };
}
