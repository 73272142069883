import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { dosageGroupApi } from 'src/api/dosage-group';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';

interface ChemicalGroupState {
  searchText?: string;
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
}

const initialState: ChemicalGroupState = {
  searchText: '',
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'name',
  order: 'asc',
};

const slice = createSlice({
  name: 'chemicalGroup',
  initialState,
  reducers: {
    setLimit(state: ChemicalGroupState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: ChemicalGroupState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setSearchText(state: ChemicalGroupState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setOrder(
      state: ChemicalGroupState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        dosageGroupApi.endpoints.getPaginatedChemicalGroups.matchPending,
        (state, action) => {
          console.log('pending', action);
        }
      )
      .addMatcher(
        dosageGroupApi.endpoints.getPaginatedChemicalGroups.matchFulfilled,
        (state, action) => {
          const { meta } = action.payload;
          state.total = meta.total;
        }
      )
      .addMatcher(
        dosageGroupApi.endpoints.getPaginatedChemicalGroups.matchRejected,
        (state, action) => {
          console.log('rejected', action);
        }
      );
  },
});

export const { reducer } = slice;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export default slice;
