import { JobRecurrence } from 'src/types/job';
import {
  IDeleteRecurrence,
  IGetJobRecurrence,
  IGetRecurrences,
  IUpdateJobRecurrence,
} from './model/recurrence.model';
import { rootSplitApi } from './root';

export const recurrenceApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getJobRecurrence: builder.query<JobRecurrence, IGetJobRecurrence>({
      query: (param: IGetJobRecurrence) =>
        `v2/organisation/${param.organisationId}/job-recurrence/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'Recurrence', id: param.id }],
    }),
    getRecurrenceJobs: builder.query<any, IGetRecurrences>({
      query: (param: IGetRecurrences) => ({
        url: `v2/organisation/${param.organisationId}/job-recurrence/list`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Recurrence' as const, id })),
              { type: 'Recurrence', id: 'LIST' },
            ]
          : [{ type: 'Recurrence', id: 'LIST' }],
    }),
    updateJobRecurrence: builder.mutation<JobRecurrence, IUpdateJobRecurrence>({
      query: (param: IUpdateJobRecurrence) => ({
        url: `v2/organisation/${param.organisationId}/job-recurrence/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          recurrenceApi.util.updateQueryData(
            'getJobRecurrence',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Recurrence', id: param.id },
        { type: 'Recurrence', id: 'LIST' },
      ],
    }),
    deleteRecurrence: builder.mutation<any, IDeleteRecurrence>({
      query: (param: IDeleteRecurrence) => ({
        url: `v1/organisations/${param.organisationId}/jobrecurrence/${param.id}/updateoccurrence`,
        method: 'put',
        body: param.body,
      }),
      invalidatesTags: ['Recurrence'],
    }),
  }),
});
export const {
  useGetRecurrenceJobsQuery,
  useDeleteRecurrenceMutation,
  useGetJobRecurrenceQuery,
  useUpdateJobRecurrenceMutation,
} = recurrenceApi;

export const {
  endpoints: { getRecurrenceJobs },
} = recurrenceApi;
