import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { contactApi } from 'src/api/contact';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { GridFilterModel } from '@mui/x-data-grid-pro';

interface ContactState {
  searchText?: string;
  filterModel?: GridFilterModel;
  tagsFilter?: string[];
  expandedContactId?: number | null;
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
}

const initialState: ContactState = {
  searchText: '',
  tagsFilter: [],
  filterModel: {
    items: [],
  },
  expandedContactId: null,
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'first_name',
  order: 'asc',
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setLimit(state: ContactState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: ContactState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setExpandedRow(state: ContactState, action: PayloadAction<{ contactId: number | null }>) {
      const { contactId } = action.payload;

      state.expandedContactId = contactId;
    },
    setSearchText(state: ContactState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setOrder(
      state: ContactState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    setTagsFilter(state: ContactState, action: PayloadAction<{ tags: string[] }>) {
      const { tags } = action.payload;

      state.tagsFilter = tags;
    },
    setFilterModel(state: ContactState, action: PayloadAction<{ filterModel: GridFilterModel }>) {
      const { filterModel } = action.payload;

      state.filterModel = filterModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(contactApi.endpoints.getContacts.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(contactApi.endpoints.getContacts.matchFulfilled, (state, action) => {
        const { meta } = action.payload;
        state.total = meta.total;
      })
      .addMatcher(contactApi.endpoints.getContacts.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const { reducer } = slice;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setExpandedRow = (contactId: number | null) => (dispatch) => {
  dispatch(slice.actions.setExpandedRow({ contactId }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export const setTagsFilter = (tags: string[]) => (dispatch) => {
  dispatch(slice.actions.setTagsFilter({ tags }));
};

export const setFilterModel = (filterModel: GridFilterModel) => (dispatch) => {
  dispatch(slice.actions.setFilterModel({ filterModel }));
};

export default slice;
