import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ISpinWaterTest } from './model/water-test.model';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { About, Options, Status, WaterTest } from 'src/types/spinTouch';

export const spinTouchApi = createApi({
  reducerPath: 'spinTouchApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['SpinOptions'],
  endpoints: (builder) => ({
    checkStatus: builder.query<Status, any>({
      query: () => 'http://localhost:61547/wlc/status',
    }),
    about: builder.query<About, any>({
      query: () => 'http://localhost:61547/wlc/about',
    }),
    update: builder.query<any, any>({
      query: () => 'http://localhost:61547/wlc/update',
    }),
    getOptions: builder.query<Options, any>({
      query: () => `http://localhost:61547/wlc/options?version=pool`,
      keepUnusedDataFor: 5000,
      providesTags: () => [{ type: 'SpinOptions', id: 'LIST' }],
    }),
    waterTest: builder.query<WaterTest, ISpinWaterTest>({
      query: (param: ISpinWaterTest) => {
        const { reagent, sample } = param;
        return `http://localhost:61547/wlc/watertest?reagent=${reagent}&sample=${sample}`;
      },
    }),
  }),
});
export const {
  useLazyCheckStatusQuery,
  useLazyAboutQuery,
  useLazyGetOptionsQuery,
  useLazyWaterTestQuery,
  useLazyUpdateQuery,
} = spinTouchApi;

export const {
  endpoints: { getOptions, checkStatus },
} = spinTouchApi;
