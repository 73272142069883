interface FreshdeskConfig {
  widgetId: string;
}

const warn = (...args) => {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }

  console.warn(...args);
};

class Freshdesk {
  WIDGET_ID = null;
  initialized = false;

  configure(config: FreshdeskConfig) {
    this.WIDGET_ID = config.widgetId;
  }

  initialize(config) {
    if (this.initialized) {
      warn('Freshdesk can only be initialized once.');
      return;
    }

    if (!document) {
      warn('Freshdesk can be initialized only on client side.');
      return;
    }

    if (!config.widgetId) {
      warn('Freshdesk requires a widget ID to be loaded.');
      return;
    }

    this.configure(config);

    const fwSettingsScript = document.createElement('script');
    const script = document.createElement('script');

    fwSettingsScript.innerHTML = `
      window.fwSettings={
      'widget_id':${this.WIDGET_ID}
      };
      !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
    `;

    script.type = 'text/javascript';
    script.src = `https://widget.freshworks.com/widgets/${this.WIDGET_ID}.js`;
    script.async = true;
    script.defer = true;

    document.head.insertBefore(script, document.head.childNodes[0]);
    document.head.insertBefore(fwSettingsScript, document.head.childNodes[0]);
  }

  // eslint-disable-next-line class-methods-use-this
  push(...args) {
    if (!window) {
      warn('GTM push works only on client side.');
      return;
    }

    if (!(window as any).dataLayer) {
      (window as any).dataLayer = [];
    }

    (window as any).dataLayer.push(...args);
  }
}

// Singleton
export const freshdesk = new Freshdesk();

export default freshdesk;
