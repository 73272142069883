import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { BatchQueue } from 'src/types/batch-queue';
import type { AppThunk } from '../store';

interface BatchQueueState {
  batchQueue: BatchQueue[];
  isLoading: boolean;
  contactTags?: string[];
  searchQuery: string;
}

const initialState: BatchQueueState = {
  batchQueue: [],
  isLoading: false,
  contactTags: [],
  searchQuery: '',
};

const slice = createSlice({
  name: 'batchQueue',
  initialState,
  reducers: {
    setBatchQueue(
      state: BatchQueueState,
      action: PayloadAction<{ batchQueue: BatchQueue[] }>
    ): void {
      const { batchQueue } = action.payload;
      state.batchQueue = batchQueue;
      state.isLoading = false;
    },
    getBatchQueue(state: BatchQueueState): void {
      state.isLoading = true;
    },
    setContactTags(state: BatchQueueState, action: PayloadAction<{ contactTags: string[] }>) {
      const { contactTags } = action.payload;

      state.contactTags = contactTags;
    },
    setSearchQuery(state: BatchQueueState, action: PayloadAction<{ searchQuery: string }>) {
      state.searchQuery = action.payload.searchQuery;
    },
  },
});

export const { reducer } = slice;

export const { setSearchQuery } = slice.actions;

export const getBatchQueue =
  (organisationId: number, contactTags: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.getBatchQueue());
    const response = await axios.get(
      `v2/organisations/${organisationId}/batch-invoice/batch-queue?contact_tags=${contactTags}`
    );
    dispatch(slice.actions.setBatchQueue({ batchQueue: response.data }));
  };

export const setContactTags = (contactTags: string[]) => (dispatch) => {
  dispatch(slice.actions.setContactTags({ contactTags }));
};

export default slice;
