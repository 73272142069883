import { ORIGINAL_REPORT_TYPE, VISUAL_REPORT_TYPE } from './constants/chemical';

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const freshdeskConfig = {
  widgetId: process.env.NEXT_PUBLIC_FRESHDESK_WIDGET_ID,
};

export const pusherConfig = {
  appKey: process.env.NEXT_PUBLIC_PUSHER_APP_KEY,
  appCluster: process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER,
};

export const muiConfig = {
  xLicencePro: process.env.NEXT_PUBLIC_X_LICENCE_PRO,
};

export const googleConfig = {
  apiKey: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  captchaKey: process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA_KEY,
};

export const apiConfig = {
  baseUrl: `${process.env.NEXT_PUBLIC_POOLTRACKR_API_URI}`,
  filterModelLimit: 5,
};

export const stripeConfig = {
  clientId: process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID,
  apiKey: process.env.NEXT_PUBLIC_STRIPE_API_KEY,
};

export const loggerConfig = {
  apiKey: process.env.NEXT_PUBLIC_LOGFLARE_API_KEY,
  sourceToken: process.env.NEXT_PUBLIC_LOGFLARE_SOURCE_TOKEN,
};

export const expressFlex = {
  quickStartLink: 'https://xfmedia.taylorxfconnect.com/promo/XpressFlex_QuickStartGuide.pdf',
  downloadLink: 'https://xfmedia.taylorxfconnect.com/program/XFConnect_Setup_v1.1.1.8.exe',
  videosLink: 'https://www.taylorxpressflex.com/',
};

export const pooltrackrLinks = {
  termsLink: 'https://pooltrackr.com/terms-and-conditions',
  privacyLink: 'https://pooltrackr.com/privacy-policy',
  knowledgeBase: 'https://pooltrackrassist.freshdesk.com/support/home',
  raiseTicket: 'https://pooltrackrassist.freshdesk.com/support/tickets/new',
  poolImportFileExample: process.env.NEXT_PUBLIC_POOL_IMPORT_FILE_EXAMPLE,
  productImportFileExample: process.env.NEXT_PUBLIC_PRODUCT_IMPORT_FILE_EXAMPLE,
  importGuide: process.env.NEXT_PUBLIC_IMPORT_GUIDE,
};

export const spinTouch = {
  downloadLink: 'https://updates.waterlinkconnect.com/stable/WaterLinkConnect.exe',
  supportLink: 'https://lamotte.com/support/remote-support/',
};

export const environmentConfig = {
  showLegacyPermissions: process.env.NEXT_PUBLIC_SHOW_LEGACY_PERMISSIONS,
  environmentId: process.env.NEXT_PUBLIC_ENVIRONMENT_ID,
  defaultReport: process.env.NEXT_PUBLIC_DEFAULT_REPORT,
  defaultConfig: {
    logoImage: '',
    loginImage: '',
    theme: {
      primary: {
        main: '#31A6F4',
        light: '#7cc2ff',
        dark: '#0077e6',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#06b6d4',
        light: '#67e8f9',
        dark: '#0e7490',
        contrastText: '#FFFFFF',
      },
      lightest: '#EDFBFF',
      light: '#57BEFF',
      main: '#31A6F4',
      dark: '#0091DD',
      darkest: '#00245F',
      contrastText: '#FFFFFF',
    },
    testReportTypes: [
      {
        value: ORIGINAL_REPORT_TYPE,
        label: 'Original',
      },
      {
        value: VISUAL_REPORT_TYPE,
        label: 'Visual',
      },
    ],
  },
};
