import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './base-query';
import { IPool } from './model/pool.model';
import {
  IAddEquipment,
  IEditEquipment,
  IDeleteEquipment,
  IGetEquipment,
  IGetEquipmentTypes,
  IDeleteEquipmentType,
  IAddEquipmentType,
  IEditEquipmentType,
  IGetEquipmentType,
  IGetEquipmentTemplates,
  IGetEquipmentTemplate,
  IAddEquipmentTemplate,
  IEditEquipmentTemplate,
  IDeleteEquipmentTemplate,
} from './model/equipment.model';

export const equipmentApi = createApi({
  reducerPath: 'equipmentApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: [
    'Equipment',
    'EquipmentList',
    'PoolEquipment',
    'EquipmentType',
    'EquipmentTypes',
    'PaginatedEquipmentTypes',
    'EquipmentTemplate',
    'EquipmentTemplates',
    'PaginatedEquipmentTemplates',
  ],
  endpoints: (builder) => ({
    getPoolEquipment: builder.query<any, IPool>({
      query: (param: IPool) =>
        `v2/organisations/${param.organisationId}/pools/${param.id}/equipment`,
      providesTags: (result, error, param) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'PoolEquipment' as const, id })),
              { type: 'PoolEquipment', id: param.id },
            ]
          : [{ type: 'PoolEquipment', id: param.id }],
    }),
    getEquipment: builder.query<any, IGetEquipment>({
      query: (param: IGetEquipment) =>
        `v2/organisation/${param.organisationId}/equipment/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'Equipment', id: param.id }],
    }),
    addEquipment: builder.mutation<any, IAddEquipment>({
      query: (param: IAddEquipment) => ({
        url: `v2/organisation/${param.organisationId}/equipment`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'EquipmentList', id: 'LIST' },
        { type: 'PoolEquipment', id: result.pool_id },
      ],
    }),
    editEquipment: builder.mutation<any, IEditEquipment>({
      query: (param: IEditEquipment) => ({
        url: `v2/organisation/${param.organisationId}/equipment/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          equipmentApi.util.updateQueryData(
            'getEquipment',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Equipment', id: param.id },
        { type: 'EquipmentList', id: param.id },
        { type: 'PoolEquipment', id: result.pool_id },
      ],
    }),
    deleteEquipment: builder.mutation<any, IDeleteEquipment>({
      query: (param: IDeleteEquipment) => ({
        url: `v2/organisation/${param.organisationId}/equipment/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Equipment', id: param.id },
        { type: 'EquipmentList', id: param.id },
        { type: 'PoolEquipment', id: param.poolId },
      ],
    }),
    getEquipmentType: builder.query<any, IGetEquipmentType>({
      query: (param: IGetEquipmentType) =>
        `v2/organisation/${param.organisationId}/equipment-type/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'EquipmentType', id: param.id }],
    }),
    getEquipmentTypes: builder.query<any, IGetEquipmentTypes>({
      query: (param: IGetEquipmentTypes) =>
        `v2/organisation/${param.organisationId}/equipment-type?order=${param.order}`,
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'EquipmentTypes' as const, id })),
              { type: 'EquipmentTypes', id: 'LIST' },
            ]
          : [{ type: 'EquipmentTypes', id: 'LIST' }],
    }),
    getPaginatedEquipmentTypes: builder.query<any, IGetEquipmentTypes>({
      query: (param: IGetEquipmentTypes) => {
        const { organisationId, filter, limit, order, page } = param;
        let url = `v2/organisation/${organisationId}/equipment-type?paginated=1&order=${order}`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (limit) {
          url += `&limit=${limit}`;
        }

        if (page) {
          url += `&page=${page + 1}`;
        }

        return url;
      },
      providesTags: (result, error, param) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'PaginatedEquipmentTypes' as const, id })),
              { type: 'PaginatedEquipmentTypes', id: 'LIST' },
            ]
          : [{ type: 'PaginatedEquipmentTypes', id: 'LIST' }],
    }),
    addEquipmentType: builder.mutation<any, IAddEquipmentType>({
      query: (param: IAddEquipmentType) => ({
        url: `v2/organisation/${param.organisationId}/equipment-type`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'EquipmentTypes', id: 'LIST' },
        { type: 'PaginatedEquipmentTypes', id: 'LIST' },
      ],
    }),
    editEquipmentType: builder.mutation<any, IEditEquipmentType>({
      query: (param: IEditEquipmentType) => ({
        url: `v2/organisation/${param.organisationId}/equipment-type/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          equipmentApi.util.updateQueryData(
            'getEquipmentType',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'EquipmentType', id: param.id },
        { type: 'EquipmentTypes', id: param.id },
        { type: 'PaginatedEquipmentTypes', id: param.id },
      ],
    }),
    deleteEquipmentType: builder.mutation<any, IDeleteEquipmentType>({
      query: (param: IDeleteEquipmentType) => ({
        url: `v2/organisation/${param.organisationId}/equipment-type/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'EquipmentType', id: param.id },
        { type: 'EquipmentTypes', id: param.id },
        { type: 'PaginatedEquipmentTypes', id: param.id },
      ],
    }),
    getEquipmentTemplates: builder.query<any, IGetEquipmentTemplates>({
      query: (param: IGetEquipmentTemplates) =>
        `v2/organisation/${param.organisationId}/equipment-template?order=${param.order}`,
    }),
    getEquipmentTemplate: builder.query<any, IGetEquipmentTemplate>({
      query: (param: IGetEquipmentTemplate) =>
        `v2/organisation/${param.organisationId}/equipment-template/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'EquipmentTemplate', id: param.id }],
    }),
    getPaginatedEquipmentTemplates: builder.query<any, IGetEquipmentTemplates>({
      query: (param: IGetEquipmentTemplates) => {
        const { organisationId, filter, limit, order, page } = param;
        let url = `v2/organisation/${organisationId}/equipment-template?paginated=1&order=${order}`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (limit) {
          url += `&limit=${limit}`;
        }

        if (page) {
          url += `&page=${page + 1}`;
        }

        return url;
      },
      providesTags: (result, error, param) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'PaginatedEquipmentTemplates' as const,
                id,
              })),
              { type: 'PaginatedEquipmentTemplates', id: 'LIST' },
            ]
          : [{ type: 'PaginatedEquipmentTemplates', id: 'LIST' }],
    }),
    addEquipmentTemplate: builder.mutation<any, IAddEquipmentTemplate>({
      query: (param: IAddEquipmentTemplate) => ({
        url: `v2/organisation/${param.organisationId}/equipment-template`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'EquipmentTemplates', id: 'LIST' },
        { type: 'PaginatedEquipmentTemplates', id: 'LIST' },
      ],
    }),
    editEquipmentTemplate: builder.mutation<any, IEditEquipmentTemplate>({
      query: (param: IEditEquipmentTemplate) => ({
        url: `v2/organisation/${param.organisationId}/equipment-template/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          equipmentApi.util.updateQueryData(
            'getEquipmentTemplate',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'EquipmentTemplate', id: param.id },
        { type: 'EquipmentTemplates', id: param.id },
        { type: 'PaginatedEquipmentTemplates', id: param.id },
      ],
    }),
    deleteEquipmentTemplate: builder.mutation<any, IDeleteEquipmentTemplate>({
      query: (param: IDeleteEquipmentTemplate) => ({
        url: `v2/organisation/${param.organisationId}/equipment-template/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'EquipmentTemplate', id: param.id },
        { type: 'EquipmentTemplates', id: param.id },
        { type: 'PaginatedEquipmentTemplates', id: param.id },
      ],
    }),
  }),
});
export const {
  useGetEquipmentQuery,
  useGetPoolEquipmentQuery,
  useEditEquipmentMutation,
  useAddEquipmentMutation,
  useDeleteEquipmentMutation,
  useGetEquipmentTypesQuery,
  useGetEquipmentTypeQuery,
  useAddEquipmentTypeMutation,
  useEditEquipmentTypeMutation,
  useGetPaginatedEquipmentTypesQuery,
  useDeleteEquipmentTypeMutation,
  useGetEquipmentTemplateQuery,
  useGetEquipmentTemplatesQuery,
  useGetPaginatedEquipmentTemplatesQuery,
  useAddEquipmentTemplateMutation,
  useEditEquipmentTemplateMutation,
  useDeleteEquipmentTemplateMutation,
} = equipmentApi;

export const {
  endpoints: {
    getEquipment,
    getPaginatedEquipmentTypes,
    getPoolEquipment,
    getEquipmentType,
    getEquipmentTypes,
    getEquipmentTemplates,
    getPaginatedEquipmentTemplates,
  },
} = equipmentApi;
