import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ARCHIVED_COLUMNS, QUOTE_COLUMNS } from '@/components/quote/constants';
import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { authApi } from 'src/api/auth';

interface TableSettingsState {
  enabledProductColumns?: string[];
  enabledInvoiceColumns?: string[];
  enabledBatchColumns?: string[];
  enabledQuoteColumns?: string[];
  enabledArchivedQuoteColumns?: string[];
  contactTable?: {
    visibilityModel: GridColumnVisibilityModel;
  };
  labPoolTable?: {
    visibilityModel: GridColumnVisibilityModel;
  };
  poolTable?: {
    visibilityModel: GridColumnVisibilityModel;
  };
  contactInvoiceTable?: {
    visibilityModel: GridColumnVisibilityModel;
  };
  batchInvoiceTable?: {
    visibilityModel: GridColumnVisibilityModel;
  };
  productTable?: {
    visibilityModel: GridColumnVisibilityModel;
  };
  jobListView?: {
    visibilityModel: GridColumnVisibilityModel;
  };
  jobToInvoiceListView?: {
    visibilityModel: GridColumnVisibilityModel;
  };
  recurrenceListView?: {
    visibilityModel: GridColumnVisibilityModel;
  };
}

const initialState: TableSettingsState = {
  enabledQuoteColumns: QUOTE_COLUMNS.map((column) => column.id),
  enabledArchivedQuoteColumns: ARCHIVED_COLUMNS.map((column) => column.id),
  contactTable: {
    visibilityModel: {
      __detail_panel_toggle__: false,
      id: false,
      category_id: false,
      type: false,
      first_name: false,
      middle_name: false,
      last_name: false,
      contact_name: false,
      company_name: false,
      email: false,
      phones: false,
      pools: false,
      created_at: false,
    },
  },
  labPoolTable: {
    visibilityModel: {
      __detail_panel_toggle__: false,
      location_id: false,
      ground_level_id: false,
      pool_volume: false,
      surface_type_id: false,
      classification_id: false,
      filter: false,
      source_water: false,
      built_by: false,
      build_date: false,
      created_at: false,
    },
  },
  poolTable: {
    visibilityModel: {
      __detail_panel_toggle__: false,
      pool_type_id: false,
      location_id: false,
      ground_level_id: false,
      pool_volume: false,
      classification_id: false,
      surface_type_id: false,
      filter: false,
      source_water: false,
      built_by: false,
      build_date: false,
      created_at: false,
    },
  },
  contactInvoiceTable: {
    visibilityModel: {
      __detail_panel_toggle__: false,
    },
  },
  batchInvoiceTable: {
    visibilityModel: {
      __detail_panel_toggle__: false,
    },
  },
  productTable: {
    visibilityModel: {
      __detail_panel_toggle__: false,
    },
  },
  jobListView: {
    visibilityModel: {
      __detail_panel_toggle__: false,
    },
  },
  jobToInvoiceListView: {
    visibilityModel: {
      __detail_panel_toggle__: false,
    },
  },
  recurrenceListView: {
    visibilityModel: {
      __detail_panel_toggle__: false,
      id: false,
      user_id: false,
      pool_id: false,
    },
  },
};

const slice = createSlice({
  name: 'tableSettings',
  initialState,
  reducers: {
    setEnabledProductColumns(
      state: TableSettingsState,
      action: PayloadAction<{ enabledColumns: string[] }>
    ) {
      state.enabledProductColumns = action.payload.enabledColumns;
    },
    setEnabledQuoteColumns(
      state: TableSettingsState,
      action: PayloadAction<{ enabledColumns: string[] }>
    ) {
      state.enabledQuoteColumns = action.payload.enabledColumns;
    },
    setEnabledArchivedQuoteColumns(
      state: TableSettingsState,
      action: PayloadAction<{ enabledColumns: string[] }>
    ) {
      state.enabledArchivedQuoteColumns = action.payload.enabledColumns;
    },
    setLabPoolVisibilityModel(
      state: TableSettingsState,
      action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>
    ) {
      state.labPoolTable.visibilityModel = action.payload.visibilityModel;
    },
    setPoolVisibilityModel(
      state: TableSettingsState,
      action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>
    ) {
      state.poolTable.visibilityModel = action.payload.visibilityModel;
    },
    setContactVisibilityModel(
      state: TableSettingsState,
      action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>
    ) {
      state.contactTable.visibilityModel = action.payload.visibilityModel;
    },
    setContactInvoiceVisibilityModel(
      state: TableSettingsState,
      action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>
    ) {
      state.contactInvoiceTable.visibilityModel = action.payload.visibilityModel;
    },
    setBatchInvoiceVisibilityModel(
      state: TableSettingsState,
      action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>
    ) {
      state.contactInvoiceTable.visibilityModel = action.payload.visibilityModel;
    },
    setProductVisibilityModel(
      state: TableSettingsState,
      action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>
    ) {
      state.productTable.visibilityModel = action.payload.visibilityModel;
    },
    setJobListViewVisibilityModel(
      state: TableSettingsState,
      action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>
    ) {
      state.jobListView.visibilityModel = action.payload.visibilityModel;
    },
    setJobToInvoiceListViewVisibilityModel(
      state: TableSettingsState,
      action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>
    ) {
      state.jobToInvoiceListView.visibilityModel = action.payload.visibilityModel;
    },
    setRecurrenceListViewVisibilityModel(
      state: TableSettingsState,
      action: PayloadAction<{ visibilityModel: GridColumnVisibilityModel }>
    ) {
      state.recurrenceListView.visibilityModel = action.payload.visibilityModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.logout.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
        return initialState;
      })
      .addMatcher(authApi.endpoints.logout.matchRejected, (state, action) => {
        return initialState;
      });
  },
});

export const { reducer } = slice;

export const setEnabledProductColumns = (enabledColumns: string[]) => (dispatch) => {
  dispatch(slice.actions.setEnabledProductColumns({ enabledColumns }));
};

export const setEnabledQuoteColumns = (enabledColumns: string[]) => (dispatch) => {
  dispatch(slice.actions.setEnabledQuoteColumns({ enabledColumns }));
};

export const setEnabledArchivedQuoteColumns = (enabledColumns: string[]) => (dispatch) => {
  dispatch(slice.actions.setEnabledArchivedQuoteColumns({ enabledColumns }));
};

export const setContactVisibilityModel =
  (visibilityModel: GridColumnVisibilityModel) => (dispatch) => {
    dispatch(slice.actions.setContactVisibilityModel({ visibilityModel }));
  };

export const setLabPoolVisibilityModel =
  (visibilityModel: GridColumnVisibilityModel) => (dispatch) => {
    dispatch(slice.actions.setLabPoolVisibilityModel({ visibilityModel }));
  };

export const setPoolVisibilityModel =
  (visibilityModel: GridColumnVisibilityModel) => (dispatch) => {
    dispatch(slice.actions.setPoolVisibilityModel({ visibilityModel }));
  };

export const setContactInvoiceVisibilityModel =
  (visibilityModel: GridColumnVisibilityModel) => (dispatch) => {
    dispatch(slice.actions.setContactInvoiceVisibilityModel({ visibilityModel }));
  };

export const setBatchInvoiceVisibilityModel =
  (visibilityModel: GridColumnVisibilityModel) => (dispatch) => {
    dispatch(slice.actions.setBatchInvoiceVisibilityModel({ visibilityModel }));
  };

export const setProductVisibilityModel =
  (visibilityModel: GridColumnVisibilityModel) => (dispatch) => {
    dispatch(slice.actions.setProductVisibilityModel({ visibilityModel }));
  };

export const setJobListViewVisibilityModel =
  (visibilityModel: GridColumnVisibilityModel) => (dispatch) => {
    dispatch(slice.actions.setJobListViewVisibilityModel({ visibilityModel }));
  };

export const setJobToInvoiceListViewVisibilityModel =
  (visibilityModel: GridColumnVisibilityModel) => (dispatch) => {
    dispatch(slice.actions.setJobToInvoiceListViewVisibilityModel({ visibilityModel }));
  };

export const setRecurrenceListViewVisibilityModel =
  (visibilityModel: GridColumnVisibilityModel) => (dispatch) => {
    dispatch(slice.actions.setRecurrenceListViewVisibilityModel({ visibilityModel }));
  };

export default slice;
