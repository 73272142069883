import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import type { AppThunk } from '../store';
import { Job } from '../types/job';

const LIMIT = 10;

interface PoolPhotosState {
  isLoading: boolean;
  page: number;
  jobs: Job[];
  hasNextPage: boolean;
}

const initialState: PoolPhotosState = {
  isLoading: false,
  page: 1,
  jobs: [],
  hasNextPage: false,
};

const slice = createSlice({
  name: 'poolPhotos',
  initialState,
  reducers: {
    reset(state: PoolPhotosState): void {
      state.isLoading = initialState.isLoading;
      state.page = initialState.page;
      state.jobs = initialState.jobs;
    },
    load(state: PoolPhotosState, action: PayloadAction<{ page: number }>): void {
      state.isLoading = true;
      state.page = action.payload.page;
    },
    loadDone(
      state: PoolPhotosState,
      action: PayloadAction<{ jobs: Job[]; hasNextPage: boolean }>
    ): void {
      const { jobs }: { jobs: Job[] } = action.payload;

      if (state.page === 1) {
        state.jobs = jobs;
      } else {
        state.jobs = [...state.jobs, ...jobs];
      }

      state.isLoading = false;
      state.hasNextPage = action.payload.hasNextPage;
    },
  },
});

export const { reducer } = slice;

export const reset =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.reset());
  };

export const load =
  (page: number, organisationId: number, poolId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.load({ page }));
    const response = await axios.get(
      `v2/organisations/${organisationId}/pools/${poolId}/jobs-photos?limit=${LIMIT}&page=${page}`
    );
    dispatch(
      slice.actions.loadDone({
        jobs: response.data.data,
        hasNextPage: response.data.next_page_url != null,
      })
    );
  };

export default slice;
