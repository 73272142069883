import getDay from 'date-fns/getDay';
import sortBy from 'lodash/sortBy';
import { technicianColors } from 'src/constants/calendar-day-view';
import { User, WorkingHours } from 'src/types/user';
import cycleArray from './cycleArray';

export const getWorkingHoursByDate = (
  workingHours: WorkingHours,
  date: Date
): [string?: string, string?: string] => {
  let result;
  const weekday = getDay(date);
  switch (weekday) {
    case 1:
      result = [workingHours.monday_start, workingHours.monday_end];
      break;
    case 2:
      result = [workingHours.tuesday_end, workingHours.tuesday_end];
      break;
    case 3:
      result = [workingHours.wednesday_end, workingHours.wednesday_end];
      break;
    case 4:
      result = [workingHours.thursday_start, workingHours.thursday_end];
      break;
    case 5:
      result = [workingHours.friday_start, workingHours.friday_end];
      break;
    case 6:
      result = [workingHours.saturday_start, workingHours.saturday_end];
      break;
    case 0:
      result = [workingHours.sunday_start, workingHours.sunday_end];
      break;
  }

  return result;
};

export const createColorGetter = (technicians: User): ((id: number) => string) => {
  const idToColor: { [technicianId: number]: string } = {};
  const getColor = cycleArray(technicianColors);

  sortBy(technicians, 'id').forEach(({ id }) => {
    idToColor[id] = getColor();
  });

  const getColorById = (id: number): string => {
    return idToColor[id];
  };

  return getColorById;
};
