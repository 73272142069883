import { MessagingSettings } from 'src/types/messaging-settings';
import { NotificationSettings } from 'src/types/notification-settings';
import { SmsTier, SmsTiers } from 'src/types/sms';
import {
  IBuySmsPack,
  IGetSmsList,
  IResendSms,
  IUpdateMessagingSettings,
  IUpdateNotificationSettings,
  IUpdateSmsPhone,
} from './model/messaging.model';
import { rootSplitApi } from './root';

export const messagingApi = rootSplitApi.injectEndpoints({
  endpoints: (build) => ({
    getNotificationSettings: build.query<NotificationSettings, string | number>({
      query: (id) => `v1/organisations/${id}/notification-settings`,
      providesTags: (result, error, id) => [{ type: 'NotificationSettings', id }],
    }),
    updateNotificationSettings: build.mutation<NotificationSettings, IUpdateNotificationSettings>({
      query: (param: IUpdateNotificationSettings) => ({
        url: `v1/organisations/${param.organisationId}/notification-settings`,
        method: 'PUT',
        body: param.body,
      }),
      async onQueryStarted({ organisationId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          messagingApi.util.updateQueryData('getNotificationSettings', organisationId, (draft) => {
            Object.assign(draft, body);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'NotificationSettings', id: param.organisationId },
      ],
    }),
    buySmsPack: build.mutation<NotificationSettings, IBuySmsPack>({
      query: (param: IBuySmsPack) => ({
        url: `v1/organisations/${param.organisationId}/buy-pack`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'NotificationSettings', id: param.organisationId },
      ],
    }),
    getMessagingSettings: build.query<MessagingSettings, number>({
      query: (id) => `v2/organisation/${id}/messaging-settings`,
      providesTags: (result, error, id) => [{ type: 'MessagingSettings', id }],
    }),
    updateMessagingSettings: build.mutation<MessagingSettings, IUpdateMessagingSettings>({
      query: (param: IUpdateMessagingSettings) => ({
        url: `v2/organisation/${param.organisationId}/messaging-settings`,
        method: 'PUT',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'MessagingSettings', id: param.organisationId },
      ],
    }),
    getSmsTiers: build.query<SmsTier[], number>({
      query: (id) => `v1/organisations/${id}/sms-tier`,
      transformResponse: (smsTiers: SmsTiers) => {
        return smsTiers?.tiers || [];
      },
      providesTags: (result, error, id) => [{ type: 'SmsTiers', id }],
    }),
    getSmsList: build.query<any, IGetSmsList>({
      query: (param: IGetSmsList) => ({
        url: `v2/organisation/${param.organisationId}/sms-notification`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'SmsList' as const, id })),
              { type: 'SmsList', id: 'LIST' },
            ]
          : [{ type: 'SmsList', id: 'LIST' }],
    }),
    updateSmsPhone: build.mutation<any, IUpdateSmsPhone>({
      query: (param: IUpdateSmsPhone) => ({
        url: `v2/organisation/${param.organisationId}/sms-notification/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [{ type: 'SmsList', id: param.id }],
    }),
    resendSms: build.mutation<any, IResendSms>({
      query: (param: IResendSms) => ({
        url: `v2/organisation/${param.organisationId}/sms-notification/${param.id}`,
        method: 'post',
      }),
      invalidatesTags: (result, error, param) => [{ type: 'SmsList', id: param.id }],
    }),
  }),
});
export const {
  useGetMessagingSettingsQuery,
  useUpdateMessagingSettingsMutation,
  useGetNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
  useBuySmsPackMutation,
  useGetSmsTiersQuery,
  useGetSmsListQuery,
  useUpdateSmsPhoneMutation,
  useResendSmsMutation,
} = messagingApi;

export const {
  endpoints: {
    updateMessagingSettings,
    getMessagingSettings,
    getNotificationSettings,
    updateNotificationSettings,
    buySmsPack,
    getSmsTiers,
    getSmsList,
    updateSmsPhone,
    resendSms,
  },
} = messagingApi;
