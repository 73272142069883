import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { EnvironmentConfig } from 'src/types/config';
import { environmentConfig } from 'src/config';
import objFromArray from 'src/utils/objFromArray';
import { authApi } from 'src/api/auth';
import { METRIC_UNIT } from 'src/constants/chemical-calculator';
import { organisationApi } from 'src/api/organisation';
import { ThemeSettings } from 'src/types/settings';
import { Language } from 'src/locales/localeType';

interface SettingsState {
  isInitialised: boolean;
  themeSettings: ThemeSettings;
  environment: null | string;
  environmentConfigs: {
    byId: Record<string, EnvironmentConfig>;
    allIds: string[];
  };
  environmentConfig: null | EnvironmentConfig;
  isSidebarOpen: boolean;
  openDrawer: boolean;
  locale: Language;
  units: string;
}

export const initialState: SettingsState = {
  isInitialised: false,
  themeSettings: {
    contrast: 'high',
    direction: 'ltr',
    layout: 'vertical',
    navColor: 'discreet',
    paletteMode: 'light',
    responsiveFontSizes: true,
    compact: false,
  },
  environment: environmentConfig.environmentId,
  environmentConfigs: {
    byId: {},
    allIds: [],
  },
  environmentConfig: null,
  isSidebarOpen: true,
  openDrawer: false,
  locale: 'en',
  units: METRIC_UNIT,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    initialise(
      state: SettingsState,
      action: PayloadAction<{ environment: string; environmentConfigs: EnvironmentConfig[] }>
    ): void {
      const { environment, environmentConfigs } = action.payload;

      state.environment = environment;
      state.environmentConfigs.byId = objFromArray(environmentConfigs);
      state.environmentConfigs.allIds = Object.keys(state.environmentConfigs.byId);
      state.environmentConfig = state.environmentConfigs.byId[state.environment];
      state.isInitialised = true;
    },
    saveThemeSettings(state: SettingsState, action: PayloadAction<ThemeSettings>): void {
      state.themeSettings = action.payload;
    },
    saveCurrentEnvironment(state: SettingsState, action: PayloadAction<string>): void {
      state.environment = action.payload;
      state.environmentConfig = state.environmentConfigs.byId[state.environment];
    },
    toggleSidebar(state: SettingsState, action: PayloadAction<boolean>): void {
      state.isSidebarOpen = action.payload;
    },
    toggleDrawer(state: SettingsState, action: PayloadAction<boolean>): void {
      state.openDrawer = action.payload;
    },
    setLocale(state: SettingsState, action: PayloadAction<Language>): void {
      state.locale = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        const { organisation } = action.payload;
        const { units } = organisation;
        state.units = units;
      })
      .addMatcher(organisationApi.endpoints.updateOrganisation.matchFulfilled, (state, action) => {
        const { units } = action.payload;
        state.units = units;
      })
      .addMatcher(organisationApi.endpoints.getOrganisation.matchFulfilled, (state, action) => {
        const { units } = action.payload;
        state.units = units;
      });
  },
});

export const { reducer } = slice;

export const {
  initialise,
  saveThemeSettings,
  saveCurrentEnvironment,
  toggleSidebar,
  toggleDrawer,
  setLocale,
} = slice.actions;

export const selectIsInitialised = (state: RootState) => state.settings.isInitialised;
export const selectThemeSettings = (state: RootState) => state.settings.themeSettings;
export const selectEnvironment = (state: RootState) => state.settings.environment;
export const selectEnvironmentConfigs = (state: RootState) => state.settings.environmentConfigs;
export const selectEnvironmentConfig = (state: RootState) => state.settings.environmentConfig;
export const selectIsSidebarOpen = (state: RootState) => state.settings.isSidebarOpen;
export const selectOpenDrawer = (state: RootState) => state.settings.openDrawer;
export const selectUnits = (state: RootState) => state.settings.units;
export const selectLocale = (state: RootState) => state.settings.locale;

export default slice;
