import find from 'lodash/find';
import filter from 'lodash/filter';
import startsWith from 'lodash/startsWith';
import map from 'lodash/map';
import {
  MOBILE_USER_PERMISSIONS,
  OPERATIONS_CONTACTS,
  OPERATIONS_JOBS,
  OPERATIONS_LAB,
  OPERATIONS_PERMISSIONS,
  OPERATIONS_POOLS,
  OPERATIONS_PRODUCTS,
  OPERATIONS_QUOTES,
  SectionPermission,
  SETUP_PERMISSIONS,
} from 'src/constants/permission';
import { Permission, User } from 'src/types/user';
import { tokens } from 'src/locales/tokens';
import { QuickLink } from 'src/types/common';
import { paths } from 'src/paths';
import { FIELD_PLAN, LAB_PLAN, PRO_PLAN } from 'src/constants/subscription';
import { Item, Section } from 'src/layouts/config';

export const getMobilePermissions = (permissions: Permission[]) => {
  return filter(permissions, (permission) =>
    find(MOBILE_USER_PERMISSIONS, (permissionName) => permissionName === permission.name)
  );
};

export const getOperationsPermissions = (permissions: Permission[], subscriptionPlan: string) => {
  return filter(permissions, (permission) =>
    find(
      OPERATIONS_PERMISSIONS,
      (sectionPermission: SectionPermission) =>
        sectionPermission.permission === permission.name &&
        sectionPermission.availablePlans.indexOf(subscriptionPlan as never) > -1
    )
  );
};

export const getSetupPermissions = (permissions: Permission[], subscriptionPlan: string) => {
  return filter(permissions, (permission) =>
    find(
      SETUP_PERMISSIONS,
      (sectionPermission: SectionPermission) =>
        sectionPermission.permission === permission.name &&
        sectionPermission.availablePlans.indexOf(subscriptionPlan as never) > -1
    )
  );
};

export const getPermissionsByType = (permissions: Permission[], type: string | string[]) => {
  return filter(permissions, (permission) => {
    if (Array.isArray(type)) {
      return find(type, (_type) => startsWith(permission.name, _type));
    } else {
      return startsWith(permission.name, type);
    }
  });
};

export const userHasPermission = (user: User, permission: string) => {
  const { permissions } = user;
  return find(permissions, (_permission) => _permission.name === permission);
};

export const filterAvailableNavSections = (
  sections: Section[],
  user: User,
  subscriptionPlan: string
): Section[] => {
  const filteredSectionItems = map(sections, (section: Section) => {
    const availableSubsections = filter(section.items, (item: Item) => {
      return (
        (!item.subscriptions || item.subscriptions.includes(subscriptionPlan)) &&
        (!item.permission || userHasPermission(user, item.permission))
      );
    }) as Item[];
    return {
      ...section,
      items: map(availableSubsections, (subsection: Item) => {
        return subsection?.items?.length > 0
          ? {
              ...subsection,
              items: filter(subsection.items, (subItem) => {
                return (
                  (!subItem.subscriptions || subItem.subscriptions.includes(subscriptionPlan)) &&
                  (!subItem.permission || userHasPermission(user, subItem.permission))
                );
              }) as Item[],
            }
          : subsection;
      }),
    };
  });
  return filter(filteredSectionItems, (filteredSection) => filteredSection.items.length > 0);
};

export const getQuickLinks = (user: User, subscriptionPlan: string): QuickLink[] => {
  const quickLinks: QuickLink[] = [
    {
      label: tokens.common.newContact,
      path: paths.contact.new,
      subscriptions: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
      permission: OPERATIONS_CONTACTS,
    },
    {
      label: tokens.common.newPool,
      path: paths.pool.new,
      subscriptions: [LAB_PLAN, FIELD_PLAN, PRO_PLAN],
      permission: OPERATIONS_POOLS,
    },
    {
      label: tokens.common.newWaterTest,
      path: paths.lab.waterTest.index,
      subscriptions: [LAB_PLAN, PRO_PLAN],
      permission: OPERATIONS_LAB,
    },
    {
      label: tokens.common.newJob,
      path: paths.job.new,
      subscriptions: [FIELD_PLAN, PRO_PLAN],
      permission: OPERATIONS_JOBS,
    },
    {
      label: tokens.common.newQuote,
      path: paths.quote.new,
      subscriptions: [FIELD_PLAN, PRO_PLAN],
      permission: OPERATIONS_QUOTES,
    },
    {
      label: tokens.common.newProduct,
      path: paths.product.new,
      subscriptions: [FIELD_PLAN, PRO_PLAN],
      permission: OPERATIONS_PRODUCTS,
    },
  ];

  return filter(quickLinks, (quickLink: QuickLink) => {
    return (
      userHasPermission(user, quickLink.permission) &&
      quickLink.subscriptions.includes(subscriptionPlan)
    );
  });
};
