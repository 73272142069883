import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import type { Phone, PhoneType, Contact, VendContact } from 'src/types/contact';
import { User } from 'src/types/user';
import { MOBILE_PATTERNS, PHONE_TYPES } from '../constants/contact';

export const getCommaSeparatedPhones = (phones: Phone[]) =>
  phones.map((v) => v.phone_number).join(', ');

export const getPhoneTypeName = (phoneType: PhoneType) =>
  find(PHONE_TYPES, { value: phoneType }).label;

export const uniquePhones = (phones: Phone[]): Phone[] => {
  const mapPhoneNumberToPhone: { [key: string]: Phone } = {};
  phones.forEach((phone) => {
    mapPhoneNumberToPhone[phone.phone_number] = phone;
  });
  return Object.values(mapPhoneNumberToPhone);
};

export const getFullName = (contact: Contact | VendContact | User) => {
  if (contact == null) {
    return '';
  }
  let fullName = contact?.first_name;

  // @ts-ignore
  if (!isEmpty(contact?.middle_name))
    // @ts-ignore
    fullName = `${fullName} ${contact?.middle_name}`;

  if (!isEmpty(contact?.last_name)) fullName = `${fullName} ${contact?.last_name}`;

  return fullName;
};

export const isValidPhoneNumber = (phoneNumber: string) =>
  Object.values(MOBILE_PATTERNS).some((pattern) => pattern.test(phoneNumber));
