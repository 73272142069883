import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from 'axios';
import { AppThunk } from 'src/store';
import { Address } from 'src/types/address';
import { DuplicationPoolSwitcher } from 'src/types/deduplicate-pool-tool';
import {
  Classification,
  GroundLevel,
  Location,
  Pool,
  PoolSanitiser,
  PoolSurfaceType,
  PoolType,
  PoolVolume,
} from 'src/types/pool';
import removeEmptyFieldsFromObject from 'src/utils/removeEmptyFieldsFromObject';
import { uniqueAddresses } from 'src/utils/address';

interface DeduplicatePoolToolState {
  isLoadingDuplications: boolean;
  isMerging: boolean;

  mergeError?: string;

  switchers: DuplicationPoolSwitcher[];

  duplicates: { [group: string]: GroupDuplications };
  duplicatesCount: number;

  pageLimit: number;
  currentPage: number;
  expandedGroupKey: string;

  // available values for all pools
  poolTypes: PoolType[];
  surfaceTypes: PoolSurfaceType[];
  poolSanitisers: PoolSanitiser[];
  poolClassifications: Classification[];
  groundLevels: GroundLevel[];
  locations: Location[];
}

interface GroupDuplications {
  primaryPoolDuplicationId: number;
  groupName: string;
  poolDuplications: PoolDuplication[];
}

interface PoolDuplication {
  // information about pool
  pool: Pool;

  // current selected values of field
  addressIndex: number;
  poolTypeIndex: number;
  poolVolume: PoolVolume;
  surfaceTypeIndex?: number;
  sanitisizers: PoolSanitiser[];
  classificationIndex?: number;
  locationIndex?: number;
  groundLevelIndex?: number;

  // available values
  addresses: Address[];
  poolVolumes: PoolVolume[];
}

const initialState: DeduplicatePoolToolState = {
  isLoadingDuplications: false,
  isMerging: false,

  switchers: [DuplicationPoolSwitcher.linkedContact],

  duplicates: {},
  duplicatesCount: 0,

  pageLimit: 10,
  currentPage: 0,
  expandedGroupKey: null,

  poolTypes: [],
  surfaceTypes: [],
  poolSanitisers: [],
  poolClassifications: [],
  groundLevels: [],
  locations: [],
};

function getPoolDuplicationIndexByGroup(
  state: DeduplicatePoolToolState,
  groupKey: string,
  poolId: number
): number | null {
  const duplicationGroup = state.duplicates[groupKey];

  if (duplicationGroup == null) {
    return -1;
  }

  const poolDuplicationIndex = duplicationGroup.poolDuplications.findIndex(
    (poolDuplication) => poolDuplication.pool.id === poolId
  );
  if (poolDuplicationIndex === -1) {
    return null;
  }

  return poolDuplicationIndex;
}

const slice = createSlice({
  name: 'deduplicatePoolTool',
  initialState,
  reducers: {
    startLoadingDuplications(state: DeduplicatePoolToolState) {
      state.isLoadingDuplications = true;
    },
    stopLoadingDuplications(state: DeduplicatePoolToolState) {
      state.isLoadingDuplications = false;
    },
    startMergingDuplications(state: DeduplicatePoolToolState) {
      state.isLoadingDuplications = true;
      state.mergeError = null;
    },
    stopMergingDuplications(state: DeduplicatePoolToolState) {
      state.isLoadingDuplications = false;
    },
    setMergeError(state: DeduplicatePoolToolState, action: PayloadAction<{ msg: string }>) {
      state.mergeError = action.payload.msg;
    },
    setPageLimit(state: DeduplicatePoolToolState, action: PayloadAction<{ limit: number }>) {
      state.pageLimit = action.payload.limit;
      state.currentPage = initialState.currentPage;
    },
    setDuplications(
      state: DeduplicatePoolToolState,
      action: PayloadAction<{
        duplications: { [group: string]: Pool[] };

        // pool specifications (all possible values)
        poolSanitisers: PoolSanitiser[];
        surfaceTypes: PoolSurfaceType[];
        poolTypes: PoolType[];
        locations: Location[];
        groundLevels: GroundLevel[];
        poolClassifications: Classification[];
      }>
    ) {
      const {
        duplications,
        poolSanitisers,
        surfaceTypes,
        poolTypes,
        locations,
        groundLevels,
        poolClassifications,
      } = action.payload;
      state.poolSanitisers = poolSanitisers;
      state.poolTypes = poolTypes;
      state.locations = locations;
      state.groundLevels = groundLevels;
      state.poolClassifications = poolClassifications;
      state.surfaceTypes = surfaceTypes;

      const duplicatesForState: { [group: string]: GroupDuplications } = {};

      Object.entries(duplications).forEach(([group, pools]) => {
        const addresses: Address[] = uniqueAddresses(
          pools.map((pool) => ({
            address_street_one: pool.address_street_one,
            address_street_two: pool.address_street_two,
            address_postcode: pool.address_postcode,
            address_state: pool.address_state,
            address_city: pool.address_city,
            address_country: pool.address_country,
          })),
          'pool'
        );

        const poolVolumes = Array.from(new Set(pools.map((pool) => pool.pool_volume)));

        duplicatesForState[group] = {
          primaryPoolDuplicationId: pools[0].id,
          groupName: group,
          poolDuplications: pools.map((pool) => {
            const addressIndex = addresses.findIndex(
              (address) =>
                address.address_country === pool.address_country &&
                address.address_postcode === pool.address_postcode &&
                address.address_state === pool.address_state &&
                address.address_street_one === pool.address_street_one
            );

            const poolTypeIndex = poolTypes.findIndex((type) => type.id === pool.pool_type_id);
            const surfaceTypeIndex = surfaceTypes.findIndex(
              (type) => type.id === pool.surface_type_id
            );
            const sanitisersIndexes = pool.pool_sanitisers.map((sanitiser) =>
              poolSanitisers.findIndex((_sanitiser) => _sanitiser.id === sanitiser.id)
            );
            const classificationIndex = poolClassifications.findIndex(
              (classifiction) => classifiction.id === pool.classification_id
            );
            const locationIndex = locations.findIndex(
              (location) => location.id === pool.location_id
            );
            const groundLevelIndex = groundLevels.findIndex(
              (groundLevel) => groundLevel.id === pool.ground_level_id
            );
            const sanitiserIds = pool.pool_sanitisers.map(({ id }) => id);
            const selectedPoolSanitisers = poolSanitisers.filter((poolSanitiser) =>
              sanitiserIds.includes(poolSanitiser.id)
            );

            return {
              pool,

              sanitisizers: selectedPoolSanitisers,
              poolVolumes,
              addressIndex,
              addresses,
              poolTypeIndex,
              poolVolume: pool.pool_volume,
              surfaceTypeIndex,
              sanitisersIndexes,
              classificationIndex,
              locationIndex,
              groundLevelIndex,
            };
          }),
        };
      });
      state.duplicates = duplicatesForState;
      state.duplicatesCount = Object.keys(state.duplicates).length;
      state.currentPage = initialState.currentPage;
      state.expandedGroupKey = initialState.expandedGroupKey;
    },
    updateField(
      state: DeduplicatePoolToolState,
      action: PayloadAction<{
        groupKey: string;
        poolId: number;
        fieldName: string;
        value: number | number[] | PoolType | PoolVolume | boolean | PoolSanitiser[];
      }>
    ) {
      const { groupKey, poolId, fieldName, value } = action.payload;
      const poolDuplicationIndex = getPoolDuplicationIndexByGroup(state, groupKey, poolId);

      if (poolDuplicationIndex == null) {
        return;
      }

      state.duplicates[groupKey].poolDuplications[poolDuplicationIndex][fieldName] = value;
    },
    ignorePool(
      state: DeduplicatePoolToolState,
      action: PayloadAction<{
        groupKey: string;
        poolId: number;
      }>
    ) {
      const { groupKey, poolId } = action.payload;
      const duplicationGroup = state.duplicates[groupKey];

      if (duplicationGroup == null || duplicationGroup.poolDuplications.length === 1) {
        return;
      }

      duplicationGroup.poolDuplications = duplicationGroup.poolDuplications.filter(
        (poolDuplication) => poolDuplication.pool.id !== poolId
      );

      if (duplicationGroup.poolDuplications.length === 1) {
        duplicationGroup.primaryPoolDuplicationId = duplicationGroup.poolDuplications[0].pool?.id;
      }
    },
    ignoreDuplicationGroup(
      state: DeduplicatePoolToolState,
      action: PayloadAction<{
        groupKey: string;
      }>
    ) {
      const { groupKey } = action.payload;
      delete state.duplicates[groupKey];
      state.duplicatesCount -= 1;
    },
    makePrimary(
      state: DeduplicatePoolToolState,
      action: PayloadAction<{
        groupKey: string;
        poolId: number;
      }>
    ) {
      const { groupKey, poolId } = action.payload;
      const duplicationGroup = state.duplicates[groupKey];

      if (duplicationGroup == null) {
        return;
      }

      duplicationGroup.primaryPoolDuplicationId = poolId;
    },
    updateDuplicationSwitcher(
      state: DeduplicatePoolToolState,
      action: PayloadAction<{
        switcher: DuplicationPoolSwitcher;
        checked: boolean;
      }>
    ) {
      const { switcher, checked } = action.payload;
      if (checked) {
        state.switchers = [...state.switchers, switcher];
      } else if (state.switchers.length > 1) {
        state.switchers = state.switchers.filter((s) => s !== switcher);
      }
    },
    setPage(
      state: DeduplicatePoolToolState,
      action: PayloadAction<{
        page: number;
      }>
    ) {
      state.currentPage = action.payload.page;
    },
    setExpandedGroupKey(
      state: DeduplicatePoolToolState,
      action: PayloadAction<{
        groupKey: string;
      }>
    ) {
      state.expandedGroupKey = action.payload.groupKey;
    },
  },
});

export const { reducer } = slice;
export const {
  setPage,
  setPageLimit,
  updateDuplicationSwitcher,
  updateField,
  ignorePool,
  makePrimary,
  ignoreDuplicationGroup,
  setExpandedGroupKey,
} = slice.actions;

interface GetDuplicationThunkPayload {
  organisationId: number;
  duplicationTypes: DuplicationPoolSwitcher[];
  poolSanitisers: PoolSanitiser[];
  surfaceTypes: PoolSurfaceType[];
  poolTypes: PoolType[];
  locations: Location[];
  groundLevels: GroundLevel[];
  poolClassifications: Classification[];
}

export const getDuplications =
  (payload: GetDuplicationThunkPayload): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoadingDuplications());
    dispatch(slice.actions.setDuplications({ duplications: {}, ...payload }));
    const filterBy = payload.duplicationTypes
      .map((duplicationType) => duplicationType.toString())
      .join(',');

    const { organisationId } = payload;

    try {
      const response = await axios.get(`v1/organisations/${organisationId}/get-duplicated-pools`, {
        params: { filterBy },
      });
      dispatch(
        slice.actions.setDuplications({
          duplications: response.data.duplicates,
          ...payload,
        })
      );
    } finally {
      dispatch(slice.actions.stopLoadingDuplications());
    }
  };

export const mergePools =
  (organisationId: number, state: DeduplicatePoolToolState): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoadingDuplications());
    const groupKey = state.expandedGroupKey;
    const groupDuplication = state.duplicates[groupKey];
    const { primaryPoolDuplicationId } = groupDuplication;

    const primaryDuplicationPool = groupDuplication.poolDuplications.find(
      (duplication) => primaryPoolDuplicationId === duplication.pool.id
    );

    const deduplicatePoolIds = groupDuplication.poolDuplications
      .filter((poolDuplication) => poolDuplication.pool.id !== primaryPoolDuplicationId)
      .map(({ pool }) => ({ id: pool.id }));
    const address = primaryDuplicationPool.addresses[primaryDuplicationPool.addressIndex];
    const poolSanitiserIds = primaryDuplicationPool.sanitisizers.map(({ id }) => ({ id }));

    const poolType = state.poolTypes[primaryDuplicationPool.poolTypeIndex];
    const surfaceTypeId = state.surfaceTypes[primaryDuplicationPool.surfaceTypeIndex]?.id;
    const classificationId =
      state.poolClassifications[primaryDuplicationPool.classificationIndex]?.id;
    const locationId = state.locations[primaryDuplicationPool.locationIndex]?.id;
    const groundLevelId = state.groundLevels[primaryDuplicationPool.groundLevelIndex]?.id;
    const payload = removeEmptyFieldsFromObject({
      id: primaryDuplicationPool.pool.id,
      address_city: address?.address_city,
      address_country: address?.address_country,
      address_postcode: address?.address_postcode,
      address_state: address?.address_state,
      address_street_one: address?.address_street_one,
      address_street_two: address?.address_street_two,
      deduplicate_pools: deduplicatePoolIds,
      pool_sanitisers: poolSanitiserIds,
      pool_type_id: poolType.id,
      pool_volume: primaryDuplicationPool.poolVolume,
      surface_type_id: surfaceTypeId,
      classification_id: classificationId,
      location_id: locationId,
      ground_level_id: groundLevelId,
    });

    try {
      await axios.put(
        `v1/organisations/${organisationId}/pools/${primaryDuplicationPool.pool.id}`,
        payload
      );
      dispatch(slice.actions.ignoreDuplicationGroup({ groupKey }));
    } catch (e) {
      if (typeof e.response.data === 'string') {
        dispatch(slice.actions.setMergeError({ msg: e.response.data }));
      }
      throw e;
    } finally {
      dispatch(slice.actions.stopLoadingDuplications());
    }
  };
