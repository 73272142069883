export const LAB_PLAN = 'lab';
export const FIELD_PLAN = 'field';
export const PRO_PLAN = 'pro';

export const SUBSCRIPTION_PLANS = [
  {
    value: LAB_PLAN,
    label: 'Lab',
  },
  {
    value: FIELD_PLAN,
    label: 'Field',
  },
  {
    value: PRO_PLAN,
    label: 'Pro',
  },
];
