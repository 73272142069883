import { QuoteSettings } from 'src/types/invoice';
import { Quote } from 'src/types/quote';
import {
  IAddQuote,
  IDeleteQuote,
  IGetArchiveQuotes,
  IGetContactQuotes,
  IGetOpenQuotes,
  IGetPoolQuotes,
  IGetQuote,
  IPreviewEmail,
  ISendQuote,
  IUpdateQuote,
  IUpdateQuoteSettings,
  IUpdateQuoteStatus,
} from './model/quote.model';
import { rootSplitApi } from './root';

export const quoteApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getQuote: builder.query<any, IGetQuote>({
      query: (param: IGetQuote) => `v2/organisation/${param.organisationId}/quote/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'Quote', id: param.id }],
    }),
    addQuote: builder.mutation<Quote, IAddQuote>({
      query: (param: IAddQuote) => ({
        url: `v2/organisation/${param.organisationId}/quote`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'Quote', id: 'LIST' }],
    }),
    updateQuote: builder.mutation<Quote, IUpdateQuote>({
      query: (param: IUpdateQuote) => ({
        url: `v2/organisation/${param.organisationId}/quote/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          quoteApi.util.updateQueryData(
            'getQuote',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Quote', id: param.id },
        { type: 'Quote', id: 'LIST' },
      ],
    }),
    updateStatus: builder.mutation<Quote, IUpdateQuoteStatus>({
      query: (param: IUpdateQuoteStatus) => ({
        url: `v2/organisation/${param.organisationId}/quote/${param.id}/change-status`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          quoteApi.util.updateQueryData(
            'getQuote',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Quote', id: param.id },
        { type: 'Quote', id: 'LIST' },
      ],
    }),
    getOpenQuotes: builder.query<any, IGetOpenQuotes>({
      query: (param: IGetOpenQuotes) => {
        const { organisationId, filter, tagsFilter, page, sortBy, limit, quoteStatusId, from, to } =
          param;
        let url = `v2/organisation/${organisationId}/quote?limit=${limit}&order=${sortBy}&page=${
          page + 1
        }&include=pool`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (quoteStatusId) {
          url += `&job_status_id=${quoteStatusId}`;
        }

        if (tagsFilter && tagsFilter !== '') {
          url += `&tags=${tagsFilter}`;
        }

        if (from && from !== 'Invalid date') {
          url += `&from=${from}`;
        }

        if (to && to !== 'Invalid date') {
          url += `&to=${to}`;
        }

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Quote' as const, id })),
              { type: 'Quote', id: 'LIST' },
            ]
          : [{ type: 'Quote', id: 'LIST' }],
    }),
    getArchiveQuotes: builder.query<any, IGetArchiveQuotes>({
      query: (param: IGetArchiveQuotes) => {
        const { organisationId, filter, page, sortBy, limit, from, to } = param;
        let url = `v2/organisation/${organisationId}/archived-quote?limit=${limit}&order=${sortBy}&page=${
          page + 1
        }&include=pool`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (from && from !== 'Invalid date') {
          url += `&from=${from}`;
        }

        if (to && to !== 'Invalid date') {
          url += `&to=${to}`;
        }

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'ArchivedQuotes' as const, id })),
              { type: 'ArchivedQuotes', id: 'LIST' },
            ]
          : [{ type: 'ArchivedQuotes', id: 'LIST' }],
    }),
    getContactQuotes: builder.query<any, IGetContactQuotes>({
      query: (param: IGetContactQuotes) => {
        const { organisationId, contactId, filter, from, to, page, sortBy, limit } = param;
        let url = `v2/organisation/${organisationId}/quote?contact_id=${contactId}&limit=${limit}&order=${sortBy}&page=${
          page + 1
        }&include=pool`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (from && from !== 'Invalid date') {
          url += `&from=${from}`;
        }

        if (to && to !== 'Invalid date') {
          url += `&to=${to}`;
        }

        return url;
      },
      providesTags: (result, error, param) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'ContactQuotes' as const, id })),
              { type: 'ContactQuotes', id: param.contactId },
            ]
          : [{ type: 'ContactQuotes', id: param.contactId }],
    }),
    getPoolQuotes: builder.query<any, IGetPoolQuotes>({
      query: (param: IGetPoolQuotes) => {
        const { organisationId, poolId, filter, from, to, page, sortBy, limit } = param;
        let url = `v2/organisation/${organisationId}/quote?pool_id=${poolId}&limit=${limit}&order=${sortBy}&page=${
          page + 1
        }&include=contact`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        if (from && from !== 'Invalid date') {
          url += `&from=${from}`;
        }

        if (to && to !== 'Invalid date') {
          url += `&to=${to}`;
        }

        return url;
      },
      providesTags: (result, error, param) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'PoolQuotes' as const, id })),
              { type: 'PoolQuotes', id: param.poolId },
            ]
          : [{ type: 'PoolQuotes', id: param.poolId }],
    }),
    getQuoteSettings: builder.query<QuoteSettings, number>({
      query: (id) => `v1/organisations/${id}/quote_settings`,
      providesTags: (result, error, id) => [
        { type: 'QuoteSettings', id: 'organisation_quote_settings' },
      ],
    }),
    updateQuoteSettings: builder.mutation<QuoteSettings, IUpdateQuoteSettings>({
      query: (param: IUpdateQuoteSettings) => ({
        url: `v1/organisations/${param.organisationId}/quote_settings/${param.id}`,
        method: 'PUT',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [{ type: 'QuoteSettings', id: param.id }],
    }),
    deleteQuote: builder.mutation<any, IDeleteQuote>({
      query: (param: IDeleteQuote) => ({
        url: `v1/organisations/${param.organisationId}/jobs/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Quote', id: param.id },
        { type: 'Quote', id: 'LIST' },
        { type: 'ContactQuotes', id: param.id },
        { type: 'PoolQuotes', id: param.id },
      ],
    }),
    sendQuote: builder.mutation<Quote, ISendQuote>({
      query: (param: ISendQuote) => ({
        url: `v2/organisation/${param.organisationId}/quote/${param.id}/send`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Quote', id: param.id },
        { type: 'Quote', id: 'LIST' },
      ],
    }),
    previewEmail: builder.mutation<any, IPreviewEmail>({
      query: (param: IPreviewEmail) => ({
        url: `v2/organisation/${param.organisationId}/quote/${param.id}/preview-email`,
        method: 'post',
        body: param.body,
      }),
    }),
  }),
});

export const {
  useGetQuoteQuery,
  useGetOpenQuotesQuery,
  useGetArchiveQuotesQuery,
  useGetContactQuotesQuery,
  useGetPoolQuotesQuery,
  useGetQuoteSettingsQuery,
  useDeleteQuoteMutation,
  useAddQuoteMutation,
  useUpdateQuoteMutation,
  useSendQuoteMutation,
  useUpdateStatusMutation,
  usePreviewEmailMutation,
  useUpdateQuoteSettingsMutation,
} = quoteApi;

export const {
  endpoints: {
    getArchiveQuotes,
    getOpenQuotes,
    getQuoteSettings,
    getQuote,
    addQuote,
    updateQuote,
    deleteQuote,
    sendQuote,
    updateStatus,
    previewEmail,
    updateQuoteSettings,
  },
} = quoteApi;
