import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { ALL_CAT_FILTER, Brand, Category, Metric, Product } from '../types/product';
import { productApi } from 'src/api/product';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { GridFilterModel } from '@mui/x-data-grid-pro';

interface ProductState {
  searchText?: string;
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
  categoryFilter?: string | number;
  expandedProductId?: Product['id'] | null;

  products: Product[];
  categories: Category[];
  metrics: Metric[];
  brands: Brand[];
  filterModel?: GridFilterModel;
}

const initialState: ProductState = {
  searchText: '',
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'name',
  order: 'asc',
  categoryFilter: ALL_CAT_FILTER,
  expandedProductId: null,

  products: [],
  categories: [],
  metrics: [],
  brands: [],
  filterModel: {
    items: [],
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setLimit(state: ProductState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
    },
    setPage(state: ProductState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setCategory(state: ProductState, action: PayloadAction<{ category: string | number }>) {
      const { category } = action.payload;

      state.categoryFilter = category;
    },
    setSearchText(state: ProductState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setOrder(
      state: ProductState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    setFilterModel(state: ProductState, action: PayloadAction<{ filterModel: GridFilterModel }>) {
      const { filterModel } = action.payload;
      state.filterModel = filterModel;
    },
    setExpandedProductId(
      state: ProductState,
      action: PayloadAction<{ productId: ProductState['expandedProductId'] }>
    ) {
      const { productId } = action.payload;

      state.expandedProductId = productId === state.expandedProductId ? null : productId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(productApi.endpoints.getBrands.matchFulfilled, (state, action) => {
        state.brands = action.payload;
      })
      .addMatcher(productApi.endpoints.getCategories.matchFulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addMatcher(productApi.endpoints.getMetrics.matchFulfilled, (state, action) => {
        state.metrics = action.payload;
      })
      .addMatcher(productApi.endpoints.getProducts.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(productApi.endpoints.getProducts.matchFulfilled, (state, action) => {
        const { data, meta } = action.payload;

        state.products = data;
        state.total = meta.total;
      })
      .addMatcher(productApi.endpoints.getProducts.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const { reducer } = slice;
export const { setFilterModel, setExpandedProductId } = slice.actions;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setCategory = (category: string | number) => (dispatch) => {
  dispatch(slice.actions.setCategory({ category }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export default slice;
