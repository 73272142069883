import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { startOfDay, endOfDay, startOfMonth, endOfMonth } from 'date-fns';
import { DateRange } from '@mui/x-date-pickers-pro';
import { SortDirection } from '@mui/material';
import { JobEvent } from 'src/types/job';

interface CalendarState {
  orderBy: string;
  order: SortDirection;
  monthJobEvents: JobEvent[];
  selectedMonthRange: DateRange<Date>;
  techniciansDrawerOpen: boolean;
}

const initialState: CalendarState = {
  orderBy: 'start_time',
  order: 'asc',
  monthJobEvents: [],
  selectedMonthRange: [startOfDay(startOfMonth(new Date())), endOfDay(endOfMonth(new Date()))],
  techniciansDrawerOpen: true,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    selectMonthlyRange(
      state: CalendarState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedMonthRange = dateRange;
    },
    setMonthViewEvents(state: CalendarState, action: PayloadAction<{ events: JobEvent[] }>): void {
      const { events } = action.payload;
      state.monthJobEvents = events;
    },
    toggleTechniciansDrawer(state: CalendarState): void {
      state.techniciansDrawerOpen = !state.techniciansDrawerOpen;
    },
  },
});

export const { reducer } = slice;

export const { setMonthViewEvents } = slice.actions;

export const selectMonthlyRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectMonthlyRange({ dateRange }));
  };

export const toggleTechniciansDrawer =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.toggleTechniciansDrawer());
  };

export default slice;
