import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Column, ColumnMatch, ParsedRow } from 'src/types/fileImporter';

interface FileImporterState {
  expectedColumns: Column[];

  parsedRows: ParsedRow[];
  fileName: string | null;

  unmatchedColumnsCountOnTheLeftOfViewPort: number;
  unmatchedColumnsCountOnTheRightOfViewPort: number;

  leftFirstUnmatchedColumnIndex: number | null;
  rightFirstUnmatchedColumnIndex: number | null;

  matchedColumns: ColumnMatch;
  // column, that currently in change mode
  changingColumnHeader: string | null;
  ignoredColumnHeaders: string[];

  issuedDuplicationColumn: Column | null;
  issuedDuplicationHeaderA: string | null;
  issuedDuplicationHeaderB: string | null;
  selectedHeaderToResolve: string | null;
  issuedDuplicationRowsA: string[];
  issuedDuplicationRowsB: string[];
  error: string | null;
}

const initialState: FileImporterState = {
  expectedColumns: [],

  parsedRows: [],
  fileName: null,

  unmatchedColumnsCountOnTheLeftOfViewPort: 0,
  unmatchedColumnsCountOnTheRightOfViewPort: 0,

  leftFirstUnmatchedColumnIndex: null,
  rightFirstUnmatchedColumnIndex: null,

  matchedColumns: {},
  changingColumnHeader: null,
  ignoredColumnHeaders: [],

  issuedDuplicationColumn: null,
  issuedDuplicationHeaderA: null,
  issuedDuplicationHeaderB: null,
  selectedHeaderToResolve: null,
  issuedDuplicationRowsA: [],
  issuedDuplicationRowsB: [],
  error: null,
};

const slice = createSlice({
  name: 'fileImporter',
  initialState,
  reducers: {
    setExpectedColumns(
      state: FileImporterState,
      action: PayloadAction<{ expectedColumns: Column[] }>
    ) {
      state.expectedColumns = action.payload.expectedColumns;
    },
    parseFile(
      state: FileImporterState,
      action: PayloadAction<{ parsedRows: ParsedRow[]; fileName: string }>
    ): void {
      const { parsedRows, fileName } = action.payload;
      state.parsedRows = parsedRows;
      state.fileName = fileName;

      // find matched columns
      const matchedColumns: ColumnMatch = {};
      const headerRow = Object.keys(parsedRows[0]);
      headerRow.forEach((header, index) => {
        const valLowerCase = header.toLowerCase().replace(/"/g, '') || `_EMPTY_${index + 1}`;
        matchedColumns[header] =
          state.expectedColumns.find(
            (col) => col.matchHeaderName.toLocaleLowerCase() === valLowerCase
          ) || null;
      });

      state.matchedColumns = matchedColumns;
    },
    setColumnsInfoAroundViewPort(
      state: FileImporterState,
      action: PayloadAction<{
        unmatchedColumnsCountOnTheLeftOfViewPort: number;
        unmatchedColumnsCountOnTheRightOfViewPort: number;
        leftFirstUnmatchedColumnIndex: number | null;
        rightFirstUnmatchedColumnIndex: number | null;
      }>
    ) {
      const {
        unmatchedColumnsCountOnTheLeftOfViewPort,
        unmatchedColumnsCountOnTheRightOfViewPort,
        leftFirstUnmatchedColumnIndex,
        rightFirstUnmatchedColumnIndex,
      } = action.payload;
      state.unmatchedColumnsCountOnTheLeftOfViewPort = unmatchedColumnsCountOnTheLeftOfViewPort;
      state.unmatchedColumnsCountOnTheRightOfViewPort = unmatchedColumnsCountOnTheRightOfViewPort;
      state.leftFirstUnmatchedColumnIndex = leftFirstUnmatchedColumnIndex;
      state.rightFirstUnmatchedColumnIndex = rightFirstUnmatchedColumnIndex;
    },
    setColumnHeaderToChange(
      state: FileImporterState,
      action: PayloadAction<{ fileColumnHeader: string }>
    ) {
      state.changingColumnHeader = action.payload.fileColumnHeader;
    },
    setDuplicationIssue(
      state: FileImporterState,
      action: PayloadAction<{
        duplicationColumn: Column;
        duplicationHeaderA: string;
        duplicationHeaderB: string;
        rowsA: string[];
        rowsB: string[];
      }>
    ) {
      const { duplicationColumn, duplicationHeaderA, duplicationHeaderB, rowsA, rowsB } =
        action.payload;
      state.issuedDuplicationColumn = duplicationColumn;
      state.issuedDuplicationHeaderA = duplicationHeaderA;
      state.issuedDuplicationHeaderB = duplicationHeaderB;
      state.issuedDuplicationRowsA = rowsA;
      state.issuedDuplicationRowsB = rowsB;

      state.selectedHeaderToResolve = duplicationHeaderA;
    },
    setSelectedHeaderToResolve(
      state: FileImporterState,
      action: PayloadAction<{ headerName: string }>
    ) {
      state.selectedHeaderToResolve = action.payload.headerName;
    },
    resolveDuplicationIssue(state: FileImporterState) {
      const selectedHeaderName = state.selectedHeaderToResolve;
      const notSelectedHeaderName =
        state.issuedDuplicationHeaderA === selectedHeaderName
          ? state.issuedDuplicationHeaderB
          : state.issuedDuplicationHeaderA;

      state.matchedColumns = {
        ...state.matchedColumns,
        [selectedHeaderName]: state.issuedDuplicationColumn,
        [notSelectedHeaderName]: null,
      };

      state.issuedDuplicationHeaderA = null;
      state.issuedDuplicationHeaderB = null;
      state.issuedDuplicationColumn = null;
      state.issuedDuplicationRowsA = [];
      state.issuedDuplicationRowsB = [];
    },
    cancelDuplicationIssue(state: FileImporterState) {
      state.issuedDuplicationHeaderA = null;
      state.issuedDuplicationHeaderB = null;
      state.issuedDuplicationColumn = null;
      state.issuedDuplicationRowsA = [];
      state.issuedDuplicationRowsB = [];
    },
    setColumnMatch(
      state: FileImporterState,
      action: PayloadAction<{
        fileColumnHeader: string;
        column: Column;
      }>
    ) {
      const { fileColumnHeader, column } = action.payload;
      state.matchedColumns = {
        ...state.matchedColumns,
        [fileColumnHeader]: column,
      };
    },
    toggleIgnore(state: FileImporterState, action: PayloadAction<{ fileColumnHeader }>) {
      const { fileColumnHeader } = action.payload;
      if (state.ignoredColumnHeaders.includes(fileColumnHeader)) {
        state.ignoredColumnHeaders = state.ignoredColumnHeaders.filter(
          (col) => col !== action.payload.fileColumnHeader
        );
      } else {
        state.ignoredColumnHeaders = [
          ...state.ignoredColumnHeaders,
          action.payload.fileColumnHeader,
        ];
      }
    },
    setError(state: FileImporterState, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
    },
    reset(state: FileImporterState) {
      return {
        ...initialState,
        expectedColumns: state.expectedColumns,
      };
    },
  },
});

export const { reducer } = slice;

export const tryToSetColumnMatch =
  (
    column: Column,
    fileColumnHeader: string,
    parsedRows: ParsedRow[],
    matchedColumns: ColumnMatch
  ) =>
  (dispatch) => {
    const duplicatedHeader = Object.keys(matchedColumns).find(
      (header) => matchedColumns[header]?.id === column.id
    );

    // there is duplicated header - set duplication issue
    if (duplicatedHeader != null) {
      dispatch(
        slice.actions.setDuplicationIssue({
          duplicationColumn: column,
          duplicationHeaderA: fileColumnHeader,
          duplicationHeaderB: duplicatedHeader,
          rowsA: parsedRows.map((row) => row[fileColumnHeader]),
          rowsB: parsedRows.map((row) => row[duplicatedHeader]),
        })
      );
    } else {
      dispatch(slice.actions.setColumnMatch({ fileColumnHeader, column }));
    }
  };

export const {
  setExpectedColumns,
  parseFile,
  setColumnsInfoAroundViewPort,
  setColumnHeaderToChange,
  toggleIgnore,
  resolveDuplicationIssue,
  setSelectedHeaderToResolve,
  cancelDuplicationIssue,
  reset,
  setError,
} = slice.actions;

export default slice;
