import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import { parseBoolByStr } from 'src/utils/bool';
import type { BatchInvoice } from 'src/types/contact-invoice';
import type { Contact } from '../types/contact';

interface ResendBatchInvoiceState {
  isLoading: boolean;
  isSending: boolean;
  isSent: boolean;

  batchInvoice?: BatchInvoice;
  contact?: Contact;
  subjectMessage: string;
  introMessage: string;
  ccEmails: string[];

  isIndividualInvoicesAttached: boolean;
  isJobSheetsAttached: boolean;
}

const initialState: ResendBatchInvoiceState = {
  isLoading: true,
  isSending: false,
  isSent: false,

  batchInvoice: null,
  contact: null,
  subjectMessage: '',
  introMessage: '',
  ccEmails: [],

  isIndividualInvoicesAttached: false,
  isJobSheetsAttached: false,
};

const slice = createSlice({
  name: 'resendBatchInvoice',
  initialState,
  reducers: {
    reset: () => initialState,
    load(state: ResendBatchInvoiceState) {
      state.isLoading = true;
    },
    initialize(
      state: ResendBatchInvoiceState,
      action: PayloadAction<{ batchInvoice: BatchInvoice }>
    ) {
      const { batchInvoice } = action.payload;

      state.batchInvoice = batchInvoice;
      state.contact = batchInvoice.contact;
      state.ccEmails = initialState.ccEmails;

      state.subjectMessage = batchInvoice.subject_message;
      state.introMessage = batchInvoice.intro_message;
      state.isIndividualInvoicesAttached = parseBoolByStr(
        batchInvoice.is_individual_invoices_attached
      );
      state.isJobSheetsAttached = parseBoolByStr(batchInvoice.is_job_sheets_attached);

      state.isLoading = false;
    },
    startSending(state: ResendBatchInvoiceState) {
      state.isSending = true;
    },
    sent(state: ResendBatchInvoiceState) {
      state.isSent = true;
      state.isSending = false;
    },
    setSubjectMessage(state: ResendBatchInvoiceState, action: PayloadAction<{ message: string }>) {
      state.subjectMessage = action.payload.message;
    },
    setIntroMessage(state: ResendBatchInvoiceState, action: PayloadAction<{ message: string }>) {
      state.introMessage = action.payload.message;
    },
    setCCEmails(state: ResendBatchInvoiceState, action: PayloadAction<{ emails: string[] }>) {
      state.ccEmails = action.payload.emails;
    },

    setIsIndividualInvoicesAttached(
      state: ResendBatchInvoiceState,
      action: PayloadAction<{ value: boolean }>
    ) {
      state.isIndividualInvoicesAttached = action.payload.value;
    },
    setIsJobSheetsAttached(
      state: ResendBatchInvoiceState,
      action: PayloadAction<{ value: boolean }>
    ) {
      state.isJobSheetsAttached = action.payload.value;
    },
  },
});

export default slice;

export const { reducer } = slice;
export const {
  reset,
  setSubjectMessage,
  setIntroMessage,
  setCCEmails,
  setIsIndividualInvoicesAttached,
  setIsJobSheetsAttached,
} = slice.actions;

export const initialize =
  (organisationId: number, invoiceId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.load());
    const response = await axios.get(
      `v2/organisations/${organisationId}/batch-invoice/${invoiceId}`
    );
    dispatch(slice.actions.initialize({ batchInvoice: response.data }));
  };

export const resend =
  (organisationId: number, state: ResendBatchInvoiceState): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startSending());
    const emails = Array.from(new Set([...state.ccEmails, state.batchInvoice.contact.email]));

    const payload = {
      recipients: emails.map((email) => ({ email })),
      description: state.introMessage,
      subject_message: state.subjectMessage,
      is_individual_invoices_attached: state.isIndividualInvoicesAttached,
      is_job_sheets_attached: state.isJobSheetsAttached,
    };

    const response = await axios.post(
      `v2/organisations/${organisationId}/batch-invoice/${state.batchInvoice.id}/resend`,
      payload
    );
    if (response.status === 200) {
      await dispatch(slice.actions.sent());
    }
  };
