import {
  IAddLabJob,
  IGetLabJob,
  ISendWaterTestEmail,
  ISendWaterTestSms,
} from './model/labjob.model';
import { rootSplitApi } from './root';

export const labJobApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getLabJob: builder.query<any, IGetLabJob>({
      query: (param: IGetLabJob) =>
        `v2/organisations/${param.organisationId}/water-test/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'LabJob', id: param.id }],
    }),
    addLabJob: builder.mutation<any, IAddLabJob>({
      query: (param: IAddLabJob) => ({
        url: `v2/organisations/${param.organisationId}/water-test`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'LabJobs', id: 'LIST' }],
    }),
    sendEmail: builder.mutation<any, ISendWaterTestEmail>({
      query: (param: ISendWaterTestEmail) => ({
        url: `v1/organisations/${param.organisationId}/labjobs/${param.id}/sendemail`,
        method: 'post',
        body: param.body,
      }),
    }),
    sendSms: builder.mutation<any, ISendWaterTestSms>({
      query: (param: ISendWaterTestSms) => ({
        url: `v1/organisations/${param.organisationId}/labjobs/${param.id}/send-sms`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [{ type: 'SmsList', id: 'LIST' }],
    }),
  }),
});
export const { useGetLabJobQuery, useAddLabJobMutation, useSendEmailMutation, useSendSmsMutation } =
  labJobApi;
