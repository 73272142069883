import startOfDay from 'date-fns/startOfDay';
import subMinutes from 'date-fns/subMinutes';
import parse from 'date-fns/parse';
import endOfDay from 'date-fns/endOfDay';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isSameDay from 'date-fns/isSameDay';
import addMinutes from 'date-fns/addMinutes';
import ceil from 'lodash/ceil';
import type { Product } from 'src/types/product';
import type { Invoice, Job, JobEvent, JobLabel } from 'src/types/job';
import { INVOICE_SENT, JOB_COMPLETE, JOB_DONE, JOB_SHEET_SENT, JOB_WIP } from 'src/constants/job';
import { User } from 'src/types/user';
import { IRescheduleJob } from 'src/api/model/job.model';
import { getWorkingHoursByDate } from './user';
import { isEmptyDate } from './date';
import { TFunction } from 'i18next';

export const transformProductToInvoice = (product: Product): Invoice => ({
  name: product.name,
  cost: product.cost,
  gst_cost: product.gst_cost,
  volume: product.volume,
  sku: product.sku,
  quantity: 1,
  product_id: product.id,
  product_metric_id: product.product_metric_id,
  is_gst_price: product.is_gst_price,
  product,
});

export const getJobStatusLabel = (t: TFunction, statusId: number): JobLabel => {
  let jobLabel: JobLabel = {
    color: 'primary',
    text: t('Open'),
  };

  switch (statusId) {
    case JOB_COMPLETE:
    case JOB_DONE:
      jobLabel = {
        color: 'info',
        text: t('Complete'),
      };
      break;
    case INVOICE_SENT:
      jobLabel = {
        color: 'success',
        text: t('Invoice sent'),
      };
      break;
    case JOB_WIP:
      jobLabel = {
        color: 'info',
        text: t('WIP'),
      };
      break;
    case JOB_SHEET_SENT:
      jobLabel = {
        color: 'warning',
        text: t('Job sheet sent'),
      };
      break;

    default:
      break;
  }

  return jobLabel;
};

const clampEndDateToSameDay = (start: Date, end: Date): [start: Date, end: Date] => {
  if (!isSameDay(start, end)) {
    return [start, endOfDay(start)];
  }

  return [start, end];
};

export const clampStartDateToSameDay = (start: Date, end: Date): [start: Date, end: Date] => {
  if (!isSameDay(start, end)) {
    return [startOfDay(end), end];
  }

  return [start, end];
};

export const insertJobAtIndex = (
  destinationEvents: JobEvent[],
  jobEvent: JobEvent,
  destinationIndex: number,
  destinationTechnician: User,
  destinationDay: Date
): Pick<JobEvent, 'id' | 'user_id' | 'start_time' | 'end_time'>[] => {
  let result: Pick<JobEvent, 'id' | 'user_id' | 'start_time' | 'end_time'>;
  let durationMinutes = 60;

  if (!isEmptyDate(jobEvent.start_time) && !isEmptyDate(jobEvent.end_time)) {
    durationMinutes = differenceInMinutes(jobEvent.end_time, jobEvent.start_time);
  }

  if (destinationEvents.length === 0) {
    // destination of events is empty - use working hours to define the start time
    const [startTime] = getWorkingHoursByDate(destinationTechnician.working_hours, destinationDay);
    let start = parse(startTime, 'HH:mm:ss', destinationDay);
    let end = addMinutes(start, durationMinutes);
    [start, end] = clampEndDateToSameDay(start, end);
    result = {
      id: jobEvent.id,
      user_id: destinationTechnician.id,
      start_time: start,
      end_time: end,
    };
  } else if (destinationIndex === 0) {
    // going to be inserted into the first position - calculate start time based on the end time of the first item
    let end = destinationEvents[0].start_time;
    let start = subMinutes(end, durationMinutes);
    [start, end] = clampStartDateToSameDay(start, end);

    result = {
      id: jobEvent.id,
      user_id: destinationTechnician.id,
      start_time: start,
      end_time: end,
    };
  } else {
    // use end time of the previous item to define start time
    let start = destinationEvents[destinationIndex - 1].end_time;
    let end = addMinutes(start, durationMinutes);
    [start, end] = clampEndDateToSameDay(start, end);
    result = {
      id: jobEvent.id,
      user_id: destinationTechnician.id,
      start_time: start,
      end_time: end,
    };
  }
  return [result];
};

export const reorderJobEvent = (
  jobEvents: JobEvent[],
  newIndex: number,
  oldIndex: number
): Pick<JobEvent, 'id' | 'user_id' | 'start_time' | 'end_time'>[] => {
  // start_time and ent_time remains in the same order
  const orderedFields = jobEvents.map((jobEvent) => ({
    user_id: jobEvent.user_id,
    start_time: jobEvent.start_time,
    end_time: jobEvent.end_time,
  }));
  const ids = jobEvents.map(({ id }) => id);
  const [removed] = ids.splice(oldIndex, 1);
  ids.splice(newIndex, 0, removed);
  return ids.map((id, index) => ({
    id,
    ...orderedFields[index],
  }));
};

export const updateJobEventsByRescheduleJobApi = (
  jobEvents: JobEvent[],
  params: IRescheduleJob[]
): JobEvent[] => {
  const mapJobIDToBody = {};
  params.forEach((param) => {
    mapJobIDToBody[param.id] = param.body;
  });
  const result = jobEvents.map((jobEvent) => {
    const body = mapJobIDToBody[jobEvent.id];
    if (body == null) {
      return jobEvent;
    }

    return {
      ...jobEvent,
      ...body,
    };
  });

  return result;
};

export const getTotalHoursOfJobEvents = (events: JobEvent[]) => {
  const result = events
    .map((event) => differenceInMinutes(event.end_time, event.start_time) / 60)
    .reduce((prev, current) => prev + current, 0);

  return ceil(result, 2);
};

export const getParentJobId = (job: Job) => {
  return job.parent?.id || job.id;
};

export const getParentJobTemplateId = (job: Job) => {
  return job.parent?.job_template_id || job.job_template_id;
};
