import { createSlice } from '@reduxjs/toolkit';
import type {
  PoolType,
  CustomException,
  PoolSanitiser,
  Location,
  GroundLevel,
  Classification,
  SanitiserClassification,
  PoolSurfaceType,
} from '../types/pool';
import { publicApi } from 'src/api/public';
import { customFieldsApi } from 'src/api/custom-fields';

interface PoolSpecificationsState {
  poolSanitisers: PoolSanitiser[];
  customExceptions: CustomException[];
  poolTypes: PoolType[];
  locations: Location[];
  groundLevels: GroundLevel[];
  classifications: Classification[];
  sanitiserClassifications: SanitiserClassification[];
  surfaceTypes: PoolSurfaceType[];
}

const initialState: PoolSpecificationsState = {
  poolSanitisers: [],
  customExceptions: [],
  poolTypes: [],
  locations: [],
  groundLevels: [],
  classifications: [],
  sanitiserClassifications: [],
  surfaceTypes: [],
};

const slice = createSlice({
  name: 'poolSpecifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(publicApi.endpoints.getPoolParams.matchFulfilled, (state, action) => {
        const { classifications, groundLevels, locations, poolTypes } = action.payload;
        state.poolTypes = poolTypes;
        state.classifications = classifications;
        state.groundLevels = groundLevels;
        state.locations = locations;
      })
      .addMatcher(customFieldsApi.endpoints.getSanitisers.matchFulfilled, (state, action) => {
        state.poolSanitisers = action.payload;
      })
      .addMatcher(customFieldsApi.endpoints.getCustomExceptions.matchFulfilled, (state, action) => {
        state.customExceptions = action.payload;
      })
      .addMatcher(customFieldsApi.endpoints.getSurfaceTypes.matchFulfilled, (state, action) => {
        state.surfaceTypes = action.payload;
      })
      .addMatcher(
        publicApi.endpoints.getSanitiserClassifications.matchFulfilled,
        (state, action) => {
          state.sanitiserClassifications = action.payload;
        }
      );
  },
});

export const { reducer } = slice;

export default slice;
