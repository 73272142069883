import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import merge from 'lodash/merge';
import { format, startOfDay, endOfDay, subMonths } from 'date-fns';
import type { AppThunk } from '../store';
import type { Pool } from '../types/pool';
import type { Contact } from '../types/contact';
import { OWNER } from '../constants/poolContact';
import type { Invoice } from '../types/contact-invoice';
import type { Meta } from '../types/pagination';
import type { SortDirection } from '@mui/material';
import { Quote } from 'src/types/quote';
import { Job } from 'src/types/job';
import { contactApi } from 'src/api/contact';
import { DateRange } from '@mui/x-date-pickers-pro';
import find from 'lodash/find';

interface ContactDetailState {
  contactId: number | null;
  contact: Contact | null;
  pools: Pool[];
  isInvoicesLoading: boolean;
  invoices: Invoice[];
  selectedInvoicesRange: DateRange<Date>;
  invoicesLimit?: number;
  invoicesPage?: number;
  invoicesTotal?: number;
  invoicesOrderBy: string;
  invoicesOrder: SortDirection;
  quotes: Quote[];
  searchQuotesText: string;
  selectedQuotesRange: DateRange<Date>;
  quotesLimit?: number;
  quotesPage?: number;
  quotesTotal?: number;
  quotesOrderBy: string;
  quotesOrder: SortDirection;
  jobs: Job[];
  searchJobsText: string;
  selectedJobsRange: DateRange<Date>;
  jobsLimit?: number;
  jobsPage?: number;
  jobsTotal?: number;
  jobsOrderBy: string;
  jobsOrder: SortDirection;
  parentContact: Contact['parent_contact'];
  parentId: Contact['parent_id'];
  parentRelationId: Contact['parent_relation_id'];
  childContacts: Contact['child_contacts'];
}

const initialState: ContactDetailState = {
  contactId: null,
  contact: null,
  pools: [],
  isInvoicesLoading: false,
  invoices: [],
  selectedInvoicesRange: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
  invoicesLimit: 10,
  invoicesPage: 0,
  invoicesTotal: 0,
  invoicesOrderBy: 'created_at',
  invoicesOrder: 'desc',
  quotes: [],
  searchQuotesText: '',
  selectedQuotesRange: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
  quotesLimit: 10,
  quotesPage: 0,
  quotesTotal: 0,
  quotesOrderBy: 'created_at',
  quotesOrder: 'desc',
  jobs: [],
  searchJobsText: '',
  selectedJobsRange: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
  jobsLimit: 10,
  jobsPage: 0,
  jobsTotal: 0,
  jobsOrderBy: 'created_at',
  jobsOrder: 'desc',
  parentContact: null,
  parentId: null,
  parentRelationId: null,
  childContacts: [],
};

const slice = createSlice({
  name: 'contactDetail',
  initialState,
  reducers: {
    reset: () => initialState,
    addPool(state: ContactDetailState, action: PayloadAction<{ pool: Pool }>): void {
      const { pool } = action.payload;
      const exists = state.pools.find((_pool) => _pool.id === pool.id);

      if (!exists) {
        state.pools.push(
          merge({}, pool, {
            pivot: {
              address_type_id: OWNER,
              pool_id: pool.id,
            },
          })
        );
      }
    },
    updatePool(state: ContactDetailState, action: PayloadAction<{ pool: Pool }>): void {
      const { pool } = action.payload;

      state.pools = state.pools.map((_pool) => {
        if (_pool.id === pool.id) {
          return merge({}, pool, { pivot: _pool.pivot });
        }

        return _pool;
      });
    },
    updatePoolRelation(
      state: ContactDetailState,
      action: PayloadAction<{ poolId: number; relationId: number }>
    ): void {
      const { poolId, relationId } = action.payload;

      state.pools = state.pools.map((_pool) => {
        if (_pool.id === poolId) {
          _pool.pivot.address_type_id = relationId;
          return _pool;
        }

        return _pool;
      });
    },
    unlinkPool(state: ContactDetailState, action: PayloadAction<{ poolId: number }>): void {
      const { poolId } = action.payload;

      state.pools = state.pools.filter((pool) => pool.id !== poolId);
    },
    getInvoices(
      state: ContactDetailState,
      action: PayloadAction<{ invoices: Invoice[]; meta: Meta }>
    ) {
      const { invoices, meta } = action.payload;

      state.invoices = invoices;
      state.invoicesTotal = meta.total;
      state.isInvoicesLoading = false;
    },
    setInvoicesLimit(state: ContactDetailState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.invoicesLimit = limit;
      state.invoicesPage = 0;
      state.invoicesTotal = 0;
    },
    setInvoicesPage(state: ContactDetailState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.invoicesPage = page;
    },
    setIsInvoicesLoading(state: ContactDetailState, action: PayloadAction<{ isLoading: boolean }>) {
      const { isLoading } = action.payload;

      state.isInvoicesLoading = isLoading;
    },
    setInvoicesOrder(
      state: ContactDetailState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.invoicesOrderBy = orderBy;
      state.invoicesOrder = order;
    },
    selectInvoicesRange(
      state: ContactDetailState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedInvoicesRange = dateRange;
    },
    resetInvoicesDateRange(state: ContactDetailState): void {
      state.selectedInvoicesRange = initialState.selectedInvoicesRange;
    },
    setQuotesSearchText(state: ContactDetailState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchQuotesText = searchText;
      state.quotesPage = 0;
    },
    setQuotesLimit(state: ContactDetailState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.quotesLimit = limit;
      state.quotesPage = 0;
      state.quotesTotal = 0;
    },
    setQuotesPage(state: ContactDetailState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.quotesPage = page;
    },
    setQuotesOrder(
      state: ContactDetailState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.quotesOrderBy = orderBy;
      state.quotesOrder = order;
    },
    selectQuotesRange(
      state: ContactDetailState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedQuotesRange = dateRange;
    },
    resetQuotesDateRange(state: ContactDetailState): void {
      state.selectedQuotesRange = initialState.selectedQuotesRange;
    },
    setQuotesMeta(state: ContactDetailState, action: PayloadAction<{ meta: Meta }>) {
      const { meta } = action.payload;

      state.quotesTotal = meta.total;
    },
    setJobsSearchText(state: ContactDetailState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchJobsText = searchText;
      state.jobsPage = 0;
    },
    setJobsLimit(state: ContactDetailState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.jobsLimit = limit;
      state.jobsPage = 0;
      state.jobsTotal = 0;
    },
    setJobsPage(state: ContactDetailState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.jobsPage = page;
    },
    setJobsOrder(
      state: ContactDetailState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.jobsOrderBy = orderBy;
      state.jobsOrder = order;
    },
    selectJobsRange(
      state: ContactDetailState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedJobsRange = dateRange;
    },
    resetJobsDateRange(state: ContactDetailState): void {
      state.selectedJobsRange = initialState.selectedJobsRange;
    },
    setJobsMeta(state: ContactDetailState, action: PayloadAction<{ meta: Meta }>) {
      const { meta } = action.payload;

      state.jobsTotal = meta.total;
    },
    unlinkParentContact(state: ContactDetailState) {
      state.parentContact = null;
      state.parentId = null;
      state.parentRelationId = null;
    },
    setParentContact(state: ContactDetailState, action: PayloadAction<{ contact: Contact }>) {
      const { contact } = action.payload;

      state.parentContact = contact;
      state.parentId = contact.id;
      state.childContacts = [];
    },
    setParentRelationId(
      state: ContactDetailState,
      action: PayloadAction<{ parentRelationId: Contact['parent_relation_id'] }>
    ) {
      const { parentRelationId } = action.payload;

      state.parentRelationId = parentRelationId;
    },
    unlinkChildContact(
      state: ContactDetailState,
      action: PayloadAction<{ childContactId: Contact['id'] }>
    ) {
      state.childContacts = state.childContacts.filter(
        (contact) => contact.id !== action.payload.childContactId
      );
    },
    setParentRelationIdForChild(
      state: ContactDetailState,
      action: PayloadAction<{
        childContactId: Contact['id'];
        parentRelationId: Contact['parent_relation_id'];
      }>
    ) {
      const { childContactId, parentRelationId } = action.payload;
      const childContact = find(state.childContacts, { id: childContactId });
      if (childContact != null) {
        childContact.parent_relation_id = parentRelationId;
      }
    },
    addNewChild(
      state: ContactDetailState,
      action: PayloadAction<{
        contact: Contact;
      }>
    ) {
      state.childContacts.push({
        ...action.payload.contact,
        parent_relation_id: null,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(contactApi.endpoints.getContact.matchFulfilled, (state, action) => {
      const contact = action.payload;
      if (state.contact?.id !== contact.id) {
        state.pools = contact.pools;
      }
      state.contact = contact;
      state.parentContact = contact.parent_contact;
      state.parentId = contact.parent_id || contact.id;
      state.parentRelationId = contact.parent_relation_id;
      state.childContacts = contact.child_contacts || [];
    });
  },
});

export const { reducer } = slice;

export const {
  reset,
  resetJobsDateRange,
  resetInvoicesDateRange,
  resetQuotesDateRange,
  unlinkParentContact,
  setParentContact,
  setParentRelationId,
  unlinkChildContact,
  setParentRelationIdForChild,
  addNewChild,
} = slice.actions;

export const addPool =
  (pool: Pool): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.addPool({ pool }));
  };

export const unlinkPool =
  (poolId: number): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.unlinkPool({ poolId }));
  };

export const updatePool =
  (pool: Pool): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updatePool({ pool }));
  };

export const updatePoolRelation =
  (poolId: number, relationId: number): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updatePoolRelation({ poolId, relationId }));
  };

export const getContactInvoices =
  (
    organisationId: number,
    contactId: number,
    selectedRange: DateRange<Date>,
    limit: number,
    page: number,
    sortBy = ''
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsInvoicesLoading({ isLoading: true }));
    const from = format(selectedRange[0], 'yyyy-MM-dd HH:mm:ss');
    const to = format(selectedRange[1], 'yyyy-MM-dd HH:mm:ss');
    const response = await axios.get(
      `v2/organisations/${organisationId}/contact-invoice?contact_id=${contactId}&from=${from}&to=${to}&limit=${limit}&order=${sortBy}&page=${
        page + 1
      }`
    );

    dispatch(slice.actions.getInvoices({ invoices: response.data.data, meta: response.data.meta }));
  };

export const setInvoicesLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setInvoicesLimit({ limit }));
};

export const setInvoicesPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setInvoicesPage({ page }));
};

export const setInvoicesOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setInvoicesOrder({ orderBy, order }));
};

export const selectInvoicesRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectInvoicesRange({ dateRange }));
  };

export const setQuotesSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setQuotesSearchText({ searchText }));
};

export const setQuotesLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setQuotesLimit({ limit }));
};

export const setQuotesPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setQuotesPage({ page }));
};

export const setQuotesOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setQuotesOrder({ orderBy, order }));
};

export const selectQuotesRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectQuotesRange({ dateRange }));
  };

export const setQuotesMeta = (meta: Meta) => (dispatch) => {
  dispatch(slice.actions.setQuotesMeta({ meta }));
};

export const setJobsSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setJobsSearchText({ searchText }));
};

export const setJobsLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setJobsLimit({ limit }));
};

export const setJobsPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setJobsPage({ page }));
};

export const setJobsOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setJobsOrder({ orderBy, order }));
};

export const selectJobsRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectJobsRange({ dateRange }));
  };

export const setJobsMeta = (meta: Meta) => (dispatch) => {
  dispatch(slice.actions.setJobsMeta({ meta }));
};

export default slice;
