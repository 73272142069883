import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { OPERATIONS_SECTION, SETUP_SECTION } from 'src/constants/navigation';
import map from 'lodash/map';
import { paths } from 'src/paths';
import { authApi } from 'src/api/auth';

export interface NavSection {
  title: string;
  isCollapsed: boolean;
}

interface NavSectionState {
  sections: NavSection[];
  currentPath: string | null;
  activeSection: string;
}

const initialState: NavSectionState = {
  sections: [
    {
      title: OPERATIONS_SECTION,
      isCollapsed: false,
    },
    {
      title: SETUP_SECTION,
      isCollapsed: true,
    },
  ],
  currentPath: null,
  activeSection: paths.operations,
};

const slice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggle(state: NavSectionState, action: PayloadAction<NavSection>) {
      const toggledSection = action.payload;
      state.sections = map(state.sections, (_section: NavSection) => {
        const section: NavSection = {
          ..._section,
          isCollapsed: true,
        };

        if (_section.title === toggledSection.title) {
          section.isCollapsed = !toggledSection.isCollapsed;
        }
        return section;
      });
    },
    setPath(state: NavSectionState, action: PayloadAction<string>) {
      state.currentPath = action.payload;
    },
    setActiveSection(state: NavSectionState, action: PayloadAction<string>) {
      state.activeSection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, () => {
      return {
        ...initialState,
      };
    });
  },
});

export const { reducer } = slice;
export const { toggle, setPath, setActiveSection } = slice.actions;
