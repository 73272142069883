export const OWNER = 1;
export const TENANT = 2;
export const AGENT_STRATA = 3;
export const OTHERS = 4;

export const OWNER_TITLE = 'Owner';
export const TENANT_TITLE = 'Tenant';
export const AGENT_TITLE = 'Agent/Strata';
export const OTHERS_TITLE = 'Others';

export const RELATION_TYPES = [
  {
    value: OWNER,
    label: OWNER_TITLE,
  },
  {
    value: TENANT,
    label: TENANT_TITLE,
  },
  {
    value: AGENT_STRATA,
    label: AGENT_TITLE,
  },
  {
    value: OTHERS,
    label: OTHERS_TITLE,
  },
];
