import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import { SortDirection } from '@mui/material';
import type { BatchInvoice, InvoiceStatus } from '../types/contact-invoice';
import type { Meta } from '../types/pagination';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { DateRange } from '@mui/x-date-pickers-pro';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { GridFilterModel } from '@mui/x-data-grid-pro';

interface Range {
  from: string;
  to: string;
}

interface BatchInvoiceState {
  searchText?: string;
  statusFilter?: string;
  filterModel?: GridFilterModel;
  contactTags?: string[];
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
  isLoading: boolean;
  invoices: BatchInvoice[];
  expandedInvoiceId?: number | null;
  selectedRange: DateRange<Date>;
}

const initialState: BatchInvoiceState = {
  searchText: '',
  filterModel: {
    items: [],
  },
  statusFilter: 'all',
  contactTags: [],
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'created_at',
  order: 'desc',
  isLoading: false,
  invoices: [],
  expandedInvoiceId: null,
  selectedRange: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
};

const slice = createSlice({
  name: 'batchInvoice',
  initialState,
  reducers: {
    setLimit(state: BatchInvoiceState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: BatchInvoiceState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setExpandedRow(state: BatchInvoiceState, action: PayloadAction<{ invoiceId: number | null }>) {
      const { invoiceId } = action.payload;

      state.expandedInvoiceId = invoiceId;
    },
    setIsLoading(state: BatchInvoiceState, action: PayloadAction<{ isLoading: boolean }>) {
      const { isLoading } = action.payload;

      state.isLoading = isLoading;
    },
    setSearchText(state: BatchInvoiceState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setStatusFilter(
      state: BatchInvoiceState,
      action: PayloadAction<{ statusFilter: InvoiceStatus }>
    ) {
      const { statusFilter } = action.payload;

      state.statusFilter = statusFilter;
    },
    setContactTags(state: BatchInvoiceState, action: PayloadAction<{ contactTags: string[] }>) {
      const { contactTags } = action.payload;

      state.contactTags = contactTags;
    },
    setOrder(
      state: BatchInvoiceState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    selectRange(
      state: BatchInvoiceState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedRange = dateRange;
    },
    resetDateRange(state: BatchInvoiceState): void {
      state.selectedRange = initialState.selectedRange;
    },
    setFilterModel(
      state: BatchInvoiceState,
      action: PayloadAction<{ filterModel: GridFilterModel }>
    ) {
      const { filterModel } = action.payload;

      state.filterModel = filterModel;
    },
  },
});

export const { reducer } = slice;

export const { resetDateRange } = slice.actions;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setStatusFilter = (statusFilter: InvoiceStatus) => (dispatch) => {
  dispatch(slice.actions.setStatusFilter({ statusFilter }));
};

export const setContactTags = (contactTags: string[]) => (dispatch) => {
  dispatch(slice.actions.setContactTags({ contactTags }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export const setExpandedRow = (invoiceId: number | null) => (dispatch) => {
  dispatch(slice.actions.setExpandedRow({ invoiceId }));
};

export const selectRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectRange({ dateRange }));
  };

export const setFilterModel = (filterModel: GridFilterModel) => (dispatch) => {
  dispatch(slice.actions.setFilterModel({ filterModel }));
};

export default slice;
