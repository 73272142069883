import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import { SortDirection } from '@mui/material';
import { ALL_TYPE_FILTER, JobTemplate, JobType } from '../types/job-template';
import { publicApi } from 'src/api/public';
import { jobTemplateApi } from 'src/api/job-template';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';

interface JobTemplateState {
  searchText?: string;
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
  jobTypeFilter?: string | number;
  jobTypes: JobType[];
  jobTemplates: JobTemplate[];
}

const initialState: JobTemplateState = {
  searchText: '',
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  jobTypeFilter: ALL_TYPE_FILTER,
  orderBy: 'name',
  order: 'asc',
  jobTypes: [],
  jobTemplates: [],
};

const slice = createSlice({
  name: 'jobTemplate',
  initialState,
  reducers: {
    getJobTypes(state: JobTemplateState, action: PayloadAction<{ jobTypes: JobType[] }>) {
      const { jobTypes } = action.payload;

      state.jobTypes = jobTypes;
    },
    setLimit(state: JobTemplateState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: JobTemplateState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setJobType(state: JobTemplateState, action: PayloadAction<{ jobType: string | number }>) {
      const { jobType } = action.payload;

      state.jobTypeFilter = jobType;
    },
    setSearchText(state: JobTemplateState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setOrder(
      state: JobTemplateState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    deleteTemplate(state: JobTemplateState, action: PayloadAction<{ templateId: number }>) {
      const { templateId } = action.payload;

      state.jobTemplates = state.jobTemplates.filter(
        (jobTemplate) => jobTemplate.id !== templateId
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(publicApi.endpoints.getJobTypes.matchFulfilled, (state, action) => {
        const jobTypes = action.payload;
        state.jobTypes = jobTypes;
      })
      .addMatcher(jobTemplateApi.endpoints.getJobTemplates.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(jobTemplateApi.endpoints.getJobTemplates.matchFulfilled, (state, action) => {
        const { data, meta } = action.payload;

        state.jobTemplates = data;
        state.total = meta.total;
      })
      .addMatcher(jobTemplateApi.endpoints.getJobTemplates.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const { reducer } = slice;

export const getJobTypes = (): AppThunk => async (dispatch) => {
  const response = await axios.get('v1/jobtypes');

  dispatch(slice.actions.getJobTypes({ jobTypes: response.data }));
};

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setJobType = (jobType: string | number) => (dispatch) => {
  dispatch(slice.actions.setJobType({ jobType }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export const deleteTemplate =
  (organisationId: number, templateId: number): AppThunk =>
  async (dispatch) => {
    await axios.delete(`v2/organisation/${organisationId}/job-template/${templateId}`);

    dispatch(slice.actions.deleteTemplate({ templateId }));
  };

export default slice;
