import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { jobApi, rescheduleJobs } from 'src/api/job';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { UNSCHEDULED } from 'src/constants/job';
import { JobEvent } from 'src/types/job';

interface UnscheduledJobState {
  unscheduledEvents: JobEvent[];
  searchText?: string;
  jobStatusId?: string | number | null;
  tagsFilter?: string[];
  filterModel?: GridFilterModel;
  orderBy: string;
  order: SortDirection;
  openDrawer: boolean;
}

const initialState: UnscheduledJobState = {
  unscheduledEvents: [],
  searchText: '',
  jobStatusId: UNSCHEDULED,
  tagsFilter: [],
  filterModel: {
    items: [],
  },
  orderBy: 'start_time',
  order: 'asc',
  openDrawer: false,
};

const slice = createSlice({
  name: 'unscheduledJobs',
  initialState,
  reducers: {
    setSearchText(state: UnscheduledJobState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
    },
    setTagsFilter(state: UnscheduledJobState, action: PayloadAction<{ tags: string[] }>) {
      const { tags } = action.payload;

      state.tagsFilter = tags;
    },
    setFilterModel(
      state: UnscheduledJobState,
      action: PayloadAction<{ filterModel: GridFilterModel }>
    ) {
      const { filterModel } = action.payload;

      state.filterModel = filterModel;
    },

    unscheduleJob(state: UnscheduledJobState, action: PayloadAction<{ jobEvent: JobEvent }>) {
      state.unscheduledEvents = [...state.unscheduledEvents, action.payload.jobEvent];
    },
    toggleDrawer(state: UnscheduledJobState) {
      state.openDrawer = !state.openDrawer;
    },
    closeDrawer(state: UnscheduledJobState) {
      state.openDrawer = false;
    },
    setUnscheduledEvents(
      state: UnscheduledJobState,
      action: PayloadAction<{ events: JobEvent[] }>
    ): void {
      const { events } = action.payload;
      state.unscheduledEvents = events;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(rescheduleJobs.matchPending, (state, action) => {
      const params = action.meta.arg.originalArgs;

      const scheduledIds = params
        .filter((param) => param.body.start_time != null && param.body.end_time != null)
        .map((param) => param.id);

      if (scheduledIds.length !== 0) {
        state.unscheduledEvents = state.unscheduledEvents.filter(
          (jobEvent) => !scheduledIds.includes(jobEvent.id)
        );
      }
    });
  },
});

export const { reducer } = slice;

export const { setUnscheduledEvents, toggleDrawer, closeDrawer } = slice.actions;

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setTagsFilter = (tags: string[]) => (dispatch) => {
  dispatch(slice.actions.setTagsFilter({ tags }));
};

export const setFilterModel = (filterModel: GridFilterModel) => (dispatch) => {
  dispatch(slice.actions.setFilterModel({ filterModel }));
};

export const unscheduleJob = (jobEvent: JobEvent) => (dispatch) => {
  dispatch(slice.actions.unscheduleJob({ jobEvent }));
};

export default slice;
