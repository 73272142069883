import { useEffect } from 'react';
import type { FC } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider as ReduxProvider } from 'react-redux';
import { CacheProvider } from '@emotion/react';
import { ConfirmProvider } from 'material-ui-confirm';
import type { EmotionCache } from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { SplashScreen } from '../components/splash-screen';
import { SettingsConsumer, SettingsProvider } from '../contexts/settings-context';
import { IconContext } from 'react-icons';
import { AuthProvider, AuthConsumer } from '../contexts/auth-context';
import { store, persistor } from '../store';
import { createTheme } from '../theme';
import { createEmotionCache } from '../utils/create-emotion-cache';
import '../locales/i18n';
import 'src/lib/nprogress';
import { PersistGate } from 'redux-persist/integration/react';
import { analytics } from 'src/lib/firebase';
import { LicenseInfo } from '@mui/x-license-pro';
import { muiConfig, freshdeskConfig } from 'src/config';
import pusherJs from 'pusher-js';
import freshdesk from 'src/lib/freshdesk';
import { Toaster } from '@/components/widgets/toaster';
import { SettingsDrawer } from '@/components/settings-drawer';

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      analytics();
    }
    freshdesk.initialize(freshdeskConfig);
    LicenseInfo.setLicenseKey(muiConfig.xLicencePro);
    pusherJs.logToConsole = true;
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Pooltrackr</title>
        <meta
          name="viewport"
          content="initial-scale=1, width=device-width"
        />
      </Head>
      <ReduxProvider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthProvider>
              <SettingsProvider>
                <SettingsConsumer>
                  {({
                    isInitialised,
                    settings,
                    openDrawer,
                    handleDrawerClose,
                    environmentConfig,
                  }) => {
                    if (!isInitialised || environmentConfig === null) return <SplashScreen />;

                    const theme = createTheme({
                      environmentConfigTheme: environmentConfig?.theme,
                      contrast: settings.contrast,
                      paletteMode: settings.paletteMode,
                      responsiveFontSizes: settings.responsiveFontSizes,
                    });

                    return (
                      <ThemeProvider theme={theme}>
                        <Head>
                          <meta
                            name="color-scheme"
                            content={settings.paletteMode}
                          />
                          <meta
                            name="theme-color"
                            content={theme.palette.neutral[900]}
                          />
                        </Head>
                        <IconContext.Provider value={{ size: '1.2em' }}>
                          <ConfirmProvider>
                            <CssBaseline />
                            <Toaster />
                            <AuthConsumer>
                              {(auth) =>
                                !auth.isInitialised ? (
                                  <SplashScreen />
                                ) : (
                                  <>
                                    {getLayout(<Component {...pageProps} />)}
                                    <SettingsDrawer
                                      onClose={handleDrawerClose}
                                      open={openDrawer}
                                    />
                                  </>
                                )
                              }
                            </AuthConsumer>
                          </ConfirmProvider>
                        </IconContext.Provider>
                      </ThemeProvider>
                    );
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            </AuthProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </CacheProvider>
  );
};

export default App;
