import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from 'axios';
import { AppThunk } from 'src/store';
import { DuplicationSwitcher } from 'src/types/deduplicate-contact-tool';

interface DeduplicateContactToolAutomaticState {
  isSubmitting: boolean;
  switchers: DuplicationSwitcher[];
  isAutomateModalOpen: boolean;

  mergedCountSuccessfully: number;
  isSuccessModalOpen: boolean;
  isMergeError: boolean;
}

const initialState: DeduplicateContactToolAutomaticState = {
  isSubmitting: false,
  switchers: [DuplicationSwitcher.contactName],
  isAutomateModalOpen: false,

  mergedCountSuccessfully: 0,
  isSuccessModalOpen: false,
  isMergeError: false,
};

const slice = createSlice({
  name: 'deduplicateContactToolAutomatic',
  initialState,
  reducers: {
    startSubmitting(state: DeduplicateContactToolAutomaticState) {
      state.isSubmitting = true;
      state.isMergeError = false;
    },
    stopSubmitting(state: DeduplicateContactToolAutomaticState) {
      state.isSubmitting = false;
    },
    setMergeError(state: DeduplicateContactToolAutomaticState) {
      state.isMergeError = true;
    },
    setMergedCount(
      state: DeduplicateContactToolAutomaticState,
      action: PayloadAction<{
        count: number;
      }>
    ) {
      state.mergedCountSuccessfully = action.payload.count;
    },
    openSuccessModal(state: DeduplicateContactToolAutomaticState) {
      state.isSuccessModalOpen = true;
    },
    closeSuccessModal(state: DeduplicateContactToolAutomaticState) {
      state.isSuccessModalOpen = false;
    },
    openAutomateModal(state: DeduplicateContactToolAutomaticState) {
      state.isAutomateModalOpen = true;
    },
    closeAutomateModal(state: DeduplicateContactToolAutomaticState) {
      state.isAutomateModalOpen = false;
    },
    updateDuplicationSwitcher(
      state: DeduplicateContactToolAutomaticState,
      action: PayloadAction<{
        switcher: DuplicationSwitcher;
        checked: boolean;
      }>
    ) {
      const { switcher, checked } = action.payload;
      if (checked) {
        state.switchers = [...state.switchers, switcher];
      } else if (state.switchers.length > 1) {
        state.switchers = state.switchers.filter((s) => s !== switcher);
      }
    },
  },
});

export const { reducer } = slice;
export const {
  openAutomateModal,
  closeAutomateModal,
  updateDuplicationSwitcher,
  closeSuccessModal,
} = slice.actions;

export const runAutoMerge =
  (organisationId: number, duplcationSwitchers: DuplicationSwitcher[]): AppThunk =>
  async (dispatch) => {
    const payload = {
      [DuplicationSwitcher.contactName]: false,
      [DuplicationSwitcher.email]: false,
      [DuplicationSwitcher.addressStreetOne]: false,
      [DuplicationSwitcher.addressCity]: false,
      [DuplicationSwitcher.addressPostCode]: false,
      [DuplicationSwitcher.fistName]: false,
      [DuplicationSwitcher.lastName]: false,
      [DuplicationSwitcher.hasOnePool]: false,
    };
    duplcationSwitchers.forEach((switcher) => {
      payload[switcher] = true;
    });

    try {
      dispatch(slice.actions.startSubmitting());
      dispatch(slice.actions.openSuccessModal());

      const response = await axios.post(
        `v1/organisations/${organisationId}/deduplicate-contacts`,
        payload
      );
      const count = response.data.merged_duplicates;
      dispatch(slice.actions.setMergedCount({ count }));
    } catch (e) {
      dispatch(slice.actions.setMergeError());
      throw e;
    } finally {
      dispatch(slice.actions.stopSubmitting());
    }
  };
