import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { poolApi } from 'src/api/pool';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { GridFilterModel } from '@mui/x-data-grid-pro';

interface LabPoolState {
  searchText?: string;
  filterModel?: GridFilterModel;
  tagsFilter?: string[];
  expandedPoolId?: number | null;
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
}

const initialState: LabPoolState = {
  searchText: '',
  filterModel: {
    items: [],
  },
  tagsFilter: [],
  expandedPoolId: null,
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'created_at',
  order: 'desc',
};

const slice = createSlice({
  name: 'labPool',
  initialState,
  reducers: {
    setLimit(state: LabPoolState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: LabPoolState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setExpandedRow(state: LabPoolState, action: PayloadAction<{ poolId: number | null }>) {
      const { poolId } = action.payload;

      state.expandedPoolId = poolId;
    },
    setSearchText(state: LabPoolState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setOrder(
      state: LabPoolState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    setTagsFilter(state: LabPoolState, action: PayloadAction<{ tags: string[] }>) {
      const { tags } = action.payload;

      state.tagsFilter = tags;
    },
    setFilterModel(state: LabPoolState, action: PayloadAction<{ filterModel: GridFilterModel }>) {
      const { filterModel } = action.payload;

      state.filterModel = filterModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(poolApi.endpoints.getPools.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(poolApi.endpoints.getPools.matchFulfilled, (state, action) => {
        const { meta } = action.payload;
        state.total = meta.total;
      })
      .addMatcher(poolApi.endpoints.getPools.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const { reducer } = slice;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setExpandedRow = (poolId: number | null) => (dispatch) => {
  dispatch(slice.actions.setExpandedRow({ poolId }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export const setTagsFilter = (tags: string[]) => (dispatch) => {
  dispatch(slice.actions.setTagsFilter({ tags }));
};

export const setFilterModel = (filterModel: GridFilterModel) => (dispatch) => {
  dispatch(slice.actions.setFilterModel({ filterModel }));
};

export default slice;
