import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '../slices/auth';
import { reducer as settingsReducer } from '../slices/settings';
import { reducer as navigationReducer } from '../slices/navigation';
import { reducer as testHistoryReducer } from '../slices/testHistory';
import { reducer as waterTestHistoryReducer } from '../slices/waterTestHistory';
import { reducer as waterTestReducer } from '../slices/water-test';
import { reducer as labPoolsReducer } from '../slices/labPool';
import { reducer as waterTestCalculatorReducer } from '../slices/water-test-calculator';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as calendarFilterReducer } from '../slices/calendarFilter';
import { reducer as calendarMoveJobModalReducer } from '../slices/calendarMoveJobModal';
import { reducer as calendarDayViewReducer } from '../slices/calendarDayView';
import { reducer as calendarWeekViewReducer } from '../slices/calendarWeekView';
import { reducer as scheduleDialogReducer } from '../slices/scheduleDialog';
import { reducer as recurrenceDialogReducer } from '../slices/recurrenceDialog';
import { reducer as accountReducer } from '../slices/account';
import { reducer as contactReducer } from '../slices/contact';
import { reducer as dashboardAnalyticsReducer } from '../slices/dashboardAnalytics';
import { reducer as dashboardFinanceReducer } from '../slices/dashboardFinance';
import { reducer as contactInvoiceReducer } from '../slices/contactInvoice';
import { reducer as batchInvoiceReducer } from '../slices/batchInvoice';
import { reducer as batchPreviewReducer } from '../slices/batchPreview';
import { reducer as batchQueueReducer } from '../slices/batchQueue';
import { reducer as resendBatchInvoiceReducer } from '../slices/resendBatchInvoice';
import { reducer as resendInvoiceReducer } from '../slices/resendInvoice';
import { reducer as poolReducer } from '../slices/pool';
import { reducer as poolSpecificationsReducer } from '../slices/poolSpecifications';
import { reducer as recurrencePeriodsReducer } from '../slices/recurrencePeriods';
import { reducer as productReducer } from '../slices/product';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as quickScheduleReducer } from '../slices/quickSchedule';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as operationsSmsReducer } from '../slices/operationsSms';
import { reducer as openedQuoteReducer } from '../slices/openedQuote';
import { reducer as archivedQuoteReducer } from '../slices/archivedQuote';
import { reducer as chemicalGroupReducer } from '../slices/chemicalGroup';
import { reducer as observationGroupReducer } from '../slices/observationGroup';
import { reducer as contactDetailReducer } from '../slices/contactDetail';
import { reducer as poolDetailReducer } from '../slices/poolDetail';
import { reducer as userReducer } from '../slices/user';
import { reducer as jobDetailReducer } from '../slices/jobDetail';
import { reducer as jobTemplateReducer } from '../slices/jobTemplate';
import { reducer as jobToInvoiceReducer } from '../slices/jobToInvoice';
import { reducer as poolPhotosReducer } from '../slices/poolPhotos';
import { reducer as deduplicatePoolToolReducer } from '../slices/deduplicatePool';
import { reducer as deduplicateContactToolReducer } from '../slices/deduplicateContactTool';
import { reducer as deduplicateContactToolAutomaticReducer } from '../slices/deduplicateContactToolAutomatic';
import { reducer as fileImporterReducer } from '../slices/fileImporter';

import { reducer as campaignReducer } from '../slices/campaign';
import { reducer as chemicalTestReducer } from '../slices/chemicalTest';
import { reducer as observationTestReducer } from '../slices/observationTest';
import { reducer as invoiceCreateReducer } from '../slices/invoiceCreate';
import { reducer as batchInvoiceDetailsReducer } from '../slices/batchInvoiceDetails';

import { reducer as vendIntegrationReducer } from '../slices/vendIntegration';
import { reducer as vendIntegrationSyncContactsReducer } from '../slices/vendIntegrationSyncContacts';
import { reducer as vendIntegrationSyncProductsReducer } from '../slices/vendIntegrationSyncProducts';
import { reducer as vendIntegrationMatchUsersReducer } from '../slices/vendIntegrationMatchUsers';
import { reducer as recurrenceDashboardReducer } from '../slices/recurrence';
import { reducer as waterTestDeviceReducer } from '../slices/water-test-device';
import { reducer as tagReducer } from '../slices/tag';
import { reducer as imageUploaderReducer } from '../slices/image-uploader';
import { reducer as quoteReducer } from '../slices/quote';
import { reducer as tableSettingsReducer } from '../slices/tableSettings';
import { reducer as jobListReducer } from '../slices/jobList';
import { reducer as unscheduledJobsReducer } from '../slices/unscheduledJobs';
import { reducer as messagingSettingsReducer } from '../slices/messagingSettings';
import { reducer as equipmentSetupReducer } from '../slices/equipmentSetup';

import { apiReducer } from 'src/api';
import { authApi } from 'src/api/auth';

const rootReducer = combineReducers({
  ...apiReducer,
  auth: authReducer,
  settings: settingsReducer,
  navigation: navigationReducer,
  testHistory: testHistoryReducer,
  waterTestHistory: waterTestHistoryReducer,
  waterTest: waterTestReducer,
  labPools: labPoolsReducer,
  waterTestCalculator: waterTestCalculatorReducer,
  account: accountReducer,
  contact: contactReducer,
  contactInvoice: contactInvoiceReducer,
  batchInvoice: batchInvoiceReducer,
  batchQueue: batchQueueReducer,
  batchPreview: batchPreviewReducer,
  batchInvoiceDetails: batchInvoiceDetailsReducer,
  resendBatchInvoice: resendBatchInvoiceReducer,
  resendInvoice: resendInvoiceReducer,
  dashboardAnalytics: dashboardAnalyticsReducer,
  dashboardFinance: dashboardFinanceReducer,
  pool: poolReducer,
  poolSpecifications: poolSpecificationsReducer,
  recurrencePeriods: recurrencePeriodsReducer,
  product: productReducer,
  calendar: persistReducer(
    { key: 'calendar', whitelist: ['techniciansDrawerOpen'], storage },
    calendarReducer
  ),
  calendarFilter: persistReducer(
    { key: 'calendarFilter', whitelist: ['displaySettings'], storage },
    calendarFilterReducer
  ),
  calendarMoveJobModal: calendarMoveJobModalReducer,
  calendarDayView: calendarDayViewReducer,
  calendarWeekView: calendarWeekViewReducer,
  scheduleDialog: scheduleDialogReducer,
  recurrenceDialog: recurrenceDialogReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  quickSchedule: quickScheduleReducer,
  openedQuote: openedQuoteReducer,
  quote: quoteReducer,
  archivedQuote: archivedQuoteReducer,
  chemicalGroup: chemicalGroupReducer,
  observationGroup: observationGroupReducer,
  contactDetail: contactDetailReducer,
  poolDetail: poolDetailReducer,
  user: userReducer,
  jobDetail: jobDetailReducer,
  jobTemplate: jobTemplateReducer,
  jobToInvoice: jobToInvoiceReducer,
  poolPhotos: poolPhotosReducer,
  fileImporter: fileImporterReducer,
  campaign: campaignReducer,
  chemicalTest: chemicalTestReducer,
  observationTest: observationTestReducer,
  invoiceCreate: invoiceCreateReducer,
  mail: mailReducer,
  deduplicatePoolTool: deduplicatePoolToolReducer,
  operationsSms: operationsSmsReducer,
  deduplicateContactTool: deduplicateContactToolReducer,
  deduplicateContactToolAutomatic: deduplicateContactToolAutomaticReducer,
  vendIntegration: vendIntegrationReducer,
  vendIntegrationSyncContacts: vendIntegrationSyncContactsReducer,
  vendIntegrationSyncProducts: vendIntegrationSyncProductsReducer,
  vendIntegrationMatchUsers: vendIntegrationMatchUsersReducer,
  recurrenceDashboard: recurrenceDashboardReducer,
  tag: tagReducer,
  imageUploader: imageUploaderReducer,
  waterTestDevice: waterTestDeviceReducer,
  tableSettings: tableSettingsReducer,
  jobList: jobListReducer,
  unscheduledJobs: persistReducer(
    { key: 'unscheduledJobs', whitelist: ['openDrawer'], storage },
    unscheduledJobsReducer
  ),
  messagingSettings: messagingSettingsReducer,
  equipmentSetup: equipmentSetupReducer,
});

// const rootReducer = (state, action) => {
//   // if (action?.meta?.arg === 'logout' && action?.type === 'authApi/executeMutation/fulfilled') {
//   //   state = undefined;
//   // }

//   return appReducer(state, action);
// };

export default rootReducer;
