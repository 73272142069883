import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { TradingTerm } from 'src/types/contact';
import { LabSettings, Organisation, Billing, OverviewStats } from 'src/types/organisation';
import { baseQueryWithInterceptor } from './base-query';
import {
  IDeleteCard,
  IGetBillingTransactions,
  IStoreCard,
  IUpdateBilling,
  IUpdateLabSettings,
  IUpdateOrganisation,
} from './model/organisation.model';

export const organisationApi = createApi({
  reducerPath: 'organisationApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: [
    'Organisation',
    'OverviewStats',
    'BillingDetails',
    'BillingTransactions',
    'EquipmentTypes',
    'SentSms',
    'LabSettings',
    'MessagingSettings',
    'TradingTerms',
  ],
  endpoints: (build) => ({
    updateOrganisation: build.mutation<Organisation, IUpdateOrganisation>({
      query: (data: IUpdateOrganisation) => ({
        url: `v1/organisations/${data.id}`,
        method: 'put',
        body: data.body,
      }),
    }),
    updateOrganisationLogo: build.mutation<any, any>({
      query: (data: any) => {
        const fd = new FormData();
        fd.append('logo', data.file);

        return {
          url: `v2/organisation/${data.organisationId}/update-logo`,
          method: 'post',
          body: fd,
        };
      },
    }),
    getOrganisation: build.query<Organisation, number>({
      query: (id) => `v2/organisation/${id}`,
      providesTags: (result) => [{ type: 'Organisation', id: result.id }],
    }),
    getOverviewStats: build.query<OverviewStats, number>({
      query: (id) => `v2/organisation/${id}/overview-stats`,
      providesTags: (result) => [{ type: 'OverviewStats', id: 'LIST' }],
    }),
    getTradingTerms: build.query<TradingTerm[], number>({
      query: (id) => `v1/organisations/${id}/trading-term`,
      providesTags: () => [{ type: 'TradingTerms', id: 'LIST' }],
    }),
    getLabSettings: build.query<any, number>({
      query: (id) => `v2/organisation/${id}/lab-settings`,
      providesTags: (result, error, id) => [{ type: 'LabSettings', id }],
    }),
    updateLabSettings: build.mutation<LabSettings, IUpdateLabSettings>({
      query: (param: IUpdateLabSettings) => ({
        url: `v2/organisation/${param.organisationId}/lab-settings`,
        method: 'PUT',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'LabSettings', id: param.organisationId },
      ],
    }),
    getBillingDetails: build.query<any, number>({
      query: (id) => `v2/organisation/${id}/billing`,
      providesTags: (result, error, id) => [{ type: 'BillingDetails', id }],
    }),
    updateBillingDetails: build.mutation<Billing, IUpdateBilling>({
      query: (param: IUpdateBilling) => ({
        url: `v2/organisation/${param.organisationId}/billing`,
        method: 'PUT',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'BillingDetails', id: param.organisationId },
      ],
    }),
    getBillingTransactions: build.query<any, IGetBillingTransactions>({
      query: (param: IGetBillingTransactions) => ({
        url: `v2/organisation/${param.organisationId}/billing/transactions`,
        method: 'POST',
        body: param.body,
      }),
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'BillingTransactions' as const, id })),
              { type: 'BillingTransactions', id: 'LIST' },
            ]
          : [{ type: 'BillingTransactions', id: 'LIST' }],
    }),
    storeCard: build.mutation<Billing, IStoreCard>({
      query: (param: IStoreCard) => ({
        url: `v2/organisation/${param.organisationId}/billing/card`,
        method: 'POST',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'BillingDetails', id: param.organisationId },
      ],
    }),
    deleteCard: build.mutation<Billing, IDeleteCard>({
      query: (param: IDeleteCard) => ({
        url: `v2/organisation/${param.organisationId}/billing/card`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'BillingDetails', id: param.organisationId },
      ],
    }),
  }),
});
export const {
  useGetOrganisationQuery,
  useGetTradingTermsQuery,
  useUpdateOrganisationLogoMutation,
  useUpdateOrganisationMutation,
  useGetLabSettingsQuery,
  useUpdateLabSettingsMutation,
  useGetBillingDetailsQuery,
  useUpdateBillingDetailsMutation,
  useStoreCardMutation,
  useDeleteCardMutation,
  useGetBillingTransactionsQuery,
  useGetOverviewStatsQuery,
} = organisationApi;

export const {
  endpoints: {
    getOrganisation,
    getTradingTerms,
    updateOrganisation,
    updateOrganisationLogo,
    getLabSettings,
    getBillingDetails,
    updateBillingDetails,
    storeCard,
    deleteCard,
    getBillingTransactions,
    getOverviewStats,
  },
} = organisationApi;
