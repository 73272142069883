import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { waterTestApi } from 'src/api/water-test';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { ObservationTest, TestOrderCard, TestOrderColumn } from 'src/types/chemical';
import objFromArray from 'src/utils/objFromArray';
import filter from 'lodash/filter';
import map from 'lodash/map';
import {
  ORDERED_RECOMMENDATIONS_ID,
  RECOMMENDATIONS_ORDER_COLUMNS,
  UNORDERED_RECOMMENDATIONS_ID,
} from 'src/constants/chemical-test';
import { getObservationTestOrderCards } from 'src/utils/chemical-test';

interface ObservationTestState {
  searchText?: string;
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
  isReodering: boolean;
  orderColumns: {
    byId: Record<string, TestOrderColumn>;
    allIds: string[];
  };
  orderCards: {
    byId: Record<string, TestOrderCard>;
    allIds: string[];
  };
}

const initialState: ObservationTestState = {
  searchText: '',
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'name',
  order: 'asc',
  isReodering: false,
  orderColumns: {
    byId: {},
    allIds: [],
  },
  orderCards: {
    byId: {},
    allIds: [],
  },
};

const slice = createSlice({
  name: 'observationTest',
  initialState,
  reducers: {
    setSearchText(state: ObservationTestState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
    },
    setLimit(state: ObservationTestState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
    },
    setPage(state: ObservationTestState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
    },
    setOrder(
      state: ObservationTestState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    getObservationTestOrders(
      state: ObservationTestState,
      action: PayloadAction<ObservationTest[]>
    ): void {
      state.orderColumns = {
        ...initialState.orderColumns,
      };
      state.orderCards = {
        ...initialState.orderCards,
      };
      const observationTests = action.payload;
      let columns = RECOMMENDATIONS_ORDER_COLUMNS;
      const cards = getObservationTestOrderCards(observationTests);
      columns = map(columns, (_column) => {
        const column = {
          ..._column,
        };
        if (column.id === ORDERED_RECOMMENDATIONS_ID) {
          const orderedTests = filter(
            cards,
            (card) => card.columnId === ORDERED_RECOMMENDATIONS_ID
          );
          column.cardIds = map(orderedTests, (recommendationCard) => recommendationCard.id);
        } else {
          const unOrderedTests = filter(
            cards,
            (card) => card.columnId === UNORDERED_RECOMMENDATIONS_ID
          );
          column.cardIds = map(unOrderedTests, (recommendationCard) => recommendationCard.id);
        }
        return column;
      });

      state.orderColumns.byId = objFromArray(columns);
      state.orderColumns.allIds = Object.keys(state.orderColumns.byId);
      state.orderCards.byId = objFromArray(cards);
      state.orderCards.allIds = Object.keys(state.orderCards.byId);
    },
    moveObservationTestOrder(
      state: ObservationTestState,
      action: PayloadAction<{ cardId: string; position: number; columnId?: string }>
    ): void {
      const { cardId, position, columnId } = action.payload;
      const sourceColumnId = state.orderCards.byId[cardId].columnId;

      // Remove card from source column
      state.orderColumns.byId[sourceColumnId].cardIds = state.orderColumns.byId[
        sourceColumnId
      ].cardIds.filter((_cardId) => _cardId !== cardId);

      // If columnId exists, it means that we have to add the card to the new column
      if (columnId) {
        // Change card's columnId reference
        state.orderCards.byId[cardId].columnId = columnId;
        // Push the cardId to the specified position
        state.orderColumns.byId[columnId].cardIds.splice(position, 0, cardId);
      } else {
        // Push the cardId to the specified position
        state.orderColumns.byId[sourceColumnId].cardIds.splice(position, 0, cardId);
      }
      state.isReodering = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        waterTestApi.endpoints.getPaginatedObservationTests.matchPending,
        (state, action) => {
          console.log('pending', action);
        }
      )
      .addMatcher(
        waterTestApi.endpoints.getPaginatedObservationTests.matchFulfilled,
        (state, action) => {
          const { meta } = action.payload;
          state.total = meta.total;
        }
      )
      .addMatcher(
        waterTestApi.endpoints.getPaginatedObservationTests.matchRejected,
        (state, action) => {
          console.log('rejected', action);
        }
      )
      .addMatcher(waterTestApi.endpoints.updateTestsOrder.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(waterTestApi.endpoints.updateTestsOrder.matchFulfilled, (state, action) => {
        state.isReodering = false;
      })
      .addMatcher(waterTestApi.endpoints.updateTestsOrder.matchRejected, (state, action) => {
        state.isReodering = false;
      });
  },
});

export const { reducer } = slice;

export const { getObservationTestOrders, moveObservationTestOrder } = slice.actions;

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export default slice;
