import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithInterceptor } from './base-query';
import {
  IAddCampaign,
  IDeleteCampaign,
  IEditCampaign,
  IGetActiveCampaigns,
  IGetCampaign,
  IGetCampaigns,
  IUploadBanner,
} from './model/campaign.model';
import each from 'lodash/each';

export const campaignApi = createApi({
  reducerPath: 'campaignApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['Campaign', 'Campaigns', 'ActiveCampaigns'],
  endpoints: (builder) => ({
    getCampaigns: builder.query<any, IGetCampaigns>({
      query: (param: IGetCampaigns) =>
        `v2/organisation/${param.organisationId}/campaign?filter=${param.filter}&limit=${param.limit}&order=${param.sortBy}&page=${param.page}`,
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Campaigns' as const, id })),
              { type: 'Campaigns', id: 'LIST' },
            ]
          : [{ type: 'Campaigns', id: 'LIST' }],
    }),
    getActiveCampaigns: builder.query<any, IGetActiveCampaigns>({
      query: (param: IGetActiveCampaigns) =>
        `v2/organisation/${param.organisationId}/active-campaigns`,
      keepUnusedDataFor: 600,
      providesTags: () => [{ type: 'ActiveCampaigns', id: 'LIST' }],
    }),
    getCampaign: builder.query<any, IGetCampaign>({
      query: (param: IGetCampaign) =>
        `v2/organisation/${param.organisationId}/campaign/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'Campaign', id: param.id }],
    }),
    deleteCampaign: builder.mutation<any, IDeleteCampaign>({
      query: (param: IDeleteCampaign) => ({
        url: `v2/organisation/${param.organisationId}/campaign/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Campaign', id: param.id },
        { type: 'Campaigns', id: param.id },
        { type: 'ActiveCampaigns', id: 'LIST' },
      ],
    }),
    editCampaign: builder.mutation<any, IEditCampaign>({
      query: (param: IEditCampaign) => ({
        url: `v2/organisation/${param.organisationId}/campaign/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = campaignApi.util.selectInvalidatedBy(getState(), [
          { type: 'Campaigns', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getCampaigns' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                campaignApi.util.updateQueryData('getCampaigns', originalArgs, (campaigns) => {
                  campaigns.data.forEach((campaign) => {
                    if (campaign.id === param.id) {
                      Object.assign(campaign, param.body);
                    } else {
                      campaign.invoice_enabled = param.body.invoice_enabled
                        ? false
                        : campaign.invoice_enabled;
                      campaign.email_enabled = param.body.email_enabled
                        ? false
                        : campaign.email_enabled;
                      campaign.water_test_enabled = param.body.water_test_enabled
                        ? false
                        : campaign.water_test_enabled;
                      campaign.job_sheet_enabled = param.body.job_sheet_enabled
                        ? false
                        : campaign.job_sheet_enabled;
                    }
                  });
                })
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Campaign', id: param.id },
        { type: 'ActiveCampaigns', id: 'LIST' },
      ],
    }),
    addCampaign: builder.mutation<any, IAddCampaign>({
      query: (param: IAddCampaign) => {
        const { organisationId, body, file } = param;
        // eslint-disable-next-line no-undef
        const fd = new FormData();
        fd.append('banner', file);
        fd.append('name', body.name);
        fd.append('email_enabled', String(+body.email_enabled));
        fd.append('invoice_enabled', String(+body.invoice_enabled));
        fd.append('job_sheet_enabled', String(+body.job_sheet_enabled));
        fd.append('water_test_enabled', String(+body.water_test_enabled));
        fd.append('start_date', body.start_date);
        if (body.expiry_date != null) {
          fd.append('expiry_date', body.expiry_date);
        }
        fd.append('url', body.url);
        return {
          url: `v2/organisation/${organisationId}/campaign`,
          contentType: 'multipart/form-data',
          method: 'POST',
          body: fd,
        };
      },
      invalidatesTags: [{ type: 'Campaigns', id: 'LIST' }],
    }),
    updateCampaignBanner: builder.mutation<any, IUploadBanner>({
      query: (param: IUploadBanner) => {
        const { organisationId, id, file } = param;
        // eslint-disable-next-line no-undef
        const fd = new FormData();
        fd.append('banner', file);
        return {
          url: `v2/organisation/${organisationId}/campaign/${id}/banner`,
          contentType: 'multipart/form-data',
          method: 'POST',
          body: fd,
        };
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Campaign', id: param.id },
        { type: 'Campaigns', id: 'LIST' },
        { type: 'ActiveCampaigns', id: 'LIST' },
      ],
    }),
  }),
});
export const {
  useGetCampaignsQuery,
  useGetActiveCampaignsQuery,
  useGetCampaignQuery,
  useDeleteCampaignMutation,
  useEditCampaignMutation,
  useAddCampaignMutation,
  useUpdateCampaignBannerMutation,
} = campaignApi;
