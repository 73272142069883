import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Subscription, SubscriptionPlan } from 'src/types/subscription';
import { baseQueryWithInterceptor } from './base-query';
import { IUnsubscribe, IUpdateSubscription } from './model/subscription';

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['Subscription', 'InvoiceHistory', 'SubscriptionPlans'],
  endpoints: (build) => ({
    getSubscriptionPlans: build.query<SubscriptionPlan[], number>({
      query: (id) => `v2/organisation/${id}/subscription-plan`,
      providesTags: () => [{ type: 'SubscriptionPlans', id: 'LIST' }],
    }),
    getSubscription: build.query<Subscription, number>({
      query: (id) => `v2/organisation/${id}/subscription`,
      providesTags: () => [{ type: 'Subscription', id: 'LIST' }],
    }),
    updateSubscription: build.mutation<Subscription, IUpdateSubscription>({
      query: (param: IUpdateSubscription) => ({
        url: `v2/organisation/${param.organisationId}/subscription`,
        method: 'PUT',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Subscription', id: param.organisationId },
      ],
    }),
    unsubscribe: build.mutation<Subscription, IUnsubscribe>({
      query: (param: IUnsubscribe) => ({
        url: `v2/organisation/${param.organisationId}/subscription/unsubscribe`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Subscription', id: param.organisationId },
      ],
    }),
  }),
});
export const {
  useGetSubscriptionPlansQuery,
  useGetSubscriptionQuery,
  useUpdateSubscriptionMutation,
  useUnsubscribeMutation,
} = subscriptionApi;

export const {
  endpoints: { getSubscriptionPlans, getSubscription, updateSubscription, unsubscribe },
} = subscriptionApi;
