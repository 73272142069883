import axios from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from 'src/store';
import type { BatchInvoice } from '../types/contact-invoice';
import type { Contact } from '../types/contact';

interface BatchInvoiceDetailsState {
  isLoading: boolean;
  batchInvoice?: BatchInvoice;
  contact?: Contact;
}

const initialState: BatchInvoiceDetailsState = {
  isLoading: true,
  batchInvoice: null,
  contact: null,
};

const slice = createSlice({
  name: 'batchInvoiceDetails',
  initialState,
  reducers: {
    startLoading(state: BatchInvoiceDetailsState) {
      state.isLoading = true;
    },
    setBatchInvoice(
      state: BatchInvoiceDetailsState,
      action: PayloadAction<{ invoice: BatchInvoice }>
    ) {
      state.batchInvoice = action.payload.invoice;
      state.contact = action.payload.invoice.contact;
      state.isLoading = false;
    },
  },
});

export const { reducer } = slice;

export const getBatchInvoice =
  (organisationId: number, batchInvoiceId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(
      `v2/organisations/${organisationId}/batch-invoice/${batchInvoiceId}`
    );
    dispatch(slice.actions.setBatchInvoice({ invoice: response.data }));
  };

export default slice;
