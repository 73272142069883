import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import getDay from 'date-fns/getDay';
import isEmpty from 'lodash/isEmpty';
import { RRule, Weekday } from 'rrule';
import { MONTHS } from 'src/constants/date';
import {
  CUSTOM_TYPE,
  EVERY_FOUR_WEEKS_TYPE,
  FORTNIGHTLY_TYPE,
  MONTHLY_TYPE,
  NO_RECURRENCE,
  SEASONAL_TYPE,
  WEEKLY_TYPE,
} from 'src/constants/recurrence';
import { RecurrenceType } from 'src/types/recurrence';

interface RecurrenceDialogState {
  open: boolean;
  selectedType: RecurrenceType;
  rRule: RRule | null;
  customRRule: RRule | null;
  isValid: boolean;
}

const weeklyRRule = (byweekday: Weekday) =>
  new RRule({
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday,
    wkst: 0,
  });

const fortnightlyRRule = (byweekday: Weekday) =>
  new RRule({
    freq: RRule.WEEKLY,
    interval: 2,
    byweekday,
    wkst: 0,
  });

const everyFourWeeksRRule = (byweekday: Weekday) =>
  new RRule({
    freq: RRule.WEEKLY,
    interval: 4,
    byweekday,
  });

const monthlyRRule = (byweekday: Weekday) =>
  new RRule({
    freq: RRule.MONTHLY,
    byweekday: new Weekday(byweekday.weekday),
    byweekno: 4,
  });

const initialState: RecurrenceDialogState = {
  selectedType: NO_RECURRENCE,
  open: false,
  rRule: null,
  customRRule: null,
  isValid: true,
};
const slice = createSlice({
  name: 'recurrenceDialog',
  initialState,
  reducers: {
    close(state: RecurrenceDialogState): void {
      state.open = false;
    },
    open(): RecurrenceDialogState {
      return {
        ...initialState,
        open: true,
      };
    },
    openToEdit(
      state: RecurrenceDialogState,
      action: PayloadAction<{ startDate: Date; rRule: RRule; customRRule: RRule | null }>
    ) {
      const { startDate, rRule, customRRule } = action.payload;
      state.open = true;
      state.rRule = rRule;
      state.customRRule = customRRule;
      const byweekday = getRRuleWeekdayByDate(startDate);

      if (!isEmpty(customRRule)) {
        state.selectedType = SEASONAL_TYPE;
      } else if (rRule.toString() === weeklyRRule(byweekday).toString()) {
        state.selectedType = WEEKLY_TYPE;
      } else if (rRule.toString() === fortnightlyRRule(byweekday).toString()) {
        state.selectedType = FORTNIGHTLY_TYPE;
      } else if (rRule.toString() === everyFourWeeksRRule(byweekday).toString()) {
        state.selectedType = EVERY_FOUR_WEEKS_TYPE;
      } else if (rRule.toString() === monthlyRRule(byweekday).toString()) {
        state.selectedType = MONTHLY_TYPE;
      } else {
        state.selectedType = CUSTOM_TYPE;
      }
    },
    selectRecurrenceType(
      state: RecurrenceDialogState,
      action: PayloadAction<{ date: Date; type: RecurrenceType }>
    ): void {
      state.selectedType = action.payload.type || null;
      const byweekday = getRRuleWeekdayByDate(action.payload.date);

      switch (action.payload.type) {
        case NO_RECURRENCE:
          state.rRule = null;
          state.customRRule = null;
          break;
        case WEEKLY_TYPE:
          state.rRule = weeklyRRule(byweekday);
          state.customRRule = null;
          break;
        case FORTNIGHTLY_TYPE:
          state.rRule = fortnightlyRRule(byweekday);
          state.customRRule = null;
          break;
        case EVERY_FOUR_WEEKS_TYPE:
          state.rRule = everyFourWeeksRRule(byweekday);
          state.customRRule = null;
          break;
        case MONTHLY_TYPE:
          state.rRule = monthlyRRule(byweekday);
          state.customRRule = null;
          break;
        case CUSTOM_TYPE:
          state.rRule = new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            byweekday,
            wkst: 0,
          });
          state.customRRule = null;
          break;
        case SEASONAL_TYPE:
          state.rRule = new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            bymonth: [12, 1, 2, 3, 4, 5], // TODO: make it configurable
            byweekday,
            wkst: 0,
          });
          state.customRRule = new RRule({
            freq: RRule.WEEKLY,
            interval: 1,
            bymonth: [6, 7, 8, 9, 10, 11], // TODO: make it configurable
            byweekday,
            wkst: 0,
          });
          break;
      }
      state.isValid = validate(state);
    },
    changeRRule(
      state: RecurrenceDialogState,
      action: PayloadAction<{ rRule: RRule; fromPeriod?: boolean }>
    ): void {
      const { rRule, fromPeriod } = action.payload;
      state.rRule = rRule;

      if (state.selectedType === SEASONAL_TYPE) {
        state.customRRule = new RRule({
          ...state.customRRule.origOptions,
          bymonth: fromPeriod
            ? MONTHS.filter((month) => !rRule.options.bymonth.includes(month))
            : state.customRRule.options.bymonth.filter(
                (month) => !rRule.options.bymonth.includes(month)
              ),
        });
      }

      state.isValid = validate(state);
    },
    changeCustomRRule(
      state: RecurrenceDialogState,
      action: PayloadAction<{ customRRule: RRule; fromPeriod?: boolean }>
    ): void {
      const { customRRule, fromPeriod } = action.payload;
      state.customRRule = customRRule;
      state.rRule = new RRule({
        ...state.rRule.origOptions,
        bymonth: fromPeriod
          ? MONTHS.filter((month) => !customRRule.options.bymonth.includes(month))
          : state.rRule.options.bymonth.filter(
              (month) => !customRRule.options.bymonth.includes(month)
            ),
      });

      state.isValid = validate(state);
    },
  },
});

export const { reducer } = slice;
export const { close, open, openToEdit, selectRecurrenceType, changeRRule, changeCustomRRule } =
  slice.actions;

function getRRuleWeekdayByDate(date: Date): Weekday {
  switch (getDay(date)) {
    case 0:
      return RRule.SU;
    case 1:
      return RRule.MO;
    case 2:
      return RRule.TU;
    case 3:
      return RRule.WE;
    case 4:
      return RRule.TH;
    case 5:
      return RRule.FR;
    case 6:
      return RRule.SA;
  }
}

function validate(state: RecurrenceDialogState): boolean {
  let result;

  if (state.selectedType === SEASONAL_TYPE) {
    if (
      state.rRule.options.bymonth.length === 0 ||
      state.customRRule.options.bymonth.length === 0
    ) {
      result = false;
    } else if (state.rRule.toText() === state.customRRule.toText()) {
      result = false;
    } else {
      result = true;
    }
  } else {
    result = true;
  }
  return result;
}
