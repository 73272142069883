import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ChemicalGroup, ObservationGroup, ObservationCategory } from 'src/types/chemical';
import { baseQueryWithInterceptor } from './base-query';
import { IPaginatedResponse } from './model/base.model';
import {
  IAddObservationGroup,
  IAddСhemicalGroup,
  IDeleteСhemicalGroup,
  IGetGhemicalGroup,
  IEditСhemicalGroup,
  IEditObservationGroup,
  IDeleteObservationGroup,
  IGetObservationGroups,
  IGetChemicalGroups,
} from './model/dosage-group.model';

export const dosageGroupApi = createApi({
  reducerPath: 'dosageGroup',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['ChemicalGroups', 'ChemicalGroup', 'ObservationGroups', 'ObservationGroup'],
  endpoints: (builder) => ({
    getPaginatedChemicalGroups: builder.query<any, IGetChemicalGroups>({
      query: (param: IGetChemicalGroups) =>
        `v2/organisation/${param.organisationId}/chemical-group?filter=${param.filter}&limit=${
          param.limit
        }&order=${param.sortBy}&page=${param.page + 1}`,
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'ChemicalGroups' as const, id })),
              { type: 'ChemicalGroups', id: 'LIST' },
            ]
          : [{ type: 'ChemicalGroups', id: 'LIST' }],
    }),
    getChemicalGroups: builder.query<any, IGetChemicalGroups>({
      query: (param: IGetChemicalGroups) =>
        `v2/organisation/${param.organisationId}/chemical-group?filter=${param.filter}&limit=${param.limit}&order=${param.sortBy}`,
      transformResponse(response: IPaginatedResponse) {
        return response?.data;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'ChemicalGroups' as const, id })),
              { type: 'ChemicalGroups', id: 'LIST' },
            ]
          : [{ type: 'ChemicalGroups', id: 'LIST' }],
    }),
    addChemicalGroup: builder.mutation<any, IAddСhemicalGroup>({
      query: (param: IAddСhemicalGroup) => ({
        url: `v2/organisation/${param.organisationId}/chemical-group`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'ChemicalGroups', id: 'LIST' }],
    }),
    addObservationGroup: builder.mutation<any, IAddObservationGroup>({
      query: (param: IAddObservationGroup) => ({
        url: `v2/organisation/${param.organisationId}/observation-group`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'ObservationGroups', id: 'LIST' }],
    }),
    getChemicalGroup: builder.query<ChemicalGroup, IGetGhemicalGroup>({
      query: (param: IGetGhemicalGroup) =>
        `v2/organisation/${param.organisationId}/chemical-group/${param.id}`,
      transformResponse(response: ChemicalGroup) {
        // @ts-ignore
        const productSkuIds = response.product_sku_ids ? response.product_sku_ids.split(',') : [];
        return {
          ...response,
          product_sku_ids: productSkuIds,
          side_effect_test_id: response?.side_effect?.chemical_test_id || undefined,
          side_effect_increase: response?.side_effect?.effect_increase || 0,
          side_effect_metric_id: response?.side_effect?.product_metric_id || undefined,
          side_effect_amount: response?.side_effect?.amount || null,
          side_effect_volume: response?.side_effect?.volume || null,
          side_effect_by: response?.side_effect?.by || null,
          side_effect_by_units: response?.side_effect?.by_units || null,
        };
      },
      providesTags: (result, error, param) => [{ type: 'ChemicalGroup', id: param.id }],
    }),
    editChemicalGroup: builder.mutation<any, IEditСhemicalGroup>({
      query: (param: IEditСhemicalGroup) => ({
        url: `v2/organisation/${param.organisationId}/chemical-group/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          dosageGroupApi.util.updateQueryData(
            'getChemicalGroup',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'ChemicalGroup', id: param.id },
        { type: 'ChemicalGroups', id: param.id },
      ],
    }),
    deleteChemicalGroups: builder.mutation<any, IDeleteСhemicalGroup[]>({
      async queryFn(params, _queryApi, _extraOptions, baseQuery) {
        const promises = params.map((param) =>
          baseQuery({
            url: `v2/organisation/${param.organisationId}/chemical-group/${param.id}`,
            method: 'delete',
          })
        );
        return { data: await Promise.all(promises) };
      },
      invalidatesTags: (result, error, params) => [
        ...params.map((param) => ({ type: 'ChemicalGroup' as const, id: param.id })),
        ...params.map((param) => ({ type: 'ChemicalGroups' as const, id: param.id })),
      ],
    }),
    getPaginatedObservationGroups: builder.query<any, IGetObservationGroups>({
      query: (param: IGetObservationGroups) =>
        `v2/organisation/${param.organisationId}/observation-group?filter=${param.filter}&limit=${
          param.limit
        }&order=${param.sortBy}&page=${param.page + 1}`,
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'ObservationGroups' as const, id })),
              { type: 'ObservationGroups', id: 'LIST' },
            ]
          : [{ type: 'ObservationGroups', id: 'LIST' }],
    }),
    getObservationGroups: builder.query<any, IGetObservationGroups>({
      query: (param: IGetObservationGroups) =>
        `v2/organisation/${param.organisationId}/observation-group?filter=${param.filter}&limit=${param.limit}&order=${param.sortBy}`,
      transformResponse(response: IPaginatedResponse) {
        return response?.data;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'ObservationGroups' as const, id })),
              { type: 'ObservationGroups', id: 'LIST' },
            ]
          : [{ type: 'ObservationGroups', id: 'LIST' }],
    }),
    getObservationGroup: builder.query<ObservationGroup, IGetGhemicalGroup>({
      query: (param: IGetGhemicalGroup) =>
        `v2/organisation/${param.organisationId}/observation-group/${param.id}`,
      transformResponse(response: ObservationGroup) {
        // @ts-ignore
        const productSkuIds = response.product_sku_ids ? response.product_sku_ids.split(',') : [];
        return {
          ...response,
          product_sku_ids: productSkuIds,
        };
      },
      providesTags: (result, error, param) => [{ type: 'ObservationGroup', id: param.id }],
    }),
    editObservationGroup: builder.mutation<any, IEditObservationGroup>({
      query: (param: IEditObservationGroup) => ({
        url: `v2/organisation/${param.organisationId}/observation-group/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          dosageGroupApi.util.updateQueryData(
            'getObservationGroup',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'ObservationGroup', id: param.id },
        { type: 'ObservationGroups', id: param.id },
      ],
    }),
    deleteObservationGroups: builder.mutation<any, IDeleteObservationGroup[]>({
      async queryFn(params, _queryApi, _extraOptions, baseQuery) {
        const promises = params.map((param) =>
          baseQuery({
            url: `v2/organisation/${param.organisationId}/observation-group/${param.id}`,
            method: 'delete',
          })
        );
        return { data: await Promise.all(promises) };
      },
      invalidatesTags: (result, error, params) => [
        ...params.map((param) => ({ type: 'ObservationGroup' as const, id: param.id })),
        ...params.map((param) => ({ type: 'ObservationGroups' as const, id: param.id })),
      ],
    }),
  }),
});
export const {
  useAddChemicalGroupMutation,
  useAddObservationGroupMutation,
  useGetChemicalGroupQuery,
  useGetChemicalGroupsQuery,
  useGetPaginatedChemicalGroupsQuery,
  useEditChemicalGroupMutation,
  useDeleteChemicalGroupsMutation,
  useEditObservationGroupMutation,
  useDeleteObservationGroupsMutation,
  useGetObservationGroupQuery,
  useGetObservationGroupsQuery,
  useGetPaginatedObservationGroupsQuery,
} = dosageGroupApi;
