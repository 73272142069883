import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import type { Job, Invoice } from '../types/job';
import type { InvoiceRecipient } from '../types/invoice';
import { Contact } from 'src/types/contact';
import { Organisation } from 'src/types/organisation';
import { InvoiceSettings } from 'src/types/invoice-settings';
import { getIntroMessage, getTotal } from 'src/utils/invoice';
import { find } from 'lodash';
import { JOB_SHEET_FIELDS } from 'src/constants/invoice';

interface InvoiceCreateState {
  job: Job | null;
  invoiceSettings: InvoiceSettings;
  recipients: InvoiceRecipient[];
  issuedDate: Date;
  dateOfVisit: Date;
  dueDate: Date;
  ccEmails: string[];
  isLoading: boolean;
  configureRecipients: number[];
  useCustomInvoiceId: boolean;
  customInvoiceId: number;
}

const initialState: InvoiceCreateState = {
  job: null,
  invoiceSettings: null,
  recipients: [],
  issuedDate: null,
  dateOfVisit: null,
  dueDate: null,
  ccEmails: [],
  isLoading: false,
  configureRecipients: [],
  useCustomInvoiceId: false,
  customInvoiceId: null,
};

const slice = createSlice({
  name: 'invoiceCreate',
  initialState,
  reducers: {
    init() {
      const now = new Date();
      return {
        ...initialState,
        isLoading: true,
        issuedDate: now,
        dateOfVisit: now,
        dueDate: now,
      };
    },
    setJob(
      state: InvoiceCreateState,
      action: PayloadAction<{ job: Job; invoiceSettings: InvoiceSettings }>
    ): void {
      const { job, invoiceSettings } = action.payload;

      state.job = job;

      state.ccEmails =
        (job.contact?.cc_emails &&
          job.contact?.cc_emails.replace(/(?:\r\n|\r|\n)/g, '').split(',')) ||
        [];
      state.recipients = [];
      state.invoiceSettings = invoiceSettings;
      state.isLoading = false;
    },
    setCCEmails(
      state: InvoiceCreateState,
      action: PayloadAction<{
        ccEmails: string[];
      }>
    ) {
      state.ccEmails = action.payload.ccEmails;
    },
    updateSubjectMessage(
      state: InvoiceCreateState,
      action: PayloadAction<{
        contactId: number;
        subjectMessage: string;
      }>
    ) {
      const { contactId, subjectMessage } = action.payload;
      const recipient = find(state.recipients, { contact_id: contactId });
      recipient.subject_message = subjectMessage;
    },
    updateIntroMessage(
      state: InvoiceCreateState,
      action: PayloadAction<{
        contactId: number;
        introMessage: string;
      }>
    ) {
      const { contactId, introMessage } = action.payload;
      const recipient = find(state.recipients, { contact_id: contactId });
      recipient.contact_intro = introMessage;
    },
    updateRecipientField(
      state: InvoiceCreateState,
      action: PayloadAction<{
        contactId: number;
        fieldName: string;
        value: boolean;
      }>
    ) {
      const { contactId, fieldName, value } = action.payload;
      const recipient = find(state.recipients, { contact_id: contactId });
      recipient[fieldName] = value;
    },
    hideJobSheet(
      state: InvoiceCreateState,
      action: PayloadAction<{
        contactId: number;
      }>
    ) {
      const { contactId } = action.payload;
      const recipient = find(state.recipients, { contact_id: contactId });
      JOB_SHEET_FIELDS.forEach((key) => {
        recipient[key] = false;
      });
    },
    showJobSheet(
      state: InvoiceCreateState,
      action: PayloadAction<{
        contactId: number;
      }>
    ) {
      const { contactId } = action.payload;
      const recipient = find(state.recipients, { contact_id: contactId });
      JOB_SHEET_FIELDS.forEach((key) => {
        recipient[key] = true;
      });
    },

    addRecipient(
      state: InvoiceCreateState,
      action: PayloadAction<{
        contact: Contact;
        introMessage: string;
      }>
    ): void {
      const { contact, introMessage } = action.payload;
      const exists = state.recipients.find(
        (_recipient: InvoiceRecipient) => _recipient.contact_id === contact.id
      );

      if (!exists) {
        state.recipients.push({
          contact_id: contact.id,
          job_id: state.job.parent?.id || state.job.id,
          contact,
          subject_message: state.invoiceSettings.subject_message,
          contact_intro: introMessage,
          email: contact.email,
          products: state.recipients.length === 0 ? state.job.invoices : [],
          display_job_photos: state.invoiceSettings.show_job_photos,
          display_invoice: state.invoiceSettings.show_invoice,
          display_tasks: state.invoiceSettings.show_tasks_list,
          display_water_tests: state.invoiceSettings.show_water_test_results,
          display_chemical_history: state.invoiceSettings.show_chemical_history,
          display_chemical_actions: state.invoiceSettings.show_chemical_actions,
          display_invoice_notes: state.invoiceSettings.show_invoice_notes,
          number: state.recipients.length + 1,
        });
      }
    },
    changeInvoiceRecipient(
      state: InvoiceCreateState,
      action: PayloadAction<{ invoice: Invoice; recipient: InvoiceRecipient }>
    ): void {
      const { invoice, recipient } = action.payload;
      state.recipients = state.recipients.map((_recipient: InvoiceRecipient) => {
        if (recipient.contact_id === _recipient.contact_id) {
          _recipient.products.push(invoice);
        } else if (_recipient.products.find((product) => product.id === invoice.id)) {
          _recipient.products = _recipient.products.filter((product) => product.id !== invoice.id);
        }

        return _recipient;
      });
    },
    deleteRecipient(state: InvoiceCreateState, action: PayloadAction<{ contactId: number }>): void {
      const { contactId } = action.payload;

      const deleteRecipient = state.recipients.find(
        (recipient) => recipient.contact_id === contactId
      );
      const recipients = state.recipients.filter((recipient) => recipient.contact_id !== contactId);

      state.recipients = recipients.map((recipient, index) => {
        recipient.number = index + 1;

        if (deleteRecipient.products.length && index === 0) {
          deleteRecipient.products.forEach((product) => {
            recipient.products.push(product);
          });
        }

        return recipient;
      });
    },
    setConfigure(
      state: InvoiceCreateState,
      action: PayloadAction<{ contactId: number; configure: boolean }>
    ) {
      const { contactId, configure } = action.payload;
      if (configure) {
        state.configureRecipients.push(contactId);
      } else {
        state.configureRecipients = state.configureRecipients.filter((id) => id !== contactId);
        const recipient = find(state.recipients, { contact_id: contactId });
        recipient.display_invoice = state.invoiceSettings.show_invoice;
        recipient.display_tasks = state.invoiceSettings.show_tasks_list;
        recipient.display_water_tests = state.invoiceSettings.show_water_test_results;
        recipient.display_chemical_history = state.invoiceSettings.show_chemical_history;
        recipient.display_chemical_actions = state.invoiceSettings.show_chemical_actions;
        recipient.display_invoice_notes = state.invoiceSettings.show_invoice_notes;
      }
    },

    setIssuedDate(state: InvoiceCreateState, action: PayloadAction<{ date: Date }>) {
      const { date } = action.payload;
      state.issuedDate = date;
    },
    setDateOfVisit(state: InvoiceCreateState, action: PayloadAction<{ date: Date }>) {
      const { date } = action.payload;
      state.dateOfVisit = date;
    },
    setDueDate(state: InvoiceCreateState, action: PayloadAction<{ date: Date }>) {
      const { date } = action.payload;
      state.dueDate = date;
    },
    setUseCustomInvoiceId(
      state: InvoiceCreateState,
      action: PayloadAction<{ useCustomInvoiceId: boolean }>
    ) {
      state.useCustomInvoiceId = action.payload.useCustomInvoiceId;
    },
    setCustomInvoiceId(state: InvoiceCreateState, action: PayloadAction<{ invoiceId: number }>) {
      state.customInvoiceId = action.payload.invoiceId;
    },
  },
});

export const { reducer } = slice;
export const {
  init,

  setCCEmails,
  addRecipient,
  updateSubjectMessage,
  updateIntroMessage,
  updateRecipientField,
  hideJobSheet,
  showJobSheet,
  setConfigure,

  setIssuedDate,
  setDateOfVisit,
  setDueDate,
  setUseCustomInvoiceId,
  setCustomInvoiceId,
} = slice.actions;

export const setJob =
  (job: Job, organisation: Organisation, invoiceSettings: InvoiceSettings): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.setJob({ job, invoiceSettings }));
    const { contact } = job;
    dispatch(
      slice.actions.addRecipient({
        contact,
        introMessage: getIntroMessage({
          organisation,
          job,
          contact,
          invoiceSettings,
          totalCost: getTotal(job.invoices),
        }),
      })
    );
  };

export const deleteRecipient =
  (contactId: number): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.deleteRecipient({ contactId }));
  };

export const changeInvoiceRecipient =
  (invoice: Invoice, recipient: InvoiceRecipient): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.changeInvoiceRecipient({ invoice, recipient }));
  };

export default slice;
