import { TFunction } from 'i18next';
import { SelectOption } from 'src/types/common';

export const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const MONTH_NAMES = (t: TFunction) => [
  t('January'),
  t('February'),
  t('March'),
  t('April'),
  t('May'),
  t('June'),
  t('July'),
  t('August'),
  t('September'),
  t('October'),
  t('November'),
  t('December'),
];

export const MONTHS_ARRAY = (t: TFunction): SelectOption<number>[] => [
  {
    label: t('Jan'),
    value: 1,
  },
  {
    label: t('Feb'),
    value: 2,
  },
  {
    label: t('Mar'),
    value: 3,
  },
  {
    label: t('Apr'),
    value: 4,
  },
  {
    label: t('May'),
    value: 5,
  },
  {
    label: t('Jun'),
    value: 6,
  },
  {
    label: t('Jul'),
    value: 7,
  },
  {
    label: t('Aug'),
    value: 8,
  },
  {
    label: t('Sep'),
    value: 9,
  },
  {
    label: t('Oct'),
    value: 10,
  },
  {
    label: t('Nov'),
    value: 11,
  },
  {
    label: t('Dec'),
    value: 12,
  },
];

export const EMPTY_DATE_STRING = '0000-00-00 00:00:00';
