import { baseQueryWithInterceptor } from './base-query';
import { IAddTag, IDeleteTag, IEditTag, IGetTags } from './model/tag.model';
import each from 'lodash/each';
import { rootSplitApi } from './root';
import { Tag } from 'src/types/tag';
import { providesList } from 'src/utils/rtk';
import { Meta } from 'src/types/pagination';

export const tagApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query<{ data: Tag[]; meta: Meta }, IGetTags>({
      query: (param: IGetTags) =>
        `v2/organisation/${param.organisationId}/tag?type=${param.type}&filter=${
          param.filter
        }&limit=${param.limit}&order=${param.sortBy}&page=${param.page + 1}`,
      providesTags: (result, error, params) => [
        ...providesList(result?.data, 'Tags'),
        { id: `LIST_TYPE_${params.type}`, type: 'Tags' },
      ],
    }),
    deleteTag: builder.mutation<any, IDeleteTag>({
      query: (param: IDeleteTag) => ({
        url: `v2/organisation/${param.organisationId}/tag/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Tag', id: param.id },
        { type: 'Tags', id: param.id },
      ],
    }),
    editTag: builder.mutation<any, IEditTag>({
      query: (param: IEditTag) => ({
        url: `v2/organisation/${param.organisationId}/tag/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = tagApi.util.selectInvalidatedBy(getState(), [
          { type: 'Tags', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getTags' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                tagApi.util.updateQueryData('getTags', originalArgs, (tags) => {
                  tags.data.forEach((tag) => {
                    if (tag.id === param.id) {
                      Object.assign(tag, param.body);
                    }
                  });
                })
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Tag', id: param.id },
        { type: 'Tags', id: param.id },
        { type: 'Job' },
      ],
    }),
    addTag: builder.mutation<any, IAddTag>({
      query: (param: IAddTag) => ({
        url: `v2/organisation/${param.organisationId}/tag`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'Tags', id: 'LIST' }],
    }),
  }),
});
export const { useGetTagsQuery, useDeleteTagMutation, useEditTagMutation, useAddTagMutation } =
  tagApi;
