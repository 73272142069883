import parse from 'date-fns/parse';
import get from 'lodash/get';
import { INVOICE_MERGE_TAGS } from 'src/constants/invoice';
import { Contact } from 'src/types/contact';
import { InvoiceSettings } from 'src/types/invoice-settings';
import { Organisation } from 'src/types/organisation';
import type { Invoice, Job } from '../types/job';
import type { Product } from '../types/product';
import { getEntityAddress } from './address';
import replaceMessageVariables from './replaceMessageVariables';

export const getSubTotal = (items: Invoice[] | Product[], gst: number) => {
  const total = items.map((i) => i.gst_cost).reduce((a, b) => a + b, 0);

  return Math.round((total / (1 + gst)) * 100) / 100;
};

export const getGst = (items: Invoice[] | Product[], gst: number) => {
  const total = items.map((i) => i.gst_cost).reduce((a, b) => a + b, 0);

  return Math.round(total * (gst / (1 + gst)) * 100) / 100;
};

export const getTotal = (items: Invoice[] | Product[]) =>
  items.map((i) => i.gst_cost).reduce((a, b) => a + b, 0);

export const getGstByTotalSum = (total: number, gst: number) =>
  Math.round(total * (gst / (1 + gst)) * 100) / 100;

export const getIntroMessage = ({
  organisation,
  job,
  contact,
  invoiceSettings,
  totalCost,
}: {
  organisation: Organisation;
  job: Job;
  contact: Contact;
  invoiceSettings: InvoiceSettings;
  totalCost: number;
}) => {
  const invoiceVariables = {
    [INVOICE_MERGE_TAGS.ORG_NAME]: get(organisation, 'name', ''),
    [INVOICE_MERGE_TAGS.ORG_ADDRESS]: getEntityAddress(organisation, 'company'),
    [INVOICE_MERGE_TAGS.ORG_EMAIL]: get(
      organisation,
      'contact_email',
      get(organisation, 'email', '')
    ),
    [INVOICE_MERGE_TAGS.ORG_PHONE]: get(organisation, 'company_phone', ''),
    [INVOICE_MERGE_TAGS.ORG_WEBSITE]:
      get(organisation, 'url', '') === null ? '' : get(organisation, 'url', ''),
    [INVOICE_MERGE_TAGS.TECH_FIRST_NAME]: get(job, 'user.first_name', ''),
    [INVOICE_MERGE_TAGS.TECH_LAST_NAME]: get(job, 'user.last_name', ''),
    [INVOICE_MERGE_TAGS.CONTACT_FIRST_NAME]: get(contact, 'first_name', ''),
    [INVOICE_MERGE_TAGS.CONTACT_LAST_NAME]: get(contact, 'last_name', ''),
    [INVOICE_MERGE_TAGS.CONTACT_ADDRESS]: getEntityAddress(contact, 'contact'),
    [INVOICE_MERGE_TAGS.JOB_TEMPLATE]: get(job, 'job_template.name', ''),
    [INVOICE_MERGE_TAGS.JOB_POOL_TYPE]: get(job, 'pool.pool_type.name', ''),
    [INVOICE_MERGE_TAGS.JOB_POOL_ADDRESS]: getEntityAddress(job.pool, 'pool'),
    [INVOICE_MERGE_TAGS.TOTAL_COST]: totalCost,
    [INVOICE_MERGE_TAGS.DATE_OF_VISIT]: parse(job.start_time, 'dd MMMM YYYY', new Date()),
  };

  return replaceMessageVariables(invoiceSettings.intro_message, invoiceVariables);
};
