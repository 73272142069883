import { createContext, useCallback, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'src/store';
import {
  initialise,
  initialState,
  saveCurrentEnvironment,
  saveThemeSettings,
  selectEnvironment,
  selectEnvironmentConfig,
  selectIsInitialised,
  selectLocale,
  selectOpenDrawer,
  selectThemeSettings,
  selectUnits,
  toggleDrawer,
} from 'src/slices/settings';
import { firestore } from 'src/lib/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { EnvironmentConfig } from 'src/types/config';
import { environmentConfig } from 'src/config';
import { METRIC_UNIT } from 'src/constants/chemical-calculator';
import { ThemeSettings } from 'src/types/settings';
import { Language } from 'src/locales/localeType';

export interface SettingsContextValue {
  isInitialised: boolean;
  openDrawer: boolean;
  settings: ThemeSettings;
  environmentConfig: EnvironmentConfig | null;
  units: string;
  locale: Language;
  saveEnvironment: (environment: string) => void;
  saveSettings: (update: ThemeSettings) => void;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
}

interface SettingsProviderProps {
  children?: ReactNode;
}

export const SettingsContext = createContext<SettingsContextValue>({
  isInitialised: false,
  openDrawer: false,
  settings: initialState.themeSettings,
  environmentConfig: null,
  units: METRIC_UNIT,
  locale: 'en',
  saveEnvironment: () => {},
  saveSettings: () => {},
  handleDrawerClose: () => {},
  handleDrawerOpen: () => {},
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;
  const settings = useSelector(selectThemeSettings);
  const isInitialised = useSelector(selectIsInitialised);
  const environment = useSelector(selectEnvironment);
  const environmentConfig = useSelector(selectEnvironmentConfig);
  const units = useSelector(selectUnits);
  const locale = useSelector(selectLocale);
  const openDrawer = useSelector(selectOpenDrawer);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const db = firestore();
        const querySnapshot = await getDocs(collection(db, 'environments'));
        const environmentConfigs = [];
        querySnapshot.forEach((doc) => {
          environmentConfigs.push(doc.data() as EnvironmentConfig);
        });
        // TODO: set environment based on the subdomain
        dispatch(initialise({ environment, environmentConfigs }));
      } catch (err) {
        console.error(err);
        dispatch(initialise({ environment, environmentConfigs: [environmentConfig] }));
      }
    };

    initialize();
  }, []);

  const saveSettings = (updatedSettings: ThemeSettings): void => {
    dispatch(saveThemeSettings(updatedSettings));
  };

  const saveEnvironment = (environment: string): void => {
    dispatch(saveCurrentEnvironment(environment));
  };

  const handleDrawerOpen = useCallback(() => {
    dispatch(toggleDrawer(true));
  }, []);

  const handleDrawerClose = useCallback(() => {
    dispatch(toggleDrawer(false));
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        isInitialised,
        settings,
        saveSettings,
        saveEnvironment,
        openDrawer,
        handleDrawerClose,
        handleDrawerOpen,
        environmentConfig,
        units,
        locale,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;
