export const JOB_OPEN = 1;
export const JOB_WIP = 2;
export const JOB_COMPLETE = 3;
export const QUOTE_SCHEDULED = 4;
export const QUOTE_SAVED = 5;
export const QUOTE_APPROVED = 6;
export const JOB_DONE = 7;
export const QUOTE_REJECTED = 8;
export const INVOICE_REJECTED = 9;
export const INVOICE_PAID = 10;
export const INVOICE_SENT = 11;
export const ENTERED_IN_ACC = 12;
export const UNSCHEDULED = 13;
export const JOB_SHEET_SENT = 14;
export const INVOICE_CREATED = 15;

export const TASK_DONE = 2;
export const TASK_UNDONE = 1;

export const ACTIVE_JOBS = [
  JOB_OPEN,
  JOB_WIP,
  JOB_COMPLETE,
  JOB_DONE,
  INVOICE_PAID,
  INVOICE_SENT,
  ENTERED_IN_ACC,
  UNSCHEDULED,
  JOB_SHEET_SENT,
];

export const BOOKING_MERGE_TAGS = {
  JOB_TEMPLATE: '{job_template}',
  JOB_SCHEDULED_DATE: '{job_scheduled_date}',
  ORG_NAME: '{org_name}',
  ORG_PHONE: '{org_phone}',
  JOB_POOL_ADDRESS: '{job_pool_address}',
};
