import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import type {
  Organisation,
  Color,
  StripeConnection,
  LabSettings,
  Billing,
} from 'src/types/organisation';
import type { InvoiceSettings } from '../types/invoice-settings';
import type { TradingTerm } from '../types/contact';
import type { Image } from '../types/image';
import type { BatchSettings, QuoteSettings } from '../types/invoice';
import { organisationApi } from 'src/api/organisation';
import { authApi } from 'src/api/auth';
import { invoiceApi } from 'src/api/invoice';
import { invoiceApi as batchInvoiceAPi } from 'src/api/batch-invoice';
import { quoteApi } from 'src/api/quote';
import find from 'lodash/find';
import { Subscription } from 'src/types/subscription';
import { subscriptionApi } from 'src/api/subscription';
import { colorApi } from 'src/api/color';

interface AccountState {
  organisation?: Organisation;
  subscription?: Subscription;
  billing?: Billing;
  gst: number;
  stripeConnection?: StripeConnection;
  logo?: Image;
  labSettings?: LabSettings;
  invoiceSettings?: InvoiceSettings;
  batchSettings?: BatchSettings;
  quoteSettings?: QuoteSettings;
  tradingTerms?: TradingTerm[];
  defaultTradingTerm: TradingTerm | null;
  colors?: Color[];
  isStripeConnected: boolean;
  isVendConnected: boolean;
  isXeroConnected: boolean;
}

const initialState: AccountState = {
  organisation: null,
  subscription: null,
  billing: null,
  gst: 0.1,
  stripeConnection: null,
  logo: null,
  labSettings: null,
  invoiceSettings: null,
  batchSettings: null,
  quoteSettings: null,
  tradingTerms: [],
  defaultTradingTerm: null,
  colors: [],
  isStripeConnected: false,
  isVendConnected: false,
  isXeroConnected: false,
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateOrganisation(state: AccountState, action: PayloadAction<{ organisation: Organisation }>) {
      const { organisation } = action.payload;

      state.organisation = organisation;
      state.stripeConnection = organisation.stripe_connection;
      state.logo = organisation.logo;
      state.isXeroConnected = organisation.is_xero_enabled;
      state.isVendConnected = organisation.is_vend_enabled;
      state.isStripeConnected = organisation.is_stripe_connected;
    },
    updateOrganisationLogo(state: AccountState, action: PayloadAction<{ logo: Image }>) {
      const { logo } = action.payload;
      state.logo = logo;
    },
    createTradingTerm(
      state: AccountState,
      action: PayloadAction<{ tradingTerm: TradingTerm }>
    ): void {
      const { tradingTerm } = action.payload;

      state.tradingTerms.push(tradingTerm);
    },
    updateTradingTerm(
      state: AccountState,
      action: PayloadAction<{ tradingTerm: TradingTerm }>
    ): void {
      const { tradingTerm } = action.payload;

      state.tradingTerms = state.tradingTerms.map((_tradingTerm) => {
        if (_tradingTerm.id === tradingTerm.id) {
          return tradingTerm;
        }

        return _tradingTerm;
      });
      state.defaultTradingTerm = find(state.tradingTerms, { is_default: true });
    },
    deleteTradingTerm(state: AccountState, action: PayloadAction<{ tradingTermId: number }>): void {
      const { tradingTermId } = action.payload;

      state.tradingTerms = state.tradingTerms.filter(
        (tradingTerm) => tradingTerm.id !== tradingTermId
      );
      state.defaultTradingTerm = find(state.tradingTerms, { is_default: true });
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        const { organisation } = action.payload;
        state.organisation = organisation;
        // TODO: we need to elaborate a dynamic taxation in case of moving to the US
        state.gst = organisation.company_address_country === 'NZ' ? 0.15 : 0.1;
        state.stripeConnection = organisation.stripe_connection;
        state.logo = organisation.logo;
        state.isXeroConnected = organisation.is_xero_enabled;
        state.isVendConnected = organisation.is_vend_enabled;
        state.isStripeConnected = organisation.is_stripe_connected;
      })
      .addMatcher(organisationApi.endpoints.getOrganisation.matchFulfilled, (state, action) => {
        const organisation = action.payload;

        state.organisation = organisation;
        // TODO: we need to elaborate a dynamic taxation in case of moving to the US
        state.gst = organisation.company_address_country === 'NZ' ? 0.15 : 0.1;
        state.stripeConnection = organisation.stripe_connection;
        state.logo = organisation.logo;
        state.isXeroConnected = organisation.is_xero_enabled;
        state.isVendConnected = organisation.is_vend_enabled;
        state.isStripeConnected = organisation.is_stripe_connected;
      })
      .addMatcher(organisationApi.endpoints.updateOrganisation.matchFulfilled, (state, action) => {
        const organisation = action.payload;

        state.organisation = organisation;
        // TODO: we need to elaborate a dynamic taxation in case of moving to the US
        state.gst = organisation.company_address_country === 'NZ' ? 0.15 : 0.1;
        state.stripeConnection = organisation.stripe_connection;
        state.logo = organisation.logo;
        state.isXeroConnected = organisation.is_xero_enabled;
        state.isVendConnected = organisation.is_vend_enabled;
        state.isStripeConnected = organisation.is_stripe_connected;
      })
      .addMatcher(colorApi.endpoints.getColors.matchFulfilled, (state, action) => {
        state.colors = action.payload;
      })
      .addMatcher(
        organisationApi.endpoints.updateOrganisationLogo.matchFulfilled,
        (state, action) => {
          const { logo } = action.payload;
          state.logo = logo;
        }
      )
      .addMatcher(organisationApi.endpoints.getLabSettings.matchFulfilled, (state, action) => {
        state.labSettings = action.payload;
      })
      .addMatcher(organisationApi.endpoints.updateLabSettings.matchFulfilled, (state, action) => {
        state.labSettings = action.payload;
      })
      .addMatcher(organisationApi.endpoints.getBillingDetails.matchFulfilled, (state, action) => {
        state.billing = action.payload;
      })
      .addMatcher(organisationApi.endpoints.storeCard.matchFulfilled, (state, action) => {
        state.billing = action.payload;
      })
      .addMatcher(organisationApi.endpoints.deleteCard.matchFulfilled, (state, action) => {
        state.billing = action.payload;
      })
      .addMatcher(
        organisationApi.endpoints.updateBillingDetails.matchFulfilled,
        (state, action) => {
          state.billing = action.payload;
        }
      )
      .addMatcher(organisationApi.endpoints.getTradingTerms.matchFulfilled, (state, action) => {
        state.tradingTerms = action.payload;
        state.defaultTradingTerm = find(state.tradingTerms, { is_default: true });
      })
      .addMatcher(subscriptionApi.endpoints.getSubscription.matchFulfilled, (state, action) => {
        state.subscription = action.payload;
      })
      .addMatcher(subscriptionApi.endpoints.updateSubscription.matchFulfilled, (state, action) => {
        state.subscription = action.payload;
      })
      .addMatcher(subscriptionApi.endpoints.unsubscribe.matchFulfilled, (state, action) => {
        state.subscription = action.payload;
      })
      .addMatcher(invoiceApi.endpoints.getInvoiceSettings.matchFulfilled, (state, action) => {
        state.invoiceSettings = action.payload;
      })
      .addMatcher(invoiceApi.endpoints.updateInvoiceSettings.matchFulfilled, (state, action) => {
        state.invoiceSettings = action.payload;
      })
      .addMatcher(quoteApi.endpoints.getQuoteSettings.matchFulfilled, (state, action) => {
        state.quoteSettings = action.payload;
      })
      .addMatcher(batchInvoiceAPi.endpoints.getBatchSettings.matchFulfilled, (state, action) => {
        state.batchSettings = action.payload;
      })
      .addMatcher(authApi.endpoints.logout.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
        return initialState;
      })
      .addMatcher(authApi.endpoints.logout.matchRejected, (state, action) => {
        return initialState;
      });
  },
});

export const { reducer } = slice;

export const createTradingTerm =
  (organisationId: number, data: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post(`v1/organisations/${organisationId}/trading-term`, data);

    dispatch(slice.actions.createTradingTerm({ tradingTerm: response.data }));
  };

export const updateTradingTerm =
  (organisationId: number, tradingTermId: number, data: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.put(
      `v1/organisations/${organisationId}/trading-term/${tradingTermId}`,
      data
    );

    dispatch(slice.actions.updateTradingTerm({ tradingTerm: response.data }));
  };

export const updateDefaultTerm =
  (organisationId: number, tradingTerm: TradingTerm): AppThunk =>
  async (dispatch): Promise<void> => {
    await axios.put(`v1/organisations/${organisationId}/trading-term/${tradingTerm.id}`, {
      name: tradingTerm.name,
      type: tradingTerm.type,
      from_type: tradingTerm.from_type,
      quantity: tradingTerm.quantity,
      is_default: !tradingTerm.is_default,
    });

    // const response = await axios.get(`v1/organisations/${organisationId}/trading-term`);

    // dispatch(slice.actions.getTradingTerms({ tradingTerms: response.data }));
  };

export const deleteTradingTerm =
  (organisationId: number, tradingTermId: number): AppThunk =>
  async (dispatch): Promise<void> => {
    await axios.delete(`v1/organisations/${organisationId}/trading-term/${tradingTermId}`);

    dispatch(slice.actions.deleteTradingTerm({ tradingTermId }));
  };

export const connectStripeAccount =
  (organisationId: number, code: string): AppThunk =>
  async (dispatch) => {
    const response = await axios.post(`v1/organisations/${organisationId}/connect-stripe`, {
      code,
    });
    dispatch(slice.actions.updateOrganisation({ organisation: response.data }));
  };

export const updateStripeConnection =
  (organisationId: number, data: any): AppThunk =>
  async (dispatch) => {
    const response = await axios.post(
      `v1/organisations/${organisationId}/update-stripe-connection`,
      data
    );
    dispatch(slice.actions.updateOrganisation({ organisation: response.data }));
  };

export const disconnectStripeAccount =
  (organisationId: number): AppThunk =>
  async (dispatch) => {
    const response = await axios.post(`v1/organisations/${organisationId}/disconnect-stripe`);
    dispatch(slice.actions.updateOrganisation({ organisation: response.data }));
  };

export default slice;
