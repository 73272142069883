/* eslint-disable no-restricted-properties */
const replaceMessageVariables = (msg: string, boundVariables: any): string => {
  const replacementRegex = new RegExp(
    Object.keys(boundVariables)
      // escape any regex literals found in the replacement keys:
      .map((e) => e.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      .join('|'),
    'g'
  );
  return msg.replace(replacementRegex, (e) => boundVariables[e]);
};

export default replaceMessageVariables;
