import { createSlice } from '@reduxjs/toolkit';
import { RecurrencePeriod } from 'src/types/recurrence-period';
import { recurrencePeriodApi } from 'src/api/recurrence-period';

interface RecurrencePeriodsState {
  periods: RecurrencePeriod[];
}

const initialState: RecurrencePeriodsState = {
  periods: [],
};

const slice = createSlice({
  name: 'recurrencePeriods',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      recurrencePeriodApi.endpoints.getRecurrencePeriods.matchFulfilled,
      (state, action) => {
        state.periods = action.payload;
      }
    );
  },
});

export const { reducer } = slice;

export default slice;
