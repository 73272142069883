import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { User } from 'src/types/user';
import { baseQueryWithInterceptor } from './base-query';
import { LoginRequest, LoginResponse } from './model/base.model';
import {
  IRecoveryPassword,
  IRegisterOrganisation,
  IResetPassword,
} from './model/organisation.model';
import { IGetUser } from './model/user.model';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithInterceptor,
  endpoints: (build) => ({
    login: build.mutation<LoginResponse, any>({
      query: (credentials: LoginRequest) => ({
        url: 'v1/orglogin',
        method: 'POST',
        body: credentials,
      }),
    }),
    register: build.mutation<any, IRegisterOrganisation>({
      query: (param: IRegisterOrganisation) => ({
        url: 'v2/register',
        method: 'POST',
        body: param.body,
      }),
    }),
    recoveryPassword: build.mutation<any, IRecoveryPassword>({
      query: (param: IRecoveryPassword) => ({
        url: 'v1/password/email',
        method: 'POST',
        body: param.body,
      }),
    }),
    resetPassword: build.mutation<any, IResetPassword>({
      query: (param: IResetPassword) => ({
        url: 'v1/password/reset',
        method: 'POST',
        body: param.body,
      }),
    }),
    getAuthenticatedUser: build.query<User, IGetUser>({
      query: (param: IGetUser) => `v2/organisation/${param.organisationId}/user/${param.id}`,
    }),
    refreshToken: build.mutation<any, any>({
      query: () => ({
        url: 'v1/refresh-token',
        method: 'POST',
      }),
    }),
    logout: build.mutation<any, any>({
      query: () => ({
        url: 'v1/logout',
        method: 'POST',
      }),
    }),
  }),
});
export const {
  useLoginMutation,
  useLogoutMutation,
  useRefreshTokenMutation,
  useRegisterMutation,
  useRecoveryPasswordMutation,
  useResetPasswordMutation,
} = authApi;

export const {
  endpoints: {
    login,
    logout,
    refreshToken,
    getAuthenticatedUser,
    register,
    resetPassword,
    recoveryPassword,
  },
} = authApi;
