import { format, parse } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { JOB_TAG_TYPE } from 'src/constants/tag';
import { Job, JobEvent } from 'src/types/job';
import { Meta } from 'src/types/pagination';
import { providesList, providesListByKey } from 'src/utils/rtk';
import {
  IAddJob,
  IDeleteJob,
  IGetCalendarEvents,
  IGetContactJobs,
  IGetJob,
  IGetJobs,
  IGetPoolJobs,
  IUnscheduleJob,
  IUpdateJob,
  IRescheduleJob,
  IAddFollowUpJob,
  IScheduleFromQuote,
} from './model/job.model';
import { rootSplitApi } from './root';

export const jobApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getJob: builder.query<Job, IGetJob>({
      query: (param: IGetJob) => `v2/organisation/${param.organisationId}/job/${param.id}`,
      providesTags: (result, error, param) => [
        { type: 'Job', id: param.id },
        { type: 'Job', id: result?.job_id },
        ...providesList(result?.followup_jobs, 'Job'),
        { type: 'Pool', id: result?.pool_id },
        { type: 'Users', id: result?.user_id },
        { type: 'Color', id: result?.colorcode_id },
        { type: 'Contact', id: result?.contact_id },
        { type: 'JobTemplate', id: result?.job_template_id },
        ...providesList(result?.pool?.contacts, 'Contact'),
        // TODO: add surface types and other custom fields
      ],
    }),
    addJob: builder.mutation<Job, IAddJob>({
      query: (param: IAddJob) => ({
        url: `v2/organisation/${param.organisationId}/job`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => {
        return [
          { type: 'Job', id: 'LIST' },
          // invalidate tags because new tags could be created
          { type: 'Tags', id: `LIST_TYPE_${JOB_TAG_TYPE}` },
          ...(!isEmpty(param.body?.recurrence_logic) && [
            { type: 'Recurrence' as const, id: 'LIST' },
          ]),
        ];
      },
    }),
    addFollowUpJob: builder.mutation<Job, IAddFollowUpJob>({
      query: (param: IAddFollowUpJob) => ({
        url: `v2/organisation/${param.organisationId}/job/${param.jobId}/followup`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [
        { type: 'Job', id: 'LIST' },
        // invalidate tags because new tags could be created
        { type: 'Tags', id: `LIST_TYPE_${JOB_TAG_TYPE}` },
      ],
    }),
    getJobs: builder.query<{ data: Job[]; meta: Meta }, IGetJobs>({
      query: (param: IGetJobs) => ({
        url: `v2/organisation/${param.organisationId}/job-list`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result) => [
        ...providesList(result?.data, 'Job'),
        ...result?.data?.map((job) => providesList(job.followup_jobs, 'Job')).flat(),
        ...providesListByKey(result?.data, 'pool_id', 'Pool').flat(),
        ...providesListByKey(result?.data, 'user_id', 'Users').flat(),
        ...providesListByKey(result?.data, 'job_template_id', 'JobTemplate').flat(),
        ...providesListByKey(result?.data, 'colorcode_id', 'Color').flat(),
        ...providesListByKey(result?.data, 'contact_id', 'Contact').flat(),
        ...result?.data?.map((job) => providesList(job.pool?.contacts, 'Contact')).flat(),
      ],
    }),
    getJobsToInvoice: builder.query<{ data: Job[]; meta: Meta }, IGetJobs>({
      query: (param: IGetJobs) => ({
        url: `v2/organisation/${param.organisationId}/job-list`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result) => [
        ...providesList(result?.data, 'Job'),
        ...result?.data?.map((job) => providesList(job.followup_jobs, 'Job')).flat(),
        ...providesListByKey(result?.data, 'pool_id', 'Pool').flat(),
        ...providesListByKey(result?.data, 'user_id', 'Users').flat(),
        ...providesListByKey(result?.data, 'job_template_id', 'JobTemplate').flat(),
        ...providesListByKey(result?.data, 'colorcode_id', 'Color').flat(),
        ...providesListByKey(result?.data, 'contact_id', 'Contact').flat(),
        ...result?.data?.map((job) => providesList(job.pool?.contacts, 'Contact')).flat(),
      ],
    }),
    getUnscheduledEvents: builder.query<JobEvent[], IGetCalendarEvents>({
      query: (param: IGetJobs) => ({
        url: `v2/organisation/${param.organisationId}/calendar-event`,
        method: 'post',
        body: param.body,
      }),
      providesTags: (result) => [
        ...providesList(result, 'Job'),
        ...providesListByKey(result, 'pool_id', 'Pool').flat(),
        ...providesListByKey(result, 'user_id', 'Users').flat(),
        ...providesListByKey(result, 'job_template_id', 'JobTemplate').flat(),
        ...providesListByKey(result, 'colorcode_id', 'Color').flat(),
        ...providesListByKey(result, 'contact_id', 'Contact').flat(),
      ],
    }),
    getCalendarEvents: builder.query<JobEvent[], IGetCalendarEvents>({
      query: (param: IGetCalendarEvents) => ({
        url: `v2/organisation/${param.organisationId}/calendar-event`,
        method: 'post',
        body: param.body,
      }),
      transformResponse(response: JobEvent[]) {
        return response?.map((_event: JobEvent) => ({
          ..._event,
          // @ts-ignore
          start_time: parse(_event.start_time as string, 'yyyy-MM-dd HH:mm:ss', new Date()),
          // @ts-ignore
          end_time: parse(_event.end_time as string, 'yyyy-MM-dd HH:mm:ss', new Date()),
        }));
      },
      providesTags: (result) => [
        ...providesList(result, 'Job'),
        ...providesListByKey(result, 'pool_id', 'Pool').flat(),
        ...providesListByKey(result, 'user_id', 'Users').flat(),
        ...providesListByKey(result, 'job_template_id', 'JobTemplate').flat(),
        ...providesListByKey(result, 'colorcode_id', 'Color').flat(),
        ...providesListByKey(result, 'contact_id', 'Contact').flat(),
        ...result?.map((job) => providesList(job.pool?.contacts, 'Contact')).flat(),
      ],
    }),
    updateJob: builder.mutation<Job, IUpdateJob>({
      query: (param: IUpdateJob) => ({
        url: `v2/organisation/${param.organisationId}/job/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          jobApi.util.updateQueryData(
            'getJob',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Job', id: param.id },
        { type: 'Job', id: 'LIST' },
        // invalidate tags because new tags could be created
        { type: 'Tags', id: `LIST_TYPE_${JOB_TAG_TYPE}` },
      ],
    }),
    unscheduleJob: builder.mutation<Job, IUnscheduleJob>({
      query: (param: IRescheduleJob) => ({
        url: `v2/organisation/${param.organisationId}/job/${param.id}/reschedule`,
        method: 'put',
        body: {
          start_time: null,
          end_time: null,
          user_id: null,
        },
        params: {
          'reschedule-future-jobs': param.rescheduleFutureJobs,
        },
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Job', id: param.id },
        // invalidate full list of unscheduled jobs
        { type: 'UnscheduledJob', id: 'LIST' },
      ],
    }),
    rescheduleJobs: builder.mutation<number, IRescheduleJob[]>({
      async queryFn(params, _queryApi, _extraOptions, baseQuery) {
        const promises = params.map((param) =>
          baseQuery({
            url: `v2/organisation/${param.organisationId}/job/${param.id}/reschedule`,
            method: 'PUT',
            body: {
              user_id: param.body.user_id,
              start_time:
                param.body.start_time != null
                  ? format(param.body.start_time, 'yyyy-MM-dd HH:mm:ss')
                  : null,
              end_time:
                param.body.end_time != null
                  ? format(param.body.end_time, 'yyyy-MM-dd HH:mm:ss')
                  : null,
            },
            params: {
              'reschedule-future-jobs': param.rescheduleFutureJobs,
            },
          })
        );
        await Promise.all(promises);
        return { data: params.length };
      },
      invalidatesTags: (result, error, params) => {
        return [
          ...params.map((param) => ({ type: 'Job' as const, id: param.id })),
          ...(params.some((param) => param.rescheduleFutureJobs) && [
            { type: 'Job' as const, id: 'LIST' },
          ]),
        ];
      },
    }),
    scheduleFromQuote: builder.mutation<Job, IScheduleFromQuote>({
      query: (param: IScheduleFromQuote) => ({
        url: `v2/organisation/${param.organisationId}/job/${param.id}/schedule-from-quote`,
        method: 'put',
        body: param.body,
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Job', id: param.id },
        { type: 'Quote', id: param.id },
        { type: 'Quote', id: 'LIST' },
      ],
    }),
    getContactJobs: builder.query<{ data: Job[]; meta: Meta }, IGetContactJobs>({
      query: (param: IGetContactJobs) => {
        const { organisationId, contactId, filter, page, sortBy, limit, from, to } = param;
        let url = `v2/organisation/${organisationId}/job?contact_id=${contactId}&limit=${limit}&order=${sortBy}&page=${
          page + 1
        }&from=${from}&to=${to}`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        return url;
      },
      providesTags: (result) => [
        ...providesList(result?.data, 'Job'),
        ...result?.data?.map((job) => providesList(job.followup_jobs, 'Job')).flat(),
        ...providesListByKey(result?.data, 'pool_id', 'Pool').flat(),
        ...providesListByKey(result?.data, 'user_id', 'Users').flat(),
        ...providesListByKey(result?.data, 'job_template_id', 'JobTemplate').flat(),
        ...providesListByKey(result?.data, 'colorcode_id', 'Color').flat(),
        ...providesListByKey(result?.data, 'contact_id', 'Contact').flat(),
        ...result?.data?.map((job) => providesList(job.pool?.contacts, 'Contact')).flat(),
      ],
    }),
    getPoolJobs: builder.query<{ data: Job[]; meta: Meta }, IGetPoolJobs>({
      query: (param: IGetPoolJobs) => {
        const { organisationId, poolId, filter, page, sortBy, limit, from, to } = param;
        let url = `v2/organisation/${organisationId}/job?pool_id=${poolId}&limit=${limit}&order=${sortBy}&page=${
          page + 1
        }&from=${from}&to=${to}`;

        if (filter && filter !== '') {
          url += `&filter=${filter}`;
        }

        return url;
      },
      providesTags: (result) => [
        ...providesList(result?.data, 'Job'),
        ...result?.data?.map((job) => providesList(job.followup_jobs, 'Job')).flat(),
        ...providesListByKey(result?.data, 'pool_id', 'Pool').flat(),
        ...providesListByKey(result?.data, 'user_id', 'Users').flat(),
        ...providesListByKey(result?.data, 'job_template_id', 'JobTemplate').flat(),
        ...providesListByKey(result?.data, 'colorcode_id', 'Color').flat(),
        ...providesListByKey(result?.data, 'contact_id', 'Contact').flat(),
        ...result?.data?.map((job) => providesList(job.pool?.contacts, 'Contact')).flat(),
      ],
    }),
    deleteJob: builder.mutation<any, IDeleteJob>({
      query: (param: IDeleteJob) => ({
        url: `v2/organisation/${param.organisationId}/job/${param.id}?destroy-future-jobs=${param.deleteFutureJobs}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => {
        return [
          { type: 'Job', id: param.id },
          { type: 'Tags', id: `LIST_TYPE_${JOB_TAG_TYPE}` },
          ...(param.deleteFutureJobs && [{ type: 'Job' as const, id: 'LIST' }]),
        ];
      },
    }),
  }),
});
export const {
  useGetContactJobsQuery,
  useGetPoolJobsQuery,
  useGetJobQuery,
  useAddJobMutation,
  useUpdateJobMutation,
  useScheduleFromQuoteMutation,
  useRescheduleJobsMutation,
  useDeleteJobMutation,
  useGetJobsQuery,
  useUnscheduleJobMutation,
  useGetCalendarEventsQuery,
  useGetUnscheduledEventsQuery,
  useGetJobsToInvoiceQuery,
  useAddFollowUpJobMutation,
} = jobApi;

export const {
  endpoints: {
    getJob,
    getContactJobs,
    getPoolJobs,
    addJob,
    updateJob,
    deleteJob,
    getJobs,
    scheduleFromQuote,
    rescheduleJobs,
    unscheduleJob,
    getCalendarEvents,
    getUnscheduledEvents,
    getJobsToInvoice,
    addFollowUpJob,
  },
} = jobApi;
