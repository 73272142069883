import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import { quoteApi } from 'src/api/quote';
import { QuoteStatus } from 'src/types/quote';
import { AppThunk } from 'src/store';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/table';
import { DateRange } from '@mui/x-date-pickers-pro';
import { startOfDay, endOfDay, subMonths } from 'date-fns';

interface OpenedQuoteState {
  searchText?: string;
  quoteStatusId?: string | number | null;
  tagsFilter?: string[];
  limit?: number;
  page?: number;
  total?: number;
  orderBy: string;
  order: SortDirection;
  selectedRange: DateRange<Date>;
  expandedQuote?: number | null;
}

const initialState: OpenedQuoteState = {
  searchText: '',
  quoteStatusId: null,
  tagsFilter: [],
  limit: DEFAULT_ROWS_PER_PAGE,
  page: 0,
  total: 0,
  orderBy: 'created_at',
  order: 'desc',
  selectedRange: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
  expandedQuote: null,
};

const slice = createSlice({
  name: 'openedQuote',
  initialState,
  reducers: {
    setLimit(state: OpenedQuoteState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.limit = limit;
      state.page = 0;
      state.total = 0;
      state.expandedQuote = null;
    },
    setPage(state: OpenedQuoteState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.page = page;
      state.expandedQuote = null;
    },
    setSearchText(state: OpenedQuoteState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
      state.page = 0;
      state.expandedQuote = null;
    },
    setOrder(
      state: OpenedQuoteState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
      state.expandedQuote = null;
    },
    setStatusFilter(state: OpenedQuoteState, action: PayloadAction<{ statusFilter: QuoteStatus }>) {
      const { statusFilter } = action.payload;

      state.quoteStatusId = statusFilter;
      state.expandedQuote = null;
    },
    setTagsFilter(state: OpenedQuoteState, action: PayloadAction<{ tags: string[] }>) {
      const { tags } = action.payload;

      state.tagsFilter = tags;
      state.expandedQuote = null;
    },
    setExpandedRow(state: OpenedQuoteState, action: PayloadAction<{ quoteId: number | null }>) {
      const { quoteId } = action.payload;

      state.expandedQuote = quoteId;
    },
    selectRange(
      state: OpenedQuoteState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedRange = dateRange;
    },
    resetDateRange(state: OpenedQuoteState): void {
      state.selectedRange = initialState.selectedRange;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(quoteApi.endpoints.getOpenQuotes.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(quoteApi.endpoints.getOpenQuotes.matchFulfilled, (state, action) => {
        const { meta } = action.payload;
        state.total = meta.total;
      })
      .addMatcher(quoteApi.endpoints.getOpenQuotes.matchRejected, (state, action) => {
        console.log('rejected', action);
      });
  },
});

export const { reducer } = slice;

export const { resetDateRange } = slice.actions;

export const setLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setLimit({ limit }));
};

export const setPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setPage({ page }));
};

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export const setTagsFilter = (tags: string[]) => (dispatch) => {
  dispatch(slice.actions.setTagsFilter({ tags }));
};

export const setStatusFilter = (statusFilter: QuoteStatus) => (dispatch) => {
  dispatch(slice.actions.setStatusFilter({ statusFilter }));
};

export const setExpandedRow = (quoteId: number | null) => (dispatch) => {
  dispatch(slice.actions.setExpandedRow({ quoteId }));
};

export const selectRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectRange({ dateRange }));
  };

export default slice;
