import { GridFilterModel, GridFilterItem, GridLinkOperator } from '@mui/x-data-grid-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DisplayEventSettings } from 'src/types/calendar';

interface CalendarFilter {
  open: boolean;

  gridFilterModel: GridFilterModel;
  techniciansFilterItem: GridFilterItem;
  statusesFilterItem: GridFilterItem;

  tagsFilter: string[];
  displaySettings: DisplayEventSettings;
}

const initialState: CalendarFilter = {
  open: false,

  gridFilterModel: {
    items: [],
    linkOperator: GridLinkOperator.And,
  },
  techniciansFilterItem: {
    columnField: 'user_id',
    operatorValue: 'isAnyOf',
    value: [],
  },
  statusesFilterItem: {
    columnField: 'job_status_id',
    operatorValue: 'isAnyOf',
    value: [],
  },
  tagsFilter: [],
  displaySettings: {
    showContactFullName: true,
    showPoolAddressStreetOne: true,
    showAddressCity: true,
    showJobTemplateName: true,
  },
};

const slice = createSlice({
  name: 'calendarFilter',
  initialState,
  reducers: {
    open(state: CalendarFilter): void {
      state.open = true;
    },
    close(state: CalendarFilter): void {
      state.open = false;
    },

    resetFilters(state: CalendarFilter) {
      state.techniciansFilterItem.value = [];
      state.statusesFilterItem.value = [];
      state.tagsFilter = [];
      updateFilterModel(state);
    },

    setTechnicians(state: CalendarFilter, action: PayloadAction<{ technicianIds: number[] }>) {
      state.techniciansFilterItem.value = action.payload.technicianIds;
      updateFilterModel(state);
    },
    deleteTechnician(state: CalendarFilter, action: PayloadAction<{ id: number }>) {
      state.techniciansFilterItem.value = state.techniciansFilterItem.value.filter(
        (id) => id !== action.payload.id
      );
      updateFilterModel(state);
    },

    addStatus(state: CalendarFilter, action: PayloadAction<{ id: number }>) {
      state.statusesFilterItem.value = [...state.statusesFilterItem.value, action.payload.id];
      updateFilterModel(state);
    },
    deleteStatus(state: CalendarFilter, action: PayloadAction<{ id: number }>) {
      state.statusesFilterItem.value = state.statusesFilterItem.value.filter(
        (statusId) => statusId !== action.payload.id
      );
      updateFilterModel(state);
    },

    setTagsFilter(state: CalendarFilter, action: PayloadAction<{ tags: string[] }>) {
      const { tags } = action.payload;
      state.tagsFilter = tags;
      updateFilterModel(state);
    },

    updateDisplayEventSettings(
      state: CalendarFilter,
      action: PayloadAction<Partial<DisplayEventSettings>>
    ) {
      state.displaySettings = {
        ...state.displaySettings,
        ...action.payload,
      };
    },
  },
});

function updateFilterModel(state: CalendarFilter) {
  const items = [state.techniciansFilterItem, state.statusesFilterItem].filter(
    ({ value }) => value.length !== 0
  );
  state.gridFilterModel.items = items.length === 0 ? null : items;
}

export const { reducer } = slice;
export const {
  open,
  close,
  resetFilters,
  setTechnicians,
  deleteTechnician,
  addStatus,
  deleteStatus,
  setTagsFilter,
  updateDisplayEventSettings,
} = slice.actions;

export default slice;
