import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { format, startOfDay, endOfDay, subMonths } from 'date-fns';
import merge from 'lodash/merge';
import type { AppThunk } from '../store';
import type { Pool } from '../types/pool';
import type { Contact } from '../types/contact';
import { OWNER } from '../constants/poolContact';
import type { Invoice } from '../types/contact-invoice';
import type { SortDirection } from '@mui/material';
import type { Meta } from '../types/pagination';
import { Quote } from 'src/types/quote';
import { Job } from 'src/types/job';
import { poolApi } from 'src/api/pool';
import { DateRange } from '@mui/x-date-pickers-pro';

interface PoolDetailState {
  poolId?: number;
  pool?: Pool;
  contacts: Contact[];
  selectedHistoryRange: DateRange<Date>;
  isInvoicesLoading: boolean;
  invoices: Invoice[];
  selectedInvoicesRange: DateRange<Date>;
  invoicesLimit?: number;
  invoicesPage?: number;
  invoicesTotal?: number;
  invoicesOrderBy: string;
  invoicesOrder: SortDirection;
  quotes: Quote[];
  searchQuotesText: string;
  selectedQuotesRange: DateRange<Date>;
  quotesLimit?: number;
  quotesPage?: number;
  quotesTotal?: number;
  quotesOrderBy: string;
  quotesOrder: SortDirection;
  jobs: Job[];
  searchJobsText: string;
  selectedJobsRange: DateRange<Date>;
  jobsLimit?: number;
  jobsPage?: number;
  jobsTotal?: number;
  jobsOrderBy: string;
  jobsOrder: SortDirection;

  equipmentIdsDeletingInProgress: number[];
}

const initialState: PoolDetailState = {
  poolId: null,
  pool: null,
  contacts: [],
  selectedHistoryRange: [startOfDay(subMonths(new Date(), 6)), endOfDay(new Date())],
  isInvoicesLoading: false,
  invoices: [],
  selectedInvoicesRange: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
  invoicesLimit: 10,
  invoicesPage: 0,
  invoicesTotal: 0,
  invoicesOrderBy: 'created_at',
  invoicesOrder: 'desc',
  quotes: [],
  searchQuotesText: '',
  selectedQuotesRange: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
  quotesLimit: 10,
  quotesPage: 0,
  quotesTotal: 0,
  quotesOrderBy: 'created_at',
  quotesOrder: 'desc',
  jobs: [],
  searchJobsText: '',
  selectedJobsRange: [startOfDay(subMonths(new Date(), 3)), endOfDay(new Date())],
  jobsLimit: 10,
  jobsPage: 0,
  jobsTotal: 0,
  jobsOrderBy: 'created_at',
  jobsOrder: 'desc',

  equipmentIdsDeletingInProgress: [],
};

const slice = createSlice({
  name: 'poolDetail',
  initialState,
  reducers: {
    reset: () => initialState,
    addContact(state: PoolDetailState, action: PayloadAction<{ contact: Contact }>): void {
      const { contact } = action.payload;
      const exists = state.contacts.find((_contact) => _contact.id === contact.id);

      if (!exists) {
        state.contacts.push(
          merge({}, contact, {
            pivot: {
              address_type_id: OWNER,
              contact_id: contact.id,
            },
          })
        );
      }
    },
    updateContact(state: PoolDetailState, action: PayloadAction<{ contact: Contact }>): void {
      const { contact } = action.payload;

      state.contacts = state.contacts.map((_contact) => {
        if (_contact.id === contact.id) {
          return merge({}, contact, { pivot: _contact.pivot });
        }

        return _contact;
      });
    },
    updateContactRelation(
      state: PoolDetailState,
      action: PayloadAction<{ contactId: number; relationId: number }>
    ): void {
      const { contactId, relationId } = action.payload;

      state.contacts = state.contacts.map((_contact) => {
        if (_contact.id === contactId) {
          _contact.pivot.address_type_id = relationId;
          return _contact;
        }

        return _contact;
      });
    },
    unlinkContact(state: PoolDetailState, action: PayloadAction<{ contactId: number }>): void {
      const { contactId } = action.payload;

      state.contacts = state.contacts.filter((contact) => contact.id !== contactId);
    },
    selectHistoryRange(
      state: PoolDetailState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedHistoryRange = dateRange;
    },
    resetHistoryDateRange(state: PoolDetailState): void {
      state.selectedHistoryRange = initialState.selectedHistoryRange;
    },
    getInvoices(
      state: PoolDetailState,
      action: PayloadAction<{ invoices: Invoice[]; meta: Meta }>
    ) {
      const { invoices, meta } = action.payload;

      state.invoices = invoices;
      state.invoicesTotal = meta.total;
      state.isInvoicesLoading = false;
    },
    setInvoicesLimit(state: PoolDetailState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.invoicesLimit = limit;
      state.invoicesPage = 0;
      state.invoicesTotal = 0;
    },
    setInvoicesPage(state: PoolDetailState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.invoicesPage = page;
    },
    setIsInvoicesLoading(state: PoolDetailState, action: PayloadAction<{ isLoading: boolean }>) {
      const { isLoading } = action.payload;

      state.isInvoicesLoading = isLoading;
    },
    setInvoicesOrder(
      state: PoolDetailState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.invoicesOrderBy = orderBy;
      state.invoicesOrder = order;
    },
    selectInvoicesRange(
      state: PoolDetailState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedInvoicesRange = dateRange;
    },
    resetInvoicesDateRange(state: PoolDetailState): void {
      state.selectedInvoicesRange = initialState.selectedInvoicesRange;
    },
    startDeletingEquipment(state: PoolDetailState, action: PayloadAction<{ equipmentId: number }>) {
      state.equipmentIdsDeletingInProgress = [
        ...state.equipmentIdsDeletingInProgress,
        action.payload.equipmentId,
      ];
    },
    stopDeletingEquipment(state: PoolDetailState, action: PayloadAction<{ equipmentId: number }>) {
      state.equipmentIdsDeletingInProgress = state.equipmentIdsDeletingInProgress.filter(
        (id) => id !== action.payload.equipmentId
      );
    },
    deleteEquipment(state: PoolDetailState, action: PayloadAction<{ equipmentId: number }>) {
      state.pool.equipments = state.pool.equipments.filter(
        (equipment) => equipment.id !== action.payload.equipmentId
      );
    },
    setQuotesSearchText(state: PoolDetailState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchQuotesText = searchText;
      state.quotesPage = 0;
    },
    setQuotesLimit(state: PoolDetailState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.quotesLimit = limit;
      state.quotesPage = 0;
      state.quotesTotal = 0;
    },
    setQuotesPage(state: PoolDetailState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.quotesPage = page;
    },
    setQuotesOrder(
      state: PoolDetailState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.quotesOrderBy = orderBy;
      state.quotesOrder = order;
    },
    selectQuotesRange(
      state: PoolDetailState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedQuotesRange = dateRange;
    },
    resetQuotesDateRange(state: PoolDetailState): void {
      state.selectedQuotesRange = initialState.selectedQuotesRange;
    },
    setQuotesMeta(state: PoolDetailState, action: PayloadAction<{ meta: Meta }>) {
      const { meta } = action.payload;

      state.quotesTotal = meta.total;
    },
    setJobsSearchText(state: PoolDetailState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchJobsText = searchText;
      state.jobsPage = 0;
    },
    setJobsLimit(state: PoolDetailState, action: PayloadAction<{ limit: number }>) {
      const { limit } = action.payload;

      state.jobsLimit = limit;
      state.jobsPage = 0;
      state.jobsTotal = 0;
    },
    setJobsPage(state: PoolDetailState, action: PayloadAction<{ page: number }>) {
      const { page } = action.payload;

      state.jobsPage = page;
    },
    setJobsOrder(
      state: PoolDetailState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.jobsOrderBy = orderBy;
      state.jobsOrder = order;
    },
    selectJobsRange(
      state: PoolDetailState,
      action: PayloadAction<{ dateRange: DateRange<Date> }>
    ): void {
      const { dateRange } = action.payload;
      state.selectedJobsRange = dateRange;
    },
    resetJobsDateRange(state: PoolDetailState): void {
      state.selectedJobsRange = initialState.selectedJobsRange;
    },
    setJobsMeta(state: PoolDetailState, action: PayloadAction<{ meta: Meta }>) {
      const { meta } = action.payload;

      state.jobsTotal = meta.total;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(poolApi.endpoints.getPool.matchFulfilled, (state, action) => {
      const pool = action.payload;
      if (state.pool?.id !== pool.id) {
        state.contacts = pool.contacts;
      }
      state.pool = pool;
    });
  },
});

export const { reducer } = slice;

export const {
  reset,
  resetJobsDateRange,
  resetHistoryDateRange,
  resetInvoicesDateRange,
  resetQuotesDateRange,
} = slice.actions;

export const addContact =
  (contact: Contact): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.addContact({ contact }));
  };

export const unlinkContact =
  (contactId: number): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.unlinkContact({ contactId }));
  };

export const updateContact =
  (contact: Contact): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateContact({ contact }));
  };

export const updateContactRelation =
  (contactId: number, relationId: number): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.updateContactRelation({ contactId, relationId }));
  };

export const selectHistoryRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectHistoryRange({ dateRange }));
  };

export const getPoolInvoices =
  (
    organisationId: number,
    poolId: number,
    selectedRange: DateRange<Date>,
    limit: number,
    page: number,
    sortBy = ''
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setIsInvoicesLoading({ isLoading: true }));
    const from = format(selectedRange[0], 'yyyy-MM-dd HH:mm:ss');
    const to = format(selectedRange[1], 'yyyy-MM-dd HH:mm:ss');
    const response = await axios.get(
      `v2/organisations/${organisationId}/contact-invoice?pool_id=${poolId}&from=${from}&to=${to}&limit=${limit}&order=${sortBy}&page=${
        page + 1
      }`
    );

    dispatch(slice.actions.getInvoices({ invoices: response.data.data, meta: response.data.meta }));
  };

export const setInvoicesLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setInvoicesLimit({ limit }));
};

export const setInvoicesPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setInvoicesPage({ page }));
};

export const setInvoicesOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setInvoicesOrder({ orderBy, order }));
};

export const selectInvoicesRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectInvoicesRange({ dateRange }));
  };

export const setQuotesSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setQuotesSearchText({ searchText }));
};

export const setQuotesLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setQuotesLimit({ limit }));
};

export const setQuotesPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setQuotesPage({ page }));
};

export const setQuotesOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setQuotesOrder({ orderBy, order }));
};

export const selectQuotesRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectQuotesRange({ dateRange }));
  };

export const setQuotesMeta = (meta: Meta) => (dispatch) => {
  dispatch(slice.actions.setQuotesMeta({ meta }));
};

export const setJobsSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setJobsSearchText({ searchText }));
};

export const setJobsLimit = (limit: number) => (dispatch) => {
  dispatch(slice.actions.setJobsLimit({ limit }));
};

export const setJobsPage = (page: number) => (dispatch) => {
  dispatch(slice.actions.setJobsPage({ page }));
};

export const setJobsOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setJobsOrder({ orderBy, order }));
};

export const selectJobsRange =
  (dateRange: DateRange<Date>): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.selectJobsRange({ dateRange }));
  };

export const setJobsMeta = (meta: Meta) => (dispatch) => {
  dispatch(slice.actions.setJobsMeta({ meta }));
};

export default slice;

export const deleteEquipment =
  (organisationId: number, equipmentId: number): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.startDeletingEquipment({ equipmentId }));
    try {
      await axios.delete(`v1/organisations/${organisationId}/equipments/${equipmentId}`);
      dispatch(slice.actions.deleteEquipment({ equipmentId }));
    } finally {
      dispatch(slice.actions.stopDeletingEquipment({ equipmentId }));
    }
  };
