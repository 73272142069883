import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios from 'axios';
import { ContactInvoice } from '../types/resend-invoice';
import { parseBoolByStr } from 'src/utils/bool';
import type { InvoiceSettings } from '../types/invoice-settings';
import type { Contact } from '../types/contact';

interface ResendInvoiceState {
  isLoading: boolean;
  isSending: boolean;
  isSent: boolean;

  contactInvoice?: ContactInvoice;
  contact?: Contact;
  subjectMessage: string;
  introMessage: string;
  ccEmails: string[];

  tasksLists: boolean;
  waterResult: boolean;
  chemicalHistory: boolean;
  chemicalActions?: boolean;
  displayInvoiceNotes: boolean;
  isJobSheetAttached: boolean;
  isInvoiceSheetAttached: boolean;

  chemicalActionsDefault: boolean;
}

const initialState: ResendInvoiceState = {
  isLoading: true,
  isSending: false,
  isSent: false,

  contactInvoice: null,
  contact: null,
  subjectMessage: '',
  introMessage: '',
  ccEmails: [],

  tasksLists: false,
  waterResult: false,
  chemicalHistory: false,
  chemicalActions: false,
  displayInvoiceNotes: false,
  isJobSheetAttached: false,
  isInvoiceSheetAttached: false,

  chemicalActionsDefault: false,
};

const slice = createSlice({
  name: 'resendInvoice',
  initialState,
  reducers: {
    reset: () => initialState,
    load(state: ResendInvoiceState) {
      state.isLoading = true;
    },
    initialize(
      state: ResendInvoiceState,
      action: PayloadAction<{ contactInvoice: ContactInvoice; invoiceSettings: InvoiceSettings }>
    ) {
      const { contactInvoice, invoiceSettings } = action.payload;

      state.contactInvoice = contactInvoice;
      state.contact = contactInvoice.contact;
      state.ccEmails = initialState.ccEmails;
      state.subjectMessage = contactInvoice.subject_message;
      state.introMessage = contactInvoice.contact_intro;

      // TODO: update backend and remove this
      state.tasksLists = parseBoolByStr(invoiceSettings.settings.tasks_list);
      state.waterResult = parseBoolByStr(invoiceSettings.settings.water_result);
      state.chemicalHistory = parseBoolByStr(invoiceSettings.settings.chemical_history);
      state.chemicalActions = parseBoolByStr(invoiceSettings.settings.chemical_actions);
      state.chemicalActionsDefault = state.chemicalActions;
      state.displayInvoiceNotes = parseBoolByStr(invoiceSettings.settings.display_invoice_notes);
      state.isJobSheetAttached = parseBoolByStr(contactInvoice.is_job_sheet_attached);
      state.isInvoiceSheetAttached = parseBoolByStr(contactInvoice.is_invoice_sheet_attached);
      state.isLoading = false;
    },
    startSending(state: ResendInvoiceState) {
      state.isSending = true;
    },
    sent(state: ResendInvoiceState) {
      state.isSent = true;
      state.isSending = false;
    },
    setSubjectMessage(state: ResendInvoiceState, action: PayloadAction<{ message: string }>) {
      state.subjectMessage = action.payload.message;
    },
    setIntroMessage(state: ResendInvoiceState, action: PayloadAction<{ message: string }>) {
      state.introMessage = action.payload.message;
    },
    setCCEmails(state: ResendInvoiceState, action: PayloadAction<{ emails: string[] }>) {
      state.ccEmails = action.payload.emails;
    },

    setTasksList(state: ResendInvoiceState, action: PayloadAction<{ value: boolean }>) {
      state.tasksLists = action.payload.value;
    },
    setWaterResult(state: ResendInvoiceState, action: PayloadAction<{ value: boolean }>) {
      state.waterResult = action.payload.value;
    },
    setChemicalHistory(state: ResendInvoiceState, action: PayloadAction<{ value: boolean }>) {
      state.chemicalHistory = action.payload.value;
    },
    setChemicalActions(state: ResendInvoiceState, action: PayloadAction<{ value: boolean }>) {
      state.chemicalActions = action.payload.value;
    },
    setDisplayInvoiceNotes(state: ResendInvoiceState, action: PayloadAction<{ value: boolean }>) {
      state.displayInvoiceNotes = action.payload.value;
    },
    setIsJobSheetAttached(state: ResendInvoiceState, action: PayloadAction<{ value: boolean }>) {
      state.isJobSheetAttached = action.payload.value;
    },
    setIsInvoiceSheetAttached(
      state: ResendInvoiceState,
      action: PayloadAction<{ value: boolean }>
    ) {
      state.isInvoiceSheetAttached = action.payload.value;
    },
  },
});

export default slice;

export const { reducer } = slice;
export const {
  reset,
  setSubjectMessage,
  setIntroMessage,
  setCCEmails,
  setTasksList,
  setWaterResult,
  setChemicalHistory,
  setChemicalActions,
  setDisplayInvoiceNotes,
  setIsJobSheetAttached,
  setIsInvoiceSheetAttached,
} = slice.actions;

export const initialize =
  (organisationId: number, invoiceId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.load());
    const response = await axios.get(
      `v1/organisations/${organisationId}/contactinvoice/${invoiceId}`
    );
    const settingsResponse = await axios.get(`v1/organisations/${organisationId}/invoice_settings`);
    dispatch(
      slice.actions.initialize({
        contactInvoice: response.data,
        invoiceSettings: settingsResponse.data,
      })
    );
  };

export const resend =
  (organisationId: number, state: ResendInvoiceState): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startSending());
    const emails = Array.from(new Set([...state.ccEmails, state.contactInvoice.contact.email]));

    const payload = {
      recipients: emails.map((email) => ({ email })),
      description: state.introMessage,
      subject_message: state.subjectMessage,
      isTaskDisp: state.tasksLists,
      isWatertestDisp: state.waterResult,
      isChemicalHistoryDisp: state.chemicalHistory,
      isChemicalActionsDisp: state.waterResult
        ? state.chemicalActions
        : state.chemicalActionsDefault,
      isInvoiceDisp: state.isInvoiceSheetAttached,
      job_sheet_invoice_notes_display: state.isJobSheetAttached,
    };

    const response = await axios.post(
      `v1/organisations/${organisationId}/contactinvoice/${state.contactInvoice.id}/resend`,
      payload
    );
    if (response.status === 200) {
      await dispatch(slice.actions.sent());
    }
  };
