export const RECURRENCE_COLUMN_ID = 'id';
export const RECURRENCE_COLUMN_CONTACT = 'contact_id';
export const RECURRENCE_COLUMN_TECHNICIAN = 'user_id';
export const RECURRENCE_COLUMN_POOL = 'pool_id';
export const RECURRENCE_COLUMN_JOB_TEMPLATE = 'job_template_id';
export const RECURRENCE_COLUMN_PATTERN = 'recurrence_text';
export const CUSTOM_RECURRENCE_COLUMN_PATTERN = 'custom_recurrence_text';
export const RECURRENCE_COLUMN_NEXT_DATE = 'next_date';
export const RECURRENCE_COLUMN_END_DATE = 'end_date';

export const CURRENT_STATUS = 'current';
export const RECENTLY_ENDED_STATUS = 'ended';
export const ENDING_SOON_STATUS = 'ending';
