import { firebaseConfig } from '../config';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const analytics = () => {
  if (typeof window !== 'undefined') {
    return firebase.analytics();
  } else {
    return null;
  }
};

export const firestore = () => {
  if (typeof window !== 'undefined') {
    return firebase.firestore();
  } else {
    return null;
  }
};

export default firebase;
