import each from 'lodash/each';
import { providesList } from 'src/utils/rtk';
import {
  IAdd,
  IChangeVisibility,
  IDeleteProduct,
  IProduct,
  IGetProducts,
  IUpdateBulk,
  IBrand,
  IGetBrands,
  IEditBrand,
  IAddBrand,
  IDeleteBrand,
  IImportProduct,
} from './model/product.model';
import { Category, Metric, Product } from 'src/types/product';
import { PRODUCT_TAG_TYPE } from 'src/constants/tag';
import { rootSplitApi } from './root';
import { Meta } from 'src/types/pagination';

export const productApi = rootSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query<{ data: Product[]; meta: Meta }, IGetProducts>({
      query: (param: IGetProducts) => {
        const { organisationId, catFilter, filter, filterBy, limit, sortBy, page, filterModel } =
          param;
        const url = `v2/organisation/${organisationId}/product-list`;
        const body: { [key: string]: any } = {
          limit,
          order: sortBy,
          page,
          filterModel,
        };

        if (catFilter) {
          body.category_id = catFilter;
        }

        if (filter) {
          if (filterBy) {
            body.filterBy = filter;
          } else {
            body.filter = filter;
          }
        }

        return {
          url,
          method: 'POST',
          body,
        };
      },
      providesTags: (result) => providesList(result?.data, 'Products'),
    }),

    getProduct: builder.query<any, IProduct>({
      query: (param: IProduct) => `v1/organisations/${param.organisationId}/products/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'Product', id: param.id }],
    }),
    changeProductVisibility: builder.mutation<any, IChangeVisibility>({
      query: (param: IChangeVisibility) => ({
        url: `v1/organisations/${param.organisationId}/products/${param.id}/visibility`,
        method: 'PUT',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const result = productApi.util.selectInvalidatedBy(getState(), [
          { type: 'Products', id: param.id },
        ]);
        if (result && result.length) {
          each(result, async ({ endpointName, originalArgs }) => {
            if (endpointName && endpointName === 'getProducts' && originalArgs) {
              const patchResult = dispatch(
                // @ts-ignore
                productApi.util.updateQueryData('getProducts', originalArgs, (products) => {
                  products.data.forEach((product) => {
                    if (product.id === param.id) {
                      Object.assign(product, param.body);
                    }
                  });
                })
              );
              try {
                await queryFulfilled;
              } catch {
                patchResult.undo();
              }
            }
          });
        }
      },
      invalidatesTags: (result, error, param) => [{ type: 'Product', id: param.id }],
    }),
    updateProductBulk: builder.mutation<any, IUpdateBulk>({
      query: (param: IUpdateBulk) => ({
        url: `v2/organisations/${param.organisationId}/products/bulk-update`,
        method: 'POST',
        body: param.body,
      }),
      async onQueryStarted({ ...param }, { dispatch, queryFulfilled, getState }) {
        const list = param.body.products;
        const { action } = param.body;
        if (action === 'delete') {
          await queryFulfilled;
          dispatch(productApi.util.invalidateTags([{ type: 'Products', id: 'LIST' }]));
        } else {
          const result = productApi.util.selectInvalidatedBy(
            getState(),
            list?.map(({ id }) => ({ type: 'Products' as const, id }))
          );
          if (result && result.length) {
            each(result, async ({ endpointName, originalArgs }) => {
              if (endpointName && endpointName === 'getProducts' && originalArgs) {
                const patchResult = dispatch(
                  // @ts-ignore
                  productApi.util.updateQueryData('getProducts', originalArgs, (products) => {
                    products.data.forEach((product) => {
                      list?.forEach(({ id }) => {
                        if (product.id === id) {
                          Object.assign(product, { visibility: action === 'visible' });
                        }
                      });
                    });
                  })
                );
                try {
                  await queryFulfilled;
                } catch {
                  patchResult.undo();
                }
              }
            });
          }
        }
      },
      invalidatesTags: [{ type: 'Tags', id: `LIST_TYPE_${PRODUCT_TAG_TYPE}` }],
    }),
    deleteProduct: builder.mutation<any, IDeleteProduct>({
      query: (param: IDeleteProduct) => ({
        url: `v1/organisations/${param.organisationId}/products/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Product', id: param.id },
        { type: 'Products', id: param.id },
        { type: 'Tags', id: `LIST_TYPE_${PRODUCT_TAG_TYPE}` },
      ],
    }),
    editProduct: builder.mutation<any, IProduct>({
      query: (param: IProduct) => ({
        url: `v1/organisations/${param.organisationId}/products/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          productApi.util.updateQueryData(
            'getProduct',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Product', id: param.id },
        { type: 'Products', id: param.id },
        { type: 'Tags', id: `LIST_TYPE_${PRODUCT_TAG_TYPE}` },
      ],
    }),
    addProduct: builder.mutation<Product, IAdd>({
      query: (param: IAdd) => ({
        url: `v1/organisations/${param.organisationId}/products`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [
        { type: 'Products', id: 'LIST' },
        { type: 'Tags', id: `LIST_TYPE_${PRODUCT_TAG_TYPE}` },
      ],
    }),
    // getBrands: builder.query<Brand[], number>({
    //   query: (id) => `v1/organisations/${id}/productsbrands?orderBy=name`,
    //   providesTags: () => [{ type: 'Brands', id: 'LIST' }],
    // }),
    getBrand: builder.query<any, IBrand>({
      query: (param: IBrand) => `v2/organisation/${param.organisationId}/product-brand/${param.id}`,
      providesTags: (result, error, param) => [{ type: 'Brand', id: param.id }],
    }),
    getBrands: builder.query<any, IGetBrands>({
      query: (param: IGetBrands) =>
        `v2/organisation/${param.organisationId}/product-brand?orderBy=name`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Brands' as const, id })),
              { type: 'Brands', id: 'LIST' },
            ]
          : [{ type: 'Brands', id: 'LIST' }],
    }),
    editBrand: builder.mutation<any, IEditBrand>({
      query: (param: IEditBrand) => ({
        url: `v2/organisation/${param.organisationId}/product-brand/${param.id}`,
        method: 'put',
        body: param.body,
      }),
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          productApi.util.updateQueryData(
            'getBrand',
            {
              organisationId: patch.organisationId,
              id: patch.id,
            },
            (draft) => {
              Object.assign(draft, patch.body);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Brand', id: param.id },
        { type: 'Brands', id: param.id },
      ],
    }),
    addBrand: builder.mutation<any, IAddBrand>({
      query: (param: IAddBrand) => ({
        url: `v2/organisation/${param.organisationId}/product-brand`,
        method: 'post',
        body: param.body,
      }),
      invalidatesTags: [{ type: 'Brands', id: 'LIST' }],
    }),
    deleteBrand: builder.mutation<any, IDeleteBrand>({
      query: (param: IDeleteBrand) => ({
        url: `v2/organisation/${param.organisationId}/product-brand/${param.id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, param) => [
        { type: 'Brand', id: param.id },
        { type: 'Brands', id: param.id },
      ],
    }),
    getMetrics: builder.query<Metric[], number>({
      query: (id) => `v2/organisation/${id}/metric?orderBy=name`,
      providesTags: () => [{ type: 'Metrics', id: 'LIST' }],
    }),
    getCategories: builder.query<Category[], number>({
      query: (id) => `v2/organisation/${id}/product-category?orderBy=name`,
      providesTags: () => [{ type: 'Categories', id: 'LIST' }],
    }),
    importProduct: builder.mutation<any, IImportProduct>({
      query: (param: IImportProduct) => {
        const fd = new FormData();
        fd.append('type', 'products');
        fd.append('import_data', param.file);

        return {
          url: `v1/organisations/${param.organisationId}/import`,
          method: 'post',
          body: fd,
        };
      },
      invalidatesTags: (result, error, param) => [
        { type: 'Products', id: 'LIST' },
        { type: 'Tags', id: `LIST_TYPE_${PRODUCT_TAG_TYPE}` },
      ],
    }),
  }),
});
export const {
  useChangeProductVisibilityMutation,
  useDeleteProductMutation,
  useAddProductMutation,
  useEditProductMutation,
  useGetProductQuery,
  useGetProductsQuery,
  useUpdateProductBulkMutation,
  useGetBrandsQuery,
  useGetMetricsQuery,
  useGetCategoriesQuery,
  useAddBrandMutation,
  useEditBrandMutation,
  useGetBrandQuery,
  useDeleteBrandMutation,
  useImportProductMutation,
} = productApi;

export const {
  endpoints: { getBrands, getMetrics, getCategories },
} = productApi;
