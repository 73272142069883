import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import startOfWeek from 'date-fns/startOfWeek';
import addDays from 'date-fns/addDays';
import endOfDay from 'date-fns/endOfDay';
import subDays from 'date-fns/subDays';
import sortBy from 'lodash/sortBy';
import { rescheduleJobs, unscheduleJob } from 'src/api/job';
import { JobEvent } from 'src/types/job';
import { updateJobEventsByRescheduleJobApi } from 'src/utils/job';
import { FORTNIGHT_TYPE, WEEK_TYPE } from 'src/constants/calendar-week-view';

interface CalendarWeekViewState {
  startDate: Date;
  endDate: Date;
  type: string;
  jobEvents: JobEvent[];
  expandedTechnicianIds: number[];
}

const initialState: CalendarWeekViewState = {
  startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
  endDate: endOfDay(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 6)),
  type: WEEK_TYPE,
  jobEvents: [],
  expandedTechnicianIds: [],
};

const slice = createSlice({
  name: 'calendarWeekView',
  initialState,
  reducers: {
    moveFromUnscheduledList(
      state: CalendarWeekViewState,
      action: PayloadAction<{ jobEvent: JobEvent }>
    ): void {
      state.jobEvents = [...state.jobEvents, action.payload.jobEvent];
    },
    setJobEvents(
      state: CalendarWeekViewState,
      action: PayloadAction<{ jobEvents: JobEvent[] }>
    ): void {
      state.jobEvents = action.payload.jobEvents;
    },
    toggleTechnician(state: CalendarWeekViewState, action: PayloadAction<{ id: number }>): void {
      const { id } = action.payload;
      if (state.expandedTechnicianIds.includes(id)) {
        state.expandedTechnicianIds = state.expandedTechnicianIds.filter((techId) => techId !== id);
      } else {
        state.expandedTechnicianIds.push(action.payload.id);
      }
    },
    changeWeekType(
      state: CalendarWeekViewState,
      action: PayloadAction<{ weekType: string }>
    ): void {
      const { weekType } = action.payload;
      state.type = weekType;
      const endDate = endOfDay(addDays(state.startDate, weekType === FORTNIGHT_TYPE ? 13 : 6));
      state.endDate = endDate;
    },
    next(state: CalendarWeekViewState) {
      const startDate = addDays(state.endDate, 1);
      const endDate = endOfDay(addDays(startDate, state.type === FORTNIGHT_TYPE ? 13 : 6));
      state.startDate = startDate;
      state.endDate = endDate;
    },
    prev(state: CalendarWeekViewState) {
      const endDate = endOfDay(subDays(state.startDate, 1));
      const startDate = subDays(endDate, state.type === FORTNIGHT_TYPE ? 13 : 6);
      state.startDate = startDate;
      state.endDate = endDate;
    },
    goToCurrentWeek(state: CalendarWeekViewState) {
      const startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
      const endDate = endOfDay(
        addDays(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          state.type === FORTNIGHT_TYPE ? 13 : 6
        )
      );
      state.startDate = startDate;
      state.endDate = endDate;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(rescheduleJobs.matchPending, (state, action) => {
        const result = updateJobEventsByRescheduleJobApi(
          state.jobEvents,
          action.meta.arg.originalArgs
        );
        state.jobEvents = sortBy(result, 'start_time');
      })
      .addMatcher(unscheduleJob.matchPending, (state, action) => {
        state.jobEvents = state.jobEvents.filter(
          ({ id }) => id !== action.meta.arg.originalArgs.id
        );
      });
  },
});

export const { reducer } = slice;
export const {
  next,
  prev,
  goToCurrentWeek,
  moveFromUnscheduledList,
  setJobEvents,
  toggleTechnician,
  changeWeekType,
} = slice.actions;

export default slice;
